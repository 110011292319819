import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { State } from '../../core/store'
import {
  PartialCustomDimensions,
  TrackAction,
  TrackingData,
} from '../constants/matomo/tracking.model'
import { firstValueFrom } from 'rxjs'
import { selectTrackingData } from '../../core/store/router/router.selectors'
import { MatomoAnalyticsService } from './matomo-analytics.service'

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(
    private store: Store<State>,
    private matomoAnalyticsService: MatomoAnalyticsService,
  ) {}

  private getTrackingData(): Promise<TrackingData> {
    return firstValueFrom(this.store.select(selectTrackingData))
  }

  async sendVisitAnalytics(pageTitle: string): Promise<void> {
    const trackingData = await this.getTrackingData()
    if (trackingData?.userId) {
      this.matomoAnalyticsService.trackVisit(pageTitle, trackingData)
    }
  }

  sendActionAnalytics(
    eventCategory: string,
    eventAction: string,
    eventLabel: string,
    customDimensions: PartialCustomDimensions = {},
  ): void {
    this.getTrackingData()
      .then(trackingData => {
        const trackActionBuilder = TrackAction.getBuilder()
          .withEventCategory(eventCategory)
          .withEventAction(eventAction)
          .withEventLabel(eventLabel)
          .withTrackingData(trackingData)

        for (const [key, value] of Object.entries(customDimensions)) {
          trackActionBuilder.addCustomDimension(parseInt(key), value)
        }

        this.matomoAnalyticsService.trackAction(trackActionBuilder)
      })
      .catch(error => {
        console.error('Error getting tracking data', error)
      })
  }
}

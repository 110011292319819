import { SyncActionFactory } from 'app/shared/utils/redux/sync.actions'
import { ParameterModel } from '../../models/parameter.model'
import { Stats3DDto } from '../../models/stats.dto'

export class ColumnsStats3dActions {
  public static load3dStats = new SyncActionFactory<{
    parameter: ParameterModel
    jobsiteId: string
    dtos: Stats3DDto[]
  }>('[COLUMNS STATS 3D] LOAD 3D STATS')

  public static set3dParameter = new SyncActionFactory<ParameterModel>(
    '[COLUMNS STATS 3D] SET 3D PARAMETER',
  )
}

import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, of } from 'rxjs'
import {
  BuildInfosDto,
  EnvInfosDto,
  ServerInfosDto,
} from './dtos/serverInfos.dto'
import { catchError, switchMap } from 'rxjs/operators'

@Injectable()
export class ServerInfosService {
  constructor(private http: HttpClient) {}

  public getServerEquipmentInfo(): Observable<ServerInfosDto> {
    return this.getServerInfo('server-equipment', 'equipment')
  }

  public getServerExportInfo(): Observable<ServerInfosDto> {
    return this.getServerInfo('server-export', 'export')
  }

  public getServerGraphInfo(): Observable<ServerInfosDto> {
    return this.getServerInfo('server-graph', 'charts')
  }

  public getServerJobsiteInfo(): Observable<ServerInfosDto> {
    return this.getServerInfo('server-jobsite', 'jobsite')
  }

  public getServerPreparationInfo(): Observable<ServerInfosDto> {
    return this.getServerInfo('server-preparation', 'preparation')
  }

  public getServerRestPilesInfo(): Observable<ServerInfosDto> {
    return this.getServerInfo('server-rest', 'rest/PILES')
  }

  public getServerRestHFInfo(): Observable<ServerInfosDto> {
    return this.getServerInfo('server-rest', 'rest/HF')
  }

  public getServerSoillibInfo(): Observable<ServerInfosDto> {
    return this.getServerInfo('server-soillib', 'soillib')
  }

  public getServerUserInfo(): Observable<ServerInfosDto> {
    return this.getServerInfo('server-user', 'user')
  }

  private getServerInfo(
    serverName: string,
    path: string,
  ): Observable<ServerInfosDto> {
    return this.http.get<ServerInfosDto>(`${path}/about`).pipe(
      switchMap(value =>
        !!value && value.build?.name != null && value.build?.version != null
          ? of(value)
          : of(this.getDefaultResult(serverName)),
      ),
      catchError(() => of(this.getDefaultResult(serverName))),
    )
  }

  private getDefaultResult(serverName: string): ServerInfosDto {
    const result = new ServerInfosDto()
    result.build = new BuildInfosDto(serverName)
    result.env = new EnvInfosDto()
    return result
  }
}

/*
 * Generated type guards for "matomo-custom-dimensions.enum.ts".
 * WARNING: Do not manually change this file.
 */
import { MatomoCustomDimensions } from './matomo-custom-dimensions.enum'

export function isMatomoCustomDimensions(
  obj: unknown,
): obj is MatomoCustomDimensions {
  const typedObj = obj as MatomoCustomDimensions
  return (
    typedObj === MatomoCustomDimensions.VISIT_USER_ID ||
    typedObj === MatomoCustomDimensions.VISIT_BUSINESS_UNIT ||
    typedObj === MatomoCustomDimensions.ACTION_USER_ID ||
    typedObj === MatomoCustomDimensions.ACTION_BUSINESS_UNIT ||
    typedObj === MatomoCustomDimensions.ACTION_TECHNIQUE_NAME ||
    typedObj === MatomoCustomDimensions.ACTION_JOBSITE_ID ||
    typedObj === MatomoCustomDimensions.ACTION_DATA ||
    typedObj === MatomoCustomDimensions.ACTION_DATA_DESCRIPTION ||
    typedObj === MatomoCustomDimensions.ACTION_UNIT_SYSTEM ||
    typedObj === MatomoCustomDimensions.ACTION_PILE_ID
  )
}

import { ChangeDetectionStrategy, Component } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { State } from '../../../../core/store'
import { UploadFileModel } from '../../models/upload-file.model'
import { FilesUploadActions } from '../../store/files-upload.actions'
import {
  getFiles,
  getNbWaitingFiles,
  isExpanded,
  isVisible,
} from '../../store/files-upload.selectors'

// FIXME: Does not seem to be used, maybe to delete
@Component({
  selector: 'uploader-files-container',
  template: `
    <uploader-files
      [files]="files$ | async"
      [isExpanded]="isExpanded$ | async"
      [nbWaitingFiles]="nbWaitingFiles$ | async"
      [isVisible]="isVisible$ | async"
      (setIsExpandedEvent)="setIsExpanded($event)"
      (closeEvent)="close()"
      (cancelFileEvent)="cancelFile($event)"
      (retryFileEvent)="retryFile($event)"
    >
    </uploader-files>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploaderFilesContainerComponent {
  files$ = this.store.pipe(select(getFiles))
  isExpanded$ = this.store.pipe(select(isExpanded))
  nbWaitingFiles$ = this.store.pipe(select(getNbWaitingFiles))
  isVisible$ = this.store.pipe(select(isVisible))

  constructor(private store: Store<State>) {}

  setIsExpanded(isExpand: boolean): void {
    this.store.dispatch(FilesUploadActions.setIsExpanded.create(isExpand))
  }

  close(): void {
    this.store.dispatch(FilesUploadActions.setVisible.create(false))
  }

  cancelFile(fileId: string): void {
    this.store.dispatch(FilesUploadActions.files.createRemoveIds([fileId]))
  }

  retryFile(file: UploadFileModel): void {
    this.store.dispatch(
      FilesUploadActions.files.createComplete([{ ...file, inError: false }]),
    )
  }
}

import * as Guards from './jobsite-tabs.model.type.guard'
import { Mode } from '../store/state'
import { TechniqueNames } from '../../../shared/remote-services/dtos/technique.dto'

const jobsiteTabsModel = [
  'follow-up',
  'productivity',
  'manual-records',
  'visualization',
  'data-table',
  'daily-diary',
  'kpi',
  'information',
  'equipments',
  'instructions',
  'cage',
  'concrete',
  'soillib-data',
  'hf-energy',
  'data-science',
  'data-science-dwall',
] as const
export type JobsiteTab = (typeof jobsiteTabsModel)[number]

/** @see {isTabDetail} ts-auto-guard:type-guard */
export interface TabDetail {
  path: JobsiteTab
  mode: Mode
  optional: boolean
  techniques: {
    name: TechniqueNames
    position: number
  }[]
}

export const appTabs: TabDetail[] = [
  {
    path: 'information',
    mode: 'EDITING',
    optional: false,
    techniques: [
      {
        name: 'PILES',
        position: 0,
      },
      {
        name: 'HF',
        position: 0,
      },
    ],
  },
  {
    path: 'equipments',
    mode: 'EDITING',
    optional: false,
    techniques: [
      {
        name: 'PILES',
        position: 1,
      },
      {
        name: 'HF',
        position: 1,
      },
    ],
  },
  {
    path: 'instructions',
    mode: 'EDITING',
    optional: false,
    techniques: [
      {
        name: 'PILES',
        position: 2,
      },
      {
        name: 'HF',
        position: 2,
      },
    ],
  },
  {
    path: 'follow-up',
    mode: 'FOLLOWING',
    optional: false,
    techniques: [
      {
        name: 'PILES',
        position: 0,
      },
    ],
  },
  {
    path: 'productivity',
    mode: 'FOLLOWING',
    optional: false,
    techniques: [
      {
        name: 'PILES',
        position: 1,
      },
      {
        name: 'HF',
        position: 0,
      },
    ],
  },
  {
    path: 'manual-records',
    mode: 'FOLLOWING',
    optional: true,
    techniques: [
      {
        name: 'HF',
        position: 1,
      },
    ],
  },
  {
    path: 'visualization',
    mode: 'FOLLOWING',
    optional: false,
    techniques: [
      {
        name: 'PILES',
        position: 2,
      },
      {
        name: 'HF',
        position: 2,
      },
    ],
  },
  {
    path: 'data-table',
    mode: 'FOLLOWING',
    optional: false,
    techniques: [
      {
        name: 'PILES',
        position: 3,
      },
      {
        name: 'HF',
        position: 3,
      },
    ],
  },
  {
    path: 'daily-diary',
    mode: 'FOLLOWING',
    optional: false,
    techniques: [
      {
        name: 'PILES',
        position: 4,
      },
      {
        name: 'HF',
        position: 4,
      },
    ],
  },
  {
    path: 'kpi',
    mode: 'FOLLOWING',
    optional: false,
    techniques: [
      {
        name: 'PILES',
        position: 5,
      },
      {
        name: 'HF',
        position: 5,
      },
    ],
  },
  {
    path: 'cage',
    mode: 'FOLLOWING',
    optional: false,
    techniques: [
      {
        name: 'PILES',
        position: 6,
      },
      {
        name: 'HF',
        position: 6,
      },
    ],
  },
  {
    path: 'concrete',
    mode: 'FOLLOWING',
    optional: false,
    techniques: [
      {
        name: 'PILES',
        position: 7,
      },
      {
        name: 'HF',
        position: 7,
      },
    ],
  },
  {
    path: 'soillib-data',
    mode: 'FOLLOWING',
    optional: true,
    techniques: [
      {
        name: 'PILES',
        position: 8,
      },
      {
        name: 'HF',
        position: 8,
      },
    ],
  },
  {
    path: 'data-science',
    mode: 'FOLLOWING',
    optional: false,
    techniques: [
      {
        name: 'PILES',
        position: 9,
      },
    ],
  },
  {
    path: 'data-science-dwall',
    mode: 'FOLLOWING',
    optional: true,
    techniques: [
      {
        name: 'HF',
        position: 9,
      },
    ],
  },
  {
    path: 'hf-energy',
    mode: 'FOLLOWING',
    optional: false,
    techniques: [
      {
        name: 'HF',
        position: 10,
      },
    ],
  },
]

export { Guards }

import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import {
  CageProdRecord,
  CageTypeDefinition,
  CageTypeSectionDefinition,
} from './dtos/cage.dto'

type InputType<
  DTO extends { id: string },
  KeyToReplace extends keyof DTO = undefined,
  Replacement = { id: string },
  KeyToOmit extends keyof DTO = 'id',
> = Omit<DTO, KeyToOmit | KeyToReplace> & { [k in KeyToReplace]: Replacement }

export type InputCageTypeSectionDefinition =
  InputType<CageTypeSectionDefinition>

export type UpdateCageTypeDefinitionInput = InputType<
  CageTypeDefinition,
  'topSection' | 'middleSection' | 'bottomSection',
  InputCageTypeSectionDefinition,
  'contractNumber'
>

export type AddCageTypeDefinitionInput = Omit<
  UpdateCageTypeDefinitionInput,
  'id'
>

export type UpdateCageProdRecordInput = InputType<
  CageProdRecord,
  'cageTypeDefinition',
  Pick<CageTypeDefinition, 'id'>,
  'contractNumber'
>

export type AddCageProdRecordInput = Omit<UpdateCageProdRecordInput, 'id'>

@Injectable()
export class CageService {
  constructor(private http: HttpClient) {}

  private baseUrl = 'rest/cage'

  public getAllTypeDefByJobsite(
    jobsiteId: string,
  ): Observable<CageTypeDefinition[]> {
    return this.http.get<CageTypeDefinition[]>(
      `${this.baseUrl}/typedef/all/${jobsiteId}`,
    )
  }

  public getAllProdByJobsite(jobsiteId: string): Observable<CageProdRecord[]> {
    return this.http.get<CageProdRecord[]>(
      `${this.baseUrl}/prod/all/${jobsiteId}`,
    )
  }

  public getProdByColumn(columnId: string): Observable<CageProdRecord> {
    return this.http.get<CageProdRecord>(`${this.baseUrl}/prod/${columnId}`)
  }

  public addTypeDef(
    definition: AddCageTypeDefinitionInput,
  ): Observable<CageTypeDefinition> {
    return this.http.post<CageTypeDefinition>(
      `${this.baseUrl}/typedef/add`,
      definition,
    )
  }

  public updateTypeDef(
    record: UpdateCageTypeDefinitionInput,
  ): Observable<CageTypeDefinition> {
    return this.http.put<CageTypeDefinition>(`${this.baseUrl}/typedef`, record)
  }

  public deleteOneTypeDef(typedDefId: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/typedef/${typedDefId}`)
  }

  public addProdRecord(
    record: AddCageProdRecordInput,
  ): Observable<CageProdRecord> {
    return this.http.post<CageProdRecord>(`${this.baseUrl}/prod/add`, record)
  }

  public deleteOneProdRecord(recordId: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/prod/${recordId}`)
  }

  public updateProdRecord(
    record: UpdateCageProdRecordInput,
  ): Observable<CageProdRecord> {
    return this.http.put<CageProdRecord>(`${this.baseUrl}/prod`, record)
  }

  public updateMultipleProdRecord(
    cageId: string,
    pileIds: string[],
  ): Observable<CageProdRecord[]> {
    return this.http.post<CageProdRecord[]>(
      `${this.baseUrl}/prod/${cageId}`,
      pileIds,
    )
  }
}

import { ApplicationModel } from 'app/keycloak/models/user.model'
import { EntitiesActionFactory } from 'app/shared/utils/redux/entities/entities.actions'
import { SyncActionFactory } from 'app/shared/utils/redux/sync.actions'
import { TechniqueDto } from '../../../shared/remote-services/dtos/technique.dto'

export class TechniquesActions {
  public static loadTechniques = new EntitiesActionFactory<TechniqueDto, void>(
    '[CORE] LOAD_TECHNIQUES',
  )
  public static selectApplication = new SyncActionFactory<{
    application: ApplicationModel
    technique?: string
  }>('[CORE] SELECT_APPLICATION')
}

import * as Guards from './action-type.enum.type.guard'

/** @see {isHFSpecificActionType} ts-auto-guard:type-guard */
export enum HFSpecificActionType {
  TRANSLATE = 'translate',
  EDIT = 'edit',
  ADD = 'add',
  DELETE = 'delete',
  ROTATE_VECTOR = 'rotate_vector',
  EDIT_ORDER_PHASING = 'edit_order_phasing',
  RESIZE = 'resize',
  DRAW_LENGTH = 'draw_length',
  DUPLICATE = 'duplicate',
}

/** @see {isPileSpecificActionType} ts-auto-guard:type-guard */
export enum PileSpecificActionType {
  PILE_DUPLICATE = 'pile_duplicate',
}
/** @see {isSharedActionType} ts-auto-guard:type-guard */
export enum SharedActionType {
  RELOCATE = 'relocate',
  RELOCATE_TRANSLATE = 'relocate_translate',
}

/** @see {isHFActionType} ts-auto-guard:type-guard */
export type HFActionType = SharedActionType | HFSpecificActionType

/** @see {isPileActionType} ts-auto-guard:type-guard */
export type PileActionType = SharedActionType | PileSpecificActionType

/** @see {isToolsActionType} ts-auto-guard:type-guard */
export type ToolsActionType = PileActionType | HFActionType | null

export const PileActionTypeValues = {
  ...SharedActionType,
  ...PileSpecificActionType,
}
export const HFActionTypeValues = {
  ...SharedActionType,
  ...HFSpecificActionType,
}

export { Guards }

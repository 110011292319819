import { JobsiteTableDataValues } from '../../../jobsites-management/jobsite-summary/jobsite-data-table/models/jobsite-table-data-values.model'
import { createSelector, MemoizedSelector } from '@ngrx/store'
import { EntitiesSelectorFactory } from '../../../shared/utils/redux/entities/entities.selectors'
import * as core from '../state'
import {
  DailyReportColumnDto,
  DailyReportType,
} from '../../../shared/remote-services/dtos/dailyReportColumn.dto'
import { getCSVExportFilteredColumns } from '../../../jobsites-management/jobsite-summary/jobsite-data-table/store/jobsite-datatable.selectors'
import { State } from '../state'

export const dailyReportColumnsSelectors = new EntitiesSelectorFactory(
  core.getState,
  state => state.dailyReportColumns,
)

export const getDailyReportColumnsByTechnique: (
  reportType: DailyReportType,
) => MemoizedSelector<State, DailyReportColumnDto[]> = reportType =>
  createSelector(
    dailyReportColumnsSelectors.getAll,
    getCSVExportFilteredColumns,
    (
      entities: DailyReportColumnDto[],
      toFilteredColumns: (keyof JobsiteTableDataValues)[],
    ) =>
      entities
        .filter(
          (column: DailyReportColumnDto) =>
            !toFilteredColumns.includes(column.key),
        )
        .filter((column: DailyReportColumnDto) =>
          reportType === 'MANUAL'
            ? !column.onlyForAutomatic
            : !column.onlyForManual,
        ),
  )

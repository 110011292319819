import { Color } from 'ol/color'
import { ColorLike } from 'ol/colorlike'
import { SyncActionFactory } from 'app/shared/utils/redux/sync.actions'
import type { ColoringByDayMapping } from './coloringbyday.reducer'

export class ColoringByDayActions {
  public static setColorMapping = new SyncActionFactory<{
    day: keyof ColoringByDayMapping
    color: ColorLike | Color
  }>('[COLORING BY DAY] SET COLOR MAPPING')
}

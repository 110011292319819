import * as Guards from './jobsite-favorite-kpi.dto.type.guard'
import { JobsiteDto } from '../../../../shared/remote-services/dtos/jobsite.dto'
import {
  StatsOverallRequest,
  StatsType,
  ValueUnit,
} from '../../models/stats.dto'

/** @see {isJobsiteFavoriteKpiType} ts-auto-guard:type-guard */
export type JobsiteFavoriteKpiType =
  | 'FIELDS'
  | 'AMOUNT_OF_DONE_PILES'
  | 'AMOUNT_OF_PARTIALLY_DONE_PILES'
  | 'AMOUNT_OF_LINEAR_METER'
  | 'AMOUNT_OF_VOLUME'
  | 'AMOUNT_OF_AREA_SIMPLE'
  | 'AMOUNT_OF_RECORDS'
  | 'AMOUNT_OF_WORKED_DAYS'

export class FavoriteKpiDto {
  id?: string | null
  type: JobsiteFavoriteKpiType
  fieldId?: string | null
  operator?: StatsType | null

  constructor(type: JobsiteFavoriteKpiType) {
    this.type = type
  }
}

/** @see {isJobsiteFavoriteKpiDto} ts-auto-guard:type-guard */
export interface JobsiteFavoriteKpiDto extends FavoriteKpiDto {
  jobsiteId?: string
}

/** @see {isDisplayedFavoriteKPIDto} ts-auto-guard:type-guard */
export interface DisplayedFavoriteKPIDto extends JobsiteFavoriteKpiDto {
  translateKey: string
  value?: ValueUnit<number>
}

/** @see {isDisplayedFavoriteKpiPayload} ts-auto-guard:type-guard */
export interface DisplayedFavoriteKpiPayload {
  jobsite: JobsiteDto
  fieldsKpi: StatsOverallRequest[]
  globalKpi: string[]
}

export { Guards }

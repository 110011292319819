import { SyncActionFactory } from '../../../../../shared/utils/redux/sync.actions'
import { LocationDto } from '../../../../../shared/remote-services/dtos/location.dto'

export class JobsiteRelocateAction {
  public static startJobsiteRelocate = new SyncActionFactory<void>(
    '[JOBSITE RELOCATE] START',
  )

  public static setJobsiteRelocateStep = new SyncActionFactory<number | null>(
    '[JOBSITE RELOCATE] SET STEP',
  )

  public static saveRelocation = new SyncActionFactory<LocationDto>(
    '[JOBSITE RELOCATE] SAVE',
  )
}

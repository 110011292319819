import { EntitiesState } from 'app/shared/utils/redux/entities/entities.state'
import { TypedAction } from 'app/shared/utils/redux/loadable/loadable.actions'
import { produce } from 'immer'
import { MapSettingsModel } from '../../models/map-settings.model'
import { MapSettingsActions } from './map-settings.actions'

export type MapSettingsState = EntitiesState<MapSettingsModel>

export const initialState: MapSettingsState = {
  loaded: false,
  loading: false,
  value: {},
}

export function reducer(
  state = initialState,
  action: TypedAction<MapSettingsModel>,
): MapSettingsState {
  switch (action.type) {
    case MapSettingsActions.updateMapSettings.CREATE: {
      return produce(state, draft => {
        draft.value[action.payload.id] = {
          radius: 200,
          ...draft.value[action.payload.id],
          ...action.payload,
        }
      })
    }
  }
  return state
}

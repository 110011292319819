import * as Guards from './analytics-event-keys.enum.type.guard'
/** @see {isAnalyticsEventKeys} ts-auto-guard:type-guard */
export enum AnalyticsEventKeys {
  EXPORT_FILE_DOWNLOAD = 'EXPORT.FILE_DOWNLOAD',
  APPLICATION_LIST = 'APPLICATION.LIST',
  AS_BUILT_TABLE_TEMPLATE_SETTINGS = 'AS_BUILT.TABLE_TEMPLATE_SETTINGS',
  CHART_MAKER_COLUMN_VISUALIZATION = 'CHART_MAKER.COLUMN_VISUALIZATION',
  CHART_MAKER_TEMPLATE_CREATION = 'CHART_MAKER.TEMPLATE_CREATION',
  CHART_MAKER_TEMPLATE_VISUALIZATION = 'CHART_MAKER.TEMPLATE_VISUALIZATION',
  JOBSITE_CREATION = 'JOBSITE.CREATION',
  JOBSITE_CAGE = 'JOBSITE.CAGE',
  JOBSITE_CONCRETE = 'JOBSITE.CONCRETE',
  JOBSITE_DAILY_DIARY = 'JOBSITE.DAILY_DIARY',
  JOBSITE_DATA_ANALYSIS_PILING = 'JOBSITE.DATA_ANALYSIS_PILING',
  JOBSITE_DATA_ANALYSIS_DWALL = 'JOBSITE.DATA_ANALYSIS_DWALL',
  JOBSITE_AS_BUILT = 'JOBSITE.AS_BUILT',
  JOBSITE_ENERGY_DATA = 'JOBSITE.ENERGY_DATA',
  JOBSITE_EQUIPMENT_SELECTION = 'JOBSITE.EQUIPMENT_SELECTION',
  JOBSITE_FOLLOW_UP = 'JOBSITE.FOLLOW_UP',
  JOBSITE_LAYOUT_INSTRUCTIONS = 'JOBSITE.LAYOUT_INSTRUCTIONS',
  JOBSITE_GENERAL_INFORMATION = 'JOBSITE.GENERAL_INFORMATION',
  JOBSITE_KPI = 'JOBSITE.KPI',
  JOBSITE_MANUAL_PROD_RECORDS = 'JOBSITE.MANUAL_PROD_RECORDS',
  JOBSITE_PRODUCTIVITY = 'JOBSITE.PRODUCTIVITY',
  JOBSITE_SOILLIB_DATA = 'JOBSITE.SOILLIB_DATA',
  JOBSITE_VISUALIZATION = 'JOBSITE.VISUALIZATION',
  JOBSITES_LIST = 'JOBSITES.LIST',
  SETTINGS_GENERAL = 'SETTINGS.GENERAL',
  SETTINGS_COMPOUND_PARAMETERS = 'SETTINGS.COMPOUND_PARAMETERS',
  SETTINGS_DATE_TIME_FORMAT_PREFERENCES = 'SETTINGS.DATE_TIME_FORMAT_PREFERENCES',
  SETTINGS_DEFAULT_HOME_PAGE_FOR_JOBSITES = 'SETTINGS.DEFAULT_HOME_PAGE_FOR_JOBSITES',
  SETTINGS_COMPANY = 'SETTINGS.COMPANY',
  SETTINGS_FEATURE_TOGGLES = 'SETTINGS.FEATURE_TOGGLES',
  DAILY_DIARY_MOBILE = 'DAILY.DIARY_MOBILE',
  EQUIPMENT_SELECTION_MOBILE_JOBSITE_SELECTION = 'EQUIPMENT_SELECTION_MOBILE.JOBSITE_SELECTION',
  EQUIPMENT_SELECTION_MOBILE_MACHINE_SELECTION = 'EQUIPMENT_SELECTION_MOBILE.MACHINE_SELECTION',
  EQUIPMENT_SELECTION_MOBILE_PILE_ASSIGNMENT = 'EQUIPMENT_SELECTION_MOBILE.PILE_ASSIGNMENT',
}
export { Guards }

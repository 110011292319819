import { Injectable } from '@angular/core'
import { Actions, createEffect } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { notEmpty } from 'app/shared/utils/notEmpty'
import { onComplete, onCreate } from 'app/shared/utils/redux/operators'
import { of, Subject } from 'rxjs'
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators'
import { FileService } from '../../../core/services/file.service'
import { getTechnique } from '../../../core/store/router/router.selectors'
import { State } from '../../../core/store/state'
import { UploadFileModel } from '../models/upload-file.model'
import { FilesUploadActions } from './files-upload.actions'
import { filesSelectors, getFiles } from './files-upload.selectors'

@Injectable()
export class FilesUploadEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private fileService: FileService,
  ) {}

  queue = new Subject()

  queueProcessing = createEffect(
    () =>
      this.queue.pipe(
        mergeMap(
          (obj: UploadFileModel) =>
            this.store.pipe(
              select(filesSelectors.getById(obj.id)),
              filter(file => !!file),
              take(1),
              filter(file => !file.loaded),
              switchMap(file =>
                this.fileService.uploadFile(obj.file, obj.technique).pipe(
                  takeUntil(
                    this.store.pipe(
                      select(filesSelectors.getById(file.id)),
                      filter(f => !f),
                    ),
                  ),
                  tap(uploadEvent =>
                    this.store.dispatch(
                      FilesUploadActions.files.createAddItems([
                        { ...obj, ...uploadEvent },
                      ]),
                    ),
                  ),
                  catchError((error: Error) =>
                    of(
                      this.store.dispatch(
                        FilesUploadActions.files.createAddItems([
                          {
                            ...obj,
                            inError: true,
                            errorDesc: error.message,
                          },
                        ]),
                      ),
                    ),
                  ),
                ),
              ),
            ),
          2,
        ),
      ),
    { dispatch: false },
  )

  filesUploading$ = createEffect(() =>
    this.actions$.pipe(
      onComplete(FilesUploadActions.files),
      map(action => action.payload),
      withLatestFrom(this.store.pipe(select(getTechnique), filter(notEmpty))),
      map(([files, technique]) => files.map(file => ({ ...file, technique }))),
      tap(files =>
        this.store.dispatch(FilesUploadActions.files.createAddItems(files)),
      ),
      tap(files => files.forEach(file => this.queue.next(file))),
      mergeMap(() => [
        FilesUploadActions.setIsExpanded.create(true),
        FilesUploadActions.setVisible.create(true),
      ]),
    ),
  )

  closeWidget$ = createEffect(() =>
    this.actions$.pipe(
      onCreate(FilesUploadActions.setVisible),
      map(action => action.payload),
      filter(isVisible => !isVisible),
      withLatestFrom(this.store.pipe(select(getFiles))),
      map(([isVisible, files]) =>
        FilesUploadActions.files.createRemoveItems(
          files.filter(file => file.loaded && !file.inError),
        ),
      ),
    ),
  )
}

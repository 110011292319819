import { createSelector } from '@ngrx/store'
import { Metric } from 'app/shared/constants/metric.enum'
import { TechniqueNames } from 'app/shared/remote-services/dtos/technique.dto'
import { getParameter } from '../../../jobsites-management/jobsite-summary/store/jobsite-summary.selectors'
import { FieldDto } from '../../../shared/remote-services/dtos/field.dto'
import { getTechnique } from '../router/router.selectors'
import { getState, State } from '../state'
import { FieldById, FieldState } from './fields.reducer'
import { getSelectedSettingsTechnique } from '../../../jobsites-management/settings/store/compound-params/compound-param-settings.selectors'
import {
  getProcessesByPile,
  getProcessesByTechnique,
  getProcessesOfJobsite,
} from '../processes/processes.selectors'
import { ProcessDto } from '../../../shared/remote-services/dtos/process.dto'

export const getFieldState = createSelector(
  getState,
  (state: State) => state.fields,
)

export const getFieldsLoaded = (fromSettings: boolean = false) =>
  createSelector(
    getFieldState,
    fromSettings ? getSelectedSettingsTechnique : getTechnique,
    (state: FieldState, techniqueName: TechniqueNames) =>
      Object.keys(state).includes(techniqueName),
  )

export const getFieldEntities = (fromSettings: boolean = false) =>
  createSelector(
    getFieldState,
    fromSettings ? getSelectedSettingsTechnique : getTechnique,
    (state: FieldState, techniqueName: TechniqueNames): FieldById =>
      state?.[techniqueName] ?? {},
  )

export const getAllFields = (fromSettings: boolean = false) =>
  createSelector(getFieldEntities(fromSettings), (entities): FieldDto[] => {
    return Object.values(entities).sort((a, b) => {
      if ((a.displayName ?? a.name) < (b.displayName ?? b.name)) {
        return -1
      }
      if ((a.displayName ?? a.name) > (b.displayName ?? b.name)) {
        return 1
      }
      return 0
    })
  })

export const getFieldIdByName = (name: string) =>
  createSelector(
    getAllFields(),
    (fields: FieldDto[]) => fields.find(f => f.name === name)?.id,
  )

export const getProductionFields = createSelector(
  getAllFields(),
  (fields: FieldDto[]): FieldDto[] =>
    fields
      .filter(field => field.type === 'PRODUCTION')
      .sort((a, b) =>
        (a.displayName ?? a.name).localeCompare(
          b.displayName ?? b.name,
          undefined,
          {
            sensitivity: 'base',
          },
        ),
      ),
)

export const getProductionFieldsNumber = createSelector(
  getProductionFields,
  (fields: FieldDto[]): FieldDto[] =>
    fields.filter(
      field =>
        [
          'java.math.BigDecimal',
          'java.lang.Integer',
          'java.lang.Long',
        ].includes(field.unit_class ?? '') || field.name === 'time',
    ),
)

export const getProductionFieldsWithoutTime = createSelector(
  getProductionFieldsNumber,
  (fields: FieldDto[]): FieldDto[] =>
    fields.filter(field => field.name !== 'time'),
)

export const getFieldsbyJobsiteProcesses = createSelector(
  getProcessesOfJobsite,
  getProcessesByTechnique,
  getProductionFieldsWithoutTime,
  (
    processesJobsite: ProcessDto[],
    processesTechnique: ProcessDto[],
    fields: FieldDto[],
  ): FieldDto[] => {
    const processesId =
      processesJobsite && processesJobsite.length > 0
        ? processesJobsite.map(process => process.id)
        : processesTechnique.map(process => process.id)

    return fields.filter(field =>
      field.processes.some(fieldProcess => processesId.includes(fieldProcess)),
    )
  },
)

export const getFieldsbyPileProcess = createSelector(
  getProcessesByPile,
  getProductionFields,
  getProcessesByTechnique,
  (
    processName: string,
    fields: FieldDto[],
    processes: ProcessDto[],
  ): FieldDto[] => {
    const processObj = processes.find(p => p.name === processName)
    return fields.filter(field =>
      field.processes.some(fieldProcess => fieldProcess === processObj.id),
    )
  },
)

export const getUnits = createSelector(
  getTechnique,
  getAllFields(),
  (technique, fields) => {
    return Object.fromEntries(
      fields.map(field => [field.name?.replace(technique, ''), field.unit]),
    )
  },
)

export const getUnitByField = (field: Metric) =>
  createSelector(getUnits, units => (units ? units[field] : undefined))

export const getParameterDisplayName = createSelector(
  getParameter,
  getAllFields(),
  (parameter, fields): string => {
    const field = fields.filter(item => item.id && item.id === parameter.metric)
    if (field && field.length > 0) {
      if (field[0].unit) {
        return field[0].displayName + '(' + field[0].unit + ')'
      } else {
        return field[0].displayName ?? field[0].name
      }
    } else {
      return ''
    }
  },
)

export const parameterIsDeviation = createSelector(
  getParameter,
  getFieldIdByName('p_dx'),
  getFieldIdByName('p_dy'),
  (parameter, dxId, dyId) => {
    return parameter.metric === dxId || parameter.metric === dyId
  },
)

import { KeycloakTokenParsed } from 'keycloak-js'
import _ from 'lodash'
import { Permissions } from './models/permissions.model'

export function parseToken(
  keycloakToken: KeycloakTokenParsed,
  clientId: string,
): Permissions {
  const clientRoles = _.get(
    keycloakToken,
    `resource_access.${clientId}.roles`,
    [],
  ).map(role => role.toLowerCase())

  const isAdmin = clientRoles.includes('administrator')

  return {
    parsedRole: {
      isAdmin,
      bu_manager: clientRoles.includes('bu_manager'),
      jobsite_manager: clientRoles.includes('jobsite_manager'),
      zl_admin: clientRoles.includes('zl_admin'),
    },
    canCreate: isAdmin,
    editTechniquesList: isAdmin,
    canConfigure: isAdmin,
  }
}

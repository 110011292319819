import { SyncActionFactory } from 'app/shared/utils/redux/sync.actions'
import { MapVisibilityEnum } from '../../../../shared/constants/map-visibility.enum'

export class MapVisibilityActions {
  public static collapseMap = new SyncActionFactory<void>(
    '[JOBSITE MAP] COLLAPSE MAP',
  )
  public static expandMap = new SyncActionFactory<void>(
    '[JOBSITE MAP] EXPAND MAP',
  )

  public static setMapVisibility = new SyncActionFactory<MapVisibilityEnum>(
    '[JOBSITE MAP] SET MAP VISIBILITY',
  )
}

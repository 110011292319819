// Build information, automatically generated by `ng-info`
const build = {
    version: "21.3.0",
    timestamp: "Wed Nov 20 2024 14:20:39 GMT+0100 (Central European Standard Time)",
    message: null,
    git: {
        user: null,
        branch: "HEAD",
        hash: "cd98a4",
        fullHash: "cd98a4ad01d752c5f7acb99077f1b56b0d824d3d"
    }
};

export default build;
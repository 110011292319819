import { CesiumToolbarOptions } from '../models/CesiumToolbarOptions.type'
import { SyncActionFactory } from '../../../../../shared/utils/redux/sync.actions'
import { CesiumCameraPosition } from '../models/CesiumCameraPosition.type'

export class CesiumMapActions {
  public static setCesiumToolbarOptions =
    new SyncActionFactory<CesiumToolbarOptions>(
      '[CESIUM MAP] SET CESIUM TOOLTIP OPTIONS',
    )
  public static handlePileClick = new SyncActionFactory<string[]>(
    '[CESIUM MAP] handle pile click',
  )

  public static setCameraPositionFromLs =
    new SyncActionFactory<CesiumCameraPosition | null>(
      '[CESIUM MAP] set camera position from local storage',
    )

  public static saveCameraPositiontoLs =
    new SyncActionFactory<CesiumCameraPosition | null>(
      '[CESIUM MAP] save camera position to local storage',
    )

  public static loadCameraPositionFromLs = new SyncActionFactory<void>(
    '[CESIUM MAP] load camera position from local storage',
  )
}

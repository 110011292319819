import { SyncActionFactory } from 'app/shared/utils/redux/sync.actions'
import { EntitiesActionFactory } from '../../../../shared/utils/redux/entities/entities.actions'
import { TemplateConfigDto } from '../../models/template/templateConfig.dto'
import { UUID } from '../../../../shared/models/utils.type'
import { LoadableActionFactory } from '../../../../shared/utils/redux/loadable/loadable.actions'

export class TemplatesActions {
  public static loadTemplates = new EntitiesActionFactory<
    TemplateConfigDto,
    void
  >('[COLUMN VISUALIZATION] LOAD TEMPLATES')
  public static checkTemplatesLinkedToJobsite = new LoadableActionFactory<
    TemplateConfigDto[],
    UUID[]
  >('[COLUMN VISUALIZATION] CHECK TEMPLATES LINKED TO JOBSITE')
  public static saveTemplate = new SyncActionFactory<{
    template: TemplateConfigDto
    url: string
  }>('[COLUMN VISUALIZATION] SAVE TEMPLATE')
  public static deleteTemplate = new SyncActionFactory<string>(
    '[COLUMN VISUALIZATION] DELETE TEMPLATE',
  )
}

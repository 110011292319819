import { LoadableSelectorFactory } from '../../../shared/utils/redux/loadable/loadable.selectors'
import * as core from '../state'

export const platformDisruptionMessageSelector = new LoadableSelectorFactory(
  core.getState,
  (state: core.State) => state.platformDisruptionMessage,
)

export const platformDisruptionsSelector = new LoadableSelectorFactory(
  core.getState,
  (state: core.State) => state.platformDisruptions,
)

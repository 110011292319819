import { ListActionFactory } from '../../../shared/utils/redux/list/list.actions'
import { CompanyDto } from '../../models/company.dto'
import { AsyncActionFactory } from '../../../shared/utils/redux/async.actions'
import { CompanyRegionsTimezoneInputDto } from '../../models/companyRegionsTimezoneInput.dto'
import { CompanyCsvPreferencesInputDto } from '../../models/companyCsvPreferencesInput.dto'

export class CompaniesActions {
  public static companies = new ListActionFactory<CompanyDto, void>(
    '[CORE] LOAD COMPANIES',
  )

  public static updateCompanyTimezones = new AsyncActionFactory<
    CompanyRegionsTimezoneInputDto,
    void
  >('[SETTINGS] UPDATE COMPANY TIMEZONES')

  public static updateCompanyCsvPreferences = new AsyncActionFactory<
    CompanyCsvPreferencesInputDto,
    void
  >('[SETTINGS] UPDATE COMPANY CSV PREFERENCES')
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'

@Component({
  selector: 'app-platform-disruptions',
  templateUrl: './platform-disruptions.component.html',
  styleUrls: ['./platform-disruptions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlatformDisruptionsComponent {
  @Input() message: string
  @Output() closedChange = new EventEmitter<boolean>()

  closeMessage(): void {
    this.closedChange.emit(true)
  }
}

import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { ColumnDto } from '../remote-services/dtos/column.dto'

@Injectable()
export class HfMachineColumnWarningService {
  constructor(private translate: TranslateService) {}

  public displayColumnWarning(column: ColumnDto, parent: ColumnDto): boolean {
    if (column.key.type === 'PANEL') {
      return !column.values.length || !column.values.width || !column.key.name
    } else {
      return (
        !column.values.length ||
        !column.values.width ||
        !column.values.targetDepth ||
        !column.values.parentId ||
        !column.key.name ||
        !parent?.values?.length ||
        !parent?.values?.width ||
        !parent?.key?.name
      )
    }
  }

  getColumnWarningTooltipContent(column: ColumnDto, parent: ColumnDto): string {
    let text = ''
    if (!column.values.length) {
      text += this.translate.instant(
        'JOBSITE_MANAGEMENT.EQUIPMENTS_SELECTION.COLUMN_MISSING_DATA.LENGTH',
      )
    }
    if (!column.values.width) {
      if (text.length > 0) text += '\n'
      text += this.translate.instant(
        'JOBSITE_MANAGEMENT.EQUIPMENTS_SELECTION.COLUMN_MISSING_DATA.WIDTH',
      )
    }
    if (!column.key.name) {
      if (text.length > 0) text += '\n'
      text += this.translate.instant(
        'JOBSITE_MANAGEMENT.EQUIPMENTS_SELECTION.COLUMN_MISSING_DATA.NAME',
      )
    }
    if (column.key.type !== 'PANEL') {
      if (!column.values.targetDepth) {
        if (text.length > 0) text += '\n'
        text += this.translate.instant(
          'JOBSITE_MANAGEMENT.EQUIPMENTS_SELECTION.COLUMN_MISSING_DATA.TARGET_DEPTH',
        )
      }
      if (!column.values.parentId) {
        if (text.length > 0) text += '\n'
        text += this.translate.instant(
          'JOBSITE_MANAGEMENT.EQUIPMENTS_SELECTION.COLUMN_MISSING_DATA.PARENT_ID',
        )
      }

      if (!parent?.values?.width) {
        if (text.length > 0) text += '\n'
        text += this.translate.instant(
          'JOBSITE_MANAGEMENT.EQUIPMENTS_SELECTION.COLUMN_MISSING_DATA.PARENT_WIDTH',
        )
      }

      if (!parent?.values?.length) {
        if (text.length > 0) text += '\n'
        text += this.translate.instant(
          'JOBSITE_MANAGEMENT.EQUIPMENTS_SELECTION.COLUMN_MISSING_DATA.PARENT_LENGTH',
        )
      }

      if (!parent?.key?.name) {
        if (text.length > 0) text += '\n'
        text += this.translate.instant(
          'JOBSITE_MANAGEMENT.EQUIPMENTS_SELECTION.COLUMN_MISSING_DATA.PARENT_NAME',
        )
      }
    }
    return text
  }
}

import { Extent } from 'ol/extent'
import { MinMax } from '../utils/MinMax'
import { produce } from 'immer'
import { TechniqueNames } from '../remote-services/dtos/technique.dto'
import {
  isValidPolygon,
  parseExtentString,
  toExtentString,
} from './parseExtentString'
import groupBy from 'lodash.groupby'
import { MapColumn } from '../../jobsites-management/jobsite-summary/models/map-object/mapColumn.type'

interface FitToRectResult {
  coords: [number, number][]
  scale: number
}

const targetExtent: Extent = [0, 0, 1000, 1000]

export function getProjectedColumns(
  columns: MapColumn[],
  techniqueName: TechniqueNames = 'PILES',
): {
  columns: MapColumn[]
  reprojectionScale: number
} {
  if (techniqueName === 'PILES') {
    const coords = columns
      .filter(c => c.localX !== null && c.localY !== null)
      .map(c => [c.localX, c.localY])
    const result: FitToRectResult = fitIntoRect(
      coords as [number, number][],
      targetExtent,
    )
    const newColumns = columns
      .filter(c => c.localX !== null && c.localY !== null)
      .map((c, index) =>
        produce(c, draft => {
          draft.localX = result.coords[index][0]
          draft.localY = result.coords[index][1]
        }),
      )

    return { columns: newColumns, reprojectionScale: result.scale }
  } else if (techniqueName === 'HF') {
    const filteredColumns = columns
      .filter(c => isValidPolygon(c.polygon))
      .map(c => {
        const coords = parseExtentString(c.polygon)
        const ids = new Array(coords.length)
        ids.fill(c.id)
        return { coords, ids }
      })
    const flatCoords = filteredColumns.flatMap(c => c.coords)
    const flatIds = filteredColumns.flatMap(c => c.ids)
    const result: FitToRectResult = fitIntoRect(
      flatCoords as [number, number][],
      targetExtent,
    )
    const idWithPolygon = result.coords.map((coord, index) => ({
      coord,
      id: flatIds[index],
    }))
    const groupedPolygons = groupBy(idWithPolygon, 'id')
    const newColumns = columns
      .filter(c => isValidPolygon(c.polygon))
      .map(c =>
        produce(c, draft => {
          draft.polygon = toExtentString(
            groupedPolygons[c.id]?.map(item => item.coord),
          )
        }),
      )
    return { columns: newColumns, reprojectionScale: result.scale }
  }
}

export function fitIntoRect(
  coords: [number, number][],
  extent: Extent,
): FitToRectResult {
  if (Array.isArray(coords)) {
    const minMaxCoords = getMinMaxCoords(coords)

    const coordsOriginX = minMaxCoords.x.minValue
    const coordsOriginY = minMaxCoords.y.minValue
    const translatedToOrigin = coords.map(
      coord =>
        [coord[0] - coordsOriginX, coord[1] - coordsOriginY] as [
          number,
          number,
        ],
    )

    const scale = calculateScale(minMaxCoords.x, minMaxCoords.y, extent)
    return {
      coords: translatedToOrigin.map(
        coord => [coord[0] * scale, coord[1] * scale] as [number, number],
      ),
      scale,
    }
  }
}

export function getMinMaxCoords(coords: [number, number][]): {
  x: MinMax
  y: MinMax
} {
  const xMinMax = new MinMax()
  const yMinMax = new MinMax()

  coords.forEach(coord => {
    xMinMax.handleValue(coord[0])
    yMinMax.handleValue(coord[1])
  })

  return { x: xMinMax, y: yMinMax }
}

function calculateScale(
  xMinMax: MinMax,
  yMinMax: MinMax,
  extent: Extent,
): number {
  const coordsWidth = xMinMax.maxValue - xMinMax.minValue
  const coordsHeight = yMinMax.maxValue - yMinMax.minValue

  if (coordsWidth !== 0 || coordsHeight !== 0) {
    const targetRectWidth = extent[2] - extent[0]
    const targetRectHeight = extent[3] - extent[1]
    return Math.min(
      targetRectWidth / coordsWidth,
      targetRectHeight / coordsHeight,
    )
  } else {
    return 1.0
  }
}

import * as Guards from './tracking.model.type.guard'
import { MatomoCustomDimensions } from './matomo-custom-dimensions.enum'

/** @see {isTrackingData} ts-auto-guard:type-guard */
export interface TrackingData {
  userId: string
  businessUnit: string
  techniqueName: string | undefined
  jobsiteId: string | undefined
}

const MANDATORY_MATOMO_DIMENSIONS: MatomoCustomDimensions[] = [
  MatomoCustomDimensions.VISIT_USER_ID,
  MatomoCustomDimensions.VISIT_BUSINESS_UNIT,
  MatomoCustomDimensions.ACTION_USER_ID,
  MatomoCustomDimensions.ACTION_BUSINESS_UNIT,
]

const TRACKING_DATA_MATOMO_DIMENSIONS: MatomoCustomDimensions[] = [
  ...MANDATORY_MATOMO_DIMENSIONS,
  MatomoCustomDimensions.ACTION_JOBSITE_ID,
  MatomoCustomDimensions.ACTION_TECHNIQUE_NAME,
]

/** @see {isTrackAction} ts-auto-guard:type-guard */
export class TrackAction {
  readonly eventCategory: string
  readonly eventAction: string
  readonly eventLabel: string
  readonly userId: string
  readonly customDimensions: PartialCustomDimensions

  private constructor(
    eventCategory: string,
    eventAction: string,
    eventLabel: string,
    userId: string,
    customDimensions: PartialCustomDimensions,
  ) {
    this.eventCategory = eventCategory
    this.eventAction = eventAction
    this.eventLabel = eventLabel
    this.userId = userId
    this.customDimensions = customDimensions
  }

  static getBuilder(): TrackActionBuilder {
    return new TrackActionBuilder()
  }

  static build(builder: TrackActionBuilder) {
    if (!builder.eventCategory) {
      throw new Error('TrackAction must contain eventCategory')
    }
    if (!builder.eventAction) {
      throw new Error('TrackAction must contain eventAction')
    }
    if (!builder.eventLabel) {
      throw new Error('TrackAction must contain eventLabel')
    }
    const dimensions = builder.customDimensions
    if (
      !builder.userId ||
      MANDATORY_MATOMO_DIMENSIONS.some(dim => !(dim in dimensions))
    ) {
      throw new Error('TrackAction must contain tracking data')
    }
    return new TrackAction(
      builder.eventCategory,
      builder.eventAction,
      builder.eventLabel,
      builder.userId,
      builder.customDimensions,
    )
  }
}

export class TrackActionBuilder {
  eventCategory: string
  eventAction: string
  eventLabel: string
  userId: string
  customDimensions: PartialCustomDimensions = {}

  withEventCategory(eventCategory: string): TrackActionBuilder {
    this.eventCategory = eventCategory
    return this
  }

  withEventAction(eventAction: string): TrackActionBuilder {
    this.eventAction = eventAction
    return this
  }

  withEventLabel(eventLabel: string): TrackActionBuilder {
    this.eventLabel = eventLabel
    return this
  }

  withTrackingData(trackingData: TrackingData): TrackActionBuilder {
    this.userId = trackingData.userId
    this.customDimensions[MatomoCustomDimensions.VISIT_USER_ID] =
      trackingData.userId
    this.customDimensions[MatomoCustomDimensions.VISIT_BUSINESS_UNIT] =
      trackingData.businessUnit
    this.customDimensions[MatomoCustomDimensions.ACTION_USER_ID] =
      trackingData.userId
    this.customDimensions[MatomoCustomDimensions.ACTION_BUSINESS_UNIT] =
      trackingData.businessUnit
    if (trackingData.techniqueName) {
      this.customDimensions[MatomoCustomDimensions.ACTION_TECHNIQUE_NAME] =
        trackingData.techniqueName
    }
    if (trackingData.jobsiteId) {
      this.customDimensions[MatomoCustomDimensions.ACTION_JOBSITE_ID] =
        trackingData.jobsiteId
    }
    return this
  }

  addCustomDimension(
    dimension: MatomoCustomDimensions,
    value: string,
  ): TrackActionBuilder {
    if (TRACKING_DATA_MATOMO_DIMENSIONS.includes(dimension)) {
      throw new Error(
        "Consider using 'withTrackingData' instead of 'addCustomDimension'",
      )
    }
    this.customDimensions[dimension] = value
    return this
  }
}

/** @see {isPartialCustomDimensions} ts-auto-guard:type-guard */
export type PartialCustomDimensions = Partial<
  Record<MatomoCustomDimensions, string>
>

export { Guards }

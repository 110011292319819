import {
  AddCageProdRecordInput,
  UpdateCageProdRecordInput,
} from 'app/shared/remote-services/cage.service'
import { ListActionFactory } from 'app/shared/utils/redux/list/list.actions'
import { SyncActionFactory } from 'app/shared/utils/redux/sync.actions'
import { ColumnLink } from '../../../../shared/remote-services/dtos/column.dto'
import { AsyncActionFactory } from '../../../../shared/utils/redux/async.actions'
import { Group } from '../models/group.model'
import { StatusType } from '../models/status.model'
import { JobsiteTableDataValues } from '../models/jobsite-table-data-values.model'
import {
  AddConcreteProdRecord,
  ConcreteProdRecordInput,
} from '../../../../shared/remote-services/dtos/concrete.dto'
import { JobsiteChecksSettingsDto } from '../../../../shared/remote-services/dtos/jobsite-checks.model'
import { LoadableActionFactory } from '../../../../shared/utils/redux/loadable/loadable.actions'
import { DateRange } from '../../../../shared/localized-date-time-picker/localized-date-range-picker.component'
import { DataTableColumnVisibility } from './jobsite-datatable.selectors'
import { ExternalLinkSource } from '../models/column-link-source.model'

export class JobsiteDatatableActions {
  public static updateDisplayedTableColumns = new ListActionFactory<{
    id: string
    tableColumns: DataTableColumnVisibility<JobsiteTableDataValues>
  }>('[JOBSITE DATATABLE] UPDATE DISPLAYED COLUMNS IN DATA TABLE')

  public static setDailyReportData = new SyncActionFactory<
    Array<Group | JobsiteTableDataValues>
  >('[JOBSITE DATATABLE]SET DAILY REPORT DATA')

  public static setSelectedDateRange = new SyncActionFactory<DateRange>(
    '[JOBSITE DATATABLE] SET SELECTED DATE RANGE',
  )

  public static setSelectedStatus = new SyncActionFactory<StatusType>(
    '[JOBSITE DATATABLE]SET SELECTED STATUS',
  )

  public static deleteExternalLink = new AsyncActionFactory<
    { id: string; source: ExternalLinkSource },
    void
  >('[JOBSITE DATATABLE]DELETE EXTERNAL LINK')

  public static createExternalLink = new AsyncActionFactory<
    ColumnLink & { source: ExternalLinkSource },
    void
  >('[JOBSITE DATATABLE]CREATE EXTERNAL LINK')

  public static updateExternalLink = new AsyncActionFactory<
    ColumnLink & { source: ExternalLinkSource },
    void
  >('[JOBSITE DATATABLE]UPDATE EXTERNAL LINK')

  public static createCageProdRecord = new AsyncActionFactory<
    AddCageProdRecordInput,
    void
  >('[JOBSITE DATATABLE] CREATE_CAGE_PROD_RECORD')

  public static updateCageProdRecord = new AsyncActionFactory<
    UpdateCageProdRecordInput,
    void
  >('[JOBSITE DATATABLE] UPDATE_CAGE_PROD')

  public static deleteCageProdRecord = new AsyncActionFactory<string, void>(
    '[JOBSITE DATATABLE] DELETE_CAGE_PROD_RECORD',
  )

  public static updateConcreteProdRecord = new AsyncActionFactory<
    ConcreteProdRecordInput,
    void
  >('[JOBSITE DATATABLE] UPDATE_CONCRETE_PROD_RECORD')

  public static createConcreteProdRecord = new AsyncActionFactory<
    AddConcreteProdRecord,
    void
  >('[JOBSITE DATATABLE] CREATE_CONCRETE_PROD_RECORD')

  public static deleteConcreteProdRecord = new AsyncActionFactory<string, void>(
    '[JOBSITE DATATABLE] DELETE_CONCRETE_PROD_RECORD',
  )

  public static openEmptyCoordinatesDialog =
    new SyncActionFactory<JobsiteTableDataValues>(
      '[JOBSITE DATATABLE] OPEN_EMPTY_COORDINATES_DIALOG',
    )

  public static loadChecksSettings = new LoadableActionFactory<
    void,
    JobsiteChecksSettingsDto
  >('[JOBSITE DATATABLE] LOAD CHECKS SETTINGS')

  public static createOrUpdateChecksSettings = new AsyncActionFactory<
    JobsiteChecksSettingsDto,
    void
  >('[JOBSITE DATATABLE] CREATE OR UPDATE CHECKS SETTINGS')

  public static resetChecksSettings = new AsyncActionFactory<string, void>(
    '[JOBSITE DATATABLE] RESET CHECKS SETTINGS',
  )

  public static openProdEventsDialog = new SyncActionFactory<string>(
    '[JOBSITE DATATABLE] OPEN PROD EVENTS DIALOG',
  )
}

import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

@Injectable()
export class JobsiteAdminService {
  constructor(private http: HttpClient) {}

  private baseUrl = 'rest'

  public resetJobsite(
    technique: string,
    jobsiteId: string,
    force = false,
  ): Observable<void> {
    return this.http.post<void>(
      `${this.baseUrl}/jobsite-reset/${technique}/${jobsiteId}`,
      null,
      {
        params: {
          force,
        },
      },
    )
  }

  public removeDuplicatedPileNames(
    technique: string,
    jobsiteId: string,
  ): Observable<void> {
    return this.http.post<void>(
      `${this.baseUrl}/${technique}/data/preparation/${jobsiteId}/remove-duplicated-pile-names`,
      null,
    )
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { ZlHelperComponentOptions } from '../../models/zl-helper.model'

@Component({
  selector: 'zl-helper',
  template: `
    <div class="zl-helper flex flex-align-center--center">
      <mat-icon
        class="flex-grow"
        [ngClass]="{
          'small-icon': options.iconSize === 'SMALL',
          'medium-icon': options.iconSize === 'MEDIUM',
          'large-icon': options.iconSize === 'LARGE'
        }"
        [ngStyle]="{
          'margin-bottom': options.marginBottom,
          'margin-left': options.marginLeft
        }"
        [mdePopoverTriggerFor]="helperPopover"
        mdePopoverTriggerOn="hover"
      >
        help
      </mat-icon>
      <mde-popover
        #helperPopover="mdePopover"
        [mdePopoverOverlapTrigger]="false"
      >
        <mat-card
          [ngClass]="{ 'grey-background': options.greyBackground }"
          class="helper"
        >
          <mat-card-content>
            <ng-template [ngIf]="popoverText != null && popoverText !== ''">
              {{ popoverText }}
            </ng-template>

            <ng-template [ngIf]="imageSrc != null">
              <img [src]="imageSrc" style="width: 100%" />
            </ng-template>
          </mat-card-content>
        </mat-card>
      </mde-popover>
    </div>
  `,
  styleUrls: ['./zl-helper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZlHelperComponent {
  @Input() popoverText: string
  @Input() imageSrc: string
  @Input() options: ZlHelperComponentOptions = {
    greyBackground: true,
    iconSize: 'MEDIUM',
    marginBottom: null,
    marginLeft: '5px',
  }
}

import * as Guards from './date-time-format.model.type.guard'

export const DATE_FORMATS = ['EN', 'FR', 'ISO'] as const

/** @see {isDateFormats} ts-auto-guard:type-guard */
export type DateFormats = (typeof DATE_FORMATS)[number]

export const DATE_SEPARATORS = ['/', '-'] as const

/** @see {isDateSeparators} ts-auto-guard:type-guard */
export type DateSeparators = (typeof DATE_SEPARATORS)[number]

export const TIME_FORMATS = ['12', '24'] as const

/** @see {isJTimeFormats} ts-auto-guard:type-guard */
export type TimeFormats = (typeof TIME_FORMATS)[number]

export { Guards }

import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { DailyReportColumnDto } from '../../shared/remote-services/dtos/dailyReportColumn.dto'

@Injectable()
export class DailyReportColumnService {
  private baseUrl = 'jobsite/dailyreportcolumns'

  constructor(private http: HttpClient) {}

  public getAll(): Observable<DailyReportColumnDto[]> {
    return this.http.get<DailyReportColumnDto[]>(this.baseUrl)
  }
}

import { ErrorHandler, Injectable } from '@angular/core'
import { Actions } from '@ngrx/effects'
import { Observable, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { AsyncActionFactory } from './async.actions'
import { onStart } from './operators'

@Injectable()
export class AsyncEffects {
  constructor(private actions$: Actions, private errorHandler: ErrorHandler) {}

  public asyncEffect<StartPayload, CompletePayload = StartPayload>(
    asyncAction: AsyncActionFactory<StartPayload, CompletePayload>,
    observableProvider: (arg: StartPayload) => Observable<CompletePayload>,
  ) {
    return this.actions$.pipe(
      onStart(asyncAction),
      map(action => action.payload),
      switchMap(payload =>
        observableProvider(payload).pipe(
          map(result => asyncAction.createComplete(result)),
          catchError((error: Error) => {
            this.errorHandler.handleError(error)
            return of(asyncAction.createFailed())
          }),
        ),
      ),
    )
  }
}

import { SyncActionFactory } from 'app/shared/utils/redux/sync.actions'
import { TemplateConfigDto } from '../../models/template/templateConfig.dto'

export class WipTemplateActions {
  public static setWipTemplate = new SyncActionFactory<TemplateConfigDto>(
    '[COLUMN VISUALIZATION] SET WIP TEMPLATE',
  )
  public static addGraph = new SyncActionFactory<string | undefined>(
    '[COLUMN VISUALIZATION] ADD GRAPH',
  )
  public static deleteGraph = new SyncActionFactory<string>(
    '[COLUMN VISUALIZATION] DELETE GRAPH',
  )
  public static updateWidth = new SyncActionFactory<{
    id: string
    width: number
  }>('[COLUMN VISUALIZATION] UPDATE CHART WIDTH')
  public static updateHeight = new SyncActionFactory<{
    id: string
    height: number
  }>('[COLUMN VISUALIZATION] UPDATE CHART HEIGHT')
  public static setGraph = new SyncActionFactory<{
    id: string
    index: number
  }>('[COLUMN VISUALIZATION] SET GRAPH')
}

import { LocalizedDateService } from '../../shared/utils/services/localized-date.service'
import { CompanyService } from './companyService'
import { CompoundParamsService } from './compound-params.service'
import { ExportService } from './export.service'
import { FileService } from './file.service'
import { GoogleAnalyticsService } from './google-analytics.service'
import { TableFormatterService } from './tableformatter.service'
import { TechniqueService } from './technique.service'
import { DailyReportColumnService } from './daily-report-column.service'
import { CsvService } from '@sde-ild/ssd-csv-lib'
import { ProgressService } from './progress.service'
import { JobsiteAdminService } from './jobsite-admin.service'
import { CsvValuesFormatterService } from './csv-values-formatter.service'

export const services = [
  CompanyService,
  CompoundParamsService,
  DailyReportColumnService,
  ExportService,
  FileService,
  GoogleAnalyticsService,
  LocalizedDateService,
  TableFormatterService,
  TechniqueService,
  CsvService,
  CsvValuesFormatterService,
  ProgressService,
  JobsiteAdminService,
]

import cloneDeep from 'lodash.clonedeep'
import { SortDirection } from '@angular/material/sort'

export class DataTableSortUtils {
  /**
   * Custom sort on Date & time columns to force having time values always
   * sorted descending whatever if date values are sorted in ascending
   * or descending mode.
   */

  static nameSort(input: any[], sortDirection: SortDirection): any[] {
    switch (sortDirection) {
      case 'asc':
        return this.ascNameSort(input)
      case 'desc':
        return this.descNameSort(input)
      default:
        return input
    }
  }

  static descNameSort(input: any[]): any[] {
    const descColumns = cloneDeep(input)
    descColumns.sort((a, b) =>
      a.name && b.name
        ? b.name.localeCompare(a.name, undefined, {
            numeric: true,
            sensitivity: 'base',
          })
        : 0,
    )
    return descColumns
  }

  static ascNameSort(input: any[]): any[] {
    const ascColumns = cloneDeep(input)
    ascColumns.sort((a, b) =>
      a.name && b.name
        ? a.name.localeCompare(b.name, undefined, {
            numeric: true,
            sensitivity: 'base',
          })
        : 0,
    )
    return ascColumns
  }
}

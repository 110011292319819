import {
  CompoundParamDtoEdit,
  CompoundParameter,
} from '../../../../core/models/compoundParameter.model'
import { AsyncActionFactory } from 'app/shared/utils/redux/async.actions'
import { SyncActionFactory } from 'app/shared/utils/redux/sync.actions'
import { State } from './state'

export class CompoundParamSettingsActions {
  public static saveNewCParam = new AsyncActionFactory<
    Omit<CompoundParamDtoEdit, 'id' | 'userId'>,
    Omit<CompoundParamDtoEdit, 'userId'>
  >('[COMPOUND PARAM SETTINGS] SAVE_NEW_C_PARAM')

  public static deleteCParam = new AsyncActionFactory<CompoundParamDtoEdit>(
    '[COMPOUND PARAM SETTINGS] DELETE_C_PARAM',
  )

  public static updateCParam = new AsyncActionFactory<CompoundParamDtoEdit>(
    '[COMPOUND PARAM SETTINGS] UPDATE_C_PARAM',
  )

  public static openEditDialog = new SyncActionFactory<CompoundParameter>(
    '[COMPOUND PARAM SETTINGS] OPEN_EDIT_DIALOG',
  )

  public static setSelectedSettingsTechnique = new SyncActionFactory<
    State['selectedSettingsTechnique']
  >('[COMPOUND PARAM SETTINGS] SET SELECTED SETTINGS TECHNIQUE')

  public static initSelectedSettingsTechnique = new SyncActionFactory(
    '[COMPOUND PARAM SETTINGS] INIT SELECTED SETTINGS TECHNIQUE',
  )
}

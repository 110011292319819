import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
} from '@angular/router'
import { select, Store } from '@ngrx/store'
import { State } from 'app/core/store/state'
import { Observable, of } from 'rxjs'
import { catchError, filter, take, tap } from 'rxjs/operators'
import { getLoaded, JobsiteActions } from '../store/jobsite'

@Injectable()
export class JobsiteGuard implements CanActivate, CanActivateChild {
  constructor(private store: Store<State>) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.checkStore(route)
  }

  canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.checkStore(route)
  }

  checkStore(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.store.pipe(
      select(getLoaded(route.params.jobsiteId)),
      tap(loaded => {
        if (!loaded) {
          this.store.dispatch(
            JobsiteActions.loadJobsite.createStart(route.params.jobsiteId),
          )
        }
      }),
      filter(loaded => loaded),
      take(1),
      catchError((e: Error) => {
        console.error(e)
        return of(false)
      }),
    )
  }
}

import * as Guards from './export.model.type.guard'
import { HFDeviationExportParams } from '../components/export-pdf-dialog/export-pdf-dialog.model'
import { APP_LANGUAGES } from '../../../shared/remote-services/dtos/userPreferences.dto'
import { ExportPdfVerticalityOptionsForm } from '../../../shared/components/export-pdf-options/verticality-options/export-pdf-verticality-options.model'

/** @see {isExportType} ts-auto-guard:type-guard */
export type ExportType =
  | 'CSV'
  | 'CUSTOM_PDF'
  | 'HF_DEVIATION'
  | 'HF_MACHINE'
  | 'HF_VERTICALITY'
  | 'RK_PILE_REPORT'
  | 'VERTICALITY_CSV'

/** @see {isLaunchMultipleExportPayload} ts-auto-guard:type-guard */
export interface LaunchMultipleExportPayload {
  columnIds: string[]
  verticality?: ExportPdfVerticalityOptionsForm
  sma?: number
  deviation?: HFDeviationExportParams
}

/** @see {isExportDailyReportRKPayload} ts-auto-guard:type-guard */
export interface ExportDailyReportRKPayload {
  enableMap: boolean
}

/** @see {isExportDailyReportPayload} ts-auto-guard:type-guard */
export interface ExportDailyReportPayload {
  format: ReportFormat
  landscape: boolean
  printMap: boolean
  globalComment: string
  reportLanguage: DailyReportLanguage | null
}

/** @see {isReportFormat} ts-auto-guard:type-guard */
export enum ReportFormat {
  A4 = 'A4',
  A3 = 'A3',
}

/** @see {isReportOrientation} ts-auto-guard:type-guard */
export enum ReportOrientation {
  PORTRAIT = 'portrait',
  LANDSCAPE = 'landscape',
}

export const DAILY_REPORT_LANGUAGES = [...APP_LANGUAGES, 'hu'] as const

/** @see {isDailyReportLanguage} ts-auto-guard:type-guard */
export type DailyReportLanguage = (typeof DAILY_REPORT_LANGUAGES)[number]

export const DailyReportLangs: { value: DailyReportLanguage; trad: string }[] =
  [
    {
      value: 'fr',
      trad: 'Français',
    },
    {
      value: 'en',
      trad: 'English',
    },
    {
      value: 'es',
      trad: 'Español',
    },
    {
      value: 'hu',
      trad: 'Magyar',
    },
  ]

export { Guards }

import { Injectable } from '@angular/core'
import { TechniqueNames } from 'app/shared/remote-services/dtos/technique.dto'
import { notEmpty } from 'app/shared/utils/notEmpty'
import Keycloak, { KeycloakTokenParsed } from 'keycloak-js'
import { Observable } from 'rxjs'
import { applicationsConfig } from './applications.enum'
import { ApplicationModel, UserModel } from './models/user.model'
import { parseToken } from './parseToken'
import { environment } from '../../environments/environment'
import { ParsedToken } from './models/token.model'
import { isTechniqueName } from '../shared/remote-services/dtos/technique.dto.type.guard'

@Injectable()
export class KeycloakService {
  static kc: Keycloak

  static setKC(keycloak: Keycloak): void {
    KeycloakService.kc = keycloak
  }

  getToken(): string {
    return KeycloakService.kc.token
  }

  updateToken(): Observable<unknown> {
    return new Observable(observer => {
      KeycloakService.kc
        .updateToken(30)
        .then(refreshed => {
          if (refreshed) {
            observer.next(KeycloakService.kc.token)
            observer.complete()
          } else {
            if (environment.platform != 'PROD') {
              console.log(
                'Token not refreshed, valid for ' +
                  Math.round(
                    KeycloakService.kc.tokenParsed.exp +
                      KeycloakService.kc.timeSkew -
                      new Date().getTime() / 1000,
                  ) +
                  ' seconds',
              )
            }
            observer.complete()
          }
        })
        .catch(() => {
          observer.error(new Error('Session expired'))
          this.logout()
        })
    })
  }

  clearToken(): void {
    KeycloakService.kc.clearToken()
  }

  getUser(): UserModel {
    const token = KeycloakService.kc.tokenParsed as ParsedToken &
      KeycloakTokenParsed
    return {
      country: token?.country,
      name: `${token?.name || token?.preferred_username}`,
      id: token.sub,
      permissions: parseToken(token, KeycloakService.kc.clientId),
      companyName: token?.company,
      roCompanyNames: (token?.roCompany ?? []).filter(notEmpty),
      env: token?.iss?.split('/').pop(),
      applications: this.getApplicationsConf(token.apps),
    }
  }

  getApplicationsConf(tokenApps: string[]): ApplicationModel[] {
    const applications: ApplicationModel[] = []

    if (!tokenApps || tokenApps.length === 0) {
      return []
    }

    applicationsConfig.forEach(appConfig => {
      const filteredTokenApps = tokenApps.filter(tokenApp =>
        tokenApp.startsWith(appConfig.keycloakName),
      )
      if (filteredTokenApps && filteredTokenApps.length > 0) {
        applications.push(
          filteredTokenApps.reduce((acc, app) => {
            acc.name = appConfig.keycloakName
            acc.dependsOnTechnique = app !== appConfig.keycloakName
            acc.url = appConfig.url
            acc.externalUrl = appConfig.externalUrl
            if (acc.dependsOnTechnique) {
              const technique = app.substring(app.lastIndexOf('_') + 1)
              if (isTechniqueName(technique)) {
                acc.techniques.push(technique as TechniqueNames)
              }
            }
            return acc
          }, new ApplicationModel()),
        )
      }
    })

    return applications
  }

  logout(): void {
    KeycloakService.kc.logout()
  }
}

import { Injectable } from '@angular/core'
import { Actions, createEffect } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { AsyncEffects } from '../../../shared/utils/redux/async.effects'
import { CompanyService } from '../../services/companyService'
import * as core from '../state'
import { CompaniesActions } from './companies.actions'
import { onStart } from '../../../shared/utils/redux/operators'
import { concatMap, switchMap, withLatestFrom } from 'rxjs/operators'
import { getCurrentCompany } from './companies.selectors'
import { SettingsActions } from '../../../jobsites-management/settings/store/settings.actions'

@Injectable()
export class CompaniesEffects {
  constructor(
    private actions$: Actions,
    private store: Store<core.State>,
    private effectFactory: AsyncEffects,
    private companiesService: CompanyService,
  ) {}

  loadCompanies$ = createEffect(() =>
    this.effectFactory.asyncEffect(CompaniesActions.companies, () =>
      this.companiesService.getCompanies(),
    ),
  )

  updateCompanyTimezones$ = createEffect(() =>
    this.actions$.pipe(
      onStart(CompaniesActions.updateCompanyTimezones),
      withLatestFrom(this.store.select(getCurrentCompany)),
      switchMap(([action, company]) =>
        this.companiesService.updateCompanyAndRegionsTimezones(
          company.id,
          action.payload,
        ),
      ),
      concatMap(() => [
        CompaniesActions.companies.createStart(),
        SettingsActions.toggleDisplaySaveAlert.create(true),
      ]),
    ),
  )

  updateCompanyCsvPreferences$ = createEffect(() =>
    this.actions$.pipe(
      onStart(CompaniesActions.updateCompanyCsvPreferences),
      withLatestFrom(this.store.select(getCurrentCompany)),
      switchMap(([action, company]) =>
        this.companiesService.updateCompanyCsvPreferences(
          company.id,
          action.payload,
        ),
      ),
      concatMap(() => [
        CompaniesActions.companies.createStart(),
        SettingsActions.toggleDisplaySaveAlert.create(true),
      ]),
    ),
  )
}

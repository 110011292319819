import { UrlTree } from '@angular/router'
import { Action } from '@ngrx/store'
import { SyncActionFactory } from 'app/shared/utils/redux/sync.actions'

export const GO = '[Router] Go'
export const BACK = '[Router] Back'
export const FORWARD = '[Router] Forward'

export class Go implements Action {
  readonly type = GO
  constructor(public payload: string, public externalUrl?: boolean) {}
}

export class Back implements Action {
  readonly type = BACK
}

export class Forward implements Action {
  readonly type = FORWARD
}

export type Actions = Go | Back | Forward

export class RouterActions {
  public static setBackUrl = new SyncActionFactory<
    string | UrlTree | undefined
  >('[ROUTER] SET BACK URL')
}

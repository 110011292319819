<div class="setting-container flex-layout-column">
  <div class="setting-separator flex-15">
    <mat-form-field>
      <input
        matInput
        autocomplete="off"
        [formControl]="separatorFormControl"
        placeholder="{{
          'PROJECT_MANAGEMENT.JOBSITE_LAYOUT.SEPARATOR_CSV' | translate
        }}"
      />
      <mat-icon matSuffix>mode_edit</mat-icon>
    </mat-form-field>
  </div>
  <ng-content></ng-content>
  <button
    class="flex-10"
    mat-raised-button
    color="primary"
    (click)="applySettings()"
    [ngClass]="{ disabled: separatorFormControl.invalid }"
    [disabled]="separatorFormControl.invalid"
  >
    OK
  </button>
</div>

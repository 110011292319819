import * as Guards from './data-science-filter.model.type.guard'
import { Moment } from 'moment'
import { exhaustiveCheck } from '../../../../shared/utils/exhautisveCheck'

export class DataScienceFilter {
  type: DataScienceFilterType | null
  filterBy: DataScienceFilterBy | null
  selectedPileId: string | null
  selectedMachines: string[] | null
  period: DataScienceFilterPeriod | null
  periodValues: string[] | null
  diameters: Array<number> | null

  private dates: Moment[] | null

  constructor(
    type: DataScienceFilterType | null,
    filterBy: DataScienceFilterBy | null,
    selectedPileId: string | null,
    selectedMachines: string[] | null,
    dates: Moment[] | null,
    diameters: Array<number> | null,
  ) {
    this.type = type
    this.dates = dates
    this.filterBy = filterBy
    const period = DataScienceFilterPeriodUtils.from(filterBy)
    this.periodValues = this.formatDates(dates, period, type)
    if (type != null)
      switch (type) {
        case DataScienceFilterType.GLOBAL:
          this.selectedMachines = selectedMachines
          this.selectedPileId = selectedPileId
          this.period = period
          break
        case DataScienceFilterType.VOLUME:
          this.period = period
          this.diameters = diameters
          break
        case DataScienceFilterType.OVERBREAK:
          this.period = period
          break
        default:
          exhaustiveCheck(type)
      }
  }

  public static copy(dataScienceFilter: DataScienceFilter) {
    return new DataScienceFilter(
      dataScienceFilter.type,
      dataScienceFilter.filterBy,
      dataScienceFilter.selectedPileId,
      dataScienceFilter.selectedMachines,
      dataScienceFilter.dates,
      dataScienceFilter.diameters,
    )
  }

  private formatDates(
    dates: Moment[] | null,
    period: DataScienceFilterPeriod,
    type: DataScienceFilterType | null,
  ): string[] {
    if (!dates || !type) return []
    switch (period) {
      case DataScienceFilterPeriod.WEEK:
        return dates.map(d =>
          type === DataScienceFilterType.OVERBREAK
            ? d.format('W-YYYY')
            : d.format('YYYY-W'),
        )
      case DataScienceFilterPeriod.MONTH:
        return dates.map(d => d.format('YYYY-M'))
      default:
        return []
    }
  }
}

/** @see {isDataScienceFilterType} ts-auto-guard:type-guard */
export enum DataScienceFilterType {
  GLOBAL = 'GLOBAL',
  VOLUME = 'VOLUME',
  OVERBREAK = 'OVERBREAK',
}

/** @see {isDataScienceFilterPeriod} ts-auto-guard:type-guard */
export enum DataScienceFilterPeriod {
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  DAY = 'DAY',
}

export class DataScienceFilterPeriodUtils {
  static from(filterBy: DataScienceFilterBy): DataScienceFilterPeriod | null {
    switch (filterBy) {
      case 'MONTH':
        return DataScienceFilterPeriod.MONTH
      case 'WEEK':
        return DataScienceFilterPeriod.WEEK
      case 'DAY':
        return DataScienceFilterPeriod.DAY
      default:
        return null
    }
  }
}

/** @see {isDataScienceFilterBy} ts-auto-guard:type-guard */
export type DataScienceFilterBy =
  | 'PILE'
  | 'MACHINE'
  | 'DIAMETER'
  | 'DAY'
  | 'WEEK'
  | 'MONTH'

export const GlobalFilterByOptions: Array<DataScienceFilterBy> = [
  'PILE',
  'MACHINE',
  'WEEK',
  'MONTH',
]

export const VolumeFilterByOptions: Array<DataScienceFilterBy> = [
  'DAY',
  'WEEK',
  'MONTH',
]

export const OverbreakFilterByOptions: Array<DataScienceFilterBy> = [
  'WEEK',
  'MONTH',
]

export { Guards }

import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { AbstractLoadableGuard } from '../../shared/utils/abstract-loadable.guard'
import { FieldsActions } from '../store/fields/fields.actions'
import { getFieldsLoaded } from '../store/fields/fields.selectors'
import { State } from '../store/state'

@Injectable()
export class FieldsGuard extends AbstractLoadableGuard<State> {
  constructor(store: Store<State>) {
    super(store, getFieldsLoaded(), FieldsActions.loadFields)
  }
}

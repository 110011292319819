import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { CompanyDto } from '../models/company.dto'
import { CompanyRegionsTimezoneInputDto } from '../models/companyRegionsTimezoneInput.dto'
import { CompanyCsvPreferencesInputDto } from '../models/companyCsvPreferencesInput.dto'

@Injectable()
export class CompanyService {
  constructor(private http: HttpClient) {}

  private baseUrl = 'jobsite/companies'

  public getCompanies(): Observable<CompanyDto[]> {
    return this.http
      .get<Record<string, CompanyDto>>(`${this.baseUrl}`)
      .pipe(map(result => Object.values(result)))
  }

  public updateCompanyAndRegionsTimezones(
    companyId: string,
    value: CompanyRegionsTimezoneInputDto,
  ): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/${companyId}/timezones`, value)
  }

  public updateCompanyCsvPreferences(
    companyId: string,
    value: CompanyCsvPreferencesInputDto,
  ): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/${companyId}/csv`, value)
  }
}

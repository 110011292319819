import * as Guards from './aggregation-type.enum.type.guard'

/** @see {isAggregationType} ts-auto-guard:type-guard */
export enum AggregationType {
  MAX = 'MAX',
  MIN = 'MIN',
  AVG = 'AVG',
  COUNT = 'COUNT',
}

export { Guards }

import { JobsiteDto } from 'app/shared/remote-services/dtos/jobsite.dto'
import { SyncActionFactory } from 'app/shared/utils/redux/sync.actions'
import { CompanyDto } from '../../../core/models/company.dto'
import { ListActionFactory } from '../../../shared/utils/redux/list/list.actions'
import {
  DisplayedFavoriteKPIDto,
  DisplayedFavoriteKpiPayload,
} from '../../jobsite-summary/jobsite-kpi/models/jobsite-favorite-kpi.dto'
import { OrderValues } from '../models/jobsites-filter.model'
import { JobsitesListState } from './state'
import { JobsitePinpointDto } from '../../../shared/remote-services/dtos/jobsitePinpoint.dto'
import { AsyncActionFactory } from '../../../shared/utils/redux/async.actions'
import { FavoriteTechniqueDto } from '../../../shared/remote-services/dtos/technique.dto'
import { FromExcel } from '../models/jobsite-excel-import.model'
import { LoadableActionFactory } from '../../../shared/utils/redux/loadable/loadable.actions'
import { WeeklyReportConfig } from '../models/weekly-report-config.model'
import { WeeklyReportConfigDialogOutput } from '../components/weekly-report/weekly-report-config-dialog.components'
import { LoadableMapActionsFactory } from '../../../shared/utils/redux/loadable-map/loadable-map.actions'
import { JobsiteStatsDto } from '../../../shared/remote-services/dtos/jobsiteStats.dto'

export class JobsitesListActions {
  public static enterJobsitesList = new SyncActionFactory<void>(
    '[JOBSITES LIST] ENTER',
  )

  public static loadJobsitesList = new SyncActionFactory<void>(
    '[JOBSITES LIST] LOAD',
  )

  public static updateMapSettings = new SyncActionFactory<{
    center: [number, number]
    zoom: number
  }>('[JOBSITES LIST] UPDATE MAP SETTINGS')

  public static initSearchFilters = new SyncActionFactory<void>(
    '[JOBSITES LIST] INIT SEARCH FILTERS',
  )

  public static provideSearch = new SyncActionFactory<string>(
    '[JOBSITES LIST] PROVIDE SEARCH',
  )

  public static clickOnStatus = new SyncActionFactory<
    JobsitesListState['status']
  >('[JOBSITES LIST] CLICK ON STATUS')

  public static setOrderDirection = new SyncActionFactory<
    JobsitesListState['orderDirection']
  >('[JOBSITES LIST] SET ORDER DIRECTION')

  public static setOrderValue = new SyncActionFactory<OrderValues>(
    '[JOBSITES LIST] SET ORDER VALUE',
  )

  public static setPageSize = new SyncActionFactory<number>(
    '[JOBSITES LIST] SET PAGE SIZE',
  )

  public static setCurrentPage = new SyncActionFactory<number>(
    '[JOBSITES LIST] SET CURRENT PAGE',
  )

  public static setTotalItems = new SyncActionFactory<number>(
    '[JOBSITES LIST] SET TOTAL ITEMS',
  )

  public static displayedJobsites = new ListActionFactory<JobsiteDto, void>(
    '[JOBSITES LIST] DISPLAYED JOBSITES',
  )

  public static setOnlyStarred = new SyncActionFactory<boolean>(
    '[JOBSITES LIST] SET ONLY STARRED',
  )

  public static loadNbAssignedMachines = new SyncActionFactory<{
    [id: string]: number
  }>('[JOBSITES LIST] LOAD ASSIGNED MACHINES')

  public static setCompanyValue = new SyncActionFactory<CompanyDto>(
    '[JOBSITES LIST] SET SELECTED COMPANY',
  )

  public static displayedFavoriteKpi = new ListActionFactory<
    DisplayedFavoriteKPIDto,
    DisplayedFavoriteKpiPayload
  >('[JOBSITES LIST] DISPLAYED_FAVORITE_KPI')

  public static setDisplayMode = new SyncActionFactory<
    JobsitesListState['displayMode']
  >('[JOBSITES LIST] SET DISPLAY MODE')

  public static setMapAnimationDone = new SyncActionFactory<boolean>(
    '[JOBSITES LIST] SET MAP ANIMATION DONE',
  )

  public static setListAnimationDone = new SyncActionFactory<boolean>(
    '[JOBSITES LIST] SET LIST ANIMATION DONE',
  )

  public static setRefreshMapSize = new SyncActionFactory<boolean>(
    '[JOBSITES LIST] SET REFRESH MAP SIZE',
  )

  public static loadMapViewPinpoints = new SyncActionFactory<void>(
    '[JOBSITES LIST] LOAD MAP VIEW PINPOINTS',
  )

  public static jobsitesPinpoints = new ListActionFactory<
    JobsitePinpointDto,
    void
  >('[JOBSITES LIST] JOBSITES PINPOINTS')

  public static setFavoriteJobsiteTechnique = new AsyncActionFactory<
    FavoriteTechniqueDto,
    void
  >('[JOBSITES LIST] SET FAVORITE JOBSITE TECHNIQUE')

  public static exportKpiCsv = new SyncActionFactory<void>(
    '[JOBSITES LIST] EXPORT KPI CSV',
  )

  public static importJobsiteFromExcel = new AsyncActionFactory<
    File,
    FromExcel
  >('[JOBSITES LIST] IMPORT JOBSITE FROM EXCEL')

  public static openWeeklyReportConfig = new SyncActionFactory<void>(
    '[JOBSITES LIST] OPEN WEEKLY REPORT CONFIG',
  )

  public static loadWeeklyReportConfig = new LoadableActionFactory<
    void,
    WeeklyReportConfig
  >('[JOBSITES LIST] LOAD WEEKLY REPORT CONFIG')

  public static saveWeeklyReportConfig =
    new SyncActionFactory<WeeklyReportConfigDialogOutput>(
      '[JOBSITES LIST] SAVE WEEKLY REPORT CONFIG',
    )

  public static loadJobsiteStats =
    new LoadableMapActionsFactory<JobsiteStatsDto>(
      '[JOBSITES LIST] LOAD JOBSITE MAP',
    )

  public static resetJobsitesListPage = new SyncActionFactory<void>(
    '[JOBSITES LIST] RESET JOBSITES LIST PAGE',
  )
}

import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router'
import * as FileSaver from 'file-saver'
import { Observable } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import { FileService } from '../../core/services/file.service'

@Injectable()
export class DownloaderGuard implements CanActivate {
  constructor(private fileService: FileService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<UrlTree> {
    return this.fileService.downloadFileWithToken(route.params.token).pipe(
      tap(blob => FileSaver.saveAs(blob, `${route.params.token}`)),
      map(() => this.router.createUrlTree(['/'])),
    )
  }
}

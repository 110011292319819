import { Injectable } from '@angular/core'
import { Actions, createEffect } from '@ngrx/effects'
import { State } from '../state'
import { Store } from '@ngrx/store'
import { ProcessesService } from '../../../shared/remote-services/processes.service'
import { ProcessesActions } from './processes.actions'
import { AsyncEffects } from '../../../shared/utils/redux/async.effects'

@Injectable()
export class ProcessesEffects {
  constructor(
    private action$: Actions,
    private store: Store<State>,
    private effectFactory: AsyncEffects,
    private service: ProcessesService,
  ) {}

  loadProcesses$ = createEffect(() =>
    this.effectFactory.asyncEffect(ProcessesActions.loadProcesses, () =>
      this.service.getAllProcesses(),
    ),
  )
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChange,
} from '@angular/core'
import { DateAdapter } from '@angular/material/core'
import { TranslateService } from '@ngx-translate/core'
import moment, { Moment } from 'moment'
import { of } from 'rxjs'
import { catchError, map, take } from 'rxjs/operators'
import Swal from 'sweetalert2'
import {
  UnitSystem,
  UserPreferencesDto,
} from '../../../remote-services/dtos/userPreferences.dto'
import { LocalizedDateService } from '../../../utils/services/localized-date.service'
import { CsvService, ImportDto } from '@sde-ild/ssd-csv-lib'
import {
  ProgressDto,
  ProgressUpdatePayload,
} from '../../../../jobsites-management/jobsite-summary/models/progress/progress.model'
import { ProgressCategory } from '../../../../jobsites-management/jobsite-summary/models/progress/progress-category.enum'
import { TechniqueNames } from '../../../remote-services/dtos/technique.dto'
import {
  CsvConfigData,
  PlannedProgressEditCsvConfigDialog,
} from '../../planned-progress-edit-csv-config.dialog'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table'
import { PlannedProgressRow } from './table-data/planned-progress-row.model'
import { ProgressType } from '../../../../jobsites-management/jobsite-summary/models/progress/progress-type.enum'
import { CsvSeparator } from '../../../../jobsites-management/settings/models/csv-separator.enum'

@Component({
  selector: 'planned-progress-edit',
  templateUrl: './planned-progress-edit.component.html',
  styleUrls: ['./planned-progress-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlannedProgressEditComponent implements OnChanges {
  @Input() plannedProgress: ProgressDto[] = []
  @Input() type: ProgressType
  @Input() userPreferences: UserPreferencesDto
  @Input() depthUnit: string
  @Input() category: ProgressCategory
  @Input() technique: TechniqueNames
  @Input() jobsiteId: string
  @Input() unitSystem: UnitSystem
  @Input() csvSeparator: CsvSeparator

  @Output() plannedProgressBatch = new EventEmitter<ProgressDto[]>()
  @Output() addPlannedProgress = new EventEmitter<ProgressDto>()
  @Output() editPlannedProgress = new EventEmitter<ProgressUpdatePayload>()
  @Output() deletePlannedProgress = new EventEmitter<ProgressDto>()

  dataSource: MatTableDataSource<PlannedProgressRow>
  displayedColumns: string[] = ['date', 'count', 'actionsColumn']

  constructor(
    private adapter: DateAdapter<Moment>,
    private csvService: CsvService,
    private translateService: TranslateService,
    private localizedDateService: LocalizedDateService,
    public dialog: MatDialog,
  ) {}

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (changes.userPreferences && changes.userPreferences.currentValue) {
      this.adapter.setLocale(this.userPreferences.language)
      this.adapter.format = date =>
        this.localizedDateService.getFormattedDate(date)
    }

    if (changes.plannedProgress || changes.category) {
      this.initTable()
    }
  }

  private initTable(): void {
    this.dataSource = new MatTableDataSource(
      PlannedProgressRow.from(
        this.plannedProgress.filter(
          p =>
            p.type === this.type &&
            (p.category == null || p.category === this.category),
        ),
      ),
    )
  }

  reset(): void {
    Swal.fire({
      title: this.translateService.instant('ALERT.SURE'),
      text: this.translateService.instant(
        'JOBSITE_MANAGEMENT.FOLLOW_UP.IMPORT.RESET',
      ),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonText: this.translateService.instant('ALERT.CANCEL'),
      confirmButtonText: this.translateService.instant('ALERT.DELETE'),
    }).then(evt => {
      if (!evt.dismiss) {
        this.plannedProgressBatch.emit([])
      }
    })
  }

  handleFileSelected(event: Event): void {
    const file = (event.target as HTMLInputElement).files[0]
    ;(event.target as HTMLInputElement).value = ''
    if (file) {
      const dialogRef = this.dialog.open<
        PlannedProgressEditCsvConfigDialog,
        CsvConfigData,
        string
      >(PlannedProgressEditCsvConfigDialog, {
        width: '500px',
        data: {
          dateFormat: this.userPreferences.dateFormat,
          jobsiteId: this.jobsiteId,
          technique: this.technique,
        },
      })
      dialogRef.afterClosed().subscribe((dateFormat: string) => {
        this.csvService
          .parseCsv(file, this.csvSeparator)
          .pipe(
            take(1),
            map(importDto => this.getProgressFromCsv(importDto, dateFormat)),
            catchError((error: Error) => {
              Swal.fire({
                title: this.translateService.instant(
                  'JOBSITE_MANAGEMENT.FOLLOW_UP.IMPORT.TITLE',
                ),
                text: error.message,
                icon: 'error',
                confirmButtonText: this.translateService.instant('GENERAL.OK'),
              })
              return of(undefined)
            }),
          )
          .subscribe((newItems: ProgressDto[]) =>
            newItems ? this.plannedProgressBatch.emit([...newItems]) : '',
          )
      })
    }
  }

  private getProgressFromCsv(
    importDto: ImportDto,
    dateFormat: string,
  ): ProgressDto[] {
    const csvHeaders = importDto.headers
      ? importDto.headers.map(h =>
          typeof h === 'string' ? h?.toLowerCase()?.trim() : null,
        )
      : null

    if (
      !csvHeaders ||
      csvHeaders.length < 2 ||
      !csvHeaders.includes('date') ||
      !csvHeaders.includes('count')
    ) {
      throw new Error(
        this.translateService.instant(
          'JOBSITE_MANAGEMENT.FOLLOW_UP.IMPORT.BAD_HEADER',
          {
            separator: this.csvSeparator,
          },
        ),
      )
    }

    const dateIndex = csvHeaders.indexOf('date')
    const countIndex = csvHeaders.indexOf('count')

    return importDto.data.map((line, index) => {
      const date = moment(
        line[dateIndex],
        dateFormat ?? this.userPreferences.dateFormat,
        true,
      )
      if (!date.isValid()) {
        throw new Error(
          this.translateService.instant(
            'JOBSITE_MANAGEMENT.FOLLOW_UP.IMPORT.DATE_INVALID',
            {
              date: line[dateIndex],
              index,
              dateFormat: dateFormat ?? this.userPreferences.dateFormat,
            },
          ),
        )
      }
      const count = Number(line[countIndex])
      return new ProgressDto(
        this.type,
        this.category,
        this.depthUnit,
        count,
        date,
      )
    })
  }

  getCategoryHeaderTranslation(category: ProgressCategory): string {
    switch (category) {
      case ProgressCategory.AREA_SIMPLE:
        return this.translateService.instant(
          'JOBSITE_MANAGEMENT.FOLLOW_UP.CHOICE_' + category,
          {
            unit: this.userPreferences.unitSystem === 'imperial' ? 'in²' : 'm²',
          },
        )
      case ProgressCategory.LENGTH:
        return this.translateService.instant(
          `JOBSITE_MANAGEMENT.FOLLOW_UP.CHOICE_${category}_${this.userPreferences.unitSystem.toUpperCase()}`,
        )
      default:
        return this.translateService.instant(
          'JOBSITE_MANAGEMENT.FOLLOW_UP.CHOICE_' + category,
        )
    }
  }

  cancelOrDelete(progressRow: PlannedProgressRow) {
    if (progressRow.isNew) {
      this.dataSource.data.shift()
      this.dataSource.data = [...this.dataSource.data]
    } else if (progressRow.isEditing) {
      progressRow.cancel()
    } else {
      this.deletePlannedProgress.emit(progressRow.getOriginalForDeletion())
    }
  }

  addRow(): void {
    this.dataSource.data.unshift(
      new PlannedProgressRow(
        new ProgressDto(this.type, this.category, this.depthUnit),
        true,
      ),
    )
    this.dataSource.data = [...this.dataSource.data]
  }

  updateOrSave(row: PlannedProgressRow): void {
    if (row.isNew) {
      this.addPlannedProgress.emit(row.getProgressionData())
    } else {
      this.editPlannedProgress.emit({
        old: row.getOriginalForDeletion(),
        new: row.getProgressionData(),
      })
    }
  }

  downloadCsvTemplate(): void {
    const data = [
      ['date', 'count'],
      [this.userPreferences.dateFormat, 10],
    ]

    this.csvService.exportCsv(
      data,
      `planned_progress_template.csv`,
      this.csvSeparator,
    )
  }
}

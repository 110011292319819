import { createSelector } from '@ngrx/store'
import { EntitiesSelectorFactory } from 'app/shared/utils/redux/entities/entities.selectors'
import { LoadableSelectorFactory } from 'app/shared/utils/redux/loadable/loadable.selectors'
import { ListSelectorFactory } from '../../../shared/utils/redux/list/list.selectors'
import { ColumnVisualizationState, getState } from './state'

export const getColumnState = createSelector(
  getState,
  (state: ColumnVisualizationState) => state.column,
)

export const columnSelectors = new LoadableSelectorFactory(
  getState,
  state => state.column,
)

export const highchartsOptionsSelectors = new EntitiesSelectorFactory(
  getState,
  state => state.highchartsOptions,
)

export const jobsiteTemplateIdSelector = new LoadableSelectorFactory(
  getState,
  state => state.jobsiteTemplateId,
)

export const userTemplateIdSelector = new LoadableSelectorFactory(
  getState,
  state => state.userTemplateId,
)

export const templateSelectors = new EntitiesSelectorFactory(
  getState,
  (state: ColumnVisualizationState) => state.templates,
)

export const defaultTemplateIdSelector = new LoadableSelectorFactory(
  getState,
  state => state.defaultTemplteId,
)

export const getSelectedDto = createSelector(
  getState,
  state => state.selectedDto,
)

export const getVisibleChartIds = createSelector(
  getSelectedDto,
  templateSelectors.getEntities,
  (dto, templates) => {
    if (dto) {
      if (dto.type === 'CHART') {
        return [dto.id]
      } else if (dto.id != null && templates?.[dto.id] != null) {
        return (templates[dto.id]?.lines ?? []).map(line => line.chartId)
      }
    }
  },
)

export const selectedHeadersSelector = new ListSelectorFactory<
  string,
  ColumnVisualizationState
>(getState, state => (state ? state.selectedHeaders : undefined))

export const recordsSelectors = new EntitiesSelectorFactory(
  getState,
  state => state.records,
)

export const getSortedRecords = createSelector(
  recordsSelectors.getAll,
  records =>
    records
      ? records.sort((a, b) =>
          a.date && b.date ? a.date.localeCompare(b.date) : 0,
        )
      : [],
)

import { createSelector } from '@ngrx/store'
import { Permissions } from 'app/keycloak/models/permissions.model'
import { ApplicationModel, UserModel } from 'app/keycloak/models/user.model'
import { LoadableSelectorFactory } from 'app/shared/utils/redux/loadable/loadable.selectors'
import { LoadableState } from 'app/shared/utils/redux/loadable/loadable.state'
import * as core from '../state'
import {
  HomePagePreferenceDto,
  UserPreferencesDto,
} from '../../../shared/remote-services/dtos/userPreferences.dto'
import { UUID } from '../../../shared/models/utils.type'
import { getEnableSortAsBuiltPreference } from '../../../jobsites-management/settings/store/feature-toggle/feature-toggle.selectors'

export const getUserState = createSelector<
  core.State,
  [core.State],
  LoadableState<UserModel>
>(core.getState, (state: core.State) => state.user)

export const getLoaded = createSelector<
  core.State,
  [LoadableState<UserModel>],
  boolean
>(getUserState, (state: LoadableState<UserModel>) => state.loaded)

export const getUserId = createSelector<
  core.State,
  [LoadableState<UserModel>, boolean],
  string
>(getUserState, getLoaded, (state, loaded) =>
  loaded ? state.value.id : undefined,
)

export const getUserName = createSelector<
  core.State,
  [LoadableState<UserModel>, boolean],
  string
>(getUserState, getLoaded, (state, loaded) =>
  loaded ? state.value.name : undefined,
)

export const getPermissions = createSelector<
  core.State,
  [LoadableState<UserModel>, boolean],
  Permissions
>(getUserState, getLoaded, (state: LoadableState<UserModel>, loaded: boolean) =>
  loaded ? state.value.permissions : undefined,
)

const getEnv = createSelector<
  core.State,
  [LoadableState<UserModel>, boolean],
  string
>(getUserState, getLoaded, (state: LoadableState<UserModel>, loaded: boolean) =>
  loaded ? state.value.env : undefined,
)

export const getApplications = createSelector<
  core.State,
  [LoadableState<UserModel>, boolean],
  ApplicationModel[]
>(getUserState, getLoaded, (state: LoadableState<UserModel>, loaded: boolean) =>
  loaded ? state.value.applications : [],
)

export const isDevEnv = createSelector(getEnv, env => 'Dev' === env)

export const userPreferencesSelector = new LoadableSelectorFactory(
  core.getState,
  (state: core.State) => state.userPreferences,
)

export const getLanguage = createSelector(
  userPreferencesSelector.getValue,
  userPref => userPref.language,
)

export const getUnitSystem = createSelector(
  userPreferencesSelector.getValue,
  userPref => userPref?.unitSystem,
)

export const getDateTimeFormats = createSelector(
  userPreferencesSelector.getValue,
  userPref => (userPref ? userPref.zlDateTimeFormats : undefined),
)

export const isUserPrefTwelveHoursTimeFormat = createSelector(
  getDateTimeFormats,
  formats => formats.timeFormat.indexOf('HH') === -1,
)

export const getRigPathEnabled = createSelector(
  userPreferencesSelector.getValue,
  userPref =>
    userPref?.zlRigPathEnabled != null ? userPref.zlRigPathEnabled : true,
)

export const getHomePagePreferences = createSelector(
  userPreferencesSelector.getValue,
  userPref =>
    userPref?.homePage != null
      ? new Map<UUID, HomePagePreferenceDto>(Object.entries(userPref?.homePage))
      : new Map<UUID, HomePagePreferenceDto>(),
)

export const getSortByDateDescendingPref = createSelector(
  userPreferencesSelector.getValue,
  getEnableSortAsBuiltPreference,
  (userPref: UserPreferencesDto, sortAsBuiltPreferenceEnabled: boolean) =>
    sortAsBuiltPreferenceEnabled
      ? userPref.zlAsBuiltDescendingDate ?? false
      : false,
)

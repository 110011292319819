import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Actions, createEffect } from '@ngrx/effects'
import { applicationsConfig } from 'app/keycloak/applications.enum'
import { onCreate } from 'app/shared/utils/redux/operators'
import { map } from 'rxjs/operators'
import { AsyncEffects } from '../../../shared/utils/redux/async.effects'
import { TechniqueService } from '../../services/technique.service'
import { Go } from '../router/router.actions'
import { TechniquesActions } from './techniques.actions'

@Injectable()
export class TechniquesEffects {
  constructor(
    private actions$: Actions,
    private effectFactory: AsyncEffects,
    private techniqueService: TechniqueService,
    private router: Router,
  ) {}

  loadTechniques = createEffect(() =>
    this.effectFactory.asyncEffect(TechniquesActions.loadTechniques, () =>
      this.techniqueService.getAll(),
    ),
  )

  selectApplication$ = createEffect(() =>
    this.actions$.pipe(
      onCreate(TechniquesActions.selectApplication),
      map(action => action.payload),
      map(({ application, technique }) => {
        const conf = applicationsConfig.find(
          applicationCfg => applicationCfg.keycloakName === application.name,
        )
        const appUrl = conf.url

        const urlTreeParams = [appUrl]
        if (technique) {
          urlTreeParams.push(technique)
        }
        const urlTree = this.router.createUrlTree(urlTreeParams)
        return new Go(urlTree.toString(), conf.externalUrl)
      }),
    ),
  )
}

import { createSelector } from '@ngrx/store'
import { INIT_ACTIVE_LAYER } from 'app/shared/constants/hf-type.enum'
import { notEmpty } from 'app/shared/utils/notEmpty'
import { EntitiesSelectorFactory } from 'app/shared/utils/redux/entities/entities.selectors'
import { getJobsiteId } from '../../../../core/store/router/router.selectors'
import {
  MapSettingsModel,
  TaggedAsDoneFilter,
} from '../../models/map-settings.model'
import { getColumns, getSelectedTab } from '../jobsite-summary.selectors'
import { getState } from '../state'
import {
  INIT_MAP_CENTER,
  INIT_MAP_ROTATION,
  INIT_MAP_ZOOM,
} from '../../../../shared/openlayer/map.factory'
import { getDataScienceFilteredIds } from '../data-science/jobsite-data-science.selectors'
import { pileTaggedAsDoneSelector } from '../../pile-tagged-as-done/store/pile-tagged-as-done.selectors'

const mapSettingsSelectors = new EntitiesSelectorFactory(
  getState,
  state => state.mapSettings,
)

export const getMapSettings = createSelector(
  mapSettingsSelectors.getEntities,
  getJobsiteId,
  (entities, jobsiteId): MapSettingsModel => {
    const jobsiteMapSettings = entities[jobsiteId]
    return {
      id: jobsiteMapSettings?.id ?? jobsiteId,
      radius: jobsiteMapSettings?.radius ?? 50,
      center: jobsiteMapSettings?.center ?? INIT_MAP_CENTER,
      zoom: jobsiteMapSettings?.zoom ?? INIT_MAP_ZOOM,
      rotation: jobsiteMapSettings?.rotation ?? INIT_MAP_ROTATION,
      visibleTypeNodes: jobsiteMapSettings?.visibleTypeNodes,
      backgroundtype: jobsiteMapSettings?.backgroundtype ?? 'plain',
      scenetype: jobsiteMapSettings?.scenetype ?? '2D',
      threedsettings: jobsiteMapSettings?.threedsettings,
      pilesExtentProfile: jobsiteMapSettings?.pilesExtentProfile,
      activeLayers: jobsiteMapSettings?.activeLayers ?? INIT_ACTIVE_LAYER,
      visibleProcessEntries: jobsiteMapSettings?.visibleProcessEntries,
    }
  },
)

export const getMapSceneType = createSelector(
  getMapSettings,
  (mapSettings: MapSettingsModel) => mapSettings.scenetype,
)

export const getVisibleTypeNodes = createSelector(
  mapSettingsSelectors.getEntities,
  getJobsiteId,
  (entities, jobsiteId) => entities[jobsiteId]?.visibleTypeNodes,
)

export const getVisibleProcessEntries = createSelector(
  mapSettingsSelectors.getEntities,
  getJobsiteId,
  (entities, jobsiteId) => entities[jobsiteId]?.visibleProcessEntries,
)

export const getTaggedAsDoneFilter = createSelector(
  mapSettingsSelectors.getEntities,
  getJobsiteId,
  (entities, jobsiteId) =>
    entities[jobsiteId]?.taggedAsDoneFilter ??
    new TaggedAsDoneFilter(true, true),
)

export const getVisiblePileIds = createSelector(
  getColumns.getAll,
  pileTaggedAsDoneSelector.getAll,
  getVisibleTypeNodes,
  getVisibleProcessEntries,
  getTaggedAsDoneFilter,
  getSelectedTab,
  getDataScienceFilteredIds,
  (
    columns,
    taggedAsDone,
    visibleTypeNodes,
    visibleProcessEntries,
    taggedAsDoneFilter,
    tab,
    dataScienceFilteredIds,
  ) => {
    if (tab === 'data-science') {
      return columns
        .filter(c => !dataScienceFilteredIds.includes(c.key.id))
        .map(c => c.key.id)
    }
    const filterIdByType = visibleTypeNodes
      ?.flatMap(n => n.pileIds)
      .filter(notEmpty)
    const visibleProcessMap = Object.fromEntries(visibleProcessEntries ?? [])
    return (
      columns
        ?.filter(col => {
          if (
            taggedAsDoneFilter?.displayNotTaggedAsDone ||
            taggedAsDoneFilter?.displayTaggedAsDone
          ) {
            if (!taggedAsDoneFilter.displayTaggedAsDone) {
              return (
                taggedAsDone.find(t => col.key.id === t.dataId) == undefined
              )
            }
            if (!taggedAsDoneFilter.displayNotTaggedAsDone) {
              return (
                taggedAsDone.find(t => col.key.id === t.dataId) != undefined
              )
            }
            return true
          }
          return false
        })
        .filter(col => {
          if (visibleProcessMap?.[col.key?.process ?? ''] == null) {
            return true
          } else {
            return visibleProcessMap[col.key.process]
          }
        })
        .filter(col => {
          if (filterIdByType == null) {
            return true
          } else {
            return filterIdByType.includes(col.key.id)
          }
        })
        .map(col => col.key?.id) ?? []
    )
  },
)

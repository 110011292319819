import { createSelector } from '@ngrx/store'
import { notEmpty } from 'app/shared/utils/notEmpty'
import { EntitiesSelectorFactory } from 'app/shared/utils/redux/entities/entities.selectors'
import { TechniqueDto } from '../../../shared/remote-services/dtos/technique.dto'
import {
  getSelectedApplication,
  getTechnique,
} from '../router/router.selectors'
import { getState as getCoreState } from '../state'

export const techniqueSelectors = new EntitiesSelectorFactory(
  getCoreState,
  state => state.techniques,
)

export const getAvailableTechniques = createSelector(
  techniqueSelectors.getAll,
  getSelectedApplication,
  (allTechniques, application): TechniqueDto[] => {
    if (!application || !application.techniques) {
      return []
    }

    return application.techniques
      .map(techniqueName =>
        allTechniques.find(technique => technique.name === techniqueName),
      )
      .filter(notEmpty)
  },
)

export const getTechniqueIdByName = createSelector(
  techniqueSelectors.getAll,
  getTechnique,
  (techniques, techName) => {
    return techniques.find(technique => technique.name === techName)?.id
  },
)

import * as Guards from './export.model.type.guard'
import { JobsiteTableDataValues } from '../../jobsites-management/jobsite-summary/jobsite-data-table/models/jobsite-table-data-values.model'
import { ExportDailyReportPayload } from '../../jobsites-management/jobsite-summary/models/export.model'
import { UUID } from '../../shared/models/utils.type'
import {
  HFDeviationExportParams,
  HFVerticalityExportParams,
} from '../../jobsites-management/jobsite-summary/components/export-pdf-dialog/export-pdf-dialog.model'
import { VerticalityDataWithUnit } from '../../jobsites-management/jobsite-summary/components/verticality-data-csv-import-dialog/verticality-data-csv-import-dialog.model'

/** @see {isExportSuffix} ts-auto-guard:type-guard */
export type ExportSuffix = 'custompdf' | ExportHFSuffix | ExportRKSuffix

const EXPORT_HF_SUFFIXES = ['deviation', 'machine', 'verticality'] as const

/** @see {isExportHFSuffix} ts-auto-guard:type-guard */
export type ExportHFSuffix = (typeof EXPORT_HF_SUFFIXES)[number]

const EXPORT_RK_SUFFIXES = ['rk'] as const

/** @see {isExportRKSuffix} ts-auto-guard:type-guard */
export type ExportRKSuffix = (typeof EXPORT_RK_SUFFIXES)[number]

export class DtoDailyGroupLine {
  data: Record<keyof JobsiteTableDataValues, string>
  warnings: Record<string, string> | null

  constructor(
    data: Record<keyof JobsiteTableDataValues, string>,
    warnings: Record<string, string> | null,
  ) {
    this.warnings = warnings
    this.data = data
  }
}

/** @see {isDtoDailyHeader} ts-auto-guard:type-guard */
export type DtoDailyHeader = {
  key: string
  label: string
}

/** @see {isDtoDailyGroup} ts-auto-guard:type-guard */
export type DtoDailyGroup = {
  value: string | undefined
  lines: DtoDailyGroupLine[]
}

/** @see {isDtoDaily} ts-auto-guard:type-guard */
export type DtoDaily = ExportDailyReportPayload & {
  headers: DtoDailyHeader[]
  groups: DtoDailyGroup[]
  pileIds: string[]
  startDate: string | undefined
  endDate: string | undefined
}

export type DtoMultiReportBody<Config> = {
  config: Config
  pilesIds: UUID[]
}

export type DtoMultiReportDeviationBody =
  DtoMultiReportBody<HFDeviationExportParams>

export type DtoMultiReportVerticalityBody =
  DtoMultiReportBody<HFVerticalityExportParams>

export type DtoReportFromVerticalityCsvBody<Config> = {
  config: Config
  payload: VerticalityDataWithUnit
}

export type DtoDeviationReportFromVerticalityCsvBody =
  DtoReportFromVerticalityCsvBody<HFDeviationExportParams>

export type DtoVerticalityReportFromVerticalityCsvBody =
  DtoReportFromVerticalityCsvBody<HFVerticalityExportParams>

export { Guards }

import { Injectable } from '@angular/core'
import { Actions, createEffect } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { State } from 'app/core/store/state'
import { onCreate, onStart } from 'app/shared/utils/redux/operators'
import { map, switchMap } from 'rxjs/operators'
import { CompoundParamsService } from '../../services/compound-params.service'
import { getTechnique } from '../router/router.selectors'
import { CompoundParamsActions } from './compoundparams.actions'

@Injectable()
export class CompoundParamsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private compoundParamService: CompoundParamsService,
  ) {}

  loadCompoundParams$ = createEffect(() =>
    this.actions$.pipe(
      onStart(CompoundParamsActions.loadCompoundParams),
      switchMap(() => this.store.pipe(select(getTechnique))),
      map(techniqueName =>
        CompoundParamsActions.loadCompoundParamsOfTechnique.create(
          techniqueName,
        ),
      ),
    ),
  )

  loadCompoundParamsOfTechnique$ = createEffect(() =>
    this.actions$.pipe(
      onCreate(CompoundParamsActions.loadCompoundParamsOfTechnique),
      switchMap(action =>
        this.compoundParamService
          .getAllCompoundParams(action.payload)
          .pipe(
            map(dtos =>
              CompoundParamsActions.loadCompoundParams.createComplete(dtos),
            ),
          ),
      ),
    ),
  )
}

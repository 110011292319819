import { UserModel } from 'app/keycloak/models/user.model'
import { AsyncActionFactory } from 'app/shared/utils/redux/async.actions'
import { SyncActionFactory } from 'app/shared/utils/redux/sync.actions'
import {
  DateTimeFormatsDto,
  HomePagePreferenceDto,
  UserPreferencesDto,
} from '../../../shared/remote-services/dtos/userPreferences.dto'
import { LoadableActionFactory } from '../../../shared/utils/redux/loadable/loadable.actions'
import { UUID } from '../../../shared/models/utils.type'

export class UserActions {
  public static loadUser = new LoadableActionFactory<void, UserModel>(
    '[CORE] LOAD_USER',
  )

  public static loadUserPreferences = new LoadableActionFactory<
    void,
    UserPreferencesDto
  >('[CORE] LOAD USER PREFERENCES')

  public static updateUnitSystem = new SyncActionFactory<UserPreferencesDto>(
    '[CORE] UPDATE UNIT SYSTEM',
  )

  public static updateLanguage = new AsyncActionFactory<UserPreferencesDto>(
    '[CORE] UPDATE LANGUAGE',
  )

  public static updateTutoIsRead = new AsyncActionFactory<UserPreferencesDto>(
    '[CORE] UPDATE TUTO IS READ',
  )

  public static updateDateTimeFormats =
    new SyncActionFactory<DateTimeFormatsDto>('[CORE] UPDATE DATE TIME FORMATS')

  public static updateRigPathEnabled = new SyncActionFactory<boolean>(
    '[CORE] UPDATE RIG PATH ENABLED',
  )

  public static updateHomePagePreferences = new SyncActionFactory<
    Map<UUID, HomePagePreferenceDto>
  >('[CORE] UPDATE HOME PAGE PREFERENCES')

  public static updateAsBuiltSortDateDescending =
    new SyncActionFactory<boolean>(
      '[CORE] UPDATE AS-BUILT SORT DATE DESCENDING',
    )
}

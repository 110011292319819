import { CsvDateFormatLocalStorageService } from './csv-date-format-local-storage.service'
import { DateTimeFormatService } from './date-time-format.service'
import { HfMachineColumnWarningService } from './hf-machine-column-warning.service'
import { HighchartsService } from './highcharts.service'

export const sharedServices = [
  CsvDateFormatLocalStorageService,
  DateTimeFormatService,
  HfMachineColumnWarningService,
  HighchartsService,
]

import { SyncActionFactory } from 'app/shared/utils/redux/sync.actions'
import { JobsiteSummaryState } from '../state'
import { DateRange } from '../../../../shared/localized-date-time-picker/localized-date-range-picker.component'
import { DataScienceFilter } from '../../jobsite-data-science/models/data-science-filter.model'
import { LoadableActionFactory } from '../../../../shared/utils/redux/loadable/loadable.actions'
import { DataScienceFilterValues } from '../../jobsite-data-science/models/data-science-filter-values.model'

export class JobsiteDataScienceActions {
  public static setDataScienceChartsUrls = new SyncActionFactory<
    JobsiteSummaryState['dataScienceChartUrls']
  >('[JOBSITE DATA SCIENCE] GET CHARTS URLS')

  public static setDateRange = new SyncActionFactory<DateRange>(
    '[JOBSITE DATA SCIENCE] SET DATE RANGE',
  )

  public static setDataScienceFilter = new SyncActionFactory<DataScienceFilter>(
    '[JOBSITE DATA SCIENCE] SET FILTER',
  )

  public static loadDataScienceFilterValues = new LoadableActionFactory<
    void,
    DataScienceFilterValues
  >('[JOBSITE DATA SCIENCE] LOAD DATA SCIENCE FILTER VALUES')
}

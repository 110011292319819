import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { FormGroup, ValidationErrors } from '@angular/forms'
import { ZlHelperComponentOptions } from 'app/shared/models/zl-helper.model'
import { JobsiteChecksWithValueControls } from '../../../../../shared/remote-services/dtos/jobsite-checks.model'

@Component({
  selector: 'warning-checks-form-row',
  template: `
    <form
      [formGroup]="parentFormGroup"
      class="form-container flex flex-wrap flex-align-end--center"
    >
      <mat-form-field class="custom-size" appearance="fill">
        <mat-label>
          {{ inputLabel }}
        </mat-label>
        <input
          matInput
          type="number"
          formControlName="checkValue"
          [min]="minValue"
          [max]="maxValue"
        />
        <zl-helper
          matSuffix
          [popoverText]="helperText"
          [options]="options"
        ></zl-helper>
        <mat-error *ngIf="parentFormGroup?.controls?.checkValue.errors">
          {{
            getErrorKey(parentFormGroup.controls.checkValue.errors) | translate
          }}
        </mat-error>
      </mat-form-field>

      <mat-slide-toggle
        class="custom-size"
        formControlName="checkEnabled"
        color="primary"
        labelPosition="before"
      >
        {{ 'AS_BUILT_CHECKS.CHECK_ENABLED' | translate }}
      </mat-slide-toggle>
    </form>
  `,
  styles: [
    `
      .form-container mat-form-field,
      .form-container mat-slide-toggle {
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 5px;
      }
      .custom-size {
        flex: 0 1 calc(50% - 20px);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WarningChecksFormRowComponent {
  @Input() inputLabel: string
  @Input() helperText: string
  @Input() parentFormGroup: FormGroup<JobsiteChecksWithValueControls>
  @Input() minValue: number | undefined
  @Input() maxValue: number | undefined

  public options: ZlHelperComponentOptions = {
    greyBackground: true,
    iconSize: 'MEDIUM',
    marginBottom: null,
    marginLeft: '10px',
  }

  getErrorKey(errors: ValidationErrors): string {
    return errors['required']
      ? 'GENERAL.FIELD_REQUIRED'
      : errors['min']
      ? 'AS_BUILT_CHECKS.ERROR_MIN_THRESHOLD'
      : 'AS_BUILT_CHECKS.ERROR_MAX_THRESHOLD'
  }
}

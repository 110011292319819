import { ListActionFactory } from 'app/shared/utils/redux/list/list.actions'
import { CompoundParamDto } from 'app/core/models/compoundParameter.model'
import { SyncActionFactory } from '../../../shared/utils/redux/sync.actions'
import { TechniqueNames } from '../../../shared/remote-services/dtos/technique.dto'

export class CompoundParamsActions {
  public static loadCompoundParams = new ListActionFactory<
    CompoundParamDto,
    void
  >('[CORE] LOAD COMPOUND PARAMS')

  public static loadCompoundParamsOfTechnique =
    new SyncActionFactory<TechniqueNames>(
      '[CORE] LOAD COMPOUND PARAMS OF TECHNIQUE',
    )
}

import { SyncActionFactory } from 'app/shared/utils/redux/sync.actions'
import { EntitiesActionFactory } from '../../../shared/utils/redux/entities/entities.actions'
import { UploadFileModel } from '../models/upload-file.model'

export class FilesUploadActions {
  public static files = new EntitiesActionFactory<UploadFileModel>(
    '[FILES UPLOAD] FILES',
  )
  public static setIsExpanded = new SyncActionFactory<boolean>(
    '[FILES UPLOAD] SET IS EXPANDED',
  )
  public static setVisible = new SyncActionFactory<boolean>(
    '[FILES UPLOAD] SET VISIBLE',
  )
}

<div mat-dialog-title style="position: relative">
  <p>
    {{ dialogTitle | translate }}
    <mat-icon
      *ngIf="dialogTitleHelpLink"
      class="help-icon"
      (click)="openHelpDoc()"
    >
      help_outline
    </mat-icon>
    <mat-icon class="setting-icon" (click)="toggleImportSettingPopup(true)">
      settings
    </mat-icon>
  </p>
  <div *ngIf="showImportSettings" class="import-settings-container boxShadow">
    <div class="import-settings">
      <shared-csv-import-settings
        (toggleImportSettingPopupEvent)="toggleImportSettingPopup($event)"
        (updateCSVSeparatorEvent)="updateCSVSeparator($event)"
        [initialSeparator]="csvSeparator"
      >
        <ng-content select="AdditionalimportSetting"></ng-content>
      </shared-csv-import-settings>
    </div>
    <span class="triangle-top"></span>
  </div>
</div>

<div mat-dialog-content class="flex-layout-column dialog-container">
  <div class="select-container flex-20 flex flex-align--center">
    <p *ngIf="selectedFile">
      <span class="fa fa-file-excel-o"></span>
      {{ selectedFile.name }}
    </p>
    <label translate class="btn btn-default btn-file">
      <span class="fa fa-file-excel-o"></span>
      PROJECT_MANAGEMENT.JOBSITE_LAYOUT.SELECT_FILE
      <input
        type="file"
        accept=".csv"
        class="hidden"
        (change)="handleFileSelected($event)"
      />
    </label>
    <!--- slot for checkbox, and error message  -->
    <ng-content select="[top]"></ng-content>
  </div>

  <div class="import-preview flex-80">
    <div class="headlink">
      <form
        [formGroup]="headersForm"
        class="field-container flex-layout-column"
      >
        <div *ngFor="let headerKeyValue of headerFormKeys | keyvalue">
          <div class="flex">
            <mat-form-field class="flex-1">
              <mat-label>{{
                headerTranslationPrefix + headerKeyValue.key | translate
              }}</mat-label>
              <mat-select formControlName="{{ headerKeyValue.key }}">
                <mat-option
                  *ngIf="!requiredHeaderFormKeys.includes(headerKeyValue.key)"
                  >None</mat-option
                >
                <mat-option
                  *ngFor="let header of csvData.headers"
                  [value]="header"
                >
                  {{ header }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field
              class="flex-50px"
              *ngIf="getUnitValue(headerKeyValue.value)"
            >
              <mat-label style="font-weight: bold; color: red">{{
                'CAGES.unit' | translate
              }}</mat-label>
              <mat-select
                [value]="getUnitValue(headerKeyValue.value)"
                (selectionChange)="
                  unitChanged(headerKeyValue.key, $event.value)
                "
              >
                <mat-option
                  *ngFor="let unitOption of availableUnits"
                  [value]="unitOption"
                  >{{ unitOption }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>
    <span class="divider"></span>
    <ng-content select="[importOption]"></ng-content>
    <div class="import-table">
      <div
        [class]="displayTable ? 'errors-wrapper' : 'full-errors-wrapper'"
        *ngIf="getErrors.length > 0"
      >
        <ul class="csv-error">
          <li *ngFor="let error of getErrors">{{ error }}</li>
        </ul>
      </div>
      <ng-container *ngIf="displayTable">
        <mat-paginator
          [pageSizeOptions]="[5, 10, 20]"
          showFirstLastButtons
        ></mat-paginator>
        <div class="table-container">
          <table mat-table [dataSource]="dataSource" matSort>
            <ng-container
              matColumnDef="{{ column }}"
              *ngFor="let column of tableHeaders"
            >
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ translateWithUnit(column) }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element[column] }}
              </td>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="tableHeaders; sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: tableHeaders"></tr>
          </table>
        </div>
      </ng-container>
    </div>
  </div>
</div>

import * as Guards from './map-settings.model.type.guard'
import { HFType } from 'app/shared/constants/hf-type.enum'
import { Process } from 'app/shared/constants/process.model'
import { Coordinate } from 'ol/coordinate'
import { TreeFlatNode } from './columns-type-tree.model'
import { ThreeDSceneSettingsModel } from './three-d-scene-settings.model'

/** @see {isBackgroundType} ts-auto-guard:type-guard */
export type BackgroundType = 'plain' | 'map' | 'satellite'

/** @see {isSceneType} ts-auto-guard:type-guard */
export type SceneType = '2D' | '3D'

/** @see {isPilesExtentProfile} ts-auto-guard:type-guard */
export interface PilesExtentProfile {
  center: Coordinate
  width: number
  height: number
}

export class TaggedAsDoneFilter {
  displayTaggedAsDone: boolean
  displayNotTaggedAsDone: boolean

  constructor(displayTaggedAsDone: boolean, displayNotTaggedAsDone: boolean) {
    this.displayNotTaggedAsDone = displayNotTaggedAsDone
    this.displayTaggedAsDone = displayTaggedAsDone
  }
}

/** @see {isMapSettingsModel} ts-auto-guard:type-guard */
export interface MapSettingsModel {
  id: string
  radius: number
  center: [number, number]
  zoom: number
  rotation: number
  visibleTypeNodes?: TreeFlatNode[]
  backgroundtype: BackgroundType
  scenetype: SceneType
  threedsettings?: ThreeDSceneSettingsModel
  pilesExtentProfile?: PilesExtentProfile
  activeLayers?: HFType[]
  visibleProcessEntries?: [process: Process, visible: boolean][]
  taggedAsDoneFilter?: TaggedAsDoneFilter
}

export { Guards }

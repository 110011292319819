import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { JobsiteChecksSettingsDto } from './dtos/jobsite-checks.model'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

@Injectable()
export class JobsiteChecksService {
  constructor(private http: HttpClient) {}

  private baseUrl = 'jobsite/jobsites/checks-settings'

  public getJobsiteChecksSettings(
    jobsiteId: string,
  ): Observable<JobsiteChecksSettingsDto> {
    return this.http.get<JobsiteChecksSettingsDto>(
      `${this.baseUrl}/${jobsiteId}`,
    )
  }

  public createOrUpdateJobsiteChecksSettings(
    jobsiteId: string,
    dto: JobsiteChecksSettingsDto,
  ): Observable<JobsiteChecksSettingsDto> {
    return dto.id != null
      ? this.updateJobsiteChecksSettings(jobsiteId, dto).pipe(map(() => dto))
      : this.createJobsiteChecksSettings(jobsiteId, dto).pipe(
          map(id => ({ ...dto, id })),
        )
  }

  public createJobsiteChecksSettings(
    jobsiteId: string,
    dto: JobsiteChecksSettingsDto,
  ): Observable<string> {
    return this.http.post<string>(`${this.baseUrl}/${jobsiteId}`, dto)
  }

  public updateJobsiteChecksSettings(
    jobsiteId: string,
    dto: JobsiteChecksSettingsDto,
  ): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/${jobsiteId}`, dto)
  }

  public deleteJobsiteChecksSettings(
    jobsiteId: string,
    id: string,
  ): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${jobsiteId}/${id}`)
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { Permissions } from '../keycloak/models/permissions.model'
import { KeycloakService } from '../keycloak/keycloak.service'
import build from '../../build'
import { environment } from 'environments/environment'
import { UserPreferencesDto } from '../shared/remote-services/dtos/userPreferences.dto'
import { JobsiteDto } from '../shared/remote-services/dtos/jobsite.dto'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { JobsiteDevInfoDialog } from '../shared/components/jobsite-dev-info.dialog'
import { TechniqueNames } from '../shared/remote-services/dtos/technique.dto'
import { ServerInfoDialogContainerComponent } from '../shared/components/server-info-dialog-container/server-info-dialog-container.component'
import { JobsiteDevInfoDialogInput } from '../shared/models/dev-info.model'
import { ManageDisruptionsDialogContainerComponent } from '../platform-disruptions/admin-modal/manage-disruptions-dialog-container.component'
import { firstValueFrom } from 'rxjs'
import { JobsiteService } from '../shared/remote-services/jobsite.service'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  @Input() userPermission: Permissions
  @Input() userPreferences: UserPreferencesDto
  @Input() currentJobsite?: JobsiteDto
  @Input() technique?: TechniqueNames

  public buildInfo = build
  public environment = environment

  constructor(
    private keycloakService: KeycloakService,
    private jobsiteService: JobsiteService,
    private dialog: MatDialog,
  ) {}

  getToken(): string {
    return `Bearer ${this.keycloakService.getToken()}`
  }

  asDate(timestamp: string | undefined): Date {
    return timestamp ? new Date(timestamp) : undefined
  }

  testSentry(): void {
    throw new Error('Sentry Test')
  }

  openJobsiteDevInfo(): void {
    this.dialog.open<JobsiteDevInfoDialog, JobsiteDevInfoDialogInput, null>(
      JobsiteDevInfoDialog,
      {
        data: {
          jobsite: this.currentJobsite,
          technique: this.technique,
        },
        width: '700px',
      },
    )
  }

  openServersVersion(): void {
    this.dialog.open<ServerInfoDialogContainerComponent, null, null>(
      ServerInfoDialogContainerComponent,
      {
        width: '700px',
        disableClose: true,
      },
    )
  }

  openPlatformDisruptions(): void {
    this.dialog.open<ManageDisruptionsDialogContainerComponent, null, null>(
      ManageDisruptionsDialogContainerComponent,
      {
        disableClose: true,
        autoFocus: false,
      },
    )
  }

  syncLutz(): void {
    if (!this.technique) return
    firstValueFrom(this.jobsiteService.syncLutz(this.technique))
  }

  goToMatomo(): void {
    window.open(
      `${this.environment.matomo.trackerUrl}/index.php?module=CoreHome&action=index&date=today&period=day&idSite=${this.environment.matomo.siteId}`,
      '_blank',
    )
  }
}

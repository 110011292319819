import { createSelector } from '@ngrx/store'
import { EntitiesSelectorFactory } from 'app/shared/utils/redux/entities/entities.selectors'
import * as core from '../state'
import { getLoaded, getUserState } from '../user'

export const companiesSelectors = new EntitiesSelectorFactory(
  core.getState,
  state => state.companies,
)

export const getUserCompany = createSelector(
  getUserState,
  getLoaded,
  companiesSelectors.getAll,
  (state, loaded, companies) =>
    loaded
      ? companies.find(c => c.name === state.value.companyName)
      : undefined,
)

export const getCurrentCompany = createSelector(
  companiesSelectors.getAll,
  getUserCompany,
  (companies, userCompany) => companies.find(c => c.id === userCompany.id),
)

export const getUserCompanyName = createSelector(
  getUserState,
  getLoaded,
  (state, loaded) => (loaded ? state.value.companyName : undefined),
)

export const getRoCompanies = createSelector(
  getUserState,
  getLoaded,
  getUserCompanyName,
  companiesSelectors.getAll,
  (state, loaded, userCompanyName, companies) => {
    if (
      loaded &&
      userCompanyName &&
      state.value.roCompanyNames &&
      companies &&
      companies.length > 1
    ) {
      return state.value.roCompanyNames
        .filter(roCompanyName => roCompanyName !== userCompanyName)
        .map(roCompanyName => companies.find(c => c.name === roCompanyName))
    } else {
      return []
    }
  },
)

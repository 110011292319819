import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { JobsiteTableDataValues } from '../../jobsites-management/jobsite-summary/jobsite-data-table/models/jobsite-table-data-values.model'
import { Metric } from 'app/shared/constants/metric.enum'
import { LocalizedDateService } from 'app/shared/utils/services/localized-date.service'
import moment from 'moment'
import { Primitive } from 'utility-types'
import { SharedTableBase } from '../../shared/models/shared-data-table.model'
import { DtoDailyGroupLine } from '../models/export.model'
import { ProdEventSummaries } from '../../jobsites-management/jobsite-summary/models/prod-event.model'
import { DailyReportLanguage } from '../../jobsites-management/jobsite-summary/models/export.model'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

interface TranslateData {
  header: Metric | null
  translateKey: string
}

@Injectable()
export class TableFormatterService {
  constructor(
    private translateService: TranslateService,
    private localizedDateService: LocalizedDateService,
  ) {}

  public getHeaderTranslation(
    column: string,
    units: Record<string, string>,
  ): string {
    const translateData: TranslateData = this.getTranslateData(column)
    return this.getFinalHeaderTranslation(
      this.translateService.instant(translateData.translateKey),
      units,
      translateData.header,
    )
  }

  public getHeaderTranslationForLanguage(
    column: string,
    units: Record<string, string>,
    language: DailyReportLanguage | null = null,
  ): Observable<string> {
    const translateData: TranslateData = this.getTranslateData(column)
    const translateIn: string =
      language !== null && this.translateService.getLangs().includes(language)
        ? language
        : this.translateService.currentLang

    return this.translateService.getTranslation(translateIn).pipe(
      map(translations => {
        const parsedResult = this.translateService.getParsedResult(
          translations,
          translateData.translateKey,
        )
        return this.getFinalHeaderTranslation(
          parsedResult,
          units,
          translateData.header,
        )
      }),
    )
  }

  private getTranslateData(column: string): TranslateData {
    let header: Metric | null = null
    let translateKey: string = 'DATA_TABLE.' + column

    if (column === 'date') {
      translateKey = 'DATA_TABLE.endDate'
    } else if (column === 'time') {
      translateKey = 'DATA_TABLE.endTime'
    } else {
      header = this.getHeader(column)
    }

    return {
      header,
      translateKey,
    }
  }

  private getFinalHeaderTranslation(
    translation: string,
    units: Record<string, string>,
    header: Metric | null,
  ): string {
    return `${translation}${
      header != null ? ' ' + this.getHeaderUnit(units, header) : ''
    }`
  }

  public getHeader(column: string): Metric | null {
    if (column.toLowerCase().includes('depth')) {
      return Metric.HeaderLength
    } else if (column.toLocaleLowerCase() === 'width') {
      return Metric.Width
    } else if (column.toLocaleLowerCase() === 'length') {
      return Metric.Length
    } else if (column === 'ptflevel' || column == 'platformLevel') {
      return Metric.PlatformLevel
    } else if (column.toLowerCase().includes('diameter')) {
      return Metric.HeaderDiameter
    } else if (column.toLowerCase().includes('volume')) {
      return Metric.HeaderTotalVolume
    } else {
      return null
    }
  }

  private getHeaderUnit(units: Record<string, string>, header: Metric): string {
    return `(${units[header] ?? 'n/a'})`
  }

  public getTableColumnValue<A extends SharedTableBase>(
    columnName: keyof A,
    value: Primitive,
  ): string {
    if (typeof value === 'bigint' || typeof value === 'boolean') {
      return value.toLocaleString()
    }
    if (typeof value === 'symbol') {
      throw Error('Value not support yet !')
    }

    const specificColumnValue = this.getSpecificColumnValue(columnName, value)

    return specificColumnValue != null
      ? specificColumnValue
      : value != null
      ? String(value)
      : ''
  }

  public getSpecificColumnValue<A extends SharedTableBase>(
    columnName: keyof A,
    value: Primitive,
  ): string | null {
    if (
      typeof value === 'bigint' ||
      typeof value === 'boolean' ||
      typeof value === 'symbol'
    ) {
      return null
    }

    if (columnName === 'date') {
      return this.localizedDateService.getFormattedDate(value)
    } else if (columnName.toString().toLowerCase().endsWith('time')) {
      return this.localizedDateService.getFormattedTime(value)
    } else if (columnName.toString().toLowerCase().includes('date')) {
      return this.localizedDateService.getFormattedDateTime(value)
    } else if (columnName === 'isDone') {
      return value
        ? this.translateService.instant('DATA_TABLE.DONE')
        : this.translateService.instant('DATA_TABLE.TODO')
    } else if (
      columnName === 'timeSpentBenne' ||
      columnName === 'timeSpentKS'
    ) {
      if (!value) {
        return ''
      }
      const duration = moment.duration(value, 'seconds')
      return `${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`
    } else {
      return null
    }
  }

  public getNormalizedLine(
    obj: JobsiteTableDataValues,
    prodEventSummaries: ProdEventSummaries | null,
  ): DtoDailyGroupLine {
    const warnings: Record<string, string> = {
      ...(prodEventSummaries?.summariesByTechniqueByColumn?.KS?.[obj.id]
        ?.dataHasBeenFiltered
        ? {
            timeSpentKS: 'PROD_EVENT',
            depthDugKs: 'PROD_EVENT',
          }
        : {}),
      ...(prodEventSummaries?.summariesByTechniqueByColumn?.BENNE?.[obj.id]
        ?.dataHasBeenFiltered
        ? {
            timeSpentBenne: 'PROD_EVENT',
            depthDugBenne: 'PROD_EVENT',
          }
        : {}),
    }
    return new DtoDailyGroupLine(
      Object.fromEntries(
        Object.entries(obj).map(([key, val]) => [
          key,
          this.getTableColumnValue(key as keyof JobsiteTableDataValues, val),
        ]),
      ) as Record<keyof JobsiteTableDataValues, string>,
      warnings,
    )
  }
}

import { Injectable } from '@angular/core'
import { Actions, createEffect } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { notEmpty } from 'app/shared/utils/notEmpty'
import { onCreate, onStart } from 'app/shared/utils/redux/operators'
import { of } from 'rxjs'
import { catchError, filter, map, switchMap, take } from 'rxjs/operators'
import { FieldService } from '../../../shared/remote-services/field.service'
import { getTechnique } from '../router/router.selectors'
import { State } from '../state'
import { FieldsActions } from './fields.actions'

@Injectable()
export class FieldsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private fieldService: FieldService,
  ) {}

  loadFields = createEffect(() =>
    this.actions$.pipe(
      onStart(FieldsActions.loadFields),
      switchMap(() =>
        this.store.pipe(select(getTechnique), filter(notEmpty), take(1)),
      ),
      map(technique => FieldsActions.loadFieldsOfTechnique.create(technique)),
    ),
  )

  loadFieldsOfTechnique$ = createEffect(() =>
    this.actions$.pipe(
      onCreate(FieldsActions.loadFieldsOfTechnique),
      map(action => action.payload),
      switchMap(technique =>
        this.fieldService.getFields(technique).pipe(
          map(fields =>
            FieldsActions.loadFields.createComplete({
              techniqueName: technique,
              fields,
            }),
          ),
          catchError(() => of(FieldsActions.loadFields.createFailed())),
        ),
      ),
    ),
  )
}

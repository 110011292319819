import { ActionReducer } from '@ngrx/store'
import { TypedAction } from 'app/shared/utils/redux/loadable/loadable.actions'
import { castDraft, produce } from 'immer'
import { reducerFromActionHandlers } from '../utils.reducers'
import { EntitiesActionFactory } from './entities.actions'
import { EntitiesState } from './entities.state'

export function createEntitiesReducer<Item>(
  actionFactory: EntitiesActionFactory<Item, any>,
  idSelector: (item: Item) => string = item => (item as any).id,
): ActionReducer<EntitiesState<Item>, TypedAction<Item>> {
  const initialState: EntitiesState<Item> = {
    loading: false,
    loaded: false,
    value: {},
  }

  return reducerFromActionHandlers<EntitiesState<Item>, any>(initialState, [
    {
      actionType: actionFactory.COMPLETE,
      handler: (
        state,
        action: ReturnType<(typeof actionFactory)['createComplete']>,
      ) => ({
        loaded: true,
        loading: false,
        value: Object.fromEntries(
          action.payload.map(item => [idSelector(item), item]),
        ),
      }),
    },
    {
      actionType: actionFactory.START,
      handler: state => ({ ...initialState, loading: true }),
    },
    {
      actionType: actionFactory.FAILED,
      handler: state =>
        produce(state, draft => {
          draft.loaded = false
          draft.loading = false
        }),
    },
    {
      actionType: actionFactory.RESET,
      handler: state => ({ ...initialState }),
    },
    {
      actionType: actionFactory.ADD_ITEMS,
      handler: (
        state,
        action: ReturnType<(typeof actionFactory)['createAddItems']>,
      ) =>
        produce(state, draft => {
          draft.loading = false
          draft.loaded = true
          for (const item of action.payload) {
            draft.value[idSelector(item)] = castDraft(item)
          }
        }),
    },
    {
      actionType: actionFactory.ADD_ITEM,
      handler: (
        state,
        action: ReturnType<(typeof actionFactory)['createAddItem']>,
      ) =>
        produce(state, draft => {
          draft.loading = false
          draft.loaded = true
          draft.value[idSelector(action.payload.item)] = castDraft(
            action.payload.item,
          )
        }),
    },
    {
      actionType: actionFactory.REMOVE_ITEMS,
      handler: (
        state,
        action: ReturnType<(typeof actionFactory)['createRemoveItems']>,
      ) =>
        produce(state, draft => {
          draft.loading = false
          draft.loaded = true
          for (const id of action.payload.map(idSelector)) {
            delete draft.value[id]
          }
        }),
    },
    {
      actionType: actionFactory.REMOVE_IDS,
      handler: (
        state,
        action: ReturnType<(typeof actionFactory)['createRemoveIds']>,
      ) =>
        produce(state, draft => {
          draft.loading = false
          draft.loaded = true
          for (const id of action.payload) {
            delete draft.value[id]
          }
        }),
    },
    {
      actionType: actionFactory.SET_ITEMS,
      handler: (
        state,
        action: ReturnType<(typeof actionFactory)['createSetItems']>,
      ) =>
        produce(state, draft => {
          draft.loading = false
          draft.loaded = true
          draft.value = castDraft(
            Object.fromEntries(
              action.payload.map(item => [idSelector(item), item]),
            ),
          )
        }),
    },
  ])
}

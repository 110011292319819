import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { State as CoreState } from 'app/core/store/state'
import { AbstractLoadableGuard } from '../../shared/utils/abstract-loadable.guard'
import { CompaniesActions } from '../store/companies/companies.actions'
import { companiesSelectors } from '../store/companies/companies.selectors'

@Injectable()
export class CompaniesGuard extends AbstractLoadableGuard<CoreState> {
  constructor(store: Store<CoreState>) {
    super(store, companiesSelectors.getLoaded, CompaniesActions.companies)
  }
}

import { produce } from 'immer'
import { Color } from 'ol/color'
import { ColorLike } from 'ol/colorlike'
import { ColoringByDayActions } from './coloringbyday.actions'

export interface ColoringByDayMapping {
  day1: ColorLike | Color
  day2: ColorLike | Color
  day3: ColorLike | Color
  day4: ColorLike | Color
  day5: ColorLike | Color
  day6: ColorLike | Color
  day7: ColorLike | Color
  day8plus: ColorLike | Color
}

export const initialState: ColoringByDayMapping = {
  day1: '#eb1a1a',
  day2: '#ebcb1a',
  day3: '#4fc926',
  day4: '#2fedda',
  day5: '#2161d9',
  day6: '#903fe0',
  day7: '#e03f90',
  day8plus: '#313636',
}

export const actionHandlers = [
  {
    actionType: ColoringByDayActions.setColorMapping.CREATE,
    handler: (
      state,
      action: ReturnType<
        (typeof ColoringByDayActions.setColorMapping)['create']
      >,
    ) => {
      return produce(state, draft => {
        draft.coloringByDay[action.payload.day] = action.payload.color
      })
    },
  },
]

import * as Guards from './concrete.dto.type.guard'
import { FormControl } from '@angular/forms'
import { ColumnLink } from './column.dto'

/** @see {isAddConcreteTypeDefinition} ts-auto-guard:type-guard */
export type AddConcreteTypeDefinition = Omit<ConcreteTypeDefinition, 'id'>

/** @see {isAddConcreteProdRecord} ts-auto-guard:type-guard */
export type AddConcreteProdRecord = Omit<ConcreteProdRecordInput, 'id'>

/** @see {isAddConcreteDelivery} ts-auto-guard:type-guard */
export type AddConcreteDelivery = Omit<ConcreteDelivery, 'id'>

/** @see {isConcreteTypeDefinition} ts-auto-guard:type-guard */
export interface ConcreteTypeDefinition {
  id?: string
  jobsiteId: string
  refIdentifierLabel: string
  orderNumber?: string | null
  lineNumber?: number | null
  wbsCode?: string | null
  supplierId: string | null
  orderDescription?: string | null
  orderUnit: string
  strengthClass: string
  cementType: string
  aggregate: number
  consistenceClassType: ConsistenceClassType
  consistenceClassValue: number
  dcSulphateClass?: string | null
  cementContent: number
  freeWaterCementRatio: number
  fines: number
  links?: ColumnLink[] | null
}

export type ConcreteTypeDefinitionControls = {
  refIdentifierLabel: FormControl<string>
  orderNumber: FormControl<string | null>
  lineNumber: FormControl<number | null>
  wbsCode: FormControl<string | null>
  supplierId: FormControl<string | null>
  orderDescription: FormControl<string | null>
  orderUnit: FormControl<string>
  strengthClass: FormControl<string>
  cementType: FormControl<string>
  aggregate: FormControl<number>
  consistenceClassType: FormControl<ConsistenceClassType>
  consistenceClassValue: FormControl<number>
  dcSulphateClass: FormControl<string | null>
  cementContent: FormControl<number>
  freeWaterCementRatio: FormControl<number>
  fines: FormControl<number>
}

/** @see {isConsistenceClassType} ts-auto-guard:type-guard */
export enum ConsistenceClassType {
  SLUMP = 'SLUMP',
  FLOW = 'FLOW',
}

/** @see {isConcreteProdRecord} ts-auto-guard:type-guard */
export interface ConcreteProdRecord {
  id: string

  concreteTypeDefinition: ConcreteTypeDefinition
  columnId: string
  jobsiteId: string

  comment?: string | null
}

/** @see {isConcreteProdRecordInput} ts-auto-guard:type-guard */
export interface ConcreteProdRecordInput extends ConcreteProdRecord {
  deliveriesIds: string[]
}

/** @see {isConcreteDelivery} ts-auto-guard:type-guard */
export interface ConcreteDelivery {
  id?: string
  concreteTypeDefinitionId: string
  jobsiteId: string
  refIdentifierLabel: string
  contractNumber?: number
  contractName?: string
  wbsCode?: string
  ticketNumber: number
  date: string
  batchTime: string
  load: number
  timeOfArrival: string
  dischargeTimeStart: string
  dischargeTimeFinish: string
  timeOfDeparture: string
  inspection?: string
  columnsIds: string[]
  inspectionValidated: boolean
  filePath?: string
}

export type ConcreteDeliveryControls = {
  refIdentifierLabel: FormControl<string>
  contractNumber: FormControl<number | null>
  contractName: FormControl<string | null>
  wbsCode: FormControl<string | null>
  ticketNumber: FormControl<number>
  date: FormControl<string>
  batchTime: FormControl<string>
  load: FormControl<number>
  timeOfArrival: FormControl<string>
  dischargeTimeStart: FormControl<string>
  dischargeTimeFinish: FormControl<string>
  timeOfDeparture: FormControl<string>
  inspection: FormControl<string | null>
  inspectionValidated: FormControl<boolean>
}

export { Guards }

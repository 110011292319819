/*
 * Generated type guards for "concrete.dto.ts".
 * WARNING: Do not manually change this file.
 */
import { isColumnLink } from './column.dto.type.guard'
import {
  AddConcreteTypeDefinition,
  AddConcreteProdRecord,
  AddConcreteDelivery,
  ConcreteTypeDefinition,
  ConsistenceClassType,
  ConcreteProdRecord,
  ConcreteProdRecordInput,
  ConcreteDelivery,
} from './concrete.dto'

export function isAddConcreteTypeDefinition(
  obj: unknown,
): obj is AddConcreteTypeDefinition {
  const typedObj = obj as AddConcreteTypeDefinition
  return (
    ((typedObj !== null && typeof typedObj === 'object') ||
      typeof typedObj === 'function') &&
    typeof typedObj['jobsiteId'] === 'string' &&
    typeof typedObj['refIdentifierLabel'] === 'string' &&
    (typeof typedObj['orderNumber'] === 'undefined' ||
      typedObj['orderNumber'] === null ||
      typeof typedObj['orderNumber'] === 'string') &&
    (typeof typedObj['lineNumber'] === 'undefined' ||
      typedObj['lineNumber'] === null ||
      typeof typedObj['lineNumber'] === 'number') &&
    (typeof typedObj['wbsCode'] === 'undefined' ||
      typedObj['wbsCode'] === null ||
      typeof typedObj['wbsCode'] === 'string') &&
    (typedObj['supplierId'] === null ||
      typeof typedObj['supplierId'] === 'string') &&
    (typeof typedObj['orderDescription'] === 'undefined' ||
      typedObj['orderDescription'] === null ||
      typeof typedObj['orderDescription'] === 'string') &&
    typeof typedObj['orderUnit'] === 'string' &&
    typeof typedObj['strengthClass'] === 'string' &&
    typeof typedObj['cementType'] === 'string' &&
    typeof typedObj['aggregate'] === 'number' &&
    (isConsistenceClassType(typedObj['consistenceClassType']) as boolean) &&
    typeof typedObj['consistenceClassValue'] === 'number' &&
    (typeof typedObj['dcSulphateClass'] === 'undefined' ||
      typedObj['dcSulphateClass'] === null ||
      typeof typedObj['dcSulphateClass'] === 'string') &&
    typeof typedObj['cementContent'] === 'number' &&
    typeof typedObj['freeWaterCementRatio'] === 'number' &&
    typeof typedObj['fines'] === 'number' &&
    (typeof typedObj['links'] === 'undefined' ||
      typedObj['links'] === null ||
      (Array.isArray(typedObj['links']) &&
        typedObj['links'].every((e: any) => isColumnLink(e) as boolean)))
  )
}

export function isAddConcreteProdRecord(
  obj: unknown,
): obj is AddConcreteProdRecord {
  const typedObj = obj as AddConcreteProdRecord
  return (
    ((typedObj !== null && typeof typedObj === 'object') ||
      typeof typedObj === 'function') &&
    typeof typedObj['jobsiteId'] === 'string' &&
    Array.isArray(typedObj['deliveriesIds']) &&
    typedObj['deliveriesIds'].every((e: any) => typeof e === 'string') &&
    (isConcreteTypeDefinition(typedObj['concreteTypeDefinition']) as boolean) &&
    typeof typedObj['columnId'] === 'string' &&
    (typeof typedObj['comment'] === 'undefined' ||
      typedObj['comment'] === null ||
      typeof typedObj['comment'] === 'string')
  )
}

export function isAddConcreteDelivery(
  obj: unknown,
): obj is AddConcreteDelivery {
  const typedObj = obj as AddConcreteDelivery
  return (
    ((typedObj !== null && typeof typedObj === 'object') ||
      typeof typedObj === 'function') &&
    typeof typedObj['jobsiteId'] === 'string' &&
    typeof typedObj['refIdentifierLabel'] === 'string' &&
    (typeof typedObj['wbsCode'] === 'undefined' ||
      typeof typedObj['wbsCode'] === 'string') &&
    typeof typedObj['concreteTypeDefinitionId'] === 'string' &&
    (typeof typedObj['contractNumber'] === 'undefined' ||
      typeof typedObj['contractNumber'] === 'number') &&
    (typeof typedObj['contractName'] === 'undefined' ||
      typeof typedObj['contractName'] === 'string') &&
    typeof typedObj['ticketNumber'] === 'number' &&
    typeof typedObj['date'] === 'string' &&
    typeof typedObj['batchTime'] === 'string' &&
    typeof typedObj['load'] === 'number' &&
    typeof typedObj['timeOfArrival'] === 'string' &&
    typeof typedObj['dischargeTimeStart'] === 'string' &&
    typeof typedObj['dischargeTimeFinish'] === 'string' &&
    typeof typedObj['timeOfDeparture'] === 'string' &&
    (typeof typedObj['inspection'] === 'undefined' ||
      typeof typedObj['inspection'] === 'string') &&
    Array.isArray(typedObj['columnsIds']) &&
    typedObj['columnsIds'].every((e: any) => typeof e === 'string') &&
    typeof typedObj['inspectionValidated'] === 'boolean' &&
    (typeof typedObj['filePath'] === 'undefined' ||
      typeof typedObj['filePath'] === 'string')
  )
}

export function isConcreteTypeDefinition(
  obj: unknown,
): obj is ConcreteTypeDefinition {
  const typedObj = obj as ConcreteTypeDefinition
  return (
    ((typedObj !== null && typeof typedObj === 'object') ||
      typeof typedObj === 'function') &&
    (typeof typedObj['id'] === 'undefined' ||
      typeof typedObj['id'] === 'string') &&
    typeof typedObj['jobsiteId'] === 'string' &&
    typeof typedObj['refIdentifierLabel'] === 'string' &&
    (typeof typedObj['orderNumber'] === 'undefined' ||
      typedObj['orderNumber'] === null ||
      typeof typedObj['orderNumber'] === 'string') &&
    (typeof typedObj['lineNumber'] === 'undefined' ||
      typedObj['lineNumber'] === null ||
      typeof typedObj['lineNumber'] === 'number') &&
    (typeof typedObj['wbsCode'] === 'undefined' ||
      typedObj['wbsCode'] === null ||
      typeof typedObj['wbsCode'] === 'string') &&
    (typedObj['supplierId'] === null ||
      typeof typedObj['supplierId'] === 'string') &&
    (typeof typedObj['orderDescription'] === 'undefined' ||
      typedObj['orderDescription'] === null ||
      typeof typedObj['orderDescription'] === 'string') &&
    typeof typedObj['orderUnit'] === 'string' &&
    typeof typedObj['strengthClass'] === 'string' &&
    typeof typedObj['cementType'] === 'string' &&
    typeof typedObj['aggregate'] === 'number' &&
    (isConsistenceClassType(typedObj['consistenceClassType']) as boolean) &&
    typeof typedObj['consistenceClassValue'] === 'number' &&
    (typeof typedObj['dcSulphateClass'] === 'undefined' ||
      typedObj['dcSulphateClass'] === null ||
      typeof typedObj['dcSulphateClass'] === 'string') &&
    typeof typedObj['cementContent'] === 'number' &&
    typeof typedObj['freeWaterCementRatio'] === 'number' &&
    typeof typedObj['fines'] === 'number' &&
    (typeof typedObj['links'] === 'undefined' ||
      typedObj['links'] === null ||
      (Array.isArray(typedObj['links']) &&
        typedObj['links'].every((e: any) => isColumnLink(e) as boolean)))
  )
}

export function isConsistenceClassType(
  obj: unknown,
): obj is ConsistenceClassType {
  const typedObj = obj as ConsistenceClassType
  return (
    typedObj === ConsistenceClassType.SLUMP ||
    typedObj === ConsistenceClassType.FLOW
  )
}

export function isConcreteProdRecord(obj: unknown): obj is ConcreteProdRecord {
  const typedObj = obj as ConcreteProdRecord
  return (
    ((typedObj !== null && typeof typedObj === 'object') ||
      typeof typedObj === 'function') &&
    typeof typedObj['id'] === 'string' &&
    (isConcreteTypeDefinition(typedObj['concreteTypeDefinition']) as boolean) &&
    typeof typedObj['columnId'] === 'string' &&
    typeof typedObj['jobsiteId'] === 'string' &&
    (typeof typedObj['comment'] === 'undefined' ||
      typedObj['comment'] === null ||
      typeof typedObj['comment'] === 'string')
  )
}

export function isConcreteProdRecordInput(
  obj: unknown,
): obj is ConcreteProdRecordInput {
  const typedObj = obj as ConcreteProdRecordInput
  return (
    (isConcreteProdRecord(typedObj) as boolean) &&
    Array.isArray(typedObj['deliveriesIds']) &&
    typedObj['deliveriesIds'].every((e: any) => typeof e === 'string')
  )
}

export function isConcreteDelivery(obj: unknown): obj is ConcreteDelivery {
  const typedObj = obj as ConcreteDelivery
  return (
    ((typedObj !== null && typeof typedObj === 'object') ||
      typeof typedObj === 'function') &&
    (typeof typedObj['id'] === 'undefined' ||
      typeof typedObj['id'] === 'string') &&
    typeof typedObj['concreteTypeDefinitionId'] === 'string' &&
    typeof typedObj['jobsiteId'] === 'string' &&
    typeof typedObj['refIdentifierLabel'] === 'string' &&
    (typeof typedObj['contractNumber'] === 'undefined' ||
      typeof typedObj['contractNumber'] === 'number') &&
    (typeof typedObj['contractName'] === 'undefined' ||
      typeof typedObj['contractName'] === 'string') &&
    (typeof typedObj['wbsCode'] === 'undefined' ||
      typeof typedObj['wbsCode'] === 'string') &&
    typeof typedObj['ticketNumber'] === 'number' &&
    typeof typedObj['date'] === 'string' &&
    typeof typedObj['batchTime'] === 'string' &&
    typeof typedObj['load'] === 'number' &&
    typeof typedObj['timeOfArrival'] === 'string' &&
    typeof typedObj['dischargeTimeStart'] === 'string' &&
    typeof typedObj['dischargeTimeFinish'] === 'string' &&
    typeof typedObj['timeOfDeparture'] === 'string' &&
    (typeof typedObj['inspection'] === 'undefined' ||
      typeof typedObj['inspection'] === 'string') &&
    Array.isArray(typedObj['columnsIds']) &&
    typedObj['columnsIds'].every((e: any) => typeof e === 'string') &&
    typeof typedObj['inspectionValidated'] === 'boolean' &&
    (typeof typedObj['filePath'] === 'undefined' ||
      typeof typedObj['filePath'] === 'string')
  )
}

import { ofType } from '@ngrx/effects'
import { Action } from '@ngrx/store'
import { SyncActionFactory } from 'app/shared/utils/redux/sync.actions'
import { OperatorFunction } from 'rxjs'
import { AsyncActionFactory } from './async.actions'
import { ListActionFactory } from './list/list.actions'
import { LoadableActionFactory, TypedAction } from './loadable/loadable.actions'

export const onStart = <StartPayload>(
  actionFactory: AsyncActionFactory<StartPayload, any>,
) =>
  ofType(actionFactory.START) as OperatorFunction<
    Action,
    ReturnType<(typeof actionFactory)['createStart']>
  >

export const onComplete = <CompletePayload>(
  actionFactory: AsyncActionFactory<any, CompletePayload>,
) =>
  ofType(actionFactory.COMPLETE) as OperatorFunction<
    Action,
    ReturnType<(typeof actionFactory)['createComplete']>
  >

export const onFailed = (actionFactory: AsyncActionFactory<any, any>) =>
  ofType(actionFactory.FAILED) as OperatorFunction<
    Action,
    ReturnType<(typeof actionFactory)['createFailed']>
  >

export const onReset = (actionFactory: LoadableActionFactory<any, any>) =>
  ofType(actionFactory.RESET) as OperatorFunction<
    Action,
    ReturnType<(typeof actionFactory)['createReset']>
  >

export const onResetValue = (actionFactory: SyncActionFactory<any>) =>
  ofType(actionFactory.RESET_VALUE) as OperatorFunction<
    Action,
    ReturnType<(typeof actionFactory)['createResetValue']>
  >

export const onCreate = <Payload>(actionFactory: SyncActionFactory<Payload>) =>
  ofType(actionFactory.CREATE) as OperatorFunction<
    Action,
    ReturnType<(typeof actionFactory)['create']>
  >

export const onAddItems = <Item>(actionFactory: ListActionFactory<Item, any>) =>
  ofType(actionFactory.ADD_ITEMS) as OperatorFunction<
    Action,
    ReturnType<(typeof actionFactory)['createAddItems']>
  >

export const onAddItem = <Item>(actionFactory: ListActionFactory<Item, any>) =>
  ofType(actionFactory.ADD_ITEM) as OperatorFunction<
    Action,
    ReturnType<(typeof actionFactory)['createAddItem']>
  >

export const onRemoveItems = <Item>(
  actionFactory: ListActionFactory<Item, any>,
) =>
  ofType(actionFactory.REMOVE_ITEMS) as OperatorFunction<
    Action,
    ReturnType<(typeof actionFactory)['createRemoveItems']>
  >

export const onRemoveIds = (actionFactory: ListActionFactory<any, any>) =>
  ofType(actionFactory.REMOVE_IDS) as OperatorFunction<
    Action,
    ReturnType<(typeof actionFactory)['createRemoveIds']>
  >

export const onSetItems = <Item>(actionFactory: ListActionFactory<Item, any>) =>
  ofType(actionFactory.SET_ITEMS) as OperatorFunction<
    Action,
    ReturnType<(typeof actionFactory)['createSetItems']>
  >
export function isStartAction<S>(
  action: TypedAction<any>,
  factory: AsyncActionFactory<S, any>,
): action is TypedAction<S> {
  return action.type === factory.START
}

export function isCompleteAction<C>(
  action: TypedAction<any>,
  factory: AsyncActionFactory<any, C>,
): action is TypedAction<C> {
  return action.type === factory.COMPLETE
}

export function isCreateAction<Payload>(
  action: TypedAction<Payload>,
  factory: SyncActionFactory<Payload>,
): action is TypedAction<Payload> {
  return action.type === factory.CREATE
}

export function isAddItemAction<I>(
  action: TypedAction<any>,
  factory: ListActionFactory<I, any>,
): action is TypedAction<I> {
  return action.type === factory.ADD_ITEM
}

export function isAddItemsAction<I>(
  action: TypedAction<any>,
  factory: ListActionFactory<I, any>,
): action is TypedAction<I[]> {
  return action.type === factory.ADD_ITEMS
}

export function isRemoveItemsAction<I>(
  action: TypedAction<any>,
  factory: ListActionFactory<I, any>,
): action is TypedAction<I[]> {
  return action.type === factory.REMOVE_ITEMS
}

export function isRemoveIdsAction(
  action: TypedAction<any>,
  factory: ListActionFactory<any, any>,
): action is TypedAction<string[]> {
  return action.type === factory.REMOVE_ITEMS
}

export function isSetItemsAction<I>(
  action: TypedAction<any>,
  factory: ListActionFactory<I, any>,
): action is TypedAction<I[]> {
  return action.type === factory.SET_ITEMS
}

import { createSelector, MemoizedSelector, Selector } from '@ngrx/store'
import { State } from '../../../../core/store'
import { LoadableSelectorFactory } from '../loadable/loadable.selectors'
import { ListState } from './list.state'

export class ListSelectorFactory<
  Item,
  SubState,
> extends LoadableSelectorFactory<Item[], SubState> {
  constructor(
    selector: Selector<State, SubState> | Selector<State, ListState<Item>>,
    lambda?: (arg: SubState) => ListState<Item>,
  ) {
    super(selector, lambda)
  }

  get getAll(): MemoizedSelector<State, Item[]> {
    return createSelector<State, [ListState<Item>], Item[]>(
      this.objectSelector,
      (state: ListState<Item>) => state?.value ?? [],
    )
  }

  get getLength(): MemoizedSelector<State, number> {
    return createSelector<State, [ListState<Item>], number>(
      this.objectSelector,
      (state: ListState<Item>) => state?.value?.length,
    )
  }
}

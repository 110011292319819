import { AsyncActionFactory } from 'app/shared/utils/redux/async.actions'
import { SyncActionFactory } from 'app/shared/utils/redux/sync.actions'
import { ColumnDto } from '../../../shared/remote-services/dtos/column.dto'
import { EntitiesActionFactory } from '../../../shared/utils/redux/entities/entities.actions'
import { ListActionFactory } from '../../../shared/utils/redux/list/list.actions'
import { LoadableActionFactory } from '../../../shared/utils/redux/loadable/loadable.actions'
import { DuplicateChartOrTemplateEventPayload } from '../models/duplicateChartOrTemplate.model'
import { ChartDto } from '../models/chart/chart.dto'
import { FileModel } from '../models/file.model'
import { RecordDto } from '../models/record.dto'
import { ChartOrTemplateDto } from '../models/template/chartOrTemplate.dto'
import { TemplateConfigDto } from '../models/template/templateConfig.dto'
import { ExportType } from '../../jobsite-summary/models/export.model'
import { ExportEventPayload } from '../../jobsite-summary/components/export-pdf-dialog/export-pdf-dialog.model'
import { OptionsWithId } from '../models/highChartOptions.model'
import { ExportPrintPayload } from './column-visualization.model'

export class ColumnVisualizationActions {
  public static loadColumn = new LoadableActionFactory<void, ColumnDto>(
    '[COLUMN VISUALIZATION] LOAD COLUMN',
  )
  public static loadCharts = new EntitiesActionFactory<ChartDto, void>(
    '[COLUMN VISUALIZATION] LOAD CHARTS',
  )
  public static loadCompanyTemplate = new LoadableActionFactory<void, string>(
    '[COLUMN VISUALIZATION] LOAD COMPANY TEMPLATE',
  )
  public static loadHighchartsOptions = new EntitiesActionFactory<
    OptionsWithId,
    ChartDto[]
  >('[COLUMN VISUALIZATION] LOAD HIGHCHARTS OPTIONS')

  public static loadHighchartsOptionsByChartId = new SyncActionFactory<string>(
    '[COLUMN VISUALIZATION] LOAD HIGHCHARTS OPTIONS BY CHART ID',
  )
  public static loadPdfFile = new SyncActionFactory<FileModel>(
    '[COLUMN VISUALIZATION] LOAD PDF FILE',
  )
  public static loadJobsiteTemplateId = new LoadableActionFactory<string>(
    '[COLUMN VISUALIZATION] LOAD JOBSITE TEMPLATE ID',
  )
  public static loadUserTemplateId = new LoadableActionFactory<string>(
    '[COLUMN VISUALIZATION] LOAD USER TEMPLATE ID',
  )
  public static loadDefaultTemplateId = new LoadableActionFactory<string>(
    '[COLUMN VISUALIZATION] LOAD DEFAULT TEMPLATE ID',
  )

  public static saveChart = new AsyncActionFactory<ChartDto>(
    '[COLUMN VISUALIZATION] SAVE CHART',
  )
  public static deleteChart = new SyncActionFactory<string>(
    '[COLUMN VISUALIZATION] DELETE CHART',
  )
  public static openChartDialog = new SyncActionFactory<string | undefined>(
    '[COLUMN VISUALIZATION] OPEN CHART DIALOG',
  )
  public static exportToCsv = new AsyncActionFactory<void, Blob>(
    '[COLUMN VISUALIZATION] EXPORT CSV',
  )
  public static exportToPdf = new AsyncActionFactory<
    ExportEventPayload,
    ExportPrintPayload
  >('[COLUMN VISUALIZATION] EXPORT PDF')
  public static print = new AsyncActionFactory<ExportType, ExportPrintPayload>(
    '[COLUMN VISUALIZATION] PRINT',
  )
  public static setWipTemplate = new SyncActionFactory<TemplateConfigDto>(
    '[COLUMN VISUALIZATION] SET WIP TEMPLATE',
  )
  public static setSelectDto = new SyncActionFactory<
    ChartOrTemplateDto | undefined
  >('[COLUMN VISUALIZATION] SET SELECTED DTO')
  public static setCompanyTemplate = new SyncActionFactory<string>(
    '[COLUMN VISUALIZATION] SET COMPANY TEMPLATE ID',
  )
  public static setJobsiteTemplate = new SyncActionFactory<string>(
    '[COLUMN VISUALIZATION] SET JOBSITE TEMPLATE ID',
  )
  public static setUserTemplate = new SyncActionFactory<string>(
    '[COLUMN VISUALIZATION] SET USER TEMPLATE ID',
  )
  public static setDefaultTemplate = new SyncActionFactory<string>(
    '[COLUMN VISUALIZATION] SET DEFAULT TEMPLATE ID',
  )
  public static selectedHeaders = new ListActionFactory<string>(
    '[COLUMN VISUALIZATION] SELECTED HEADERS',
  )
  public static records = new EntitiesActionFactory<RecordDto, void>(
    '[COLUMN VISUALIZATION] RECORDS',
  )
  public static openDuplicateChartOrTemplateDialog =
    new SyncActionFactory<DuplicateChartOrTemplateEventPayload>(
      '[COLUMN VISUALIZATION] OPEN DUPLICATE CHART OR TEMPLATE DIALOG',
    )

  public static deleteUserTemplateLink = new SyncActionFactory<void>(
    '[COLUMN VISUALIZATION] DELETE USER TEMPLATE LINK',
  )
  public static deleteJobsiteTemplateLink = new SyncActionFactory<void>(
    '[COLUMN VISUALIZATION] DELETE JOBSITE TEMPLATE LINK',
  )
  public static deleteCompanyTemplateLink = new SyncActionFactory<void>(
    '[COLUMN VISUALIZATION] DELETE COMPANY TEMPLATE LINK',
  )
}

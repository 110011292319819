import { CanActivate, CanActivateChild, UrlTree } from '@angular/router'
import { Selector, Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators'
import { LoadableActionFactory } from './redux/loadable/loadable.actions'

export type GuardChecks = {
  preCheck?: () => Observable<boolean>
  postCheck?: () => Observable<boolean | UrlTree>
}

export class AbstractLoadableGuard<S> implements CanActivate, CanActivateChild {
  constructor(
    protected store: Store<S>,
    private selector: Selector<S, boolean>,
    private action: LoadableActionFactory<void, unknown>,
    private reset: boolean = false,
    private checks: GuardChecks = {
      preCheck: () => of(true),
      postCheck: () => of(true),
    },
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return (
      typeof this.checks?.preCheck === 'function'
        ? this.checks.preCheck()
        : of(true)
    ).pipe(
      switchMap(value => (value ? this.checkStore() : of(false))),
      switchMap(() =>
        typeof this.checks?.postCheck === 'function'
          ? this.checks.postCheck()
          : of(true),
      ),
      catchError(() => of(false)),
    )
  }

  canActivateChild(): Observable<boolean | UrlTree> {
    return this.canActivate()
  }

  checkStore(): Observable<boolean> {
    if (this.reset) {
      this.store.dispatch(this.action.createReset())
    }
    return this.store.select(this.selector).pipe(
      tap(loaded => {
        if (!loaded) {
          this.store.dispatch(this.action.createStart())
        }
      }),
      filter(loaded => Boolean(loaded)),
      take(1),
      catchError((e: Error) => {
        console.error(e)
        return of(false)
      }),
    )
  }
}

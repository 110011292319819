export class CesiumToolbarOptions {
  globeOpacity: number
  displaySurveys: boolean
  displayPiles: boolean
  displayMerlons: boolean
  displayPanels: boolean

  constructor(
    globeOpacity: number,
    displaySurveys: boolean,
    displayPiles: boolean,
    displayMerlons: boolean = false,
    displayPanels: boolean = false,
  ) {
    this.globeOpacity = globeOpacity
    this.displaySurveys = displaySurveys
    this.displayPiles = displayPiles
    this.displayMerlons = displayMerlons
    this.displayPanels = displayPanels
  }
}

import { CompaniesEffects } from './companies/companies.effects'
import { CompoundParamsEffects } from './compoundparams/compoundparams.effects'
import { FieldsEffects } from './fields/fields.effects'
import { JobsiteEffects } from './jobsite'
import { LoaderEffects } from './loader/loader.effects'
import { RouterEffects } from './router/router.effects'
import { TechniquesEffects } from './techniques/techniques.effects'
import { UserEffects } from './user'
import { CageEffects } from './cage/cage.effects'
import { DailyReportColumnsEffects } from './daily-report-columns/daily-report-columns.effects'
import { ConcreteEffects } from './concrete/concrete.effects'
import { ProviderEffects } from './provider/provider.effects'
import { ProcessesEffects } from './processes/processes.effects'
import { JobsiteProgressEffects } from './jobsite-progress/jobsite-progress.effects'
import { PlatformDisruptionsEffects } from './platform-disruptions/platform-disruptions.effects'

export * from './state'

export const effects = [
  RouterEffects,
  JobsiteEffects,
  FieldsEffects,
  TechniquesEffects,
  UserEffects,
  LoaderEffects,
  CompaniesEffects,
  CompoundParamsEffects,
  DailyReportColumnsEffects,
  CageEffects,
  ConcreteEffects,
  ProviderEffects,
  ProcessesEffects,
  JobsiteProgressEffects,
  PlatformDisruptionsEffects,
]

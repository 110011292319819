import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { State as CoreState } from 'app/core/store/state'
import { AbstractLoadableGuard } from '../../shared/utils/abstract-loadable.guard'
import { processesSelector } from '../store/processes/processes.selectors'
import { ProcessesActions } from '../store/processes/processes.actions'

@Injectable()
export class ProcessesGuard extends AbstractLoadableGuard<CoreState> {
  constructor(store: Store<CoreState>) {
    super(store, processesSelector.getLoaded, ProcessesActions.loadProcesses)
  }
}

import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { State as CoreState } from 'app/core/store/state'
import { AbstractLoadableGuard } from '../../shared/utils/abstract-loadable.guard'
import { cageTypeDefSelector } from '../store/cage/cage.selectors'
import { CageActions } from '../store/cage/cage.actions'

@Injectable()
export class CageTypeDefGuard extends AbstractLoadableGuard<CoreState> {
  constructor(store: Store<CoreState>) {
    super(
      store,
      cageTypeDefSelector.getLoaded,
      CageActions.cageTypeDefActions,
      true,
    )
  }
}

import { createSelector } from '@ngrx/store'
import * as core from '../state'

export const getLoaderState = createSelector(
  core.getState,
  (state: core.State) => state.loader,
)

export const isLoading = createSelector<core.State, [number], boolean>(
  getLoaderState,
  (state: number) => state !== 0,
)

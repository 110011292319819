/*
 * Generated type guards for "jobsite-table-data-values.model.ts".
 * WARNING: Do not manually change this file.
 */
import { isColumnLink } from '../../../../shared/remote-services/dtos/column.dto.type.guard'
import { isCageProdRecord } from '../../../../shared/remote-services/dtos/cage.dto.type.guard'
import { isConcreteProdRecord } from '../../../../shared/remote-services/dtos/concrete.dto.type.guard'
import { JobsiteTableDataValues } from './jobsite-table-data-values.model'

export function isJobsiteTableDataValues(
  obj: unknown,
): obj is JobsiteTableDataValues {
  const typedObj = obj as JobsiteTableDataValues
  return (
    ((typedObj !== null && typeof typedObj === 'object') ||
      typeof typedObj === 'function') &&
    typeof typedObj['id'] === 'string' &&
    typeof typedObj['name'] === 'string' &&
    (typeof typedObj['isDone'] === 'undefined' ||
      typedObj['isDone'] === null ||
      typedObj['isDone'] === false ||
      typedObj['isDone'] === true) &&
    (typeof typedObj['date'] === 'undefined' ||
      typedObj['date'] === null ||
      typeof typedObj['date'] === 'string') &&
    (typeof typedObj['time'] === 'undefined' ||
      typedObj['time'] === null ||
      typeof typedObj['time'] === 'string') &&
    (typeof typedObj['process'] === 'undefined' ||
      typedObj['process'] === null ||
      typeof typedObj['process'] === 'string') &&
    (typeof typedObj['localX'] === 'undefined' ||
      typedObj['localX'] === null ||
      typeof typedObj['localX'] === 'number') &&
    (typeof typedObj['localY'] === 'undefined' ||
      typedObj['localY'] === null ||
      typeof typedObj['localY'] === 'number') &&
    (typeof typedObj['polygon'] === 'undefined' ||
      typedObj['polygon'] === null ||
      typeof typedObj['polygon'] === 'string') &&
    (typeof typedObj['parentId'] === 'undefined' ||
      typedObj['parentId'] === null ||
      typeof typedObj['parentId'] === 'string') &&
    (typeof typedObj['hfType'] === 'undefined' ||
      typedObj['hfType'] === null ||
      typeof typedObj['hfType'] === 'string') &&
    (typeof typedObj['volume'] === 'undefined' ||
      typedObj['volume'] === null ||
      typeof typedObj['volume'] === 'number') &&
    (typeof typedObj['volumeInst'] === 'undefined' ||
      typedObj['volumeInst'] === null ||
      typeof typedObj['volumeInst'] === 'string') &&
    (typeof typedObj['startLiftDate'] === 'undefined' ||
      typedObj['startLiftDate'] === null ||
      typeof typedObj['startLiftDate'] === 'string') &&
    (typeof typedObj['stopLiftDate'] === 'undefined' ||
      typedObj['stopLiftDate'] === null ||
      typeof typedObj['stopLiftDate'] === 'string') &&
    (typeof typedObj['startLiftTime'] === 'undefined' ||
      typedObj['startLiftTime'] === null ||
      typeof typedObj['startLiftTime'] === 'string') &&
    (typeof typedObj['stopLiftTime'] === 'undefined' ||
      typedObj['stopLiftTime'] === null ||
      typeof typedObj['stopLiftTime'] === 'string') &&
    (typeof typedObj['startDrillDate'] === 'undefined' ||
      typedObj['startDrillDate'] === null ||
      typeof typedObj['startDrillDate'] === 'string') &&
    (typeof typedObj['stopDrillDate'] === 'undefined' ||
      typedObj['stopDrillDate'] === null ||
      typeof typedObj['stopDrillDate'] === 'string') &&
    (typeof typedObj['startDrillTime'] === 'undefined' ||
      typedObj['startDrillTime'] === null ||
      typeof typedObj['startDrillTime'] === 'string') &&
    (typeof typedObj['stopDrillTime'] === 'undefined' ||
      typedObj['stopDrillTime'] === null ||
      typeof typedObj['stopDrillTime'] === 'string') &&
    (typeof typedObj['drillHrs'] === 'undefined' ||
      typedObj['drillHrs'] === null ||
      typeof typedObj['drillHrs'] === 'string') &&
    (typeof typedObj['drillHrsDec'] === 'undefined' ||
      typedObj['drillHrsDec'] === null ||
      typeof typedObj['drillHrsDec'] === 'string') &&
    (typeof typedObj['liftHrs'] === 'undefined' ||
      typedObj['liftHrs'] === null ||
      typeof typedObj['liftHrs'] === 'string') &&
    (typeof typedObj['liftHrsDec'] === 'undefined' ||
      typedObj['liftHrsDec'] === null ||
      typeof typedObj['liftHrsDec'] === 'string') &&
    (typeof typedObj['plateformLevel'] === 'undefined' ||
      typedObj['plateformLevel'] === null ||
      typeof typedObj['plateformLevel'] === 'number') &&
    (typeof typedObj['depthInst'] === 'undefined' ||
      typedObj['depthInst'] === null ||
      typeof typedObj['depthInst'] === 'number') &&
    (typeof typedObj['depthProd'] === 'undefined' ||
      typedObj['depthProd'] === null ||
      typeof typedObj['depthProd'] === 'number') &&
    (typeof typedObj['diameterInst'] === 'undefined' ||
      typedObj['diameterInst'] === null ||
      typeof typedObj['diameterInst'] === 'number') &&
    (typeof typedObj['diameterProd'] === 'undefined' ||
      typedObj['diameterProd'] === null ||
      typeof typedObj['diameterProd'] === 'number') &&
    (typeof typedObj['type'] === 'undefined' ||
      typedObj['type'] === null ||
      typeof typedObj['type'] === 'string') &&
    (typeof typedObj['subtype'] === 'undefined' ||
      typedObj['subtype'] === null ||
      typeof typedObj['subtype'] === 'string') &&
    (typeof typedObj['cage'] === 'undefined' ||
      typedObj['cage'] === null ||
      typeof typedObj['cage'] === 'string') &&
    (typeof typedObj['nbr'] === 'undefined' ||
      typedObj['nbr'] === null ||
      typeof typedObj['nbr'] === 'number') &&
    (typeof typedObj['rig'] === 'undefined' ||
      typedObj['rig'] === null ||
      typeof typedObj['rig'] === 'string') &&
    (typeof typedObj['zone'] === 'undefined' ||
      typedObj['zone'] === null ||
      typeof typedObj['zone'] === 'string') &&
    (typeof typedObj['zoneAsBuilt'] === 'undefined' ||
      typedObj['zoneAsBuilt'] === null ||
      typeof typedObj['zoneAsBuilt'] === 'string') &&
    (typeof typedObj['timeSpentKS'] === 'undefined' ||
      typedObj['timeSpentKS'] === null ||
      typeof typedObj['timeSpentKS'] === 'number') &&
    (typeof typedObj['depthDugKs'] === 'undefined' ||
      typedObj['depthDugKs'] === null ||
      typeof typedObj['depthDugKs'] === 'number') &&
    (typeof typedObj['timeSpentBenne'] === 'undefined' ||
      typedObj['timeSpentBenne'] === null ||
      typeof typedObj['timeSpentBenne'] === 'number') &&
    (typeof typedObj['depthDugBenne'] === 'undefined' ||
      typedObj['depthDugBenne'] === null ||
      typeof typedObj['depthDugBenne'] === 'number') &&
    (typeof typedObj['links'] === 'undefined' ||
      typedObj['links'] === null ||
      (Array.isArray(typedObj['links']) &&
        typedObj['links'].every((e: any) => isColumnLink(e) as boolean))) &&
    (typeof typedObj['overbreak'] === 'undefined' ||
      typedObj['overbreak'] === null ||
      typeof typedObj['overbreak'] === 'number') &&
    (typeof typedObj['cageProd'] === 'undefined' ||
      typedObj['cageProd'] === null ||
      (isCageProdRecord(typedObj['cageProd']) as boolean)) &&
    (typeof typedObj['concreteProd'] === 'undefined' ||
      typedObj['concreteProd'] === null ||
      (isConcreteProdRecord(typedObj['concreteProd']) as boolean)) &&
    (typeof typedObj['discarded'] === 'undefined' ||
      typedObj['discarded'] === null ||
      typedObj['discarded'] === false ||
      typedObj['discarded'] === true) &&
    (typeof typedObj['pileVolume'] === 'undefined' ||
      typedObj['pileVolume'] === null ||
      typeof typedObj['pileVolume'] === 'number') &&
    (typeof typedObj['asBuiltTheoVolume'] === 'undefined' ||
      typedObj['asBuiltTheoVolume'] === null ||
      typeof typedObj['asBuiltTheoVolume'] === 'number') &&
    (typeof typedObj['prodEvents'] === 'undefined' ||
      typedObj['prodEvents'] === null ||
      typedObj['prodEvents'] === false ||
      typedObj['prodEvents'] === true)
  )
}

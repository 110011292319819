// Environment content for the deployed prod platform

export const environment = {
  production: true,
  gaEnabled: true,
  gaKey: 'UA-147463668-1',
  sentryEnabled: true,
  sentryDsn:
    'https://35f286eeba2a4ce6addea90cf5623c07@glitchtip.sde-ild.soletanche-bachy.org/1',
  platform: 'PROD',
  featureToggle: {
    enableActivityData: true,
    enableHomePagePreferences: true,
    enableSortAsBuiltPreference: true,
    enableCsvPreference: true,
    enableCesiumMapForVisualization: false,
    enableDataScienceDwall: true,
  },
  showDialogTuto: 1,
  matomo: {
    trackerUrl: 'https://matomo.sde-ild.soletanche-bachy.org',
    siteId: 2,
  },
}

import * as Guards from './userPreferences.dto.type.guard'
import { JobsiteTab } from '../../../jobsites-management/jobsite-summary/models/jobsite-tabs.model'
import { Mode } from '../../../jobsites-management/jobsite-summary/store/state'
import { UUID } from '../../models/utils.type'

export const APP_LANGUAGES = ['en', 'fr', 'es'] as const

/** @see {isUserLanguage} ts-auto-guard:type-guard */
export type UserLanguage = (typeof APP_LANGUAGES)[number]

/** @see {isUnitSystem} ts-auto-guard:type-guard */
export type UnitSystem = 'imperial' | 'metric'

/** @see {isDateTimeFormatsDto} ts-auto-guard:type-guard */
export interface DateTimeFormatsDto {
  dateFormat: string
  timeFormat: string
  dateTimeFormat: string
  highChartsDateFormat?: string
}

/** @see {isHomePagePreferenceDto} ts-auto-guard:type-guard */
export interface HomePagePreferenceDto {
  id?: UUID
  editingSection: JobsiteTab
  followingSection: JobsiteTab
  defaultSection: Mode
}

/** @see {isUserPreferencesDto} ts-auto-guard:type-guard */
export interface UserPreferencesDto {
  language: UserLanguage
  unitSystem: UnitSystem
  dateFormat: string
  zlDateTimeFormats: DateTimeFormatsDto
  zlRigPathEnabled: boolean
  homePage: Record<UUID, HomePagePreferenceDto>
  zlAsBuiltDescendingDate: boolean
  tutoIsRead: number
}

export { Guards }

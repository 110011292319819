import { FormControl } from '@angular/forms'
import { DateSelectModel } from '../../models/date-time-format.model'
import { TimeFormats } from '../../../../shared/models/date-time-format.model'

export interface DateTimeFormatsSettings {
  dateFormat: DateSelectModel
  timeFormat: TimeFormats
  dateTimeDateFormat: DateSelectModel
  dateTimeSeparated: boolean
  dateTimeSeparator: string
  dateTimeTimeFormat: TimeFormats
  dateTimeReversed: boolean
}

export interface DateTimeFormatsSettingsControls {
  dateFormat: FormControl<DateSelectModel>
  timeFormat: FormControl<TimeFormats>
  dateTimeDateFormat: FormControl<DateSelectModel>
  dateTimeSeparated: FormControl<boolean>
  dateTimeSeparator: FormControl<string>
  dateTimeTimeFormat: FormControl<TimeFormats>
  dateTimeReversed: FormControl<boolean>
}

export const initialDateTimeFormatsSettings: DateTimeFormatsSettings = {
  dateFormat: { format: 'EN', separator: '-' },
  timeFormat: '24',
  dateTimeDateFormat: {
    format: 'EN',
    separator: '-',
  },
  dateTimeSeparated: false,
  dateTimeSeparator: '',
  dateTimeTimeFormat: '24',
  dateTimeReversed: false,
}

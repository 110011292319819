import { reducerFromActionHandlers } from '../utils.reducers'
import { ListActionFactory } from './list.actions'
import { ListState } from './list.state'

export function createListReducer<Item>(
  actionFactory: ListActionFactory<Item, any>,
) {
  const initialState: ListState<Item> = {
    loading: false,
    loaded: false,
    value: [],
  }

  return reducerFromActionHandlers(initialState, [
    {
      actionType: actionFactory.COMPLETE,
      handler: (
        state,
        action: ReturnType<(typeof actionFactory)['createComplete']>,
      ) => ({
        value: action.payload,
        loaded: true,
        loading: false,
      }),
    },
    {
      actionType: actionFactory.START,
      handler: state => ({ ...initialState, loading: true }),
    },
    {
      actionType: actionFactory.FAILED,
      handler: state => ({ ...state, loading: false, loaded: false }),
    },
    {
      actionType: actionFactory.RESET,
      handler: state => ({ ...initialState }),
    },
    {
      actionType: actionFactory.ADD_ITEMS,
      handler: (
        state,
        action: ReturnType<(typeof actionFactory)['createAddItems']>,
      ) => ({
        loading: false,
        loaded: true,
        value: [...state.value, ...action.payload].filter(
          (x, i, a) => a.indexOf(x) === i,
        ),
      }),
    },
    {
      actionType: actionFactory.ADD_ITEM,
      handler: (
        state,
        action: ReturnType<(typeof actionFactory)['createAddItem']>,
      ) => {
        if (action.payload.index !== undefined) {
          return {
            loading: false,
            loaded: true,
            value: [
              ...state.value.slice(0, action.payload.index),
              action.payload.item,
              ...state.value.slice(action.payload.index),
            ],
          }
        }
        return { ...state, value: [...state.value, action.payload.item] }
      },
    },
    {
      actionType: actionFactory.REMOVE_ITEMS,
      handler: (
        state,
        action: ReturnType<(typeof actionFactory)['createRemoveItems']>,
      ) => ({
        loading: false,
        loaded: true,
        value: [...state.value.filter(item => !action.payload.includes(item))],
      }),
    },
    {
      actionType: actionFactory.SET_ITEMS,
      handler: (
        state,
        action: ReturnType<(typeof actionFactory)['createSetItems']>,
      ) => ({ loading: false, loaded: true, value: [...action.payload] }),
    },
  ])
}

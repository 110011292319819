import { createSelector, MemoizedSelector, Selector } from '@ngrx/store'
import { State } from '../../../../core/store/index'
import { LoadableState } from './loadable.state'

export class LoadableSelectorFactory<Item, SubState> {
  protected objectSelector: Selector<State, LoadableState<Item>>

  constructor(
    selector: Selector<State, SubState> | Selector<State, LoadableState<Item>>,
    lambda?: (arg: SubState) => LoadableState<Item>,
  ) {
    this.objectSelector =
      lambda != null
        ? createSelector(selector as Selector<State, SubState>, lambda)
        : (selector as Selector<State, LoadableState<Item>>)
  }

  get getLoaded(): MemoizedSelector<State, boolean> {
    return createSelector<State, [LoadableState<Item>], boolean>(
      this.objectSelector,
      (state: LoadableState<Item>) => Boolean(state?.loaded),
    )
  }

  get getLoading(): MemoizedSelector<State, boolean> {
    return createSelector<State, [LoadableState<Item>], boolean>(
      this.objectSelector,
      (state: LoadableState<Item>) => Boolean(state?.loading),
    )
  }

  get getValue(): MemoizedSelector<State, Item> {
    return createSelector(
      this.objectSelector,
      (state: LoadableState<Item>) => state?.value,
    )
  }
}

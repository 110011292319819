import { TechniqueNames } from 'app/shared/remote-services/dtos/technique.dto'
import { LoadableActionFactory } from '../../../shared/utils/redux/loadable/loadable.actions'
import { SyncActionFactory } from '../../../shared/utils/redux/sync.actions'
import { FieldsCompletePayload } from './fields.model'

export class FieldsActions {
  public static loadFields = new LoadableActionFactory<
    void,
    FieldsCompletePayload
  >('[CORE] LOAD FIELDS')

  public static loadFieldsOfTechnique = new SyncActionFactory<TechniqueNames>(
    '[CORE] LOAD FIELDS OF TECHNIQUE',
  )
}

import { ListActionFactory } from '../../../../shared/utils/redux/list/list.actions'
import { DailyManualProdRecordDto } from '../models/manual-prod-records.model'
import { AsyncActionFactory } from '../../../../shared/utils/redux/async.actions'
import { SyncActionFactory } from '../../../../shared/utils/redux/sync.actions'

export class JobsiteManualProdRecordsActions {
  public static loadManualProdRecords = new ListActionFactory<
    DailyManualProdRecordDto,
    void
  >('[MANUAL PROD RECORDS] LOAD MANUAL PROD RECORDS')

  public static createManualProdRecords = new AsyncActionFactory<
    DailyManualProdRecordDto,
    void
  >('[MANUAL PROD RECORDS] SAVE MANUAL PROD RECORDS')

  public static updateManualProdRecords = new AsyncActionFactory<
    DailyManualProdRecordDto,
    void
  >('[MANUAL PROD RECORDS] UPDATE MANUAL PROD RECORDS')

  public static deleteManualProdRecords = new AsyncActionFactory<string, void>(
    '[MANUAL PROD RECORDS] DELETE MANUAL PROD RECORDS',
  )

  public static setResetManualProdRecordsForm = new SyncActionFactory<boolean>(
    '[MANUAL PROD RECORDS] SET RESET MANUAL PROD RECORDS FORM',
  )

  public static openManualProdRecordEditDialog = new SyncActionFactory<string>(
    '[MANUAL PROD RECORDS] OPEN MANUAL PROD RECORD EDIT DIALOG',
  )
}

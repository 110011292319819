import { TypedAction } from 'app/shared/utils/redux/loadable/loadable.actions'
import { isCompleteAction } from 'app/shared/utils/redux/operators'
import { VectorRotationToolActions } from './vector-rotation-tool.actions'

export interface VectorRotationToolState {
  currentColId: string | undefined
  permutation: number
  originalPolygon: string
}

export const INIT_STATE: VectorRotationToolState = {
  currentColId: undefined,
  permutation: 0,
  originalPolygon: '',
}

export const reducer = (
  state = INIT_STATE,
  action: TypedAction<any>,
): VectorRotationToolState => {
  if (isCompleteAction(action, VectorRotationToolActions.rotateVector)) {
    return {
      ...state,
      ...action.payload,
    }
  }
  return state
}

export class ColumnDescriptor {
  public readonly version = COLUMN_DESCRIPTOR_VERSION // Used for serialization issues with local storage

  public index: number
  public selected: boolean

  constructor(index: number, selected: boolean) {
    this.index = index
    this.selected = selected
  }
}

export const COLUMN_DESCRIPTOR_VERSION = 1

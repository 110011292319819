import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { AbstractLoadableGuard } from '../../shared/utils/abstract-loadable.guard'
import { getProgressDataLoaded } from '../store/jobsite-progress/jobsite-progress.selectors'
import { JobsiteProgressActions } from '../store/jobsite-progress/jobsite-progress.actions'
import { State } from '../store'

@Injectable()
export class ProgressGuard extends AbstractLoadableGuard<State> {
  constructor(store: Store<State>) {
    super(
      store,
      getProgressDataLoaded,
      JobsiteProgressActions.getProgressData,
      true,
    )
  }
}

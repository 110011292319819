import { environment } from '../../../../../environments/environment'

export interface FeatureToggleValue {
  envValue: boolean
  userValue: boolean | null
}

export interface FeatureToggleState {
  enableActivityData: FeatureToggleValue
  enableHomePagePreferences: FeatureToggleValue
  enableSortAsBuiltPreference: FeatureToggleValue
  enableCsvPreference: FeatureToggleValue
  enableCesiumMapForVisualization: FeatureToggleValue
  enableDataScienceDwall: FeatureToggleValue
}

export const initialFeatureTogglesSetting: FeatureToggleState = {
  enableActivityData: {
    envValue: environment.featureToggle.enableActivityData,
    userValue: null,
  },
  enableHomePagePreferences: {
    envValue: environment.featureToggle.enableHomePagePreferences,
    userValue: null,
  },
  enableSortAsBuiltPreference: {
    envValue: environment.featureToggle.enableSortAsBuiltPreference,
    userValue: null,
  },
  enableCsvPreference: {
    envValue: environment.featureToggle.enableCsvPreference,
    userValue: null,
  },
  enableCesiumMapForVisualization: {
    envValue: environment.featureToggle.enableCesiumMapForVisualization,
    userValue: null,
  },
  enableDataScienceDwall: {
    envValue: environment.featureToggle.enableDataScienceDwall,
    userValue: null,
  },
}

export function deserialize(
  storedState: FeatureToggleState,
): FeatureToggleState {
  const state = { ...initialFeatureTogglesSetting }
  if (storedState != null) {
    Object.entries(initialFeatureTogglesSetting).forEach(
      (value: [string, FeatureToggleValue]) => {
        const featureName = value[0]
        const lsEnvValue = storedState[featureName]?.envValue
        const lsUserPref = storedState[featureName]?.userValue

        const defaultEnvValue = value[1].envValue

        if (
          lsEnvValue != null &&
          lsUserPref != null &&
          lsEnvValue === defaultEnvValue
        ) {
          state[featureName] = {
            envValue: defaultEnvValue,
            userValue: lsUserPref,
          }
        } else {
          state[featureName] = {
            envValue: defaultEnvValue,
            userValue: null,
          }
        }
      },
    )
  }
  return state
}

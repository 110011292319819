import * as Guards from './companyCsvPreferencesInput.dto.type.guard'
import { UUID } from '../../shared/models/utils.type'
import { FormControl, FormGroup, FormRecord } from '@angular/forms'
import {
  CsvDecimalSeparator,
  CsvSeparator,
} from '../../jobsites-management/settings/models/csv-separator.enum'

/** @see {isCompanyCsvPreferencesInputDto} ts-auto-guard:type-guard */
export interface CompanyCsvPreferencesInputDto {
  defaultPreferences: CsvPreferencesDto
  regionsPreferences: Record<UUID, CsvPreferencesDto>
}

export interface CompanyCsvPreferencesInputControls {
  defaultPreferences: FormGroup<CsvPreferencesControls>
  regionsPreferences: FormRecord<FormGroup<CsvPreferencesControls>>
}

/** @see {isCsvPreferencesDto} ts-auto-guard:type-guard */
export interface CsvPreferencesDto {
  csvSeparator: CsvSeparator | null
  csvDecimalSeparator: CsvDecimalSeparator | null
}

export const DEFAULT_CSV_PREFERENCES: CsvPreferencesDto = {
  csvSeparator: CsvSeparator.SEMICOLON,
  csvDecimalSeparator: CsvDecimalSeparator.POINT,
}

export interface CsvPreferencesControls {
  csvSeparator: FormControl<CsvSeparator | null>
  csvDecimalSeparator: FormControl<CsvDecimalSeparator | null>
}

export { Guards }

import { Pipe, PipeTransform } from '@angular/core'
import { TableFormatterService } from '../../../core/services/tableformatter.service'

@Pipe({
  standalone: true,
  name: 'translateHeader',
})
export class TableHeaderTranslationPipe implements PipeTransform {
  constructor(private service: TableFormatterService) {}

  transform(value: string, units: Record<string, string>): string {
    return this.service.getHeaderTranslation(value, units)
  }
}

import { InjectionToken, Provider } from '@angular/core'
import { UrlTree } from '@angular/router'
import * as fromRouter from '@ngrx/router-store'
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store'
import { UserModel } from 'app/keycloak/models/user.model'
import { CageTypeDefinition } from 'app/shared/remote-services/dtos/cage.dto'
import { DailyReportColumnDto } from 'app/shared/remote-services/dtos/dailyReportColumn.dto'
import { UserPreferencesDto } from 'app/shared/remote-services/dtos/userPreferences.dto'
import { createEntitiesReducer } from 'app/shared/utils/redux/entities/entities.reducer'
import { EntitiesState } from 'app/shared/utils/redux/entities/entities.state'
import { createLoadableReducer } from 'app/shared/utils/redux/loadable/loadable.reducer'
import { LoadableState } from 'app/shared/utils/redux/loadable/loadable.state'
import { createValueReducer } from 'app/shared/utils/redux/utils.reducers'
import { CompanyDto } from '../models/company.dto'
import { CompoundParamDto } from '../models/compoundParameter.model'
import { CageActions } from './cage/cage.actions'
import { CompaniesActions } from './companies/companies.actions'
import { CompoundParamsActions } from './compoundparams/compoundparams.actions'
import { DailyReportColumnsActions } from './daily-report-columns/daily-report-columns.actions'
import { fieldsReducer, FieldState } from './fields/fields.reducer'
import * as jobsite from './jobsite/jobsite.reducer'
import * as loader from './loader/loader.reducer'
import { RouterStateUrl } from './router/router.model'
import { RouterActions } from './router/router.actions'
import { TechniquesActions } from './techniques/techniques.actions'
import * as techniques from './techniques/techniques.reducer'
import { UserActions } from './user/user.actions'
import {
  ConcreteDelivery,
  ConcreteTypeDefinition,
} from '../../shared/remote-services/dtos/concrete.dto'
import { ConcreteActions } from './concrete/concrete.actions'
import { ProviderActions } from './provider/provider.actions'
import { ProviderDto } from '../../shared/remote-services/dtos/provider.dto'
import { ProcessDto } from '../../shared/remote-services/dtos/process.dto'
import { ProcessesActions } from './processes/processes.actions'
import { ProgressDto } from '../../jobsites-management/jobsite-summary/models/progress/progress.model'
import { JobsiteProgressActions } from './jobsite-progress/jobsite-progress.actions'
import { ChartPeriod } from '../../jobsites-management/jobsite-summary/jobsite-productivity/models/productivity.model'
import {
  DtoActiveDisruption,
  DtoPlatformDisruption,
} from '../models/PlatformDisruption.model'
import { PlatformDisruptionsActions } from './platform-disruptions/platform-disruptions.actions'

export interface State {
  router: fromRouter.RouterReducerState<RouterStateUrl>
  jobsite: jobsite.State
  techniques: techniques.TechniqueState
  user: LoadableState<UserModel>
  loader: number
  fields: FieldState
  userPreferences: LoadableState<UserPreferencesDto>
  backUrl?: string | UrlTree
  companies: EntitiesState<CompanyDto>
  compoundParams: EntitiesState<CompoundParamDto>
  dailyReportColumns: EntitiesState<DailyReportColumnDto>
  cageTypeDef: EntitiesState<CageTypeDefinition>
  concreteTypeDef: EntitiesState<ConcreteTypeDefinition>
  concreteDelivery: EntitiesState<ConcreteDelivery>
  providers: EntitiesState<ProviderDto>
  processes: EntitiesState<ProcessDto>
  progressPeriod: ChartPeriod
  progressData: LoadableState<ProgressDto[]>
  platformDisruptionMessage: LoadableState<DtoActiveDisruption>
  platformDisruptions: LoadableState<DtoPlatformDisruption[]>
}

const reducers: ActionReducerMap<State> = {
  router: fromRouter.routerReducer,
  jobsite: jobsite.reducer,
  techniques: createEntitiesReducer(TechniquesActions.loadTechniques),
  user: createLoadableReducer(UserActions.loadUser, undefined),
  loader: loader.reducer,
  fields: fieldsReducer,
  userPreferences: createLoadableReducer(UserActions.loadUserPreferences, {
    language: 'fr',
    unitSystem: 'metric',
    dateFormat: 'YYYY-MM-DD',
    zlDateTimeFormats: {
      dateFormat: 'MM-dd-yyyy',
      timeFormat: 'HH:mm',
      dateTimeFormat: 'MM-dd-yyyy HH:mm',
    },
    zlRigPathEnabled: true,
    homePage: null,
    zlAsBuiltDescendingDate: false,
    tutoIsRead: 0,
  }),
  backUrl: createValueReducer(undefined, RouterActions.setBackUrl),
  companies: createEntitiesReducer(CompaniesActions.companies),
  compoundParams: createEntitiesReducer(
    CompoundParamsActions.loadCompoundParams,
  ),
  dailyReportColumns: createEntitiesReducer(
    DailyReportColumnsActions.loadDailyReportColumns,
  ),
  cageTypeDef: createEntitiesReducer(CageActions.cageTypeDefActions),
  concreteTypeDef: createEntitiesReducer(
    ConcreteActions.concreteTypeDefActions,
  ),
  concreteDelivery: createEntitiesReducer(
    ConcreteActions.concreteDeliveryActions,
  ),
  providers: createEntitiesReducer(ProviderActions.providerActions),
  processes: createEntitiesReducer(ProcessesActions.loadProcesses),

  progressData: createLoadableReducer<ProgressDto[]>(
    JobsiteProgressActions.getProgressData,
    [],
  ),
  progressPeriod: createValueReducer<State['progressPeriod']>(
    'DAILY',
    JobsiteProgressActions.updateProgressPeriod,
  ),
  platformDisruptionMessage: createLoadableReducer(
    PlatformDisruptionsActions.loadPlatformDisruptionMessage,
    { message: null },
  ),
  platformDisruptions: createLoadableReducer(
    PlatformDisruptionsActions.loadAllDisruptions,
    [],
  ),
}

export const getState = createFeatureSelector<State>('core')

export const reducerInjecteur = new InjectionToken('Core State')

function getReducers(): ActionReducerMap<State> {
  return reducers
}

export const reducerProvider: Provider = [
  { provide: reducerInjecteur, useFactory: getReducers },
]

import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { State as CoreState } from 'app/core/store/state'
import { AbstractLoadableGuard } from '../../shared/utils/abstract-loadable.guard'
import { dailyReportColumnsSelectors } from '../store/daily-report-columns/daily-report-columns.selectors'
import { DailyReportColumnsActions } from '../store/daily-report-columns/daily-report-columns.actions'

@Injectable()
export class DailyReportColumnsGuard extends AbstractLoadableGuard<CoreState> {
  constructor(store: Store<CoreState>) {
    super(
      store,
      dailyReportColumnsSelectors.getLoaded,
      DailyReportColumnsActions.loadDailyReportColumns,
    )
  }
}

import {
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms'
import { RangeModel } from 'app/jobsites-management/jobsite-summary/jobsite-activity-data/models/range.model'

export class MinMax {
  minValue = Number.POSITIVE_INFINITY
  maxValue = Number.NEGATIVE_INFINITY

  private handledValuesCount = 0

  static validator: ValidatorFn = (
    group: FormGroup<MinMaxControls>,
  ): ValidationErrors | null => {
    const min = group?.controls?.min?.value
    const max = group?.controls?.max?.value

    return Number(min) > Number(max) ? { valueError: true } : null
  }

  setValue(range: RangeModel<number>) {
    this.minValue = range.min
    this.maxValue = range.max
    this.handledValuesCount = 1
  }

  handleValue(value: number) {
    if (value < this.minValue) {
      this.minValue = value
    }
    if (value > this.maxValue) {
      this.maxValue = value
    }
    if (value !== undefined) {
      this.handledValuesCount++
    }
  }

  get result(): { min: number; max: number } {
    if (this.handledValuesCount > 0) {
      return { min: this.minValue, max: this.maxValue }
    } else {
      return { min: undefined, max: undefined }
    }
  }

  isDefined(): boolean {
    return this.handledValuesCount > 0
  }
}

export interface MinMaxControls {
  min: FormControl<number | null>
  max: FormControl<number | null>
}

import {
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

@Injectable()
export class CleanerInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<
    | HttpSentEvent
    | HttpHeaderResponse
    | HttpProgressEvent
    | HttpResponse<any>
    | HttpUserEvent<any>
  > {
    if (req.body) {
      const body = this.sanitizePayload(req.body)
      const reqCopy = req.clone({ body })
      return next.handle(reqCopy)
    } else {
      return next.handle(req)
    }
  }

  private sanitizePayload(payload: any) {
    const isObject = v => v && typeof v === 'object'
    const isFormData = v => v && v instanceof FormData
    const isArray = v => v && Array.isArray(v)
    const isValid = v => v !== undefined && v !== null && v !== ''

    if (isFormData(payload)) {
      return payload
    }

    if (isArray(payload)) {
      return payload
        .map(v => (isObject(v) ? this.sanitizePayload(v) : v))
        .filter(v => isValid(v))
    } else {
      return Object.entries(payload)
        .map(([k, v]) => [k, isObject(v) ? this.sanitizePayload(v) : v])
        .reduce((a, [k, v]) => {
          if (isValid(v)) {
            a[k] = v
          }
          return a
        }, {})
    }
  }
}

import * as Guards from './map-visibility.enum.type.guard'

/** @see {isMapVisibilityEnum} ts-auto-guard:type-guard */
export enum MapVisibilityEnum {
  COLLAPSED = 'collapsed',
  EXPANDED = 'expanded',
  DEFAULT = 'default',
}

export { Guards }

import { SyncActionFactory } from '../../../shared/utils/redux/sync.actions'

export class SettingsActions {
  public static toggleDisplaySaveAlert = new SyncActionFactory<boolean>(
    '[SETTINGS] TOGGLE DISPLAY SAVE ALERT',
  )

  public static isDefaultHomePagePref = new SyncActionFactory<boolean>(
    '[SETTINGS] IS DEFAULT HOME PAGE PREF',
  )
}

import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { OrderValues } from '../../jobsites-management/jobsites-list/models/jobsites-filter.model'
import { JobsitesListState } from 'app/jobsites-management/jobsites-list/store/state'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'
import { TreeNode } from '../../jobsites-management/jobsite-summary/models/columns-type-tree.model'
import { HFType } from '../constants/hf-type.enum'
import {
  isValidPolygon,
  parseExtentString,
  toExtentString,
} from '../openlayer/parseExtentString'
import { notEmpty } from '../utils/notEmpty'
import { PagedResult } from '../utils/pagedResult.model'
import { ColumnDto, ColumnKey, ColumnLink } from './dtos/column.dto'
import { DailyReportDto } from './dtos/dailyReport.dto'
import { JobsiteDto } from './dtos/jobsite.dto'
import {
  FavoriteTechniqueDto,
  TechniqueDto,
  TechniqueNames,
} from './dtos/technique.dto'
import { JobsitePinpointDto } from './dtos/jobsitePinpoint.dto'
import {
  CheckAvailabilityInput,
  CheckAvailabilityResponse,
} from '../../jobsites-management/jobsite-creation/models/checkAvailability.model'
import { ProgressDto } from '../../jobsites-management/jobsite-summary/models/progress/progress.model'
import { Coordinate } from 'ol/coordinate'
import { getMinMaxCoords } from '../openlayer/fitIntoRect'

@Injectable()
export class JobsiteService {
  constructor(private http: HttpClient) {}

  private baseUrl = 'jobsite/jobsites'

  public getJobsitesByTechnique(
    techniqueName: TechniqueNames,
  ): Observable<JobsiteDto[]> {
    return this.http
      .get<JobsiteDto[]>(`${this.baseUrl}/${techniqueName}/all`)
      .pipe(
        map(rslt =>
          rslt.map(el => this.computeDerivatedDto(el, techniqueName)),
        ),
      )
  }

  public getInProgressJobsitesByTechnique(
    techniqueName: TechniqueNames,
  ): Observable<JobsiteDto[]> {
    return this.http
      .get<JobsiteDto[]>(`${this.baseUrl}/${techniqueName}/in-progress`)
      .pipe(
        map(rslt =>
          rslt.map(el => this.computeDerivatedDto(el, techniqueName)),
        ),
      )
  }

  public getPagedJobsites(
    techniqueName?: TechniqueNames,
    pageSize = 20,
    pageIndex = 0,
    search?: string,
    sortValue?: OrderValues,
    sortDirection: 'asc' | 'desc' = 'asc',
    status?: JobsitesListState['status'],
    company?: string,
    userFavorite?: boolean,
  ): Observable<PagedResult<JobsiteDto>> {
    return this.http
      .get<PagedResult<Omit<JobsiteDto, 'progress' | 'status'>>>(
        `${this.baseUrl}/list/${techniqueName}`,
        {
          params: Object.fromEntries(
            [
              ['page', `${pageIndex}`],
              ['size', `${pageSize}`],
              status ? ['status', status] : undefined,
              search ? ['nameOrContract', search] : undefined,
              company ? ['company', company] : undefined,
              sortValue != null &&
              Array.isArray(sortValue) &&
              sortValue.length >= 1
                ? ['sort', `${sortValue.join(',')},${sortDirection}`]
                : sortValue != null && !Array.isArray(sortValue)
                ? ['sort', `${sortValue},${sortDirection}`]
                : undefined,
              userFavorite ? ['favorite', userFavorite] : undefined,
            ].filter(notEmpty),
          ),
        },
      )
      .pipe(
        map(rslt => ({
          ...rslt,
          content: rslt.content.map(e =>
            this.computeDerivatedDto(e, techniqueName),
          ),
        })),
      )
  }

  public getJobsite(
    id: string,
    techniqueName: TechniqueNames,
  ): Observable<JobsiteDto> {
    return this.http
      .get<JobsiteDto>(`${this.baseUrl}/${id}`)
      .pipe(map(rslt => this.computeDerivatedDto(rslt, techniqueName)))
  }

  public getColumns(
    jobsiteId: string,
    techniqueName: TechniqueNames,
  ): Observable<ColumnDto[]> {
    return this.http
      .get<ColumnDto[]>(`${this.baseUrl}/columns/${techniqueName}/${jobsiteId}`)
      .pipe(
        // TODO delete mock localisation
        map(columns =>
          columns.map((column, index): ColumnDto => {
            if (
              column.values.localX === null ||
              column.values.localY === null
            ) {
              const nbLines = Math.floor(Math.sqrt(columns.length))
              const localX = Math.floor(index / nbLines)
              const localY = index - localX * nbLines
              return {
                key: {
                  ...column.key,
                },
                values: {
                  ...column.values,
                  localX,
                  localY,
                },
                links: column.links ? [...column.links] : undefined,
              }
            } else {
              return column
            }
          }),
        ),
      )
  }

  public getColumnsAndTypes(
    jobsiteId: string,
    techniqueName: TechniqueNames,
  ): Observable<{
    columns: ColumnDto[]
    types: TreeNode[]
    nullCoordIds: string[]
  }> {
    return this.http
      .get<{ columns: ColumnDto[]; types: TreeNode[] }>(
        `${this.baseUrl}/columns/${techniqueName}/${jobsiteId}/type`,
      )
      .pipe(
        map(response => {
          const nullCoordIds: string[] = []
          const dataBounds: Coordinate = this.getDataBounds(response.columns)
          return {
            columns: response.columns.map((column, index): ColumnDto => {
              if (
                techniqueName === 'PILES' &&
                (column.values.localX == null || column.values.localY == null)
              ) {
                nullCoordIds.push(column.key.id)
                const nbLines = Math.floor(Math.sqrt(response.columns.length))
                const computedLocalX = Math.floor(index / nbLines)
                const computedLocalY = index - computedLocalX * nbLines
                return {
                  key: {
                    ...column.key,
                  },
                  values: {
                    ...column.values,
                    computedLocalX,
                    computedLocalY,
                  },
                  links: column.links ? [...column.links] : undefined,
                }
              } else if (
                techniqueName === 'HF' &&
                (!column.values.polygon || !column.key.type)
              ) {
                if (!column.key.type) {
                  column.key.type = HFType.PILE
                }
                if (!column.values.polygon) {
                  nullCoordIds.push(column.key.id)
                  const nbLines = Math.floor(Math.sqrt(response.columns.length))
                  const tmpX = Math.floor(index / nbLines)
                  const y = dataBounds[1] - (1 + index - tmpX * nbLines)
                  const x = dataBounds[0] + tmpX
                  const step = 0.9
                  const coordinates: [number, number][] = [
                    [x, y],
                    [x, y + step],
                    [x + step, y + step],
                    [x + step, y],
                    [x, y],
                  ]
                  column.values.polygon = toExtentString(coordinates)
                }
                return column
              } else {
                return column
              }
            }),
            types: response.types,
            nullCoordIds,
          }
        }),
      )
  }

  public getColumnsProdValues(
    jobsiteId: string,
    techniqueName: TechniqueNames,
    uuids: string[],
  ): Observable<Record<string, DailyReportDto>> {
    return this.http.post<Record<string, DailyReportDto>>(
      `${this.baseUrl}/${techniqueName}/${jobsiteId}/dailyreport`,
      uuids,
    )
  }

  public updateOneColumn(
    jobsiteId: string,
    techniqueName: TechniqueNames,
    column: ColumnDto,
  ): Observable<void> {
    return this.http.post<void>(
      `${this.baseUrl}/columns/${techniqueName}/${jobsiteId}/one`,
      column,
    )
  }

  public updateNameColumn(
    jobsiteId: string,
    techniqueName: TechniqueNames,
    columnKey: ColumnKey & { parentId?: string },
    newName: string,
  ): Observable<void> {
    return this.http.post<void>(
      `${this.baseUrl}/columns/${techniqueName}/${jobsiteId}/name/${newName}`,
      columnKey,
    )
  }

  public updateColumns(
    jobsiteId: string,
    techniqueName: TechniqueNames,
    columns: ColumnDto[],
  ): Observable<void> {
    return this.http.post<void>(
      `${this.baseUrl}/columns/${techniqueName}/${jobsiteId}`,
      columns,
    )
  }

  public createColumn(
    jobsiteId: string,
    techniqueName: TechniqueNames,
    column: ColumnDto,
  ): Observable<void> {
    return this.http.put<void>(
      `${this.baseUrl}/columns/${techniqueName}/${jobsiteId}/one`,
      column,
    )
  }

  public deleteColumns(
    jobsiteId: string,
    columnIds: ColumnKey[],
    technique: TechniqueNames,
  ): Observable<void> {
    return this.http.put<void>(
      `${this.baseUrl}/columns/${technique}/${jobsiteId}/delete`,
      columnIds,
    )
  }

  public overwriteColumns(
    jobsiteId: string,
    techniqueName: TechniqueNames,
    columns: ColumnDto[],
  ): Observable<void> {
    return this.http.put<void>(
      `${this.baseUrl}/columns/${techniqueName}/${jobsiteId}`,
      columns,
    )
  }

  public getJobsiteIdsByMachineIds(machineIds: string[]): Observable<string[]> {
    if (!machineIds || machineIds.length === 0) {
      return of(null)
    }
    return this.http.post<string[]>('equipment/resources/jobsites', machineIds)
  }

  public saveJobsite(jobsite: JobsiteDto): Observable<JobsiteDto> {
    return this.http.post<JobsiteDto>('jobsite/jobsites/create', jobsite)
  }

  public createJobsiteWithProgress(
    jobsite: JobsiteDto,
    progress: ProgressDto[],
  ): Observable<JobsiteDto> {
    return this.http.post<JobsiteDto>('jobsite/jobsites/add/with-progress', {
      jobsite: {
        ...jobsite,
        techniques: jobsite.techniques.map(t => ({ ...t, techniqueId: t.id })),
      },
      progress: progress.map(p => ({
        ...p,
        date: p.date.format('YYYY-MM-DD'),
      })),
    })
  }

  public updateJobsite(jobsite: JobsiteDto) {
    return this.http
      .put(`jobsite/jobsites/${jobsite.id}/general-info`, jobsite)
      .pipe(map(() => jobsite))
  }

  public setJobsiteStatus(
    technique: TechniqueNames,
    jobsiteId: string,
    status: string,
  ) {
    return this.http
      .post(
        `jobsite/jobsites/technique/${technique}/${jobsiteId}/${status}`,
        undefined,
      )
      .pipe(
        map(() => ({
          status,
          technique,
          jobsiteId,
        })),
      )
  }

  getDoneColumnIds(
    technique: TechniqueNames,
    columnIds: string[],
    jobsiteId: string,
  ): Observable<string[]> {
    return this.http.post<string[]>(
      `${this.baseUrl}/columns/${technique}/${jobsiteId}/done`,
      columnIds,
    )
  }

  getNbAssignedMachines(
    technique: TechniqueNames,
  ): Observable<{ [id: string]: number }> {
    return this.http.get<{ [id: string]: number }>(
      `equipment/resources/machines/${technique}/nb`,
    )
  }

  private computeDerivatedDto(
    input: Omit<JobsiteDto, 'status' | 'progress'>,
    technique: TechniqueNames,
  ): JobsiteDto {
    const techDto: TechniqueDto = input.techniques.find(
      t => t.name === technique,
    )
    return {
      ...input,
      status: techDto?.status,
    }
  }

  deleteExternalLink(idExternalLink: string): Observable<void> {
    return this.http.delete<void>(
      `${this.baseUrl}/columns/external-links/${idExternalLink}`,
    )
  }

  createExternalLink(payload: ColumnLink): Observable<void> {
    return this.http.post<void>(
      `${this.baseUrl}/columns/external-links`,
      payload,
    )
  }

  updateExternalLink(payload: ColumnLink): Observable<void> {
    return this.http.put<void>(
      `${this.baseUrl}/columns/external-links/${payload.id}`,
      payload,
    )
  }

  getJobsitesPinpoints(
    techniqueName?: TechniqueNames,
    search?: string,
    status?: JobsitesListState['status'],
    company?: string,
    userFavorite?: boolean,
  ): Observable<JobsitePinpointDto[]> {
    return this.http.get<JobsitePinpointDto[]>(
      `${this.baseUrl}/pinpoints/${techniqueName}`,
      {
        params: Object.fromEntries(
          [
            status ? ['status', status] : undefined,
            search ? ['nameOrContract', search] : undefined,
            company ? ['company', company] : undefined,
            userFavorite ? ['favorite', userFavorite] : undefined,
          ].filter(notEmpty),
        ),
      },
    )
  }

  setFavoriteJobsiteTechnique(
    techniqueName: TechniqueNames,
    payload: FavoriteTechniqueDto,
  ): Observable<boolean> {
    return this.http.put<boolean>(
      `${this.baseUrl}/technique/favorite/${techniqueName}/${payload.jobsite.id}`,
      {
        favorite: payload.isFavorite,
      },
    )
  }

  checkAvailability(
    input: CheckAvailabilityInput,
    technique: TechniqueNames,
    jobsiteId: string = null,
  ): Observable<CheckAvailabilityResponse> {
    const endPath = jobsiteId
      ? `check-availability/${jobsiteId}`
      : 'check-availability'
    return this.http.post<CheckAvailabilityResponse>(
      `${this.baseUrl}/${technique}/${endPath}`,
      input,
    )
  }

  syncLutz(technique: TechniqueNames): Observable<void> {
    return this.http.post<void>(
      `equipment/resources/machines/${technique}/sync/lutz`,
      null,
    )
  }

  private getDataBounds(columns: ColumnDto[]): Coordinate {
    const coords: Coordinate[] = []
    columns.forEach(column => {
      if (isValidPolygon(column?.values?.polygon)) {
        coords.push(...parseExtentString(column?.values?.polygon))
      }
    })
    const minMaxCoords = getMinMaxCoords(coords as [number, number][])

    if (minMaxCoords.x.isDefined() && minMaxCoords.y.isDefined()) {
      return [minMaxCoords.x.minValue, minMaxCoords.y.minValue]
    } else {
      return [0, 0]
    }
  }
}

import { Action } from '@ngrx/store'
import { AsyncActionFactory } from '../async.actions'

export interface TypedAction<Payload> extends Action {
  payload: Payload
}

export class LoadableActionFactory<
  StartPayload,
  CompletePayload = StartPayload,
> extends AsyncActionFactory<StartPayload, CompletePayload> {
  get RESET(): string {
    return this.name + '.RESET'
  }

  public createReset(): Action {
    return { type: this.RESET }
  }

  constructor(protected name: string) {
    super(name)
  }
}

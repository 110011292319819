import * as Guards from './hf-type.enum.type.guard'

/** @see {isHfType} ts-auto-guard:type-guard */
export enum HFType {
  PILE = 'PILE',
  MERLON = 'MERLON',
  PANEL = 'PANEL',
}

/**
 * Attention: should keep the order of the following three elements:
 * 1. HFType.PILE, 2. HFType.MERLON, 3. HFType.PANEL
 * which is important for 3D to render transparent objects
 */
export const HF_TYPE_LIST: readonly HFType[] = [
  HFType.PILE,
  HFType.MERLON,
  HFType.PANEL,
] as const

export const INIT_ACTIVE_LAYER = [HFType.PILE]

export { Guards }

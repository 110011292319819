import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core'
import {
  DtoPlatformDisruption,
  PlatformDisruptionMessage,
  PlatformDisruptionMessageControls,
} from '../../core/models/PlatformDisruption.model'
import { TypedChanges } from '../../shared/utils/TypedChange'
import { FormControl, FormGroup, Validators } from '@angular/forms'

@Component({
  selector: 'manage-disruptions-dialog',
  templateUrl: 'manage-disruptions-dialog.component.html',
  styleUrls: ['manage-disruptions-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageDisruptionsDialogComponent implements OnChanges {
  @Input() disruptions: DtoPlatformDisruption[] = []

  @Output() closeDialog = new EventEmitter<void>()
  @Output() closeDisruption = new EventEmitter<string>()
  @Output() createDisruption = new EventEmitter<PlatformDisruptionMessage>()

  public displayLoader = true
  public form: FormGroup<PlatformDisruptionMessageControls> = new FormGroup({
    messageEn: new FormControl(null, [Validators.required]),
    messageEs: new FormControl(null, [Validators.required]),
    messageFr: new FormControl(null, [Validators.required]),
  })

  public readonly displayedColumns: string[] = [
    'messageEn',
    'messageEs',
    'messageFr',
    'startDate',
    'endDate',
  ]

  ngOnChanges(
    changes: TypedChanges<{ disruptions: DtoPlatformDisruption[] }>,
  ): void {
    if (
      changes.disruptions?.currentValue?.length > 0 ||
      !changes?.disruptions?.isFirstChange()
    ) {
      this.displayLoader = false
    }
  }

  addDisruption(): void {
    this.createDisruption.emit(this.form.getRawValue())
    this.form.reset()
  }
}

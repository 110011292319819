import { ColumnDescriptor } from './column-descriptor'

const transitions = {
  0: columnDescriptorV0ToV1,
}

function columnDescriptorV0ToV1<C extends string>(
  data: Record<C, boolean>,
): Record<C, ColumnDescriptor> {
  const keys = Object.keys(data)
  const result = {}
  let index = 0
  for (const key of keys) {
    result[key] = {
      index: index++,
      selected: !!data[key],
      version: 1,
    }
  }
  return result as Record<C, ColumnDescriptor>
}

/**
 * Schema migration for ColumnDescriptor : allow to migrate older serialized version in local storage for example
 */
export function migrateColumnDescriptorIfNeeded<C extends string>(
  data: Record<C, any>,
): Record<C, ColumnDescriptor> {
  if (data) {
    let transformedData = data
    const allVersions: number[] = Object.keys(data)
      .map(key => data[key])
      .map(value => (value?.version as number) || 0)
    let transformedDataVersion = allVersions?.length
      ? Math.min(...allVersions)
      : 0

    let nextStep = transitions[transformedDataVersion++]
    while (nextStep) {
      transformedData = nextStep(transformedData)
      nextStep = transitions[transformedDataVersion++]
    }
    return transformedData
  } else {
    return data
  }
}

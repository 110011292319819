import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core'
import { ServerInfosDto } from '../../remote-services/dtos/serverInfos.dto'
import { TypedChanges } from '../../utils/TypedChange'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'server-info-dialog',
  template: `
    <h2 mat-dialog-title>
      {{ 'SERVER_INFO.TITLE' | translate }}
    </h2>

    <div
      *ngIf="displayLoader; else dialogContent"
      class="spinner flex flex-align-center--center"
    >
      <mat-spinner color="primary"></mat-spinner>
    </div>

    <ng-template #dialogContent>
      <mat-dialog-content class="dialog-content">
        <mat-accordion>
          <mat-expansion-panel *ngFor="let infos of serverInfos">
            <mat-expansion-panel-header>
              <mat-panel-title class="flex flex-gap-15px">
                <mat-icon>
                  {{ infos?.build?.version != null ? 'dns' : 'warning' }}
                </mat-icon>

                <span>
                  {{ getServerName(infos) }}
                </span>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="flex-layout-column flex-gap-15px">
              <span
                *ngIf="infos.build.version == null"
                class="unavailable-content"
              >
                {{ 'SERVER_INFO.UNAVAILABLE_SERVER.CONTENT' | translate }}
              </span>

              <div>
                <h4>
                  {{ 'SERVER_INFO.BUILD.TITLE' | translate }}
                </h4>
                <server-info-item
                  infoTitleTranslationKey="SERVER_INFO.BUILD.VERSION"
                  [infoValue]="infos.build.version ?? 'N/A'"
                ></server-info-item>
                <server-info-item
                  *ngIf="infos.build.time"
                  infoTitleTranslationKey="SERVER_INFO.BUILD.TIME"
                  [infoValue]="infos.build.time | localizedDateTime"
                ></server-info-item>
                <server-info-item
                  *ngIf="infos.build.group"
                  infoTitleTranslationKey="SERVER_INFO.BUILD.GROUP"
                  [infoValue]="infos.build.group"
                ></server-info-item>
                <server-info-item
                  *ngIf="infos.build.artifact"
                  infoTitleTranslationKey="SERVER_INFO.BUILD.ARTIFACT"
                  [infoValue]="infos.build.artifact"
                ></server-info-item>
              </div>

              <div *ngIf="!!infos.git">
                <h4>
                  {{ 'SERVER_INFO.GIT.TITLE' | translate }}
                </h4>
                <server-info-item
                  infoTitleTranslationKey="SERVER_INFO.GIT.BRANCH"
                  [infoValue]="infos.git.branch"
                ></server-info-item>
                <server-info-item
                  infoTitleTranslationKey="SERVER_INFO.GIT.COMMIT"
                  [infoValue]="infos.git.commit"
                ></server-info-item>
                <server-info-item
                  infoTitleTranslationKey="SERVER_INFO.GIT.COMMIT_TIME"
                  [infoValue]="infos.git.commit_time | localizedDateTime"
                ></server-info-item>
              </div>

              <div>
                <h4>
                  {{ 'SERVER_INFO.ENV.TITLE' | translate }}
                </h4>
                <server-info-item
                  infoTitleTranslationKey="SERVER_INFO.ENV.PROFILES"
                  [infoValue]="getProfiles(infos.env?.profiles)"
                ></server-info-item>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-dialog-content>
    </ng-template>

    <mat-dialog-actions class="flex flex-align-center--center">
      <button mat-raised-button type="button" (click)="closeEvent.emit()">
        {{ 'GENERAL.CLOSE' | translate }}
      </button>
    </mat-dialog-actions>
  `,
  styles: [
    `
      .spinner {
        width: 100%;
      }

      .dialog-content {
        padding: 15px;
      }

      .unavailable-content {
        color: red;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServerInfoDialogComponent implements OnChanges {
  @Input() serverInfos: ServerInfosDto[] = []

  @Output() closeEvent = new EventEmitter<void>()

  public displayLoader = true

  constructor(private translate: TranslateService) {}

  ngOnChanges(changes: TypedChanges<{ serverInfos: ServerInfosDto[] }>): void {
    if (
      changes.serverInfos?.currentValue?.length > 0 ||
      !changes?.serverInfos?.isFirstChange()
    ) {
      this.displayLoader = false
    }
  }

  getServerName(info: ServerInfosDto): string {
    return info?.build?.name != null
      ? info?.build?.name.charAt(0).toUpperCase() + info?.build?.name.slice(1)
      : this.translate.instant('SERVER_INFO.UNAVAILABLE_SERVER.TITLE')
  }

  getProfiles(profiles: string[]): string {
    return profiles?.length > 0 ? profiles.join(', ') : 'N/A'
  }
}

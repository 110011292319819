import * as Guards from './progress-category.enum.type.guard'

/** @see {isProgressCategory} ts-auto-guard:type-guard */
export enum ProgressCategory {
  COUNT = 'COUNT',
  LENGTH = 'LENGTH',
  AREA_SIMPLE = 'AREA_SIMPLE',
}

export { Guards }

import { InjectionToken } from '@angular/core'
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store'
import * as core from '../../../../core/store'
import { createEntitiesReducer } from '../../../../shared/utils/redux/entities/entities.reducer'
import { EntitiesState } from '../../../../shared/utils/redux/entities/entities.state'
import { createValueReducer } from '../../../../shared/utils/redux/utils.reducers'
import { Group } from '../models/group.model'
import { StatusType } from '../models/status.model'
import { JobsiteDatatableActions } from './jobsite-datatable.actions'
import { JobsiteTableDataValues } from '../models/jobsite-table-data-values.model'
import {
  DEFAULT_WARNING_CHECKS_DATA,
  JobsiteChecksSettingsDto,
} from '../../../../shared/remote-services/dtos/jobsite-checks.model'
import { LoadableState } from '../../../../shared/utils/redux/loadable/loadable.state'
import { createLoadableReducer } from '../../../../shared/utils/redux/loadable/loadable.reducer'
import { DateRange } from '../../../../shared/localized-date-time-picker/localized-date-range-picker.component'
import { DataTableColumnVisibility } from './jobsite-datatable.selectors'

export interface State extends core.State {
  jobsiteDatatable: JobsiteDatatableState
}

export interface JobsiteDatatableState {
  displayedTableColumns: EntitiesState<{
    id: string
    tableColumns: DataTableColumnVisibility<JobsiteTableDataValues>
  }>
  dailyReportData?: Array<Group | JobsiteTableDataValues>
  selectedDateRange: DateRange
  SelectedStatus?: StatusType
  checksSettings: LoadableState<JobsiteChecksSettingsDto>
}

const reducers: ActionReducerMap<JobsiteDatatableState> = {
  displayedTableColumns: createEntitiesReducer(
    JobsiteDatatableActions.updateDisplayedTableColumns,
  ),
  dailyReportData: createValueReducer(
    undefined,
    JobsiteDatatableActions.setDailyReportData,
  ),
  selectedDateRange: createValueReducer(
    new DateRange(null, null),
    JobsiteDatatableActions.setSelectedDateRange,
  ),
  SelectedStatus: createValueReducer(
    undefined,
    JobsiteDatatableActions.setSelectedStatus,
  ),
  checksSettings: createLoadableReducer(
    JobsiteDatatableActions.loadChecksSettings,
    DEFAULT_WARNING_CHECKS_DATA,
  ),
}

export const getState =
  createFeatureSelector<JobsiteDatatableState>('jobsiteDatatable')

export const reducerInjecteur = new InjectionToken(
  'Jobsite Datatable Registered Reducers',
)

function getReducers(): ActionReducerMap<JobsiteDatatableState> {
  return reducers
}

export const reducerProvider = [
  { provide: reducerInjecteur, useFactory: getReducers },
]

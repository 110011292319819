import { reducerFromActionHandlers } from '../utils.reducers'
import { LoadableActionFactory } from './loadable.actions'
import { LoadableState } from './loadable.state'

export function createLoadableReducer<Item>(
  actionFactory: LoadableActionFactory<any, Item>,
  defaultValue: Item,
) {
  const initialState: LoadableState<Item> = {
    loading: false,
    loaded: false,
    value: defaultValue,
  }

  return reducerFromActionHandlers<LoadableState<Item>, void | Item>(
    initialState,
    [
      {
        actionType: actionFactory.START,
        handler: () => ({ ...initialState, loading: true, loaded: false }),
      },
      { actionType: actionFactory.FAILED, handler: () => initialState },
      { actionType: actionFactory.RESET, handler: () => initialState },
      {
        actionType: actionFactory.COMPLETE,
        handler: (
          state,
          action: ReturnType<(typeof actionFactory)['createComplete']>,
        ) => ({
          loading: false,
          loaded: true,
          value: action.payload,
        }),
      },
    ],
  )
}

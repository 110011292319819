import * as generalSettings from './general/state'
import * as compoundParamSettings from './compound-params/state'
import {
  DateTimeFormatsSettings,
  initialDateTimeFormatsSettings,
} from './date-time-format/state'
import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  MetaReducer,
} from '@ngrx/store'
import { createValueReducer } from '../../../shared/utils/redux/utils.reducers'
import { DateTimeFormatsSettingsActions } from './date-time-format/date-time-formats-settings.actions'
import { InjectionToken, Provider } from '@angular/core'
import { localStorageSync } from 'ngrx-store-localstorage'
import { SettingsActions } from './settings.actions'
import {
  deserialize,
  FeatureToggleState,
  initialFeatureTogglesSetting,
} from './feature-toggle/state'
import { FeatureToggleActions } from './feature-toggle/feature-toggle.actions'

export interface State {
  general: generalSettings.State
  compoundParams: compoundParamSettings.State
  dateTimeFormats: DateTimeFormatsSettings
  displaySaveAlert: boolean
  isDefaultHomePagePref: boolean
  featureToggles: FeatureToggleState
}

const reducers: ActionReducerMap<State> = {
  general: generalSettings.reducers,
  compoundParams: compoundParamSettings.reducers,
  dateTimeFormats: createValueReducer<State['dateTimeFormats']>(
    initialDateTimeFormatsSettings,
    DateTimeFormatsSettingsActions.setDateTimeFormatsSettings,
  ),
  displaySaveAlert: createValueReducer(
    false,
    SettingsActions.toggleDisplaySaveAlert,
  ),
  isDefaultHomePagePref: createValueReducer(
    true,
    SettingsActions.isDefaultHomePagePref,
  ),
  featureToggles: createValueReducer(
    initialFeatureTogglesSetting,
    FeatureToggleActions.setFeatureToggles,
  ),
}

export const getState = createFeatureSelector<State>('settingsState')

export const reducerInjector = new InjectionToken(
  'Settings Registered Reducers',
)

function getReducers(): ActionReducerMap<State> {
  return reducers
}

export const reducerProvider: Provider = [
  {
    provide: reducerInjector,
    useFactory: getReducers,
  },
]

function localStorageSyncReducer(
  reducer: ActionReducer<State>,
): ActionReducer<State> {
  return localStorageSync({
    keys: [
      'general',
      'displaySaveAlert',
      {
        featureToggles: {
          deserialize: deserialize,
        },
      },
    ],
    rehydrate: true,
  })(reducer)
}

export const metaReducers: Array<MetaReducer<any, any>> = [
  localStorageSyncReducer,
]

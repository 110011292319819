import { NgModule } from '@angular/core'
import { SsdSoillibLibTranslateLoader } from '@sde-ild/ssd-soillib-lib'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { forkJoin, from, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import * as merge from 'deepmerge'
import { isUserLanguage } from '../../shared/remote-services/dtos/userPreferences.dto.type.guard'

function createMultiTranslateFactory(
  ssdSoillibLibTranslateLoader: SsdSoillibLibTranslateLoader,
) {
  return new MultiTranslateLoader(ssdSoillibLibTranslateLoader)
}

class MultiTranslateLoader implements TranslateLoader {
  constructor(
    private ssdSoillibLibTranslateLoader: SsdSoillibLibTranslateLoader,
  ) {}

  public getTranslation(lang: string): Observable<unknown> {
    const requests = isUserLanguage(lang)
      ? [
          from(import(`../../../assets/i18n/${lang}.json`)),
          this.ssdSoillibLibTranslateLoader.getTranslation(lang),
        ]
      : [from(import(`../../../assets/i18n/${lang}.json`))]

    return forkJoin(requests).pipe(
      map(response =>
        response.map((r: unknown) => {
          if (typeof r === 'object') {
            return { ...r }
          }
        }),
      ),
      map(response => merge.all(response)),
    )
  }
}

@NgModule({
  declarations: [],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createMultiTranslateFactory,
        deps: [SsdSoillibLibTranslateLoader],
      },
    }),
  ],
})
export class TranslationModule {}

import { TechniqueNames } from 'app/shared/remote-services/dtos/technique.dto'
import { FieldDto } from '../../../shared/remote-services/dtos/field.dto'
import { reducerFromActionHandlers } from '../../../shared/utils/redux/utils.reducers'
import { FieldsActions } from './fields.actions'

export type FieldById = {
  [id: string]: FieldDto
}

export type FieldState = {
  [techniqueName in TechniqueNames]: FieldById
}

const initialState: FieldState = {} as any

export const fieldsReducer = reducerFromActionHandlers(initialState, [
  {
    actionType: FieldsActions.loadFields.COMPLETE,
    handler: (
      state,
      action: ReturnType<(typeof FieldsActions.loadFields)['createComplete']>,
    ): FieldState => ({
      ...state,
      [action.payload.techniqueName]: Object.fromEntries(
        action.payload.fields.map(field => [field.id, field]),
      ),
    }),
  },
  {
    actionType: FieldsActions.loadFields.FAILED,
    handler: () => initialState,
  },
  {
    actionType: FieldsActions.loadFields.RESET,
    handler: () => initialState,
  },
])

import { ElementRef } from '@angular/core'
import { JobsiteSummaryState } from '../../jobsites-management/jobsite-summary/store/state'
import { defaults } from 'ol/control'
import { Coordinate } from 'ol/coordinate'
import { platformModifierKeyOnly } from 'ol/events/condition'
import * as olExtent from 'ol/extent'
import {
  boundingExtent,
  containsCoordinate,
  getCenter,
  getHeight,
  getWidth,
} from 'ol/extent'
import { FeatureLike } from 'ol/Feature'
import GeometryType from 'ol/geom/GeometryType'
import { defaults as interactionDefaults } from 'ol/interaction'
import olInterfactionDraw from 'ol/interaction/Draw'
import olLayerLayer from 'ol/layer/Layer'
import olLayerVector from 'ol/layer/Vector'
import olMap from 'ol/Map'
import olOverlay from 'ol/Overlay'
import OverlayPositioning from 'ol/OverlayPositioning'
import olSourceVector from 'ol/source/Vector'
import olView from 'ol/View'
import { ThreeDProfileModel } from '../../jobsites-management/jobsite-summary/models/three-d-scene.model'
import { throttle } from 'lodash'
import { MapColumn } from '../../jobsites-management/jobsite-summary/models/map-object/mapColumn.type'

export const INIT_MAP_ZOOM = 1
export const INIT_MAP_CENTER = olExtent.getCenter([0, 0, 10000, 10000]) as [
  number,
  number,
]
export const INIT_MAP_ROTATION = 0

export function getMap(
  mapDiv: ElementRef,
  center: [number, number] = INIT_MAP_CENTER,
  zoom: number = INIT_MAP_ZOOM,
  rotation: number = INIT_MAP_ROTATION,
  type: string = '',
) {
  const mouseWheelZoom = type !== 'tabMap'
  return new olMap({
    interactions: interactionDefaults({ mouseWheelZoom }),
    controls: defaults({
      attribution: false,
      zoom: false,
    }),
    target: mapDiv.nativeElement,
    layers: [],
    view: new olView({
      center,
      zoom,
      rotation,
    }),
  })
}

// Source: http://openlayers.org/en/latest/examples/icon.html?mode=raw
export function setCursorPointerOnFeatures(
  map: olMap,
  layers: olLayerLayer[],
  isEnable: () => boolean = () => true,
) {
  map.on('pointermove', evt => {
    if (isEnable()) {
      const hit = map.hasFeatureAtPixel(evt.pixel, {
        layerFilter: currentLayer => layers.includes(currentLayer),
      })
      ;(map.getTargetElement() as any).style.cursor = hit ? 'pointer' : ''
    }
  })
}

export function addFixTooltipOverlay(
  map: olMap,
  tooltip: ElementRef,
  type?: string,
) {
  const offset = type ? [0, 0] : [10, 0]

  const overlay = new olOverlay({
    element: tooltip.nativeElement,
    offset,
    positioning: OverlayPositioning.BOTTOM_LEFT,
  })

  map.addOverlay(overlay)

  return overlay
}

export function addTooltip(
  map: olMap,
  tooltip: ElementRef,
  layers: olLayerLayer[],
  getContent: (
    features: FeatureLike[],
    pos: JobsiteSummaryState['popoverPosition'],
  ) => void | string | undefined,
  type?: string,
) {
  const offset = type ? [0, 0] : [10, 0]

  const overlay = new olOverlay({
    element: tooltip.nativeElement,
    offset,
    positioning: OverlayPositioning.BOTTOM_LEFT,
  })

  let oldFeatures: FeatureLike[] = []
  let popUpIsfocus = false

  map.addOverlay(overlay)
  map.on(
    'pointermove',
    throttle(evt => {
      if (popUpIsfocus) {
        popUpIsfocus = false
        return
      }
      const features: FeatureLike[] = []
      map.forEachFeatureAtPixel(
        evt.pixel,
        featureArg => {
          features.push(featureArg)
        },
        {
          layerFilter: currentLayer =>
            layers.find(
              layer => layer.get('name') === currentLayer.get('name'),
            ) != null,
        },
      )
      if (
        features.length === oldFeatures.length &&
        features.filter(f => !oldFeatures.includes(f))?.length === 0
      ) {
        return
      }
      oldFeatures = features
      const mapSize = map.getSize()
      const pos = evt.pixel[1] < mapSize[1] / 2 ? 'bottom' : 'top'
      const feature = features[0]
      let display = 'none'
      if (type && features.length > 0) {
        // for the map with tabs
        getContent(features, pos)
        overlay.setPosition(evt.coordinate)
        display = ''
      } else if (feature) {
        // for the map of page jobsite list
        const content = getContent([feature], 'bottom')
        if (content !== undefined) {
          overlay.setPosition(evt.coordinate)
          tooltip.nativeElement.innerHTML = content
          display = ''
        }
      }
      tooltip.nativeElement.style.display = display
      overlay.getElement().addEventListener('pointermove', evt => {
        evt.stopImmediatePropagation()
        popUpIsfocus = true
      })
    }, 250),
  )
  return overlay
}

export function getDrawInteraction(
  map: olMap,
  columnSources: olSourceVector[],
  drawingStartEvent: () => void,
  drawingEndEvent: (columnIds: string[]) => void,
): olInterfactionDraw {
  const drawingSource = new olSourceVector()
  const drawingLayer = new olLayerVector({ source: drawingSource })
  drawingLayer.set('name', 'draw')
  map.addLayer(drawingLayer)

  const drawInteraction = new olInterfactionDraw({
    source: drawingSource,
    type: GeometryType.POLYGON,
    freehandCondition: platformModifierKeyOnly,
    condition: platformModifierKeyOnly,
  })

  if (drawingStartEvent) {
    drawInteraction.on('drawstart', _ => {
      drawingStartEvent()
    })
  }

  if (drawingEndEvent) {
    drawInteraction.on('drawend', (e: any) => {
      e.preventDefault()
      setTimeout(() => drawingSource.clear(), 300)
      setTimeout(() => {
        const features = columnSources.flatMap(source => source.getFeatures())
        const columnIdsToSelect = features
          .filter(feature => {
            const extent = feature.getGeometry().getExtent()
            return drawingSource.getFeaturesAtCoordinate(getCenter(extent))
              .length
          })
          .map(feature => (feature.get('column') as MapColumn).id)
        drawingEndEvent(columnIdsToSelect)
      }, 100)
    })
  }

  return drawInteraction
}

export function getProfileOfPilesExtent(
  coordinates: Coordinate[],
): ThreeDProfileModel {
  const pilesExtent = boundingExtent(coordinates)
  return {
    center: getCenter(pilesExtent),
    width: getWidth(pilesExtent),
    height: getHeight(pilesExtent),
  }
}

export function containsCoords(
  extent: olExtent.Extent,
  coordinates: Coordinate,
): boolean {
  return containsCoordinate(extent, coordinates)
}

import { Injectable } from '@angular/core'
import { Actions, createEffect } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { CageService } from 'app/shared/remote-services/cage.service'
import { onStart } from 'app/shared/utils/redux/operators'
import { map, switchMap, withLatestFrom } from 'rxjs/operators'
import { getJobsiteId } from '../router/router.selectors'
import { State } from '../state'
import { CageActions } from './cage.actions'

@Injectable()
export class CageEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private service: CageService,
  ) {}

  loadDailyReportColumns$ = createEffect(() =>
    this.actions$.pipe(
      onStart(CageActions.cageTypeDefActions),
      withLatestFrom(this.store.pipe(select(getJobsiteId))),
      switchMap(([action, jobsiteId]) =>
        this.service.getAllTypeDefByJobsite(jobsiteId),
      ),
      map(res => CageActions.cageTypeDefActions.createAddItems(res)),
    ),
  )
}

import { Injectable } from '@angular/core'
import { AbstractLoadableGuard } from '../../shared/utils/abstract-loadable.guard'
import { State as CoreState } from 'app/core/store/state'
import { Store } from '@ngrx/store'
import { getChecksSettings } from '../../jobsites-management/jobsite-summary/jobsite-data-table/store/jobsite-datatable.selectors'
import { JobsiteDatatableActions } from '../../jobsites-management/jobsite-summary/jobsite-data-table/store/jobsite-datatable.actions'
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router'
import { Observable } from 'rxjs'

@Injectable()
export class JobsiteChecksGuard
  extends AbstractLoadableGuard<CoreState>
  implements CanDeactivate<void>
{
  constructor(store: Store<CoreState>) {
    super(
      store,
      getChecksSettings.getLoaded,
      JobsiteDatatableActions.loadChecksSettings,
    )
  }

  canDeactivate(
    component: void,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (currentState.url !== nextState.url) {
      this.store.dispatch(
        JobsiteDatatableActions.loadChecksSettings.createReset(),
      )
    }
    return true
  }
}

import { Injectable } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { environment } from '../../../environments/environment'
import { TechniqueNames } from '../../shared/remote-services/dtos/technique.dto'

declare let gtag: any

@Injectable()
export class GoogleAnalyticsService {
  constructor(private router: Router) {}

  public event(
    eventName: string,
    params: { name: string } | { technique: TechniqueNames },
  ): void {
    if (environment.gaEnabled) {
      gtag('event', eventName, params)
    } else {
      console.log(
        `[Google Analytics] Send ${eventName} with params ${JSON.stringify(
          params,
        )}`,
      )
    }
  }

  public init(): void {
    if (environment.gaEnabled) {
      this.listenForRouteChanges()

      try {
        const script1 = document.createElement('script')
        script1.async = true
        script1.src =
          'https://www.googletagmanager.com/gtag/js?id=' + environment.gaKey
        document.head.appendChild(script1)

        const script2 = document.createElement('script')
        script2.innerHTML =
          `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '` +
          environment.gaKey +
          `', {'send_page_view': false});
      `
        document.head.appendChild(script2)
      } catch (ex) {
        console.error('Error appending google analytics')
        console.error(ex as Error)
      }
    }
  }

  private listenForRouteChanges(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', environment.gaKey, {
          page_path: event.urlAfterRedirects,
        })
      }
    })
  }
}

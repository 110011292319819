import { InjectionToken } from '@angular/core'
import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  MetaReducer,
} from '@ngrx/store'
import { CompanyDto } from 'app/core/models/company.dto'
import * as core from 'app/core/store'
import { JobsiteDto } from 'app/shared/remote-services/dtos/jobsite.dto'
import { createListReducer } from 'app/shared/utils/redux/list/list.reducer'
import {
  createDefaultValueReducer,
  createValueReducer,
} from 'app/shared/utils/redux/utils.reducers'
import { localStorageSync } from 'ngrx-store-localstorage'
import { fromLonLat } from 'ol/proj'
import { DisplayedFavoriteKPIDto } from '../../jobsite-summary/jobsite-kpi/models/jobsite-favorite-kpi.dto'
import { OrderValues } from '../models/jobsites-filter.model'
import { JobsitesListActions } from './jobsites-list.actions'
import { JobsitePinpointDto } from '../../../shared/remote-services/dtos/jobsitePinpoint.dto'
import { JobsiteStatus } from '../../../shared/remote-services/dtos/jobsiteStatus.dto'
import { WeeklyReportConfig } from '../models/weekly-report-config.model'
import { LoadableState } from '../../../shared/utils/redux/loadable/loadable.state'
import { createLoadableReducer } from '../../../shared/utils/redux/loadable/loadable.reducer'
import { JobsiteStatsDto } from '../../../shared/remote-services/dtos/jobsiteStats.dto'
import { createLoadableMapReducer } from '../../../shared/utils/redux/loadable-map/loadable-map.reducer'
import { LoadableMapState } from '../../../shared/utils/redux/loadable-map/loadable-map.state'
import { ListState } from '../../../shared/utils/redux/list/list.state'

export interface State extends core.State {
  jobsitesList: JobsitesListState
}

export interface JobsitesListState {
  mapSettings: { center: [number, number]; zoom: number }
  search: string | undefined
  status: JobsiteStatus | null | undefined
  orderDirection: 'ASC' | 'DESC' | undefined
  orderValue: OrderValues | undefined
  currentPage: number | undefined
  pageSize: number | undefined
  totalItems: number | undefined
  onlyStarred: boolean | undefined
  nbAssignedMachines: { [id: string]: number }
  selectedCompany?: CompanyDto
  displayedJobsites: ListState<JobsiteDto>
  displayedFavoriteKpi: ListState<DisplayedFavoriteKPIDto>
  displayMode: 'LIST' | 'MAP'
  mapAnimationDone: boolean
  listAnimationDone: boolean
  refreshMapSize: boolean
  mapPinpoints: ListState<JobsitePinpointDto>
  weeklyReportConfig: LoadableState<WeeklyReportConfig>
  jobsiteStats: LoadableMapState<JobsiteStatsDto>
}

const reducers: ActionReducerMap<JobsitesListState> = {
  weeklyReportConfig: createLoadableReducer(
    JobsitesListActions.loadWeeklyReportConfig,
    {
      weeklyReportLang: 'en',
      weeklyReportUnitSystem: 'METRIC',
      weeklyReportHourOfDay: 18,
      weeklyReportDayOfWeek: 'FRIDAY',
      weeklyReportEmail: '',
      jobsiteIds: [],
    },
  ),
  jobsiteStats: createLoadableMapReducer<JobsiteStatsDto>(
    JobsitesListActions.loadJobsiteStats,
  ),
  mapSettings: createValueReducer(
    {
      center: fromLonLat([2.1629996, 48.8741176]) as [number, number],
      zoom: 4,
    },
    JobsitesListActions.updateMapSettings,
  ),
  search: createDefaultValueReducer(JobsitesListActions.provideSearch),
  orderDirection: createDefaultValueReducer(
    JobsitesListActions.setOrderDirection,
  ),
  orderValue: createDefaultValueReducer(JobsitesListActions.setOrderValue),
  status: createDefaultValueReducer(JobsitesListActions.clickOnStatus),
  currentPage: createDefaultValueReducer(JobsitesListActions.setCurrentPage),
  pageSize: createDefaultValueReducer(JobsitesListActions.setPageSize),
  totalItems: createDefaultValueReducer(JobsitesListActions.setTotalItems),
  onlyStarred: createDefaultValueReducer(JobsitesListActions.setOnlyStarred),
  nbAssignedMachines: createValueReducer(
    {},
    JobsitesListActions.loadNbAssignedMachines,
  ),
  selectedCompany: createDefaultValueReducer(
    JobsitesListActions.setCompanyValue,
  ),
  displayedJobsites: createListReducer(JobsitesListActions.displayedJobsites),
  displayedFavoriteKpi: createListReducer(
    JobsitesListActions.displayedFavoriteKpi,
  ),
  displayMode: createValueReducer<JobsitesListState['displayMode']>(
    'LIST',
    JobsitesListActions.setDisplayMode,
  ),
  mapAnimationDone: createValueReducer<boolean>(
    false,
    JobsitesListActions.setMapAnimationDone,
  ),
  listAnimationDone: createValueReducer<boolean>(
    false,
    JobsitesListActions.setListAnimationDone,
  ),
  refreshMapSize: createValueReducer<boolean>(
    true,
    JobsitesListActions.setRefreshMapSize,
  ),
  mapPinpoints: createListReducer(JobsitesListActions.jobsitesPinpoints),
}

export const getState = createFeatureSelector<JobsitesListState>('jobsitesList')

export const reducerInjecteur = new InjectionToken(
  'Jobsites List Registered Reducers',
)

function getReducers(): ActionReducerMap<JobsitesListState> {
  return reducers
}

export const reducerProvider = [
  { provide: reducerInjecteur, useFactory: getReducers },
]

// Save the mapSettings in the user's localStorage
export function localStorageSyncReducer(
  reducer: ActionReducer<JobsitesListState>,
): ActionReducer<JobsitesListState> {
  return localStorageSync({
    keys: [
      'mapSettings',
      'status',
      'pageSize',
      'orderDirection',
      'orderValue',
      'onlyStarred',
      'companyValue',
    ],
    rehydrate: true,
    storageKeySerializer: key => 'JobsitesList-' + key,
  })(reducer)
}
export const metaReducers: Array<MetaReducer<any, any>> = [
  localStorageSyncReducer,
]

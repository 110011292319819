import * as Guards from './csv-separator.enum.type.guard'

/** @see {isCsvSeparator} ts-auto-guard:type-guard */
export enum CsvSeparator {
  COMMA = ',',
  SEMICOLON = ';',
}

/** @see {isCsvDecimalSeparator} ts-auto-guard:type-guard */
export enum CsvDecimalSeparator {
  COMMA = ',',
  POINT = '.',
}

export { Guards }

import * as Guards from './matomo-custom-dimensions.enum.type.guard'
/** @see {isMatomoCustomDimensions} ts-auto-guard:type-guard */
export enum MatomoCustomDimensions {
  VISIT_USER_ID = 1,
  VISIT_BUSINESS_UNIT = 2,
  ACTION_USER_ID = 3,
  ACTION_BUSINESS_UNIT = 4,
  ACTION_TECHNIQUE_NAME = 5,
  ACTION_JOBSITE_ID = 6,
  ACTION_DATA = 7,
  ACTION_DATA_DESCRIPTION = 8,
  ACTION_UNIT_SYSTEM = 9,
  ACTION_PILE_ID = 10,
}
export { Guards }

import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { AbstractLoadableGuard } from '../../shared/utils/abstract-loadable.guard'
import { State } from '../store'
import { UserActions } from '../store/user/user.actions'
import { userPreferencesSelector } from '../store/user/user.selectors'

@Injectable()
export class UserPreferencesGuard extends AbstractLoadableGuard<State> {
  constructor(store: Store<State>) {
    super(
      store,
      userPreferencesSelector.getLoaded,
      UserActions.loadUserPreferences,
    )
  }
}

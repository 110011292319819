import { createSelector } from '@ngrx/store'
import { EntitiesSelectorFactory } from '../../../shared/utils/redux/entities/entities.selectors'
import { getJobsiteId } from '../router/router.selectors'
import * as core from '../state'
import {
  ConcreteDelivery,
  ConcreteTypeDefinition,
} from '../../../shared/remote-services/dtos/concrete.dto'

export const concreteTypeDefSelector = new EntitiesSelectorFactory<
  ConcreteTypeDefinition,
  core.State
>(core.getState, state => state.concreteTypeDef)

export const concreteDeliverySelector = new EntitiesSelectorFactory<
  ConcreteDelivery,
  core.State
>(core.getState, state => state.concreteDelivery)

export const getConcreteTypeDefForJobsite = createSelector(
  concreteTypeDefSelector.getAll,
  getJobsiteId,
  (concreteTypeDefs, jobsiteId) => {
    return concreteTypeDefs.filter(def => def.jobsiteId === jobsiteId)
  },
)

export const getConcreteTypeDefById = (id: string) =>
  createSelector(getConcreteTypeDefForJobsite, concreteTypeDefs => {
    return concreteTypeDefs.find(n => n.id === id)
  })

export const getConcreteDeliveryByConcreteType = (concreteTypeId: string) =>
  createSelector(concreteDeliverySelector.getAll, concreteDeliveries => {
    return concreteDeliveries.filter(
      n => n.concreteTypeDefinitionId === concreteTypeId,
    )
  })

import { createSelector } from '@ngrx/store'
import { CageTypeDefinition } from '../../../shared/remote-services/dtos/cage.dto'
import { EntitiesSelectorFactory } from '../../../shared/utils/redux/entities/entities.selectors'
import { getJobsiteId } from '../router/router.selectors'
import * as core from '../state'

export const cageTypeDefSelector = new EntitiesSelectorFactory<
  CageTypeDefinition,
  core.State
>(core.getState, state => state.cageTypeDef)

export const getCageTypeDefForJobsite = createSelector(
  cageTypeDefSelector.getAll,
  getJobsiteId,
  (cageTypeDefs, jobsiteId) => {
    return cageTypeDefs.filter(def => def.jobsiteId === jobsiteId)
  },
)

import { Action } from '@ngrx/store'
import { EntitiesState } from 'app/shared/utils/redux/entities/entities.state'
import { produce } from 'immer'
import { StatsModel } from '../../models/stats.model'
import { ColumnsStatsActions } from './columns-stats.actions'

export interface State extends EntitiesState<StatsModel> {
  loadedMetrics: { [jobsiteId: string]: { [metric: string]: string[] } }
}

export const initialState: State = {
  loadedMetrics: {},
  loaded: false,
  loading: false,
  value: {},
}

export function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case ColumnsStatsActions.loadStats.CREATE: {
      // immer is used for deep updates, see https://immerjs.github.io/
      return produce(state, draft => {
        const { jobsiteId, parameter, dtos } = (
          action as ReturnType<(typeof ColumnsStatsActions.loadStats)['create']>
        ).payload

        if (!draft.loadedMetrics[jobsiteId]) {
          draft.loadedMetrics[jobsiteId] = {}
        }

        const newEntities = dtos.map(dto => {
          const model: StatsModel = draft.value[dto.id]
            ? { ...draft.value[dto.id] }
            : { ...dto, stats: {} }

          if (!model.stats[jobsiteId]) {
            model.stats[jobsiteId] = {}
          }

          if (!draft.loadedMetrics[jobsiteId][parameter.metric]) {
            model.stats[jobsiteId][parameter.metric] = {}
          }
          model.stats[jobsiteId][parameter.metric][parameter.operator] =
            dto.stats[parameter.metric]
          return model
        })

        if (!draft.loadedMetrics[jobsiteId][parameter.metric]) {
          draft.loadedMetrics[jobsiteId][parameter.metric] = []
        }
        if (
          !draft.loadedMetrics[jobsiteId][parameter.metric].includes(
            parameter.operator,
          )
        ) {
          draft.loadedMetrics[jobsiteId][parameter.metric].push(
            parameter.operator,
          )
        }

        for (const entity of newEntities) {
          draft.value[entity.id] = entity
        }
      })
    }

    case ColumnsStatsActions.resetStats.CREATE: {
      return { ...initialState }
    }
  }

  return state
}

/*
 * Generated type guards for "column.dto.ts".
 * WARNING: Do not manually change this file.
 */
import { isParaValuesVersusDepth } from '../../../jobsites-management/jobsite-summary/models/stats.dto.type.guard'
import {
  ColumnDto,
  ColumnKey,
  ColumnValues,
  ColumnLink,
  ColumnLinkArray,
  ColumnComment,
} from './column.dto'
import { HFType } from '../../constants/hf-type.enum'

export function isColumnDto(obj: unknown): obj is ColumnDto {
  const typedObj = obj as ColumnDto
  return (
    ((typedObj !== null && typeof typedObj === 'object') ||
      typeof typedObj === 'function') &&
    (isColumnKey(typedObj['key']) as boolean) &&
    ((typedObj['values'] !== null && typeof typedObj['values'] === 'object') ||
      typeof typedObj['values'] === 'function') &&
    (typeof typedObj['values']['targetDepth'] === 'undefined' ||
      typeof typedObj['values']['targetDepth'] === 'number') &&
    (typeof typedObj['values']['thrustPresCriterion'] === 'undefined' ||
      typeof typedObj['values']['thrustPresCriterion'] === 'number') &&
    (typeof typedObj['values']['thrustCriterion'] === 'undefined' ||
      typeof typedObj['values']['thrustCriterion'] === 'number') &&
    (typeof typedObj['values']['anchorageLayer'] === 'undefined' ||
      typeof typedObj['values']['anchorageLayer'] === 'number') &&
    (typeof typedObj['values']['torquePresCriterion'] === 'undefined' ||
      typeof typedObj['values']['torquePresCriterion'] === 'number') &&
    (typeof typedObj['values']['torqueCriterion'] === 'undefined' ||
      typeof typedObj['values']['torqueCriterion'] === 'number') &&
    (typeof typedObj['values']['energyCriterion'] === 'undefined' ||
      typeof typedObj['values']['energyCriterion'] === 'number') &&
    (isColumnValues(typedObj['values']) as boolean) &&
    (typeof typedObj['stats'] === 'undefined' ||
      (((typedObj['stats'] !== null && typeof typedObj['stats'] === 'object') ||
        typeof typedObj['stats'] === 'function') &&
        Object.entries<any>(typedObj['stats']).every(
          ([key, value]) =>
            ((value !== null && typeof value === 'object') ||
              typeof value === 'function') &&
            Object.entries<any>(value).every(
              ([key, value]) =>
                typeof value === 'number' && typeof key === 'string',
            ) &&
            typeof key === 'string',
        ))) &&
    (typeof typedObj['stats3d'] === 'undefined' ||
      (((typedObj['stats3d'] !== null &&
        typeof typedObj['stats3d'] === 'object') ||
        typeof typedObj['stats3d'] === 'function') &&
        Object.entries<any>(typedObj['stats3d']).every(
          ([key, value]) =>
            ((value !== null && typeof value === 'object') ||
              typeof value === 'function') &&
            Object.entries<any>(value).every(
              ([key, value]) =>
                Array.isArray(value) &&
                value.every(
                  (e: any) => isParaValuesVersusDepth(e) as boolean,
                ) &&
                typeof key === 'string',
            ) &&
            typeof key === 'string',
        ))) &&
    Array.isArray(typedObj['links']) &&
    typedObj['links'].every((e: any) => isColumnLink(e) as boolean)
  )
}

export function isColumnKey(obj: unknown): obj is ColumnKey {
  const typedObj = obj as ColumnKey
  return (
    ((typedObj !== null && typeof typedObj === 'object') ||
      typeof typedObj === 'function') &&
    typeof typedObj['id'] === 'string' &&
    typeof typedObj['name'] === 'string' &&
    (typeof typedObj['process'] === 'undefined' ||
      typedObj['process'] === 'DMX' ||
      typedObj['process'] === 'HF' ||
      typedObj['process'] === 'JTC' ||
      typedObj['process'] === 'KS' ||
      typedObj['process'] === 'PFA' ||
      typedObj['process'] === 'PFC' ||
      typedObj['process'] === 'SBP' ||
      typedObj['process'] === 'SMX' ||
      typedObj['process'] === 'TCT' ||
      typedObj['process'] === 'VBF' ||
      typedObj['process'] === 'EPF') &&
    (typeof typedObj['type'] === 'undefined' ||
      typedObj['type'] === HFType.PILE ||
      typedObj['type'] === HFType.MERLON ||
      typedObj['type'] === HFType.PANEL)
  )
}

export function isColumnValues(obj: unknown): obj is ColumnValues {
  const typedObj = obj as ColumnValues
  return (
    ((typedObj !== null && typeof typedObj === 'object') ||
      typeof typedObj === 'function') &&
    (typeof typedObj['cage'] === 'undefined' ||
      typeof typedObj['cage'] === 'string') &&
    (typeof typedObj['localX'] === 'undefined' ||
      typeof typedObj['localX'] === 'number') &&
    (typeof typedObj['localY'] === 'undefined' ||
      typeof typedObj['localY'] === 'number') &&
    (typeof typedObj['computedLocalX'] === 'undefined' ||
      typeof typedObj['computedLocalX'] === 'number') &&
    (typeof typedObj['computedLocalY'] === 'undefined' ||
      typeof typedObj['computedLocalY'] === 'number') &&
    (typeof typedObj['nbr'] === 'undefined' ||
      typeof typedObj['nbr'] === 'number') &&
    (typeof typedObj['polygon'] === 'undefined' ||
      typeof typedObj['polygon'] === 'string') &&
    (typeof typedObj['width'] === 'undefined' ||
      typeof typedObj['width'] === 'number') &&
    (typeof typedObj['length'] === 'undefined' ||
      typeof typedObj['length'] === 'number') &&
    (typeof typedObj['prodDiameter'] === 'undefined' ||
      typeof typedObj['prodDiameter'] === 'number') &&
    (typeof typedObj['subtype'] === 'undefined' ||
      typeof typedObj['subtype'] === 'string') &&
    (typeof typedObj['type'] === 'undefined' ||
      typeof typedObj['type'] === 'string') &&
    (typeof typedObj['platformLevel'] === 'undefined' ||
      typeof typedObj['platformLevel'] === 'number') &&
    (typeof typedObj['topLevel'] === 'undefined' ||
      typeof typedObj['topLevel'] === 'number') &&
    (typeof typedObj['alert'] === 'undefined' ||
      typeof typedObj['alert'] === 'string') &&
    (typeof typedObj['axeAngle'] === 'undefined' ||
      typeof typedObj['axeAngle'] === 'number') &&
    (typeof typedObj['axeDistance'] === 'undefined' ||
      typeof typedObj['axeDistance'] === 'number') &&
    (typeof typedObj['diameter'] === 'undefined' ||
      typeof typedObj['diameter'] === 'number') &&
    (typeof typedObj['latitude'] === 'undefined' ||
      typeof typedObj['latitude'] === 'number') &&
    (typeof typedObj['longitude'] === 'undefined' ||
      typeof typedObj['longitude'] === 'number') &&
    (typeof typedObj['columnType'] === 'undefined' ||
      typeof typedObj['columnType'] === 'string') &&
    (typeof typedObj['zone'] === 'undefined' ||
      typeof typedObj['zone'] === 'string') &&
    (typeof typedObj['discarded'] === 'undefined' ||
      typedObj['discarded'] === false ||
      typedObj['discarded'] === true) &&
    (typeof typedObj['parentId'] === 'undefined' ||
      typeof typedObj['parentId'] === 'string') &&
    (typeof typedObj['panelOrder'] === 'undefined' ||
      typeof typedObj['panelOrder'] === 'number') &&
    (typeof typedObj['panelPhasing'] === 'undefined' ||
      typeof typedObj['panelPhasing'] === 'string') &&
    (typeof typedObj['lineRatio'] === 'undefined' ||
      typeof typedObj['lineRatio'] === 'number') &&
    (typeof typedObj['lineString'] === 'undefined' ||
      typeof typedObj['lineString'] === 'string')
  )
}

export function isColumnLink(obj: unknown): obj is ColumnLink {
  const typedObj = obj as ColumnLink
  return (
    ((typedObj !== null && typeof typedObj === 'object') ||
      typeof typedObj === 'function') &&
    (typeof typedObj['id'] === 'undefined' ||
      typeof typedObj['id'] === 'string') &&
    (typeof typedObj['idData'] === 'undefined' ||
      typeof typedObj['idData'] === 'string') &&
    (typeof typedObj['idCage'] === 'undefined' ||
      typeof typedObj['idCage'] === 'string') &&
    (typeof typedObj['idConcrete'] === 'undefined' ||
      typeof typedObj['idConcrete'] === 'string') &&
    (typeof typedObj['url'] === 'undefined' ||
      typeof typedObj['url'] === 'string') &&
    (typeof typedObj['nameFull'] === 'undefined' ||
      typeof typedObj['nameFull'] === 'string') &&
    (typeof typedObj['nameShort'] === 'undefined' ||
      typeof typedObj['nameShort'] === 'string') &&
    (typeof typedObj['icon'] === 'undefined' ||
      typeof typedObj['icon'] === 'string')
  )
}

export function isColumnLinkArray(obj: unknown): obj is ColumnLinkArray {
  const typedObj = obj as ColumnLinkArray
  return (
    Array.isArray(typedObj) &&
    typedObj.every((e: any) => isColumnLink(e) as boolean)
  )
}

export function isColumnComment(obj: unknown): obj is ColumnComment {
  const typedObj = obj as ColumnComment
  return (
    ((typedObj !== null && typeof typedObj === 'object') ||
      typeof typedObj === 'function') &&
    (typeof typedObj['id'] === 'undefined' ||
      typeof typedObj['id'] === 'string') &&
    (typeof typedObj['jobsiteId'] === 'undefined' ||
      typeof typedObj['jobsiteId'] === 'string') &&
    (typeof typedObj['columnId'] === 'undefined' ||
      typeof typedObj['columnId'] === 'string') &&
    (typeof typedObj['columnName'] === 'undefined' ||
      typeof typedObj['columnName'] === 'string') &&
    (typeof typedObj['comment'] === 'undefined' ||
      typeof typedObj['comment'] === 'string') &&
    (typeof typedObj['createdAt'] === 'undefined' ||
      typeof typedObj['createdAt'] === 'string') &&
    (typeof typedObj['deleted'] === 'undefined' ||
      typedObj['deleted'] === false ||
      typedObj['deleted'] === true)
  )
}

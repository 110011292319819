import {
  LoadableActionFactory,
  TypedAction,
} from '../loadable/loadable.actions'

export class ListActionFactory<
  Item,
  StartPayload = Item[],
> extends LoadableActionFactory<StartPayload, Item[]> {
  get ADD_ITEMS(): string {
    return `${this.name}.ADD_ITEMS`
  }

  get ADD_ITEM(): string {
    return `${this.name}.ADD_ITEM`
  }

  get REMOVE_ITEMS(): string {
    return `${this.name}.REMOVE_ITEMS`
  }

  get REMOVE_IDS(): string {
    return `${this.name}.REMOVE_IDS`
  }

  get SET_ITEMS(): string {
    return `${this.name}.SET_ITEMS`
  }

  constructor(name: string) {
    super(name)
  }

  public createAddItems(selectedItems: Item[]): TypedAction<Item[]> {
    return {
      type: this.ADD_ITEMS,
      payload: selectedItems,
    }
  }

  public createAddItem(
    item: Item,
    index?: number,
  ): TypedAction<{ item: Item; index?: number }> {
    return {
      type: this.ADD_ITEM,
      payload: { item, index },
    }
  }

  public createRemoveItems(selectedItems: Item[]): TypedAction<Item[]> {
    return {
      type: this.REMOVE_ITEMS,
      payload: selectedItems,
    }
  }

  public createRemoveIds(ids: string[]): TypedAction<string[]> {
    return {
      type: this.REMOVE_IDS,
      payload: ids,
    }
  }

  public createSetItems(items: Item[]): TypedAction<Item[]> {
    return {
      type: this.SET_ITEMS,
      payload: items,
    }
  }
}

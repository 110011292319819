import { createSelector } from '@ngrx/store'
import { ApplicationModel } from 'app/keycloak/models/user.model'
import { TrackingData } from 'app/shared/constants/matomo/tracking.model'
import { TechniqueNames } from 'app/shared/remote-services/dtos/technique.dto'
import { getState, State } from '../state'
import { getApplications, getUserId } from '../user'
import { RouterStateUrl } from './router.model'
import { getUserCompanyName } from '../companies/companies.selectors'

export const getRouterState = createSelector<State, [State], RouterStateUrl>(
  getState,
  (state: State) => state.router && state.router.state,
)

export const getRouterUrl = createSelector<State, [RouterStateUrl], string>(
  getRouterState,
  (state: RouterStateUrl) => state?.url,
)

export const getTechnique = createSelector<
  State,
  [RouterStateUrl],
  TechniqueNames
>(getRouterState, state => {
  return state?.params?.techniqueName as TechniqueNames
})

export const getJobsiteId = createSelector<State, [RouterStateUrl], string>(
  getRouterState,
  state => {
    return state?.params?.jobsiteId
  },
)

export const getColumnId = createSelector<State, [RouterStateUrl], string>(
  getRouterState,
  state => {
    return state?.params?.columnId
  },
)

export const getBackUrl = createSelector(getState, state => state.backUrl)

export const getSelectedApplication = createSelector(
  getRouterState,
  getApplications,
  (state, applications): ApplicationModel | undefined => {
    if (state) {
      const splits = state.url?.split('/', 2)
      if (splits.length === 2) {
        return applications.find(appConfig => appConfig.url === splits[1])
      }
    }
    return undefined
  },
)

export const selectTrackingData = createSelector(
  getUserId,
  getUserCompanyName,
  getTechnique,
  getJobsiteId,
  (
    userId: string,
    businessUnit: string,
    techniqueName: string,
    jobsiteId: string,
  ): TrackingData => ({
    userId,
    businessUnit,
    techniqueName,
    jobsiteId,
  }),
)

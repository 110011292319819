import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import {
  DateTimeFormatsDto,
  HomePagePreferenceDto,
  UserPreferencesDto,
} from './dtos/userPreferences.dto'
import { KeycloakService } from '../../keycloak/keycloak.service'
import { UUID } from '../models/utils.type'

@Injectable()
export class UserPreferencesService {
  constructor(
    private http: HttpClient,
    private keycloakService: KeycloakService,
  ) {}

  private baseUrl = 'user/user/preferences'

  public getUserSettings(): Observable<UserPreferencesDto> {
    return this.http.get<UserPreferencesDto>(this.baseUrl)
  }

  public updateLanguagePreference(newLanguage: string): Observable<void> {
    return this.http
      .put<void>(`${this.baseUrl}/language/${newLanguage}`, null)
      .pipe(map(() => this.keycloakService.clearToken()))
  }

  public updateTutoIsRead(newTutoIsRead: number): Observable<void> {
    return this.http.put<void>(
      `${this.baseUrl}/tuto-is-read/${newTutoIsRead}`,
      null,
    )
  }

  public updateUnitSystemPreference(newUnitSystem: string): Observable<void> {
    return this.http
      .put<void>(`${this.baseUrl}/unit-system/${newUnitSystem}`, null)
      .pipe(map(() => this.keycloakService.clearToken()))
  }

  public updateDateFormatPreference(newDateFormat: string): Observable<void> {
    return this.http.put<void>(
      `${this.baseUrl}/date-format/${newDateFormat}`,
      null,
    )
  }

  public updateDateTimeFormatsPreferences(
    formats: DateTimeFormatsDto,
  ): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/date-time-formats`, formats)
  }

  public updateRigPathEnabledPreference(enabled: boolean): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/rig-path/${enabled}`, null)
  }

  public updateHomePagePreferences(
    homePagePreference: Record<UUID, HomePagePreferenceDto>,
  ): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/home-page`, homePagePreference)
  }

  public updateAsBuiltSortDateDescending(
    descending: boolean,
  ): Observable<void> {
    return this.http.put<void>(
      `${this.baseUrl}/as-built-sort-date/${descending}`,
      null,
    )
  }
}

import { SyncActionFactory } from 'app/shared/utils/redux/sync.actions'
import { LoadColumnsStatsPayload } from '../../models/stats.dto'

export class ColumnsStatsActions {
  public static loadStats = new SyncActionFactory<LoadColumnsStatsPayload>(
    '[COLUMNS STATS] LOAD STATS',
  )

  public static resetStats = new SyncActionFactory<void>(
    '[COLUMNS STATS] RESET STATS',
  )
}

<mat-table class="table-margin-bottom" [dataSource]="dataSource">
  <ng-container matColumnDef="date">
    <mat-header-cell *matHeaderCellDef>
      {{ 'JOBSITE_MANAGEMENT.FOLLOW_UP.DATE' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let row">
      <mat-form-field [floatLabel]="row.getFloatLabel()" style="width: 90%">
        <input
          matInput
          [matDatepicker]="picker"
          (click)="picker.open()"
          [formControl]="row.form.controls['date']"
          [readonly]="!row.isEditing"
          [placeholder]="'JOBSITE_MANAGEMENT.FOLLOW_UP.DATE' | translate"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="count">
    <mat-header-cell *matHeaderCellDef>
      {{ getCategoryHeaderTranslation(category) }}
      <span *ngIf="category === 'LENGTH'">({{ depthUnit }})</span>
    </mat-header-cell>
    <mat-cell *matCellDef="let row">
      <mat-form-field [floatLabel]="row.getFloatLabel()" style="width: 90%">
        <input
          type="number"
          min="1"
          matInput
          [formControl]="row.form.controls['count']"
          [readonly]="!row.isEditing"
          [placeholder]="'JOBSITE_MANAGEMENT.FOLLOW_UP.NUMBER' | translate"
        />
      </mat-form-field>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="actionsColumn">
    <mat-header-cell *matHeaderCellDef>
      <button mat-icon-button color="primary" (click)="addRow()">
        <i class="fa fa-plus mat-icon"></i>
      </button>
      <button
        matTooltip="{{
          'JOBSITE_MANAGEMENT.FOLLOW_UP.CSV_IMPORT_TOOLTIP'
            | translate
              : { format: userPreferences.dateFormat, separator: csvSeparator }
        }}"
        matTooltipClass="button-tooltip"
        mat-icon-button
        color="primary"
        (click)="fileInput.click()"
      >
        <i class="fa fa-file-excel-o mat-icon"></i>
        <input
          #fileInput
          type="file"
          accept=".csv"
          class="hidden"
          title="Import CSV"
          (change)="handleFileSelected($event)"
        />
      </button>
      <button
        matTooltip="{{
          'JOBSITE_MANAGEMENT.FOLLOW_UP.DOWNLOAD_CSV_TEMPLATE' | translate
        }}"
        matTooltipClass="button-tooltip"
        mat-icon-button
        color="primary"
        (click)="downloadCsvTemplate()"
      >
        <i class="fa fa-download mat-icon"></i>
      </button>
      <button
        mat-icon-button
        color="primary"
        (click)="reset()"
        *ngIf="this.plannedProgress && this.plannedProgress.length"
        [title]="'JOBSITE_MANAGEMENT.FOLLOW_UP.IMPORT.RESET_ALL' | translate"
      >
        <i class="fa fa-trash mat-icon"></i>
      </button>
    </mat-header-cell>
    <mat-cell *matCellDef="let row">
      <button
        *ngIf="!row.isEditing"
        mat-icon-button
        color="warn"
        (click)="row.setEditing()"
      >
        <i class="fa fa-pencil mat-icon"></i>
      </button>
      <button
        *ngIf="row.isEditing"
        mat-icon-button
        color="warn"
        [disabled]="!row.form.valid"
        (click)="updateOrSave(row)"
      >
        <i class="fa fa-check mat-icon"></i>
      </button>
      <button mat-icon-button color="warn" (click)="cancelOrDelete(row)">
        <i class="fa fa-times mat-icon"></i>
      </button>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>

import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { AbstractLoadableGuard } from '../../shared/utils/abstract-loadable.guard'
import { State } from '../store'
import { TechniquesActions } from '../store/techniques/techniques.actions'
import { techniqueSelectors } from '../store/techniques/techniques.selectors'

@Injectable()
export class TechniquesGuard extends AbstractLoadableGuard<State> {
  constructor(store: Store<State>) {
    super(store, techniqueSelectors.getLoaded, TechniquesActions.loadTechniques)
  }
}

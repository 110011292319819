import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

@Component({
  selector: 'server-info-item',
  template: `
    <div class="flex flex-gap-10px">
      <span>
        {{ infoTitleTranslationKey | translate }}
      </span>
      <span class="info-value">
        {{ infoValue }}
      </span>
    </div>
  `,
  styles: [
    `
      .info-value {
        font-style: italic;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServerInfoItemComponent {
  @Input() infoTitleTranslationKey: string
  @Input() infoValue: string
}

<ng-container *ngIf="userPermission?.parsedRole.zl_admin">
  <div class="footer">
    <span>
      <button (click)="openServersVersion()">Servers version</button>
    </span>

    <span>
      <button (click)="openPlatformDisruptions()">Platform disruptions</button>
    </span>

    <span *ngIf="buildInfo?.version"> Version: {{ buildInfo?.version }} </span>

    <span *ngIf="buildInfo?.timestamp">
      Build time:
      {{ asDate(buildInfo?.timestamp) | localizedDateTime }}
    </span>

    <span *ngIf="buildInfo?.git?.hash && buildInfo?.git?.fullHash">
      <button [cdkCopyToClipboard]="buildInfo?.git?.fullHash">
        Commit: {{ buildInfo?.git?.hash }}
      </button>
    </span>

    <span> Environment: {{ environment.platform }} </span>

    <span>
      Prod flag:
      {{ environment.production ? 'production 🏭' : 'development 🚧' }}
    </span>

    <span>
      Sentry enabled: {{ environment.sentryEnabled }}
      <button (click)="testSentry()">Test sentry</button>
    </span>

    <span> GA enabled: {{ environment.gaEnabled }} </span>

    <span>
      <button (click)="goToMatomo()">Matomo</button>
    </span>

    <span class="jwt">
      <button [cdkCopyToClipboard]="getToken()">Copy JWT</button>
    </span>

    <span>
      <button (click)="syncLutz()">Sync Lutz</button>
    </span>

    <ng-container *ngIf="currentJobsite">
      <span>
        Jobsite: <button (click)="openJobsiteDevInfo()">Admin</button>
      </span>
    </ng-container>
  </div>
</ng-container>

import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ProcessDto } from './dtos/process.dto'
import { TechniqueNames } from './dtos/technique.dto'

@Injectable()
export class ProcessesService {
  constructor(private http: HttpClient) {}

  private baseUrl = 'rest/processes'

  public getAllProcesses(): Observable<ProcessDto[]> {
    return this.http.get<ProcessDto[]>(this.baseUrl)
  }

  public getProcessByName(processName: string): Observable<ProcessDto> {
    return this.http.get<ProcessDto>(`${this.baseUrl}/${processName}`)
  }

  public getProcessesByTechnique(
    techniqueName: TechniqueNames,
  ): Observable<ProcessDto[]> {
    return this.http.get<ProcessDto[]>(
      `${this.baseUrl}/technique/${techniqueName}`,
    )
  }
}

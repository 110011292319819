import { NgModule } from '@angular/core'
import { CoreModule } from '../../core/core.module'
import { components } from './components/components'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { reducerInjecteur, reducerProvider } from './store/state'
import { effects } from './store/effects'

@NgModule({
  imports: [
    CoreModule,
    StoreModule.forFeature('uploaderFiles', reducerInjecteur, {}),
    EffectsModule.forFeature(effects),
  ],
  declarations: [...components],
  exports: [...components],
  providers: [reducerProvider],
})
export class UploaderFilesModule {}

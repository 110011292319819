import { createSelector, MemoizedSelector, Selector } from '@ngrx/store'
import { State } from '../../../../core/store'
import { LoadableSelectorFactory } from '../loadable/loadable.selectors'
import { EntitiesState } from './entities.state'

export class EntitiesSelectorFactory<
  Item,
  SubState,
> extends LoadableSelectorFactory<Record<string, Item>, SubState> {
  constructor(
    selector: Selector<State, SubState> | Selector<State, EntitiesState<Item>>,
    lambda?: (arg: SubState) => EntitiesState<Item>,
  ) {
    super(selector, lambda)
  }

  get getEntities(): MemoizedSelector<State, Record<string, Readonly<Item>>> {
    return createSelector<State, [EntitiesState<Item>], Record<string, Item>>(
      this.objectSelector,
      (state: EntitiesState<Item>) => state?.value ?? {},
    )
  }

  get getAll(): MemoizedSelector<State, Item[]> {
    return createSelector<State, [EntitiesState<Item>], Item[]>(
      this.objectSelector,
      (state: EntitiesState<Item>) => Object.values(state?.value ?? {}),
    )
  }

  get getIds(): MemoizedSelector<State, string[]> {
    return createSelector<State, [EntitiesState<Item>], string[]>(
      this.objectSelector,
      (state: EntitiesState<Item>) => Object.keys(state?.value ?? {}),
    )
  }

  get getById(): (id: string) => MemoizedSelector<State, Item> {
    return id =>
      createSelector<State, [EntitiesState<Item>], Item>(
        this.objectSelector,
        (state: EntitiesState<Item>) => state.value?.[id],
      )
  }
}

import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import {
  DtoActiveDisruption,
  DtoPlatformDisruption,
  PlatformDisruptionMessage,
} from '../../core/models/PlatformDisruption.model'

@Injectable()
export class PlatformDisruptionsService {
  constructor(private http: HttpClient) {}

  private baseUrl = 'rest/platform/disruptions'

  public getLastActivePlatformDisruption(): Observable<DtoActiveDisruption> {
    return this.http.get<DtoActiveDisruption>(`${this.baseUrl}/active`)
  }

  public getAllPlatformDisruptions(): Observable<DtoPlatformDisruption[]> {
    return this.http.get<DtoPlatformDisruption[]>(this.baseUrl)
  }

  public createPlatformDisruption(
    message: PlatformDisruptionMessage,
  ): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/add`, message)
  }

  public closePlatformDisruption(disruptionId: string): Observable<void> {
    return this.http.put<void>(
      `${this.baseUrl}/close/${disruptionId}`,
      undefined,
    )
  }
}

import { ListActionFactory } from '../../../../shared/utils/redux/list/list.actions'
import {
  Jobsite,
  SoilStats,
  SoilSurvey,
  SurveyData,
} from '@sde-ild/ssd-soillib-lib'
import { LoadableActionFactory } from '../../../../shared/utils/redux/loadable/loadable.actions'
import { SyncActionFactory } from '../../../../shared/utils/redux/sync.actions'

export class JobsiteSoillibDataActions {
  public static loadSoillibJobsites = new ListActionFactory<Jobsite, void>(
    '[SOILLIB DATA] LOAD SOILLIB JOBSITES',
  )

  public static loadSoilStats = new LoadableActionFactory<void, SoilStats>(
    '[SOILLIB DATA] LOAD SOIL STATS',
  )

  public static loadJobsiteInfo = new LoadableActionFactory<void, Jobsite>(
    '[SOILLIB DATA] LOAD JOBSITE INFO',
  )

  public static loadJobsiteSoilSurveys = new ListActionFactory<
    SoilSurvey,
    string
  >('[SOILLIB DATA] LOAD JOBSITE SOIL SURVEYS')

  public static loadSurveysData = new LoadableActionFactory<
    void,
    Map<string, SurveyData[]>
  >('[SOILLIB DATA] LOAD SOIL SURVEYS DATA')

  public static openSoillibChartDialog = new SyncActionFactory<string>(
    '[SOILLIB DATA] OPEN SOILLIB CHART DIALOG',
  )
}

import { CompaniesGuard } from './companies.guard'
import { FieldsGuard } from './fields.guard'
import { JobsiteGuard } from './jobsite.guard'
import { PermissionsGuard } from './permissions.guard'
import { TechniquesGuard } from './techniques.guard'
import { CageTypeDefGuard } from './cage.guard'
import { UserPreferencesGuard } from './user-preferences.guard'
import { DailyReportColumnsGuard } from './daily-report-columns.guard'
import { ConcreteTypeDefGuard } from './concrete.guard'
import { ProviderGuard } from './provider.guard'
import { ConcreteDeliveryGuard } from './concrete-delivery.guard'
import { ProcessesGuard } from './processes.guard'
import { MobilePermissionsGuard } from './mobile-permissions.guard'
import { SoillibJobsitesGuard } from './soillib-jobsites.guard'
import { ProgressGuard } from './progress.guard'
import { JobsiteChecksGuard } from './jobsite-checks.guard'
import { PlatformDisruptionsGuard } from './platform-disruptions.guard'
import { WeeklyReportConfigGuard } from './weekly-report-config.guard'

export const guards = [
  CompaniesGuard,
  DailyReportColumnsGuard,
  FieldsGuard,
  JobsiteGuard,
  PermissionsGuard,
  MobilePermissionsGuard,
  TechniquesGuard,
  UserPreferencesGuard,
  CageTypeDefGuard,
  ConcreteTypeDefGuard,
  ConcreteDeliveryGuard,
  ProviderGuard,
  ProcessesGuard,
  SoillibJobsitesGuard,
  ProgressGuard,
  JobsiteChecksGuard,
  PlatformDisruptionsGuard,
  WeeklyReportConfigGuard,
]

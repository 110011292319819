export function getBrowerVersion(): string {
  const ua = navigator.userAgent
  let tem
  let m =
    ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) ||
    []
  if (/trident/i.test(m[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || []
    return 'IE ' + (tem[1] || '')
  }
  if (m[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/)
    if (tem != null) {
      return tem.slice(1).join(' ').replace('OPR', 'Opera')
    }
  }
  m = m[2] ? [m[1], m[2]] : [navigator.appName, navigator.appVersion, '-?']
  // eslint-disable-next-line no-cond-assign
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    m.splice(1, 1, tem[1])
  }
  return m.join(' ')
}

import { InjectionToken } from '@angular/core'
import {
  Action,
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  MetaReducer,
} from '@ngrx/store'
import * as core from 'app/core/store'
import { MapVisibilityEnum } from 'app/shared/constants/map-visibility.enum'
import { CageProdRecord } from 'app/shared/remote-services/dtos/cage.dto'
import { ColumnDto } from 'app/shared/remote-services/dtos/column.dto'
import { DailyReportDto } from 'app/shared/remote-services/dtos/dailyReport.dto'
import { createEntitiesReducer } from 'app/shared/utils/redux/entities/entities.reducer'
import { EntitiesState } from 'app/shared/utils/redux/entities/entities.state'
import { createListReducer } from 'app/shared/utils/redux/list/list.reducer'
import { ListState } from 'app/shared/utils/redux/list/list.state'
import { createLoadableReducer } from 'app/shared/utils/redux/loadable/loadable.reducer'
import { LoadableState } from 'app/shared/utils/redux/loadable/loadable.state'
import { createValueReducer } from 'app/shared/utils/redux/utils.reducers'
import { localStorageSync } from 'ngrx-store-localstorage'
import { JobsitesListActions } from '../../jobsites-list/store/jobsites-list.actions'
import { OrderPhasingSubTool } from '../components/jobsite-map-instructions-buttons/hf/order-phasing/order-phasing-toolbar.model'
import { ColumnsSelectionModel } from '../models/columns-selection.model'
import { TreeNode } from '../models/columns-type-tree.model'
import { EventModel } from '../models/event.model'
import { ToolsActionType } from '../models/action-type.enum'
import { InteractionMode } from '../models/interaction-mode.enum'
import {
  MapColumnsPopoverModel,
  MapSurveyLayerPopoverModel,
  MapSurveyPopoverModel,
} from '../models/map-popover.model'
import { MetricFilter } from '../models/metric-filter.model'
import { ParameterModel } from '../models/parameter.model'
import { ProdEvent, ProdEventSummaries } from '../models/prod-event.model'
import { StatsSummary } from '../models/stats.dto'
import { ZoneColorDto } from '../models/zone-color.dto'
import * as stats3d from './columns-stats-3d/columns-stats-3d.reducer'
import * as stats from './columns-stats/columns-stats.reducer'
import { ExportActions } from './export/export.actions'
import { JobsiteSummaryActions } from './jobsite-summary.actions'
import {
  MapSettingsState,
  reducer as mapSettingsReducer,
} from './map-settings/map-settings.reducer'
import * as mapVisibility from './map-visibility/map-visibility.reducer'
import {
  INIT_STATE as vectorRotationToolInitState,
  reducer as vectorRotationToolReducer,
  VectorRotationToolState,
} from './vector-rotation-tool/vector-rotation-tool.reducer'
import { ConcreteProdRecord } from '../../../shared/remote-services/dtos/concrete.dto'
import { DateRange } from '../../../shared/localized-date-time-picker/localized-date-range-picker.component'
import { JobsiteManualProdRecordsActions } from '../jobsite-manual-prod-records/store/jobsite-manual-prod-records.actions'
import { DailyManualProdRecordDto } from '../jobsite-manual-prod-records/models/manual-prod-records.model'
import { JobsiteProdEventActions } from './prod-event/jobsite-prod-event.actions'
import { DtoEnergyRecord } from '../jobsite-energy/models/EnergyRecord.type'
import { JobsiteEnergyActions } from '../jobsite-energy/store/jobsite-energy-actions'
import { WithError } from '../models/data-science/with-error.model'
import { SafeUrl } from '@angular/platform-browser'
import { JobsiteDataScienceActions } from './data-science/jobsite-data-science.actions'
import { ColorRangeConfigModel } from '../models/color-range-config.model'
import { ColumnColorRangeConfigActions } from './column-color-range-config/column-color-range-config.actions'
import { JobsiteTab } from '../models/jobsite-tabs.model'
import { PileTaggedAsDoneActions } from '../pile-tagged-as-done/store/pile-tagged-as-done.actions'
import { DtoPileTaggedAsDone } from '../pile-tagged-as-done/models/pile-tagged-as-done.model'
import { DataScienceFilter } from '../jobsite-data-science/models/data-science-filter.model'
import { DataScienceFilterValues } from '../jobsite-data-science/models/data-science-filter-values.model'
import { CesiumToolbarOptions } from '../components/jobsite-cesium-map/models/CesiumToolbarOptions.type'
import { CesiumMapActions } from '../components/jobsite-cesium-map/store/cesium-map.actions'
import {
  DataAnalysisYield,
  DataAnalysisYieldWithError,
} from '../jobsite-data-science-dwall/models/DataAnalysisYield.type'
import { JobsiteDataScienceDwallActions } from '../jobsite-data-science-dwall/store/jobsite-data-science-dwall.actions'
import { PileSelectionType } from '../jobsite-data-science-dwall/components/jobsite-data-science-dwall.component'
import { JobsiteRelocateAction } from '../components/jobsite-relocate/store/jobsite-relocate.action'
import { ExportType } from '../models/export.model'
import { CesiumCameraPosition } from '../components/jobsite-cesium-map/models/CesiumCameraPosition.type'

export const MODES = ['EDITING', 'FOLLOWING'] as const
export type Mode = (typeof MODES)[number]

export interface State extends core.State {
  jobsiteSummary: JobsiteSummaryState
}

export interface JobsiteSummaryState {
  mode: Mode
  selectedTab: JobsiteTab
  tabIsLoading: boolean
  highlightColumnId: string | null
  columns: EntitiesState<ColumnDto>
  events: EntitiesState<EventModel>
  mapSettings: MapSettingsState
  mapVisibility: MapVisibilityEnum
  columnsStats: stats.State
  columnsStats3d: stats3d.State
  parameter: ParameterModel
  columnColorMode: 'DEFAULT' | 'PARAMETER'
  columnColorRangeConfig: ColorRangeConfigModel
  nameFilters: ListState<string>
  metricFilters: ListState<MetricFilter>
  dateRangeFilter: DateRange
  interactionMode: InteractionMode
  selectedColumnIds: ListState<string>
  columnsSelections: EntitiesState<ColumnsSelectionModel>
  exportMode?: ExportType
  jobsiteDetails: LoadableState<StatsSummary>
  typeTree: ListState<TreeNode>
  typeTreeOpened: boolean
  mapPopoverContent: (
    | MapColumnsPopoverModel
    | MapSurveyPopoverModel
    | MapSurveyLayerPopoverModel
  )[]
  popoverPosition: 'bottom' | 'top'
  columnsProd: LoadableState<Record<string, DailyReportDto>>
  cageProdRecordsByColumn: EntitiesState<CageProdRecord>
  concreteProdRecordsByColumn: EntitiesState<ConcreteProdRecord>
  nullCoordinateColumnIds: ListState<string>
  doneColumnIds: ListState<string>
  zonesColor: EntitiesState<ZoneColorDto>
  columnIdsToFit: ListState<string>
  HfEditEnabled: boolean
  selectedTool: ToolsActionType
  prodEventSummaries: Omit<ProdEventSummaries, 'jobsiteId'>
  vectorRotationTool: VectorRotationToolState
  orderPhasingSubToolSelected: OrderPhasingSubTool
  colorizeByDayEnabled: boolean
  rigEnabledPaths: EntitiesState<{
    key: string
    value: boolean
  }>
  asBuiltWarningsColorModeEnabled: boolean
  toolNbSelectedFeatures: number
  manualProdRecords: EntitiesState<DailyManualProdRecordDto>
  resetManualProdRecordsForm: boolean
  prodEvents: LoadableState<ProdEvent[]>
  warnMessageKey: string
  displayWarnMessage: boolean
  rigSliderValue: number
  rigSliderHighValue: number
  showRangeSlider: boolean
  energyRecords: EntitiesState<DtoEnergyRecord>
  selectedEnergyRecordId: number
  dataScienceDateRange: DateRange
  dataScienceChartUrls: WithError<SafeUrl>[]
  dataScienceFilter: DataScienceFilter
  dataScienceFilterValues: LoadableState<DataScienceFilterValues>
  pilesTaggedAsDone: EntitiesState<DtoPileTaggedAsDone>
  cesiumToolbarOptions: CesiumToolbarOptions
  dataAnalysisYield: EntitiesState<DataAnalysisYield>
  selectedDataAnalysisYield: DataAnalysisYieldWithError
  dataAnalysisYieldSelectedPile: PileSelectionType
  jobsiteRelocateStep: number | null
  mapFocusCenterValue: number[] | null
  cesiumCameraPositionFromLs: CesiumCameraPosition | null
}

const initialParameter: ParameterModel = {
  metric: undefined,
  operator: undefined,
  interval: 0.4,
  dx: undefined,
  dy: undefined,
  dxOperator: undefined,
  dyOperator: undefined,
}
const reducers: ActionReducerMap<JobsiteSummaryState> = {
  mode: createValueReducer<Mode>('EDITING', JobsiteSummaryActions.setMode),
  selectedTab: createValueReducer<JobsiteTab>(
    'information',
    JobsiteSummaryActions.setSelectedTab,
  ),
  tabIsLoading: createValueReducer<boolean>(
    false,
    JobsiteSummaryActions.setIsTabLoading,
  ),
  highlightColumnId: createValueReducer(
    null,
    JobsiteSummaryActions.setHighlightColumn,
  ),
  columns: createEntitiesReducer(
    JobsiteSummaryActions.loadJobsiteColumns,
    col => col.key?.id,
  ),
  events: createEntitiesReducer(JobsiteSummaryActions.provideEvents),
  mapSettings: mapSettingsReducer,
  mapVisibility: mapVisibility.reducer,
  columnsStats: stats.reducer,
  columnsStats3d: stats3d.reducer,
  parameter: createValueReducer(
    initialParameter,
    JobsiteSummaryActions.setParameter,
  ),
  columnColorMode: createValueReducer(
    'DEFAULT',
    JobsiteSummaryActions.setColumnColorMode,
  ),
  columnColorRangeConfig: createValueReducer(
    {},
    ColumnColorRangeConfigActions.set,
  ),
  nameFilters: createListReducer(JobsiteSummaryActions.provideNameFilters),
  metricFilters: createListReducer(JobsiteSummaryActions.provideMetricFilters),
  dateRangeFilter: createValueReducer(
    new DateRange(null, null),
    JobsiteSummaryActions.updateDateRangeFilter,
  ),
  interactionMode: createValueReducer(
    InteractionMode.NAVIGATION,
    JobsiteSummaryActions.setInteractionMode,
  ),
  selectedColumnIds: createListReducer(JobsiteSummaryActions.selectedColumnIds),
  columnsSelections: createEntitiesReducer(
    JobsiteSummaryActions.columnsSelections,
  ),
  exportMode: createValueReducer(undefined, ExportActions.openDialog),
  jobsiteDetails: createLoadableReducer(
    JobsiteSummaryActions.jobsiteDetails,
    new StatsSummary(),
  ),
  typeTree: createListReducer(JobsiteSummaryActions.loadTypeTree),
  typeTreeOpened: createValueReducer(
    false,
    JobsiteSummaryActions.toggleTypeTree,
  ),
  mapPopoverContent: createValueReducer(
    [],
    JobsiteSummaryActions.setMapPopoverContent,
  ),
  popoverPosition: createValueReducer(
    'top',
    JobsiteSummaryActions.setPopoverPosition,
  ),
  columnsProd: createLoadableReducer(JobsiteSummaryActions.loadColumnsProd, {}),
  doneColumnIds: createListReducer(JobsiteSummaryActions.doneColumnIds),
  cageProdRecordsByColumn: createEntitiesReducer(
    JobsiteSummaryActions.cageProdRecordActions,
    item => item.columnId,
  ),
  concreteProdRecordsByColumn: createEntitiesReducer(
    JobsiteSummaryActions.concreteProdRecordActions,
    item => item.columnId,
  ),
  nullCoordinateColumnIds: createListReducer(
    JobsiteSummaryActions.setNullCoordinateColumnIds,
  ),
  zonesColor: createEntitiesReducer(JobsiteSummaryActions.zonesColor),
  columnIdsToFit: createListReducer(JobsiteSummaryActions.setColumnIdsToFit),
  HfEditEnabled: createValueReducer(
    false,
    JobsiteSummaryActions.updateHfEditEnabled,
  ),
  selectedTool: createValueReducer(
    null,
    JobsiteSummaryActions.changeSelectedTool,
  ),
  prodEventSummaries: createValueReducer(
    {
      summariesByTechniqueByColumn: {
        BENNE: {},
        KS: {},
      },
    },
    JobsiteSummaryActions.setProdEventSummaries,
  ),
  vectorRotationTool: vectorRotationToolReducer,
  orderPhasingSubToolSelected: createValueReducer(
    'SET_MERLON',
    JobsiteSummaryActions.selectOrderPhasingSubTool,
  ),
  colorizeByDayEnabled: createValueReducer(
    false,
    JobsiteSummaryActions.setColorizeByDay,
  ),
  rigEnabledPaths: createEntitiesReducer(
    JobsiteSummaryActions.rigEnabledPathEntitiesActions,
    item => item.key,
  ),
  asBuiltWarningsColorModeEnabled: createValueReducer(
    false,
    JobsiteSummaryActions.updateAsbuiltWarningColorMode,
  ),
  toolNbSelectedFeatures: createValueReducer(
    0,
    JobsiteSummaryActions.setToolNbSelectedFeatures,
  ),
  manualProdRecords: createEntitiesReducer(
    JobsiteManualProdRecordsActions.loadManualProdRecords,
  ),
  resetManualProdRecordsForm: createValueReducer(
    false,
    JobsiteManualProdRecordsActions.setResetManualProdRecordsForm,
  ),
  prodEvents: createLoadableReducer<ProdEvent[]>(
    JobsiteProdEventActions.getProdEvents,
    [],
  ),
  warnMessageKey: createValueReducer(
    null,
    JobsiteSummaryActions.setWarnMessageKey,
  ),
  displayWarnMessage: createValueReducer(
    false,
    JobsiteSummaryActions.setDisplayWarnMessage,
  ),
  rigSliderValue: createValueReducer<number>(
    0,
    JobsiteSummaryActions.setRigSliderValue,
  ),
  rigSliderHighValue: createValueReducer<number>(
    null,
    JobsiteSummaryActions.setRigSliderHighValue,
  ),
  showRangeSlider: createValueReducer<boolean>(
    false,
    JobsiteSummaryActions.setShowRangeSlider,
  ),
  energyRecords: createEntitiesReducer(
    JobsiteEnergyActions.loadEnergyRecords,
    item => item.id.toString(),
  ),
  selectedEnergyRecordId: createValueReducer<number>(
    null,
    JobsiteEnergyActions.setSelectedEnergyRecordId,
  ),
  dataScienceDateRange: createValueReducer<DateRange>(
    new DateRange(null, null),
    JobsiteDataScienceActions.setDateRange,
  ),
  dataScienceChartUrls: createValueReducer<WithError<SafeUrl>[]>(
    [],
    JobsiteDataScienceActions.setDataScienceChartsUrls,
  ),
  dataScienceFilter: createValueReducer<DataScienceFilter>(
    new DataScienceFilter(null, null, null, null, null, null),
    JobsiteDataScienceActions.setDataScienceFilter,
  ),
  dataScienceFilterValues: createLoadableReducer<DataScienceFilterValues>(
    JobsiteDataScienceActions.loadDataScienceFilterValues,
    {
      machines: [],
      diameters: [],
      dates: [],
    },
  ),
  pilesTaggedAsDone: createEntitiesReducer(
    PileTaggedAsDoneActions.loadPilesTaggedAsDone,
    item => item.id,
  ),
  cesiumToolbarOptions: createValueReducer<CesiumToolbarOptions>(
    new CesiumToolbarOptions(1, true, true, true, true),
    CesiumMapActions.setCesiumToolbarOptions,
  ),
  dataAnalysisYield: createEntitiesReducer(
    JobsiteDataScienceDwallActions.load,
    item => item.id.toString(),
  ),
  selectedDataAnalysisYield: createValueReducer<DataAnalysisYieldWithError>(
    null,
    JobsiteDataScienceDwallActions.setSelectedDataAnalysisYield,
  ),
  dataAnalysisYieldSelectedPile: createValueReducer<PileSelectionType>(
    null,
    JobsiteDataScienceDwallActions.setSelectedPile,
  ),
  jobsiteRelocateStep: createValueReducer<number | null>(
    null,
    JobsiteRelocateAction.setJobsiteRelocateStep,
  ),
  mapFocusCenterValue: createValueReducer<number[] | null>(
    null,
    JobsiteSummaryActions.updateMapFocusCenterValue,
  ),
  cesiumCameraPositionFromLs: createValueReducer(
    null,
    CesiumMapActions.setCameraPositionFromLs,
  ),
}

export const getState =
  createFeatureSelector<JobsiteSummaryState>('jobsiteSummary')

export const reducerInjecteur = new InjectionToken(
  'Jobsite Summary Registered Reducers',
)

function getReducers(): ActionReducerMap<JobsiteSummaryState> {
  return reducers
}

export const reducerProvider = [
  { provide: reducerInjecteur, useFactory: getReducers },
]

export function clearState(
  reducer: ActionReducer<JobsiteSummaryState>,
): ActionReducer<JobsiteSummaryState> {
  return (state: JobsiteSummaryState, action: Action): JobsiteSummaryState => {
    if (action.type === JobsitesListActions.enterJobsitesList.CREATE) {
      state = {
        ...state,
        nameFilters: {
          loaded: false,
          loading: false,
          value: [],
        },
        metricFilters: {
          loaded: false,
          loading: false,
          value: [],
        },
        dateRangeFilter: new DateRange(null, null),
        parameter: initialParameter,
        columnColorMode: 'DEFAULT',
        events: {
          loaded: false,
          loading: false,
          value: {},
        },
        mode: 'EDITING',
        selectedTab: 'information',
        highlightColumnId: null,
        interactionMode: InteractionMode.NAVIGATION,
        typeTreeOpened: false,
        doneColumnIds: {
          loaded: false,
          loading: false,
          value: [],
        },
        jobsiteDetails: {
          loaded: false,
          loading: false,
          value: new StatsSummary(),
        },
        zonesColor: {
          loaded: false,
          loading: false,
          value: {},
        },
        columns: {
          loaded: false,
          loading: false,
          value: {},
        },
        HfEditEnabled: false,
        vectorRotationTool: vectorRotationToolInitState,
        toolNbSelectedFeatures: 0,
        manualProdRecords: {
          loaded: false,
          loading: false,
          value: {},
        },
      }
    }
    return reducer(state, action)
  }
}

// Save the mapSettings in the user's localStorage
export function localStorageSyncReducer(
  reducer: ActionReducer<JobsiteSummaryState>,
): ActionReducer<JobsiteSummaryState> {
  return localStorageSync({ keys: ['mapSettings'], rehydrate: true })(reducer)
}

export const metaReducers: Array<MetaReducer<any, any>> = [
  clearState,
  localStorageSyncReducer,
]

export type ColorMode = JobsiteSummaryState['columnColorMode']
export type PopoverPosition = JobsiteSummaryState['popoverPosition']

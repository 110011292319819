import { createSelector } from '@ngrx/store'
import { ListSelectorFactory } from 'app/shared/utils/redux/list/list.selectors'
import { getState, JobsitesListState } from './state'
import { LoadableSelectorFactory } from '../../../shared/utils/redux/loadable/loadable.selectors'

export const getMapSettings = createSelector(
  getState,
  (state: JobsitesListState) => state.mapSettings,
)

export const getSearch = createSelector(
  getState,
  (state: JobsitesListState) => state.search,
)

export const getStatus = createSelector(
  getState,
  (state: JobsitesListState) => state.status,
)

export const getOrderDirection = createSelector(
  getState,
  (state: JobsitesListState) => state.orderDirection,
)

export const getOrderValue = createSelector(
  getState,
  (state: JobsitesListState) => state.orderValue,
)

export const getSelectedCompany = createSelector(
  getState,
  (state: JobsitesListState) => state?.selectedCompany,
)

export const getCurrentPage = createSelector(
  getState,
  (state: JobsitesListState) => state.currentPage,
)

export const getPageSize = createSelector(
  getState,
  (state: JobsitesListState) => state.pageSize,
)

export const getTotalItems = createSelector(
  getState,
  (state: JobsitesListState) => state.totalItems,
)

export const getOnlyStarred = createSelector(
  getState,
  // Warning! This data is initialized with the local storage as a string
  (state: JobsitesListState) =>
    state.onlyStarred !== undefined
      ? Boolean(JSON.parse(String(state.onlyStarred)))
      : false,
)

export const getSelectedJobsite = (jobsiteId: string) =>
  createSelector(getState, (state: JobsitesListState) =>
    state.displayedJobsites.value.find(j => j.id === jobsiteId),
  )

export const displayedJobsiteSelector = new ListSelectorFactory(
  getState,
  (state: JobsitesListState) => state.displayedJobsites,
)

export const getNbAssignedMachines = createSelector(
  getState,
  state => state.nbAssignedMachines,
)

export const getJobsiteFiltering = createSelector(
  getOnlyStarred,
  getCurrentPage,
  getPageSize,
  getOrderDirection,
  getOrderValue,
  getStatus,
  (onlyStarred, currentPage, pageSize, orderDirection, orderValue, status) => ({
    onlyStarred,
    currentPage,
    pageSize,
    orderDirection,
    orderValue,
    status,
  }),
)

export const getKpiToDisplayByJobsiteId = (jobsiteId: string) =>
  createSelector(getState, (state: JobsitesListState) => {
    const filteredValue = state.displayedFavoriteKpi
      ? state.displayedFavoriteKpi.value.filter(
          value => value.jobsiteId === jobsiteId,
        )
      : []

    return Array.from(new Set(filteredValue.map(val => val.id))).map(id =>
      filteredValue.find(a => a.id === id),
    )
  })

export const getDisplayMode = createSelector(
  getState,
  (state: JobsitesListState) => state.displayMode,
)

export const getMapAnimationDone = createSelector(
  getState,
  (state: JobsitesListState) => state.mapAnimationDone,
)

export const getListAnimationDone = createSelector(
  getState,
  (state: JobsitesListState) => state.listAnimationDone,
)

export const getRefreshMapSize = createSelector(
  getState,
  (state: JobsitesListState) => state.refreshMapSize,
)

export const getMapPinpoints = new ListSelectorFactory(
  getState,
  (state: JobsitesListState) => state.mapPinpoints,
)

export const getJobsiteStats = createSelector(
  getState,
  state => state.jobsiteStats,
)

export const weeklyReportConfigSelector = new LoadableSelectorFactory(
  getState,
  (state: JobsitesListState) => state.weeklyReportConfig,
)

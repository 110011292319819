import { ListActionFactory } from '../../../../shared/utils/redux/list/list.actions'
import {
  DataAnalysisYield,
  DataAnalysisYieldWithError,
} from '../models/DataAnalysisYield.type'
import { SyncActionFactory } from '../../../../shared/utils/redux/sync.actions'
import { PileSelectionType } from '../components/jobsite-data-science-dwall.component'
import { AsyncActionFactory } from '../../../../shared/utils/redux/async.actions'

export class JobsiteDataScienceDwallActions {
  public static load = new ListActionFactory<DataAnalysisYield, void>(
    '[JOBSITE DATA SCIENCE DWALL] LOAD',
  )

  public static loadSelectedDataAnalysisYield =
    new AsyncActionFactory<DataAnalysisYield>(
      '[JOBSITE DATA SCIENCE DWALL] LOAD SELECTED DATA ANALYSIS YIELD',
    )

  public static setSelectedDataAnalysisYield =
    new SyncActionFactory<DataAnalysisYieldWithError>(
      '[JOBSITE DATA SCIENCE DWALL] SET SELECTED DATA ANALYSIS YIELD',
    )

  public static setSelectedPile = new SyncActionFactory<PileSelectionType>(
    '[JOBSITE DATA SCIENCE DWALL] SET SELECTED PILE',
  )
}

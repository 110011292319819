import { Action } from '@ngrx/store'
import { EntitiesState } from 'app/shared/utils/redux/entities/entities.state'
import { produce } from 'immer'
import { Stats3dModel } from '../../models/stats.model'
import { ColumnsStats3dActions } from './columns-stats-3d.actions'

export interface State extends EntitiesState<Stats3dModel> {
  loadedMetrics: { [jobsiteId: string]: { [metric: string]: string[] } }
}

export const initialState: State = {
  loadedMetrics: {},
  loaded: false,
  loading: false,
  value: {},
}

export function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case ColumnsStats3dActions.load3dStats.CREATE: {
      // immer is used for deep updates, see https://immerjs.github.io/
      return produce(state, draft => {
        const { jobsiteId, parameter, dtos } = (
          action as ReturnType<
            (typeof ColumnsStats3dActions.load3dStats)['create']
          >
        ).payload

        if (!draft.loadedMetrics[jobsiteId]) {
          draft.loadedMetrics[jobsiteId] = {}
        }

        const newEntities = dtos.map(dto => {
          const model: Stats3dModel = draft.value[dto.id]
            ? { ...draft.value[dto.id] }
            : { ...dto, stats: {} }

          if (!model.stats[jobsiteId]) {
            model.stats[jobsiteId] = {}
          }

          if (!draft.loadedMetrics[jobsiteId][parameter.metric]) {
            model.stats[jobsiteId][parameter.metric] = {}
          }
          model.stats[jobsiteId][parameter.metric][parameter.operator] =
            dto.stats[parameter.metric]
          return model
        })

        if (!draft.loadedMetrics[jobsiteId][parameter.metric]) {
          draft.loadedMetrics[jobsiteId][parameter.metric] = []
        }
        if (
          !draft.loadedMetrics[jobsiteId][parameter.metric].includes(
            parameter.operator,
          )
        ) {
          draft.loadedMetrics[jobsiteId][parameter.metric].push(
            parameter.operator,
          )
        }

        for (const entity of newEntities) {
          draft.value[entity.id] = entity
        }
      })
    }
  }

  return state
}

import { TypedAction } from './loadable/loadable.actions'
import { Action } from '@ngrx/store'

export class SyncActionFactory<Payload = void> {
  get CREATE(): string {
    return this.name
  }

  get RESET_VALUE(): string {
    return this.name + '.RESET_VALUE'
  }

  public create(payload: Payload): TypedAction<Payload> {
    return {
      type: this.CREATE,
      payload,
    }
  }

  public createResetValue(): Action {
    return { type: this.RESET_VALUE }
  }

  constructor(private readonly name: string) {}
}

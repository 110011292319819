<div class="planned-edit-table-container flex-layout-column">
  <div class="ol-popup-closer" (click)="closeEvent.emit()"></div>
  <div class="flex-layout-column flex-1 flex-fill">
    <mat-radio-group
      *ngIf="categories"
      style="padding-top: 25px"
      color="primary"
      class="flex flex-wrap flex-50px flex-align-space-around"
      [ngModel]="progressCategory"
      (change)="updateProgressCategoryEvent.emit($event.value)"
    >
      <mat-radio-button
        *ngFor="let category of categories"
        [value]="category"
        >{{ getCategoryHeaderTranslation(category) }}</mat-radio-button
      >
    </mat-radio-group>
    <mat-tab-group
      class="planned-progress-max-height flex-1"
      mat-align-tabs="center"
    >
      <mat-tab
        label="{{
          'JOBSITE_MANAGEMENT.FOLLOW_UP.TAB_PROGRESS_' + type | translate
        }}"
        *ngFor="let type of progressType"
      >
        <planned-progress-edit
          [plannedProgress]="plannedProgress"
          [type]="type"
          [userPreferences]="userPreferences"
          [depthUnit]="depthUnits"
          [category]="progressCategory"
          [technique]="technique"
          [unitSystem]="userPreferences.unitSystem"
          [csvSeparator]="csvSeparator"
          (deletePlannedProgress)="deletePlannedProgress.emit($event)"
          (addPlannedProgress)="addPlannedProgress.emit($event)"
          (editPlannedProgress)="editPlannedProgress.emit($event)"
          (plannedProgressBatch)="
            batchPlannedProgress($event, type, progressCategory)
          "
        ></planned-progress-edit>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

export function typedKeys<T>(o: T): Array<keyof T> {
  return Object.keys(o) as any
}

export function typedEntries<T>(o: T): Array<[keyof T, T[keyof T]]> {
  return Object.entries(o) as any
}

export function typedFromEntries<K extends string | number | symbol, V>(
  entries: [K, V][],
): Record<K, V> {
  return Object.fromEntries(entries) as any
}

import * as Guards from './metric.enum.type.guard'

/** @see {isMetric} ts-auto-guard:type-guard */
export enum Metric {
  // Instruction
  UniqueName = 'un',
  xCoordinate = 'x',
  yCoordinate = 'y',
  TargetDepth = 'td',
  ThrustPressureCriterian = 'thpc',
  ThrustCriterion = 'thc',
  AnchorageLayer = 'al',
  TorquePressureCriterion = 'topc',
  TorqueCriterion = 'toc',
  EnergyCriterion = 'ec',
  Diameter = 'd',
  ColumnType = 'ct',
  Alert = 'a',
  TopLevel = 'tl',
  PlatformLevel = 'pl',
  Latitude = 'lat',
  Longitude = 'lgt',
  Zone = 'zone',
  Width = 'width',
  Length = 'length',

  // Production
  Depth = 'p_d',
  Flowrate = 'p_o',
  Pressure = 'p_p',
  HydraulicPressure = 'p_hp',
  DeviationX = 'p_ddx',
  DeviationY = 'p_ddy',
  Twist = 'p_t',

  // Headers
  HeaderContract = 'h_c',
  HeaderDiameter = 'h_d',
  HeaderJobsite = 'h_j',
  HeaderLength = 'h_l',
  HeaderLutzHash = 'h_lh',
  HeaderName = 'h_n',
  HeaderNumber = 'h_nb',
  HeaderOverconsumption = 'h_o',
  HeaderSection = 'h_s',
  HeaderStartDate = 'h_sd',
  HeaderStartLiftDate = 'h_sld',
  HeaderStopDrillDate = 'h_sdd',
  HeaderTotalVolume = 'h_v',
}

export { Guards }

import { Injectable } from '@angular/core'
import { MatomoRouteInterceptorBase } from '@ngx-matomo/router'
import { ActivatedRouteSnapshot, Router } from '@angular/router'
import { AnalyticsService } from '../../shared/service/analytics.service'

@Injectable()
export class TrackDataInterceptor extends MatomoRouteInterceptorBase<string> {
  constructor(
    public router: Router,
    private analyticsService: AnalyticsService,
  ) {
    super(router)
  }

  protected extractRouteData(route: ActivatedRouteSnapshot): string {
    return route.data?.analytics?.title ?? this.router.url
  }

  protected async processRouteData(pageTitle: string): Promise<void> {
    if (pageTitle) {
      await this.analyticsService.sendVisitAnalytics(pageTitle)
    }
  }
}

import { Injectable, OnDestroy } from '@angular/core'
import { select, Store } from '@ngrx/store'
import moment, { MomentInput } from 'moment'
import { filter } from 'rxjs/operators'
import { State } from '../../../core/store'
import { getDateTimeFormats } from '../../../core/store/user'
import { DateTimeFormatsDto } from '../../remote-services/dtos/userPreferences.dto'
import { DateTimeFormatService } from '../../service/date-time-format.service'
import { Subscription } from 'rxjs'

@Injectable()
export class LocalizedDateService implements OnDestroy {
  defaultFormats: DateTimeFormatsDto

  private subscriptions: Subscription[] = []

  constructor(
    private store: Store<State>,
    private formatService: DateTimeFormatService,
  ) {
    this.subscriptions.push(
      this.store
        .pipe(
          select(getDateTimeFormats),
          filter(d => !!d),
        )
        .subscribe(
          dateTimeFormats =>
            (this.defaultFormats =
              this.formatService.parseBackendFormats(dateTimeFormats)),
        ),
    )
  }

  public getFormattedValue(value?: MomentInput, pattern?: string): string {
    if (!value) {
      return ''
    }

    if (!pattern || ['l', 'L'].includes(pattern)) {
      return moment(value).format(this.defaultFormats.dateFormat)
    }

    if (pattern === 'short') {
      return moment(value).format(this.defaultFormats.dateTimeFormat)
    }

    return moment(value).format(pattern)
  }

  public getFormattedDate(value?: MomentInput): string {
    return this.getFormattedValue(value, this.defaultFormats.dateFormat)
  }

  public getFormattedTime(value?: MomentInput): string {
    return this.getFormattedValue(value, this.defaultFormats.timeFormat)
  }

  public getFormattedDateTime(value?: MomentInput): string {
    return this.getFormattedValue(value, this.defaultFormats.dateTimeFormat)
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }
}

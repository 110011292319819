import { EntitiesSelectorFactory } from '../../../shared/utils/redux/entities/entities.selectors'
import * as core from '../state'
import { createSelector } from '@ngrx/store'
import { getTechnique } from '../router/router.selectors'
import { ProcessDto } from '../../../shared/remote-services/dtos/process.dto'
import { TechniqueNames } from '../../../shared/remote-services/dtos/technique.dto'
import { ColumnDto } from '../../../shared/remote-services/dtos/column.dto'
import { columnSelectors } from '../../../jobsites-management/column-visualization/store/column-visualization.selectors'
import { getJobsite } from '../jobsite'
import { JobsiteDto } from '../../../shared/remote-services/dtos/jobsite.dto'

export const processesSelector = new EntitiesSelectorFactory(
  core.getState,
  state => state.processes,
)

export const getProcessesByTechnique = createSelector(
  processesSelector.getAll,
  getTechnique,
  (entities: ProcessDto[], technique: TechniqueNames) => {
    return entities.filter(
      (process: ProcessDto) => process.technique === technique,
    )
  },
)

export const getProcessesOfJobsite = createSelector(
  getJobsite,
  (jobsite: JobsiteDto) => {
    return jobsite?.processes ?? []
  },
)

export const getProcessesByPile = createSelector(
  columnSelectors.getValue,
  (column: ColumnDto) => {
    return column.key.process
  },
)

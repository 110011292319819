import { SyncActionFactory } from 'app/shared/utils/redux/sync.actions'
import {
  ExportDailyReportPayload,
  ExportDailyReportRKPayload,
  ExportType,
  LaunchMultipleExportPayload,
} from '../../models/export.model'

export class ExportActions {
  public static openDialog = new SyncActionFactory<ExportType>(
    '[JOBSITE EXPORT] OPEN DIALOG',
  )

  public static launchMultipleExport =
    new SyncActionFactory<LaunchMultipleExportPayload>(
      '[JOBSITE EXPORT] LAUNCH MULTIPLE EXPORT',
    )

  public static exportDailyReport =
    new SyncActionFactory<ExportDailyReportPayload>(
      '[JOBSITE SUMMARY] EXPORT DAILY REPORT',
    )

  public static exportCastaurCsv = new SyncActionFactory<void>(
    '[JOBSITE EXPORT] EXPORT CASTAURCSV',
  )

  public static exportDailyReportRK =
    new SyncActionFactory<ExportDailyReportRKPayload>(
      '[JOBSITE EXPORT] EXPORT DAILY REPORT RK',
    )

  public static exportTeklaCsv = new SyncActionFactory<void>(
    '[JOBSITE EXPORT] EXPORT TEKLA CSV',
  )
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core'
import { DateAdapter } from '@angular/material/core'
import { select, Store } from '@ngrx/store'
import { Moment } from 'moment'
import { Subscription } from 'rxjs'
import { State } from '../../core/store'
import { getLanguage } from '../../core/store/user'
import { LocalizedDateService } from '../utils/services/localized-date.service'

@Component({
  selector: 'localized-date-picker',
  template: `
    <div class="flex flex-align-center--center flex-1">
      <mat-form-field class="flex flex-1">
        <mat-label>{{ label }}</mat-label>
        <input
          matInput
          autocomplete="off"
          [matDatepicker]="picker"
          [disabled]="disabled"
          [value]="value"
          [min]="min"
          [max]="max"
          (dateChange)="dateChange.emit($event.value)"
          (click)="picker.open()"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <button
        [disabled]="disabled || this.value == null"
        mat-icon-button
        (click)="clearClick.emit()"
      >
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocalizedDatePickerComponent implements OnInit, OnDestroy {
  @Input() disabled = false
  @Input() value: Moment
  @Input() label = ''
  @Input() min: Moment
  @Input() max: Moment

  @Output() dateChange = new EventEmitter<Moment>()
  @Output() clearClick = new EventEmitter()

  private subscription: Subscription

  constructor(
    private store: Store<State>,
    private adapter: DateAdapter<Moment>,
    private localizedDateService: LocalizedDateService,
  ) {}

  ngOnInit(): void {
    this.adapter.format = date =>
      this.localizedDateService.getFormattedDate(date)

    this.subscription = this.store
      .pipe(select(getLanguage))
      .subscribe(lang => this.adapter.setLocale(lang))
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }
}

import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import {
  AddConcreteDelivery,
  AddConcreteProdRecord,
  AddConcreteTypeDefinition,
  ConcreteDelivery,
  ConcreteProdRecord,
  ConcreteProdRecordInput,
  ConcreteTypeDefinition,
} from './dtos/concrete.dto'
import { Observable } from 'rxjs'

@Injectable()
export class ConcreteService {
  constructor(private http: HttpClient) {}

  private baseUrl = 'rest/concrete'

  public getAllTypeDefByJobsite(
    jobsiteId: string,
  ): Observable<ConcreteTypeDefinition[]> {
    return this.http.get<ConcreteTypeDefinition[]>(
      `${this.baseUrl}/typedef/all/${jobsiteId}`,
    )
  }

  public getAllProdByJobsite(
    jobsiteId: string,
  ): Observable<ConcreteProdRecord[]> {
    return this.http.get<ConcreteProdRecord[]>(
      `${this.baseUrl}/prod/all/${jobsiteId}`,
    )
  }

  public getProdByColumn(columnId: string): Observable<ConcreteProdRecord> {
    return this.http.get<ConcreteProdRecord>(`${this.baseUrl}/prod/${columnId}`)
  }

  public addTypeDef(
    definition: AddConcreteTypeDefinition,
  ): Observable<ConcreteTypeDefinition> {
    return this.http.post<ConcreteTypeDefinition>(
      `${this.baseUrl}/typedef/add`,
      definition,
    )
  }

  public updateTypeDef(
    record: ConcreteTypeDefinition,
  ): Observable<ConcreteTypeDefinition> {
    return this.http.put<ConcreteTypeDefinition>(
      `${this.baseUrl}/typedef`,
      record,
    )
  }

  public deleteOneTypeDef(typedDefId: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/typedef/${typedDefId}`)
  }

  public addProdRecord(
    record: AddConcreteProdRecord,
  ): Observable<ConcreteProdRecord> {
    return this.http.post<ConcreteProdRecord>(
      `${this.baseUrl}/prod/add`,
      record,
    )
  }

  public deleteOneProdRecord(recordId: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/prod/${recordId}`)
  }

  public updateProdRecord(
    record: ConcreteProdRecord,
  ): Observable<ConcreteProdRecord> {
    return this.http.put<ConcreteProdRecordInput>(
      `${this.baseUrl}/prod`,
      record,
    )
  }

  // concrete delivery management

  public addConcreteDelivery(
    delivery: AddConcreteDelivery,
  ): Observable<ConcreteDelivery> {
    return this.http.post<ConcreteDelivery>(
      `${this.baseUrl}/delivery/add`,
      delivery,
    )
  }

  public updateConcreteDelivery(
    delivery: ConcreteDelivery,
  ): Observable<ConcreteDelivery> {
    return this.http.put<ConcreteDelivery>(`${this.baseUrl}/delivery`, delivery)
  }

  public deleteConcreteDelivery(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/delivery/${id}`)
  }

  public getConcreteDeliveriesByJobsite(
    jobsiteId: string,
  ): Observable<ConcreteDelivery[]> {
    return this.http.get<ConcreteDelivery[]>(
      `${this.baseUrl}/delivery/all-by-jobsite/${jobsiteId}`,
    )
  }

  public updateMultipleProdRecord(
    deliveryId: string,
    pileIds: string[],
  ): Observable<DtoMultipleConcretePileAssignmentResponse> {
    return this.http.post<DtoMultipleConcretePileAssignmentResponse>(
      `${this.baseUrl}/prod/${deliveryId}`,
      pileIds,
    )
  }
}

export interface DtoMultipleConcretePileAssignmentResponse {
  concreteDeliveries: ConcreteDelivery[]
  concreteProdRecords: ConcreteProdRecord[]
}

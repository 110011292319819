import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { map } from 'rxjs/operators'
import { AbstractLoadableGuard } from '../../shared/utils/abstract-loadable.guard'
import { State } from '../store'
import { getLoaded, UserActions } from '../store/user'

@Injectable()
export class MobilePermissionsGuard extends AbstractLoadableGuard<State> {
  constructor(store: Store<State>) {
    super(store, getLoaded, UserActions.loadUser, false, {
      postCheck: () =>
        this.store.pipe(
          map(() => {
            return true
          }),
        ),
    })
  }
}

/*
 * Generated type guards for "hf-type.enum.ts".
 * WARNING: Do not manually change this file.
 */
import { HFType } from './hf-type.enum'

export function isHfType(obj: unknown): obj is HFType {
  const typedObj = obj as HFType
  return (
    typedObj === HFType.PILE ||
    typedObj === HFType.MERLON ||
    typedObj === HFType.PANEL
  )
}

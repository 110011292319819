<div class="message-container">
  <div class="message-row">
    <mat-icon class="message-icon" color="primary">warning</mat-icon>
    <p class="message-content">{{ message }}</p>
  </div>
  <div class="close-btn-wrapper">
    <button
      mat-raised-button
      class="close-btn"
      color="primary"
      type="button"
      (click)="closeMessage()"
    >
      {{ 'GENERAL.CLOSE' | translate }}
    </button>
  </div>
</div>

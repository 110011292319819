import { LoadableActionFactory } from '../../../shared/utils/redux/loadable/loadable.actions'
import {
  DtoActiveDisruption,
  DtoPlatformDisruption,
  PlatformDisruptionMessage,
} from '../../models/PlatformDisruption.model'
import { AsyncActionFactory } from '../../../shared/utils/redux/async.actions'

export class PlatformDisruptionsActions {
  public static loadPlatformDisruptionMessage = new LoadableActionFactory<
    void,
    DtoActiveDisruption
  >('[CORE] LOAD PLATFORM DISRUPTION MESSAGE')

  public static loadAllDisruptions = new LoadableActionFactory<
    void,
    DtoPlatformDisruption[]
  >('[CORE] LOAD ALL DISRUPTIONS')

  public static createNewDisruption = new AsyncActionFactory<
    PlatformDisruptionMessage,
    void
  >('[CORE] CREATE NEW DISRUPTION')

  public static closeDisruption = new AsyncActionFactory<string, void>(
    '[CORE] CLOSE DISRUPTION',
  )
}

import { Action } from '@ngrx/store'
import { AsyncActionFactory } from '../async.actions'
import { TypedAction } from '../loadable/loadable.actions'

export class LoadableMapActionsFactory<Item> extends AsyncActionFactory<
  string[],
  void
> {
  get RESET(): string {
    return this.name + '.RESET'
  }
  get SET_ITEM(): string {
    return this.name + '.SET_ITEM'
  }

  public createReset(): Action {
    return { type: this.RESET }
  }

  public setItem(entry: {
    key: string
    value: Item
  }): TypedAction<{ key: string; value: Item }> {
    return { type: this.SET_ITEM, payload: entry }
  }

  constructor(protected name: string) {
    super(name)
  }
}

import { TechniqueNames } from 'app/shared/remote-services/dtos/technique.dto'
import { AsyncActionFactory } from 'app/shared/utils/redux/async.actions'
import { SyncActionFactory } from 'app/shared/utils/redux/sync.actions'
import { LoadableActionFactory } from '../../../shared/utils/redux/loadable/loadable.actions'
import { ProgressCategory } from '../../../jobsites-management/jobsite-summary/models/progress/progress-category.enum'
import {
  ProgressionBatchImportDto,
  ProgressDto,
} from '../../../jobsites-management/jobsite-summary/models/progress/progress.model'
import { State } from '../state'

export class JobsiteProgressActions {
  public static getProgressData = new LoadableActionFactory<
    void,
    ProgressDto[]
  >('[JOBSITE PROGRESS] GET PROGRESS DATA')

  public static updateProgressCategory = new AsyncActionFactory<
    ProgressCategory,
    {
      techniqueName: TechniqueNames
      jobsiteId: string
      progressCategory: ProgressCategory
    }
  >('[JOBSITE PROGRESS] UPDATE PROGRESS CATEGORY')

  public static addPlannedProgress = new AsyncActionFactory<ProgressDto, void>(
    '[JOBSITE PROGRESS] ADD PLANNED PROGRESS',
  )

  public static editPlannedProgress = new AsyncActionFactory<ProgressDto, void>(
    '[JOBSITE PROGRESS] EDIT PLANNED PROGRESS',
  )

  public static deletePlannedProgress = new AsyncActionFactory<string, void>(
    '[JOBSITE PROGRESS] DELETE PLANNED PROGRESS',
  )

  public static plannedProgressBatch = new AsyncActionFactory<
    ProgressionBatchImportDto,
    void
  >('[JOBSITE PROGRESS] BATCH PLANNED PROGRESS')

  public static updateProgressPeriod = new SyncActionFactory<
    State['progressPeriod']
  >('[JOBSITE PROGRESS] UPDATE PROGRESS PERIOD')
}

import * as Guards from './jobsite-checks.model.type.guard'
import { FormControl, FormGroup } from '@angular/forms'

/** @see {isJobsiteChecksDto} ts-auto-guard:type-guard */
export interface JobsiteChecksDto {
  checkEnabled: boolean
}

/** @see {isJobsiteChecksWithValueDto} ts-auto-guard:type-guard */
export interface JobsiteChecksWithValueDto extends JobsiteChecksDto {
  checkValue: number
}

export interface JobsiteChecksWithValueControls {
  checkValue: FormControl<number>
  checkEnabled: FormControl<boolean>
}

/** @see {isJobsiteChecksSettingsDto} ts-auto-guard:type-guard */
export interface JobsiteChecksSettingsDto {
  id?: string
  depthCheck: JobsiteChecksWithValueDto
  overBreakCheck: JobsiteChecksWithValueDto
  depthComparisonCheck: JobsiteChecksWithValueDto
  diameterCheck: JobsiteChecksDto
}

export interface JobsiteChecksSettingsControls {
  depthCheck: FormGroup<JobsiteChecksWithValueControls>
  overBreakCheck: FormGroup<JobsiteChecksWithValueControls>
  depthComparisonCheck: FormGroup<JobsiteChecksWithValueControls>
  diameterCheckEnable: FormControl<boolean>
}

export const DEFAULT_WARNING_CHECKS_DATA: JobsiteChecksSettingsDto = {
  depthCheck: {
    checkEnabled: true,
    checkValue: 0,
  },
  overBreakCheck: {
    checkEnabled: true,
    checkValue: 5,
  },
  depthComparisonCheck: {
    checkEnabled: true,
    checkValue: 5,
  },
  diameterCheck: {
    checkEnabled: true,
  },
}

export { Guards }

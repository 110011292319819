import * as Guards from './stats.dto.type.guard'
import { ParameterModel } from './parameter.model'

/** @see {isStatsDto} ts-auto-guard:type-guard */
export interface StatsDto {
  id: string
  stats: { [metric: string]: number }
}

/** @see {isParaValuesVersusDepth} ts-auto-guard:type-guard */
export interface ParaValuesVersusDepth {
  start: number
  end: number
  value: number
}

/** @see {isStats3DDto} ts-auto-guard:type-guard */
export interface Stats3DDto {
  id: string
  stats: { [metric: string]: ParaValuesVersusDepth[] }
}

/** @see {isStatsType} ts-auto-guard:type-guard */
export type StatsType =
  | 'MAX'
  | 'MIN'
  | 'SUM'
  | 'COUNT'
  | 'AVG'
  | 'SUM_MAX'
  | 'SUM_AVG'
  | 'AVG_AVG'

export interface ValueUnit<C> {
  value: C | null
  unit: string | null
}

/** @see {isStatsOverallRequest} ts-auto-guard:type-guard */
export interface StatsOverallRequest {
  fieldId: string
  operators: StatsType[]
}

/** @see {isStatsOverallSummary} ts-auto-guard:type-guard */
export interface StatsOverallSummary {
  fieldId: string
  values: Map<StatsType, ValueUnit<number>>
}

export class StatsSummary {
  done: number | null
  patiallyDone: number | null
  records: number | null
  start: string | null
  totalDepth: number | null
  totalVolume: number | null
  totalAreaSimple: number | null

  constructor() {
    this.done = null
    this.patiallyDone = null
    this.records = null
    this.start = null
    this.totalDepth = null
    this.totalVolume = null
    this.totalAreaSimple = null
  }
}

/** @see {isLoadColumnsStatsPayload} ts-auto-guard:type-guard */
export interface LoadColumnsStatsPayload {
  parameter: ParameterModel
  jobsiteId: string
  dtos: StatsDto[]
}

export { Guards }

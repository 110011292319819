import { createSelector } from '@ngrx/store'
import { EntitiesSelectorFactory } from '../../../shared/utils/redux/entities/entities.selectors'
import * as core from '../state'
import { ProviderDto } from '../../../shared/remote-services/dtos/provider.dto'

export const providerSelector = new EntitiesSelectorFactory<
  ProviderDto,
  core.State
>(core.getState, state => state.providers)

export const getProviders = createSelector(
  providerSelector.getAll,
  providers => {
    return providers
  },
)

export const getConcreteProviders = createSelector(
  providerSelector.getAll,
  providers => {
    return providers.filter(p => p.concrete)
  },
)

import {
  CsvPreferencesDto,
  DEFAULT_CSV_PREFERENCES,
} from '../models/companyCsvPreferencesInput.dto'
import { CsvDecimalSeparator } from '../../jobsites-management/settings/models/csv-separator.enum'
import { exhaustiveCheck } from '../../shared/utils/exhautisveCheck'
import { Primitive } from 'utility-types'
import { SharedTableBase } from '../../shared/models/shared-data-table.model'
import { Injectable } from '@angular/core'
import { TableFormatterService } from './tableformatter.service'

@Injectable()
export class CsvValuesFormatterService {
  private readonly numberFormatOptions: Intl.NumberFormatOptions = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 20,
  }

  constructor(private tableFormatterService: TableFormatterService) {}

  public convertValueToString(
    value: Primitive,
    csvPreferences: CsvPreferencesDto = DEFAULT_CSV_PREFERENCES,
  ): string {
    switch (typeof value) {
      case 'string':
        return value.includes(csvPreferences.csvSeparator)
          ? `"${value}"`
          : value
      case 'number':
        switch (csvPreferences.csvDecimalSeparator) {
          case CsvDecimalSeparator.POINT:
            return value
              .toLocaleString('en-US', this.numberFormatOptions)
              .replace(/,/g, '')
          case CsvDecimalSeparator.COMMA:
            return value
              .toLocaleString('fr-FR', this.numberFormatOptions)
              .replace(/\s/g, '')
          default:
            exhaustiveCheck(csvPreferences.csvDecimalSeparator)
        }
        break
      case 'bigint':
        return value.toLocaleString()
      case 'boolean':
        return value.toString()
      case 'symbol':
        throw Error('Value not support yet !')
      default:
        return ''
    }
  }

  public convertDailyReportValueToString<A extends SharedTableBase>(
    columnName: keyof A,
    value: Primitive,
    csvPreferences: CsvPreferencesDto = DEFAULT_CSV_PREFERENCES,
  ): string {
    const specificColumnValue =
      this.tableFormatterService.getSpecificColumnValue(columnName, value)

    return this.convertValueToString(
      specificColumnValue ?? value,
      csvPreferences,
    )
  }
}

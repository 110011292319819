import { EntitiesSelectorFactory } from '../../../../shared/utils/redux/entities/entities.selectors'
import { getState } from '../../store/state'
import { createSelector } from '@ngrx/store'

export const pileTaggedAsDoneSelector = new EntitiesSelectorFactory(
  getState,
  state => state.pilesTaggedAsDone,
)

export const findPileTaggedAsDoneByColumnId = (columnId: string) =>
  createSelector(pileTaggedAsDoneSelector.getAll, values =>
    values.find(v => v.dataId == columnId),
  )

export const displayPileTaggedAsDoneFilters = createSelector(
  pileTaggedAsDoneSelector.getAll,
  v => v?.length > 0 ?? false,
)

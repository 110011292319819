<nav class="navbar navbar-default">
  <div class="flex-layout-row navbar-main-ctnr">
    <div
      class="navbar-header-left flex-50 flex-layout-row flex-align-start--center"
    >
      <a
        class="navbar-brand flex-layout-row flex-align-start--center"
        [routerLink]="['/']"
      >
        <img alt="zlyze" src="/assets/logos/zlyze.png" class="logo-menard" />
      </a>

      <div
        *ngIf="currentUrl !== '/'"
        class="navbar-back-button flex-1 flex-layout-row flex-align--center flex-gap-15px"
      >
        <mat-divider [vertical]="true" class="divider"></mat-divider>
        <button
          mat-raised-button
          color="primary"
          type="button"
          class="back-button"
          (click)="back()"
        >
          {{ 'HEADER.BACK' | translate }}
        </button>
      </div>

      <div
        *ngIf="selectedJobsiteContractNumber"
        class="navbar-jobsite-info flex-fill flex-layout-row flex-align-center--center flex-gap-5px"
      >
        <span class="flex-ellipsis" [title]="jobsiteName">{{
          jobsiteName
        }}</span>
        <span class="flex-no-shrink">-</span>
        <span
          class="jobsite-contract-number"
          [ngClass]="{
            'no-shrink': selectedJobsiteContractNumber?.length < 15,
            'flex-ellipsis': selectedJobsiteContractNumber?.length >= 15
          }"
          [title]="selectedJobsiteContractNumber"
          >{{ selectedJobsiteContractNumber }}</span
        >
        <span *ngIf="jobsiteCompany" class="flex-no-shrink">-</span>
        <span *ngIf="jobsiteCompany" class="flex-no-shrink">{{
          jobsiteCompany.name
        }}</span>
        <span *ngIf="!jobsiteConfigurable" class="flex-no-shrink"
          >({{ 'JOBSITE_MANAGEMENT.LIST.READ_ONLY' | translate }})</span
        >
      </div>
    </div>

    <div
      class="navbar-header-right flex-50 flex-layout-row flex-align-end--center"
    >
      <button
        mat-icon-button
        *ngIf="message?.message"
        class="platform-disruption-popup-button"
        (click)="togglePlatformDisruptionPopup()"
      >
        <mat-icon color="primary">warning</mat-icon>
      </button>

      <a class="navbar-brand" [routerLink]="['/']">
        <img
          alt="Brand"
          *ngIf="headerLogoLink"
          [src]="headerLogoLink"
          class="logo-menard"
        />
      </a>
      <div>{{ username | titlecase }}</div>
      <button
        mat-icon-button
        [matMenuTriggerFor]="menu"
        [disableRipple]="true"
        #menuTrigger="matMenuTrigger"
      >
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #menu="matMenu" class="flex-align-end">
        <button mat-menu-item [disabled]="true">
          {{ 'HEADER.PROFIL' | translate }}
        </button>
        <button mat-menu-item [matMenuTriggerFor]="languages">
          {{ 'HEADER.LANG' | translate }}
        </button>
        <button mat-menu-item [matMenuTriggerFor]="units">
          {{ 'HEADER.UNITS' | translate }}
        </button>
        <button mat-menu-item (click)="goToSettings()">
          {{ 'HEADER.SETTINGS' | translate }}
        </button>
        <button
          mat-menu-item
          (click)="goToHelp()"
          [ngClass]="{ active: !hideOverlay }"
        >
          {{ 'HEADER.HELP' | translate }}
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="logout()">
          {{ 'HEADER.LOGOUT' | translate }}
        </button>
      </mat-menu>

      <mat-menu #languages="matMenu">
        <button
          mat-menu-item
          [ngClass]="{ active: userPreferences?.language === 'en' }"
          (click)="selectLanguage('en')"
        >
          English
        </button>
        <button
          mat-menu-item
          [ngClass]="{ active: userPreferences?.language === 'fr' }"
          (click)="selectLanguage('fr')"
        >
          Français
        </button>
        <button
          mat-menu-item
          [ngClass]="{ active: userPreferences?.language === 'es' }"
          (click)="selectLanguage('es')"
        >
          Español
        </button>
      </mat-menu>

      <mat-menu #units="matMenu">
        <button
          mat-menu-item
          [ngClass]="{ active: userPreferences?.unitSystem === 'metric' }"
          (click)="setUnitSystem('metric')"
        >
          {{ 'HEADER.MET' | translate }}
        </button>
        <button
          mat-menu-item
          [ngClass]="{ active: userPreferences?.unitSystem === 'imperial' }"
          (click)="setUnitSystem('imperial')"
        >
          {{ 'HEADER.IMP' | translate }}
        </button>
      </mat-menu>
    </div>
  </div>

  <mat-progress-bar
    *ngIf="loading"
    class="progress-bar"
    mode="indeterminate"
  ></mat-progress-bar>
</nav>

<app-platform-disruptions
  *ngIf="message?.message && !closed"
  class="platform-disruption-popup"
  [message]="message.message"
  (closedChange)="updatePlatformDisruptionPopup($event)"
>
</app-platform-disruptions>

<div *ngIf="!hideOverlay" class="overlay">
  <div class="arrow">
    <div class="text">{{ 'GENERAL.HERE_HELP_DOC' | translate }}</div>
    <mat-checkbox
      class="checkbox"
      color="primary"
      (change)="onCheck($event.checked)"
      >{{ 'GENERAL.NOT_REMIND' | translate }}</mat-checkbox
    ><br />
    <button mat-raised-button class="button" color="primary" (click)="hide()">
      OK
    </button>
  </div>
</div>

import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core'
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog'
import { TranslateService } from '@ngx-translate/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { UserPreferencesDto } from '../remote-services/dtos/userPreferences.dto'
import { TechniqueNames } from '../remote-services/dtos/technique.dto'
import { CsvDateFormatLocalStorageService } from '../service/csv-date-format-local-storage.service'
import { DateTimeFormatService } from '../service/date-time-format.service'
import { DATE_FORMATS, DateFormats } from '../models/date-time-format.model'

export interface CsvConfigData {
  dateFormat: UserPreferencesDto['dateFormat']
  jobsiteId: string
  technique: TechniqueNames
}

export interface DateFormatDelimiter {
  format: DateFormats
  delimiter: string
}

export interface DateFormatDelimiterControls {
  dateFormat: FormControl<DateFormats>
  delimiter: FormControl<string>
}

@Component({
  selector: 'planned-progress-edit-csv-config-dialog',
  template: `
    <div>
      <h3>
        {{ 'JOBSITE_MANAGEMENT.FOLLOW_UP.CSV_DATE_FORMAT_TITLE' | translate }}
      </h3>
      <div style="margin: 20px 0">
        <form [formGroup]="form">
          <mat-form-field>
            <input
              type="text"
              maxlength="1"
              formControlName="delimiter"
              placeholder="{{
                'JOBSITE_MANAGEMENT.FOLLOW_UP.CSV_DATE_DELIMITER' | translate
              }}"
              matInput
            />
            <mat-error *ngIf="form.controls?.delimiter?.hasError('required')"
              >{{ 'GENERAL.FIELD_REQUIRED' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-radio-group
            class="flex flex-align-space-between"
            formControlName="dateFormat"
          >
            <mat-radio-button
              *ngFor="let format of dateFormatOptions"
              color="primary"
              [value]="format"
            >
              {{
                'DATE_TIME_FORMAT.DATE.' + format
                  | translate : { separator: form.controls?.delimiter?.value }
              }}
            </mat-radio-button>
          </mat-radio-group>
        </form>
      </div>
      <div class="flex flex-align-space-around" style="margin: 0">
        <button
          style="font-size: 14px"
          mat-raised-button
          type="button"
          (click)="onCancel()"
        >
          {{ 'GENERAL.CANCEL' | translate }}
        </button>
        <button
          style="font-size: 14px"
          mat-raised-button
          color="primary"
          (click)="onValidate()"
          [disabled]="!form.valid"
        >
          {{ 'GENERAL.VALIDATE' | translate }}
        </button>
      </div>
    </div>
  `,
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlannedProgressEditCsvConfigDialog implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: CsvConfigData,
    private dialogRef: MatDialogRef<PlannedProgressEditCsvConfigDialog>,
    private translateService: TranslateService,
    private csvDateFormatLsService: CsvDateFormatLocalStorageService,
    private formatService: DateTimeFormatService,
  ) {}
  public form: FormGroup<DateFormatDelimiterControls>

  readonly dateFormatOptions = DATE_FORMATS

  ngOnInit(): void {
    const initialValue =
      this.csvDateFormatLsService.loadFromLocalStorageOrDefault(
        this.data.dateFormat,
        this.data.jobsiteId,
        this.data.technique,
      )
    this.form = new FormGroup({
      delimiter: new FormControl(initialValue.delimiter, [Validators.required]),
      dateFormat: new FormControl(initialValue.format, [Validators.required]),
    })
  }

  onCancel(): void {
    this.dialogRef.close(null)
  }

  onValidate(): void {
    const result = this.form.getRawValue()
    const dl = result.delimiter
    const dateFormat = this.formatService.getDateFormat(result.dateFormat, dl)
    this.csvDateFormatLsService.saveToLocalStorage(
      { delimiter: dl, format: result.dateFormat },
      this.data.jobsiteId,
      this.data.technique,
    )
    this.dialogRef.close(dateFormat)
  }
}

import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { AppModule } from './app/app.module'
import { environment } from './environments/environment'
import { KeycloakService } from 'app/keycloak/keycloak.service'
import Keycloak from 'keycloak-js'
import * as Cesium from 'cesium'

window['CESIUM_BASE_URL'] = '/assets/cesium/'

Cesium.Ion.defaultAccessToken = 'noTokenBecauseWeAreNotUsingCesiumIon'

declare const KeycloakConfig: {
  url: string
  realm: string
  clientId: string
  secret: string
}
;(async () => {
  if (environment.production) {
    enableProdMode()
  }

  const kc = new Keycloak(KeycloakConfig)

  await kc.init({
    onLoad: 'login-required',
  })

  if (kc.authenticated) {
    KeycloakService.setKC(kc)
    await platformBrowserDynamic().bootstrapModule(AppModule)
  }
})()

import { reducerFromActionHandlers } from '../../../../shared/utils/redux/utils.reducers'
import { ChartConfigDto } from '../../models/template/chartConfig.dto'
import { TemplateConfigDto } from '../../models/template/templateConfig.dto'
import { WipTemplateActions } from './wipTemplate.actions'

const initialState: TemplateConfigDto = {
  id: '',
  name: '',
  lines: [],
}

export const reducers = reducerFromActionHandlers(initialState, [
  {
    actionType: WipTemplateActions.setWipTemplate.CREATE,
    handler: setWipTemplate,
  },
  {
    actionType: WipTemplateActions.addGraph.CREATE,
    handler: (state, action) => ({
      ...state,
      lines: calculateHeight([
        ...state.lines,
        {
          width: 25,
          height: 50,
          chartId: action.payload,
          index: state.lines.length,
        },
      ]),
    }),
  },
  { actionType: WipTemplateActions.setGraph.CREATE, handler: setGraph },
  { actionType: WipTemplateActions.deleteGraph.CREATE, handler: deleteGraph },
  { actionType: WipTemplateActions.updateWidth.CREATE, handler: updateWidth },
  { actionType: WipTemplateActions.updateHeight.CREATE, handler: updateHeight },
])

function setWipTemplate(
  state: TemplateConfigDto,
  action: ReturnType<typeof WipTemplateActions.setWipTemplate.create>,
): TemplateConfigDto {
  const template = action.payload
  return { ...template }
}

function setGraph(
  state: TemplateConfigDto,
  action: ReturnType<typeof WipTemplateActions.setGraph.create>,
): TemplateConfigDto {
  const index = action.payload.index
  const chartId = action.payload.id

  const lines = [...state.lines]
  lines[index] = { ...lines[index], chartId }
  return { ...state, lines: calculateHeight(lines) }
}

function deleteGraph(
  state: TemplateConfigDto,
  action: ReturnType<typeof WipTemplateActions.deleteGraph.create>,
): TemplateConfigDto {
  const chartId = action.payload

  const lines = [...state.lines].filter(config => config.chartId !== chartId)
  return { ...state, lines: calculateHeight(lines) }
}

function updateWidth(
  state: TemplateConfigDto,
  action: ReturnType<typeof WipTemplateActions.updateWidth.create>,
): TemplateConfigDto {
  const width = action.payload.width
  const chartId = action.payload.id

  const lines = [...state.lines].map(config =>
    config.chartId === chartId ? { ...config, width } : config,
  )
  return { ...state, lines: calculateHeight(lines) }
}

function updateHeight(
  state: TemplateConfigDto,
  action: ReturnType<typeof WipTemplateActions.updateHeight.create>,
): TemplateConfigDto {
  const height = action.payload.height
  const chartId = action.payload.id

  const lines = [...state.lines].map(config =>
    config.chartId === chartId ? { ...config, height } : config,
  )
  return { ...state, lines: calculateHeight(lines) }
}

function calculateHeight(charts: ChartConfigDto[]) {
  let currentLine = -1
  let acc = 100

  const firstCharts = []
  const chartByLine: Record<number, ChartConfigDto[]> = {}

  for (const chart of charts) {
    if (acc + chart.width > 100) {
      acc = chart.width
      currentLine++
      chartByLine[currentLine] = []
      firstCharts.push(chart)
    } else {
      acc += chart.width
    }

    chartByLine[currentLine].push(chart)
  }

  const result: ChartConfigDto[] = []
  firstCharts.forEach((firstChart, index) => {
    chartByLine[index].forEach(chart => {
      if (firstChart.height !== chart.height) {
        result.push({ ...chart, height: firstChart.height })
      } else {
        result.push(chart)
      }
    })
  })

  return result
}

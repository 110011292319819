import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { State as CoreState } from 'app/core/store/state'
import { AbstractLoadableGuard } from '../../shared/utils/abstract-loadable.guard'
import { concreteDeliverySelector } from '../store/concrete/concrete.selectors'
import { ConcreteActions } from '../store/concrete/concrete.actions'

@Injectable()
export class ConcreteDeliveryGuard extends AbstractLoadableGuard<CoreState> {
  constructor(store: Store<CoreState>) {
    super(
      store,
      concreteDeliverySelector.getLoaded,
      ConcreteActions.concreteDeliveryActions,
      true,
    )
  }
}

/*
 * Generated type guards for "cage.dto.ts".
 * WARNING: Do not manually change this file.
 */
import {
  CageTypeSectionDefinition,
  CageTypeDefinition,
  LinkShearType,
  CageProdRecord,
} from './cage.dto'
import { isColumnLink } from './column.dto.type.guard'

export function isCageTypeSectionDefinition(
  obj: unknown,
): obj is CageTypeSectionDefinition {
  const typedObj = obj as CageTypeSectionDefinition
  return (
    ((typedObj !== null && typeof typedObj === 'object') ||
      typeof typedObj === 'function') &&
    typeof typedObj['id'] === 'string' &&
    (typeof typedObj['mainBar1Number'] === 'undefined' ||
      typedObj['mainBar1Number'] === null ||
      typeof typedObj['mainBar1Number'] === 'number') &&
    (typeof typedObj['mainBar1Grade'] === 'undefined' ||
      typedObj['mainBar1Grade'] === null ||
      typeof typedObj['mainBar1Grade'] === 'string') &&
    (typeof typedObj['mainBar1Length'] === 'undefined' ||
      typedObj['mainBar1Length'] === null ||
      typeof typedObj['mainBar1Length'] === 'number') &&
    (typeof typedObj['mainBar1LengthUnit'] === 'undefined' ||
      typedObj['mainBar1LengthUnit'] === null ||
      typeof typedObj['mainBar1LengthUnit'] === 'string') &&
    (typeof typedObj['mainBar2Number'] === 'undefined' ||
      typedObj['mainBar2Number'] === null ||
      typeof typedObj['mainBar2Number'] === 'number') &&
    (typeof typedObj['mainBar2Grade'] === 'undefined' ||
      typedObj['mainBar2Grade'] === null ||
      typeof typedObj['mainBar2Grade'] === 'string') &&
    (typeof typedObj['mainBar2Length'] === 'undefined' ||
      typedObj['mainBar2Length'] === null ||
      typeof typedObj['mainBar2Length'] === 'number') &&
    (typeof typedObj['mainBar2LengthUnit'] === 'undefined' ||
      typedObj['mainBar2LengthUnit'] === null ||
      typeof typedObj['mainBar2LengthUnit'] === 'string') &&
    (isLinkShearType(typedObj['linkShearType']) as boolean) &&
    (typeof typedObj['linkShearGrade'] === 'undefined' ||
      typedObj['linkShearGrade'] === null ||
      typeof typedObj['linkShearGrade'] === 'string') &&
    (typeof typedObj['linkShearPitch'] === 'undefined' ||
      typedObj['linkShearPitch'] === null ||
      typeof typedObj['linkShearPitch'] === 'number') &&
    (typeof typedObj['linkShearPitchUnit'] === 'undefined' ||
      typedObj['linkShearPitchUnit'] === null ||
      typeof typedObj['linkShearPitchUnit'] === 'string')
  )
}

export function isCageTypeDefinition(obj: unknown): obj is CageTypeDefinition {
  const typedObj = obj as CageTypeDefinition
  return (
    ((typedObj !== null && typeof typedObj === 'object') ||
      typeof typedObj === 'function') &&
    typeof typedObj['id'] === 'string' &&
    typeof typedObj['jobsiteId'] === 'string' &&
    typeof typedObj['contractNumber'] === 'string' &&
    typeof typedObj['refIdentifierLabel'] === 'string' &&
    (typeof typedObj['overwriteDescription'] === 'undefined' ||
      typedObj['overwriteDescription'] === null ||
      typeof typedObj['overwriteDescription'] === 'string') &&
    (typeof typedObj['pileNominalDiameter'] === 'undefined' ||
      typedObj['pileNominalDiameter'] === null ||
      typeof typedObj['pileNominalDiameter'] === 'number') &&
    (typeof typedObj['pileNominalDiameterUnit'] === 'undefined' ||
      typedObj['pileNominalDiameterUnit'] === null ||
      typeof typedObj['pileNominalDiameterUnit'] === 'string') &&
    (typeof typedObj['cover'] === 'undefined' ||
      typedObj['cover'] === null ||
      typeof typedObj['cover'] === 'number') &&
    (typeof typedObj['coverUnit'] === 'undefined' ||
      typedObj['coverUnit'] === null ||
      typeof typedObj['coverUnit'] === 'string') &&
    (typeof typedObj['comment'] === 'undefined' ||
      typedObj['comment'] === null ||
      typeof typedObj['comment'] === 'string') &&
    (isCageTypeSectionDefinition(typedObj['topSection']) as boolean) &&
    (typeof typedObj['topSectionProjection'] === 'undefined' ||
      typedObj['topSectionProjection'] === null ||
      typeof typedObj['topSectionProjection'] === 'number') &&
    (typeof typedObj['topSectionProjectionUnit'] === 'undefined' ||
      typedObj['topSectionProjectionUnit'] === null ||
      typeof typedObj['topSectionProjectionUnit'] === 'string') &&
    (isCageTypeSectionDefinition(typedObj['middleSection']) as boolean) &&
    (typeof typedObj['middleSectionRepeatCount'] === 'undefined' ||
      typedObj['middleSectionRepeatCount'] === null ||
      typeof typedObj['middleSectionRepeatCount'] === 'number') &&
    (typeof typedObj['middleSectionSpliceLength'] === 'undefined' ||
      typedObj['middleSectionSpliceLength'] === null ||
      typeof typedObj['middleSectionSpliceLength'] === 'number') &&
    (typeof typedObj['middleSectionSpliceLengthUnit'] === 'undefined' ||
      typedObj['middleSectionSpliceLengthUnit'] === null ||
      typeof typedObj['middleSectionSpliceLengthUnit'] === 'string') &&
    (isCageTypeSectionDefinition(typedObj['bottomSection']) as boolean) &&
    (typeof typedObj['bottomSectionSpliceLength'] === 'undefined' ||
      typedObj['bottomSectionSpliceLength'] === null ||
      typeof typedObj['bottomSectionSpliceLength'] === 'number') &&
    (typeof typedObj['bottomSectionSpliceLengthUnit'] === 'undefined' ||
      typedObj['bottomSectionSpliceLengthUnit'] === null ||
      typeof typedObj['bottomSectionSpliceLengthUnit'] === 'string') &&
    (typeof typedObj['links'] === 'undefined' ||
      typedObj['links'] === null ||
      (Array.isArray(typedObj['links']) &&
        typedObj['links'].every((e: any) => isColumnLink(e) as boolean)))
  )
}

export function isLinkShearType(obj: unknown): obj is LinkShearType {
  const typedObj = obj as LinkShearType
  return typedObj === 'rings' || typedObj === 'helical'
}

export function isCageProdRecord(obj: unknown): obj is CageProdRecord {
  const typedObj = obj as CageProdRecord
  return (
    ((typedObj !== null && typeof typedObj === 'object') ||
      typeof typedObj === 'function') &&
    typeof typedObj['id'] === 'string' &&
    (isCageTypeDefinition(typedObj['cageTypeDefinition']) as boolean) &&
    typeof typedObj['columnId'] === 'string' &&
    typeof typedObj['jobsiteId'] === 'string' &&
    typeof typedObj['contractNumber'] === 'string' &&
    (typeof typedObj['comment'] === 'undefined' ||
      typedObj['comment'] === null ||
      typeof typedObj['comment'] === 'string')
  )
}

import * as core from '../../../../core/store'
import { ListState } from '../../../../shared/utils/redux/list/list.state'
import {
  Jobsite,
  SoilStats,
  SoilSurvey,
  SurveyData,
} from '@sde-ild/ssd-soillib-lib'
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store'
import { InjectionToken } from '@angular/core'
import { createListReducer } from '../../../../shared/utils/redux/list/list.reducer'
import { JobsiteSoillibDataActions } from './jobsite-soillib-data.actions'
import { LoadableState } from '../../../../shared/utils/redux/loadable/loadable.state'
import { createLoadableReducer } from '../../../../shared/utils/redux/loadable/loadable.reducer'

export interface State extends core.State {
  soillibDataState: SoillibDataState
}

export interface SoillibDataState {
  jobsites: ListState<Jobsite>
  soilStats: LoadableState<SoilStats | null>
  jobsite: LoadableState<Jobsite | null>
  jobsiteSoilSurveys: ListState<SoilSurvey>
  surveysData: LoadableState<Map<string, SurveyData[]>>
}

const reducers: ActionReducerMap<SoillibDataState> = {
  jobsites: createListReducer(JobsiteSoillibDataActions.loadSoillibJobsites),
  soilStats: createLoadableReducer(
    JobsiteSoillibDataActions.loadSoilStats,
    null,
  ),
  jobsite: createLoadableReducer(
    JobsiteSoillibDataActions.loadJobsiteInfo,
    null,
  ),
  jobsiteSoilSurveys: createListReducer(
    JobsiteSoillibDataActions.loadJobsiteSoilSurveys,
  ),
  surveysData: createLoadableReducer(
    JobsiteSoillibDataActions.loadSurveysData,
    null,
  ),
}

export const getState =
  createFeatureSelector<SoillibDataState>('soillibDataState')

export const reducerInjector = new InjectionToken(
  'Soillib data Registered Reducers',
)

function getReducers(): ActionReducerMap<SoillibDataState> {
  return reducers
}

export const reducerProvider = [
  { provide: reducerInjector, useFactory: getReducers },
]

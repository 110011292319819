import { Injectable } from '@angular/core'
import { Actions, createEffect } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { State } from '../state'
import { DailyReportColumnService } from '../../services/daily-report-column.service'
import { AsyncEffects } from '../../../shared/utils/redux/async.effects'
import { DailyReportColumnsActions } from './daily-report-columns.actions'

@Injectable()
export class DailyReportColumnsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private effectFactory: AsyncEffects,
    private service: DailyReportColumnService,
  ) {}

  loadDailyReportColumns$ = createEffect(() =>
    this.effectFactory.asyncEffect(
      DailyReportColumnsActions.loadDailyReportColumns,
      () => this.service.getAll(),
    ),
  )
}

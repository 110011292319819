import { SyncActionFactory } from 'app/shared/utils/redux/sync.actions'
import {
  ColorRangeConfig,
  ColorRangeConfigModel,
} from '../../models/color-range-config.model'

export class ColumnColorRangeConfigActions {
  public static update = new SyncActionFactory<ColorRangeConfig>(
    '[COLUMN COLOR RANGE CONFIG] UPDATE COLOR RANGE CONFIG',
  )

  public static set = new SyncActionFactory<ColorRangeConfigModel>(
    '[COLUMN COLOR RANGE CONFIG] SET COLOR RANGE CONFIG',
  )
}

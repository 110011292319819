import { ErrorHandler, Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import * as Sentry from '@sentry/angular'
import Swal from 'sweetalert2'
import { getBrowerVersion } from '../utils/browser-version'

@Injectable()
export class ColumnErrorHandler extends ErrorHandler {
  constructor(private translate: TranslateService) {
    super()
  }

  public handleError(error: any): void {
    super.handleError(error)
    if (error && getBrowerVersion().indexOf('IE') === 0) {
      this.showBrowserVersionErrorMessage()
    } else if (
      error &&
      error.message &&
      error.message.includes('Session expired')
    ) {
      this.sessionExpired()
    } else if (error && error.message) {
      Sentry.captureException(error.message)
      let mess = ''
      if (error.message.length <= 101) {
        mess = error.message
      } else {
        mess = error.message.substring(0, 100) + '...'
      }
      this.showGeneralErrorMessage(mess)
    } else {
      this.showGeneralErrorMessage()
    }
  }

  private showBrowserVersionErrorMessage(): void {
    Swal.fire({
      title: 'Sorry, Z-lyze does not support IE11 and its earlier versions',
      html: '<h4>We recommend you to use Google Chrome 9+, Firefox 4+<h4><hr><img src="../assets/logos/sb.png" height="40" alt="Soletanche bachy">',
      icon: 'error',
      showCancelButton: false,
      customClass: { container: 'swal-wide-sb' },
      showConfirmButton: false,
      allowOutsideClick: false,
    })
  }

  private sessionExpired(): void {
    const title = this.translate.instant('GENERAL.SESSION_EXPIRED.TITLE')
    const description = this.translate.instant(
      'GENERAL.SESSION_EXPIRED.DESCRIPTION',
    )
    Swal.fire({
      title,
      text: description,
      icon: 'warning',
      confirmButtonText: this.translate.instant('GENERAL.OK'),
    }).then(() => window.location.reload())
  }

  private showGeneralErrorMessage(
    des: string = this.translate.instant(
      'GENERAL.UNEXPECTED_ERROR.DESCRIPTION',
    ),
  ): void {
    const title = this.translate.instant('GENERAL.UNEXPECTED_ERROR.TITLE')
    Swal.fire({
      title,
      text: des,
      icon: 'error',
      confirmButtonText: this.translate.instant('GENERAL.OK'),
    })
  }
}

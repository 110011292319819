import { Injectable } from '@angular/core'
import {
  Event as RouterEvent,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router'
import { createEffect } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { tap } from 'rxjs/operators'
import { State } from '../state'
import { LoaderActions } from './loader.actions'

@Injectable()
export class LoaderEffects {
  constructor(private router: Router, private store: Store<State>) {}

  updateLoader$ = createEffect(
    () =>
      this.router.events.pipe(
        tap((event: RouterEvent) => {
          if (event instanceof NavigationStart) {
            this.store.dispatch(LoaderActions.start.create())
          } else if (
            event instanceof NavigationEnd ||
            event instanceof NavigationError ||
            event instanceof NavigationCancel
          ) {
            this.store.dispatch(LoaderActions.stop.create())
          }
        }),
      ),
    { dispatch: false },
  )
}

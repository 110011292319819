import { createSelector } from '@ngrx/store'
import { getState } from '../../../store/state'
import {
  getAllNotDiscardedColumns,
  getMapFocusCenterValue,
} from '../../../store/jobsite-summary.selectors'
import {
  parseExtentString,
  toExtentString,
} from '../../../../../shared/openlayer/parseExtentString'
import { createOrUpdateFromCoordinate, extend, Extent } from 'ol/extent'
import { Coordinate } from 'ol/coordinate'
import cloneDeep from 'lodash.clonedeep'
import { fromLonLat, toLonLat } from 'ol/proj'
import { getTechnique } from '../../../../../core/store/router/router.selectors'
import { ColumnDto } from '../../../../../shared/remote-services/dtos/column.dto'
import { TechniqueNames } from '../../../../../shared/remote-services/dtos/technique.dto'
import { exhaustiveCheck } from '../../../../../shared/utils/exhautisveCheck'

export const getJobsiteRelocateStep = createSelector(
  getState,
  state => state.jobsiteRelocateStep,
)

export const getJobsiteRelocateColumns = createSelector(
  getAllNotDiscardedColumns,
  getMapFocusCenterValue,
  getTechnique,
  (columns, center, technique) => {
    if (center == null) return columns
    const extentBound = getExtentBound(columns, technique)
    if (extentBound == null || extentBound.length < 4) return columns
    const maxExtentSide = Math.max(
      Math.abs(extentBound[0] - extentBound[2]),
      Math.abs(extentBound[1] - extentBound[3]),
    )
    return columns
      .map(c => relocate(c, center, extentBound, maxExtentSide, technique))
      .filter(c => c != null)
  },
)

function relocatePolygon(
  polygon: string,
  extent: Extent,
  center: Coordinate,
  scale: number,
  offset: number,
): string {
  if (polygon == null) return null
  return toExtentString(
    parseExtentString(polygon).map(c => [
      scale * (c[0] - extent[0]) + center[0] - offset,
      scale * (c[1] - extent[1]) + center[1] - offset,
    ]),
  )
}

function relocate(
  column: ColumnDto,
  center: Coordinate,
  extentBound: Extent,
  maxExtentSide: number,
  technique: TechniqueNames,
): ColumnDto {
  const cloned = cloneDeep(column)
  switch (technique) {
    case 'PILES': {
      const lonLat = relocatePile(
        [column.values.localX, column.values.localY],
        extentBound,
        fromLonLat(center),
        150 / maxExtentSide,
        75,
      )
      cloned.values.longitude = lonLat[0]
      cloned.values.latitude = lonLat[1]
      break
    }
    case 'HF':
      cloned.values.polygon = relocatePolygon(
        cloned.values.polygon,
        extentBound,
        fromLonLat(center),
        150 / maxExtentSide,
        75,
      )
      break
    default:
      exhaustiveCheck(technique)
  }
  return cloned
}

function relocatePile(
  coord: Coordinate,
  extent: Extent,
  center: Coordinate,
  scale: number,
  offset: number,
): Coordinate {
  if (coord == null) return null

  return toLonLat([
    scale * (coord[0] - extent[0]) + center[0] - offset,
    scale * (coord[1] - extent[1]) + center[1] - offset,
  ])
}

function getExtentBound(
  columns: ColumnDto[],
  technique: TechniqueNames,
): Extent {
  if (technique === 'PILES') {
    return columns
      .map(c => [c.values.localX, c.values.localY])
      .map(c => createOrUpdateFromCoordinate(c))
      .reduce((acc, ex) => {
        if (acc == null) acc = ex
        return extend(acc, ex)
      }, null)
  }
  return columns
    .map(c => c.values.polygon)
    .map(p => parseExtentString(p))
    .map(c => createOrUpdateFromCoordinate(c[0]))
    .reduce((acc, ex) => {
      if (acc == null) acc = ex
      return extend(acc, ex)
    }, null)
}

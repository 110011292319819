import { Injectable } from '@angular/core'
import Highcharts, { Options } from 'highcharts'
import Drilldown from 'highcharts/modules/drilldown'
import { TranslateService } from '@ngx-translate/core'

@Injectable()
export class HighchartsService {
  private defaultOptions: Options = {
    accessibility: {
      enabled: false,
    },
    chart: {
      style: {
        fontFamily:
          '"Lucida Grande", "Lucida Sans Unicode", Verdana, Arial, Helvetica, sans-serif',
        fontSize: '16px',
      },
    },
    title: {
      style: {
        fontWeight: 'normal',
        fontSize: '18px',
      },
    },
    legend: {
      itemStyle: {
        fontWeight: 'bold',
      },
      itemHiddenStyle: {
        color: '#cccccc',
      },
    },
    xAxis: {
      labels: {
        distance: 8,
        style: {
          color: '#666666',
          fontSize: '11px',
        },
      },
      lineColor: '#ccd6eb',
    },
    yAxis: {
      labels: {
        distance: 8,
        style: {
          color: '#666666',
          fontSize: '11px',
        },
      },
    },
    drilldown: {
      breadcrumbs: {
        floating: true,
        relativeTo: 'spacingBox',
        buttonTheme: {
          fill: '#e20025',
          'stroke-width': 0,
          padding: 8,
          r: 5,
          style: {
            fontSize: '12px',
            color: 'white',
            'text-transform': 'uppercase',
            'font-family': "'Open Sans', sans serif",
          },
          states: {
            hover: {
              fill: '#e20025',
            },
          },
        },
        showFullPath: false,
        formatter: (_level): string =>
          this.translate.instant(
            'GENERAL.HIGHCHARTS.DRILL_DOWN_BREADCRUMBS_BUTTON',
          ),
      },
      activeAxisLabelStyle: {
        color: '#003399FF',
      },
      activeDataLabelStyle: {
        color: '#003399FF',
      },
    },
  }

  constructor(private translate: TranslateService) {}

  setupDefaultChartOptions(): void {
    Drilldown(Highcharts)
    Highcharts.setOptions(this.defaultOptions)
  }
}

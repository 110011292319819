import { getState, JobsiteSummaryState } from '../state'
import { createSelector } from '@ngrx/store'
import { getParameter } from '../jobsite-summary.selectors'
import { getJobsiteId } from 'app/core/store/router/router.selectors'
import {
  ColorRangeConfig,
  ColorRangeConfigModel,
} from '../../models/color-range-config.model'
import { ParameterModel } from '../../models/parameter.model'
import { State } from '../../../../core/store'
import { getMapSceneType } from '../map-settings/map-settings.selectors'
import { SceneType } from '../../models/map-settings.model'

export const getColorRangeConfigState = createSelector<
  State,
  [JobsiteSummaryState],
  ColorRangeConfigModel
>(getState, (state: JobsiteSummaryState) => state.columnColorRangeConfig)

export const getCurrentColorRangeConfig = createSelector<
  State,
  [ColorRangeConfigModel, ParameterModel, string, SceneType],
  ColorRangeConfig
>(
  getColorRangeConfigState,
  getParameter,
  getJobsiteId,
  getMapSceneType,
  (
    state: ColorRangeConfigModel,
    parameter: ParameterModel,
    jobsiteId: string,
    sceneType: SceneType,
  ) =>
    state?.[jobsiteId]?.[parameter.metric]?.[parameter.operator]?.[sceneType],
)

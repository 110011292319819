/*
 * Generated type guards for "export.model.ts".
 * WARNING: Do not manually change this file.
 */
import {
  ExportSuffix,
  ExportHFSuffix,
  ExportRKSuffix,
  DtoDailyHeader,
  DtoDailyGroupLine,
  DtoDailyGroup,
  DtoDaily,
} from './export.model'
import { isExportDailyReportPayload } from '../../jobsites-management/jobsite-summary/models/export.model.type.guard'

export function isExportSuffix(obj: unknown): obj is ExportSuffix {
  const typedObj = obj as ExportSuffix
  return (
    typedObj === 'custompdf' ||
    typedObj === 'deviation' ||
    typedObj === 'machine' ||
    typedObj === 'verticality' ||
    (isExportRKSuffix(typedObj) as boolean)
  )
}

export function isExportHFSuffix(obj: unknown): obj is ExportHFSuffix {
  const typedObj = obj as ExportHFSuffix
  return (
    typedObj === 'deviation' ||
    typedObj === 'machine' ||
    typedObj === 'verticality'
  )
}

export function isExportRKSuffix(obj: unknown): obj is ExportRKSuffix {
  const typedObj = obj as ExportRKSuffix
  return typedObj === 'rk'
}

export function isDtoDailyHeader(obj: unknown): obj is DtoDailyHeader {
  const typedObj = obj as DtoDailyHeader
  return (
    ((typedObj !== null && typeof typedObj === 'object') ||
      typeof typedObj === 'function') &&
    typeof typedObj['key'] === 'string' &&
    typeof typedObj['label'] === 'string'
  )
}

export function isDtoDailyGroup(obj: unknown): obj is DtoDailyGroup {
  const typedObj = obj as DtoDailyGroup
  return (
    ((typedObj !== null && typeof typedObj === 'object') ||
      typeof typedObj === 'function') &&
    (typeof typedObj['value'] === 'undefined' ||
      typeof typedObj['value'] === 'string') &&
    Array.isArray(typedObj['lines']) &&
    typedObj['lines'].every((e: any) => e instanceof DtoDailyGroupLine)
  )
}

export function isDtoDaily(obj: unknown): obj is DtoDaily {
  const typedObj = obj as DtoDaily
  return (
    (isExportDailyReportPayload(typedObj) as boolean) &&
    ((typedObj !== null && typeof typedObj === 'object') ||
      typeof typedObj === 'function') &&
    Array.isArray(typedObj['headers']) &&
    typedObj['headers'].every((e: any) => isDtoDailyHeader(e) as boolean) &&
    Array.isArray(typedObj['groups']) &&
    typedObj['groups'].every((e: any) => isDtoDailyGroup(e) as boolean) &&
    Array.isArray(typedObj['pileIds']) &&
    typedObj['pileIds'].every((e: any) => typeof e === 'string') &&
    (typeof typedObj['startDate'] === 'undefined' ||
      typeof typedObj['startDate'] === 'string') &&
    (typeof typedObj['endDate'] === 'undefined' ||
      typeof typedObj['endDate'] === 'string')
  )
}

import { CommonModule } from '@angular/common'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { KeycloakService } from './keycloak.service'
import { TokenInterceptor } from './token.interceptor'

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [
    KeycloakService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
  exports: [],
})
export class KeycloakModule {}

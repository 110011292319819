import { JobsiteDto } from 'app/shared/remote-services/dtos/jobsite.dto'
import { reducerFromActionHandlers } from 'app/shared/utils/redux/utils.reducers'
import cloneDeep from 'lodash.clonedeep'
import { JobsiteActions } from './jobsite.actions'
import {
  CheckAvailabilityInput,
  CheckAvailabilityResponse,
} from '../../../jobsites-management/jobsite-creation/models/checkAvailability.model'
import { JobsiteProgressActions } from '../jobsite-progress/jobsite-progress.actions'

export interface State {
  currentJobsite: JobsiteDto | null
  checkAvailability: CheckAvailabilityResponse
  lastCheckAvailabilityValues: CheckAvailabilityInput
}

export const initialState: State = {
  currentJobsite: null,
  checkAvailability: null,
  lastCheckAvailabilityValues: null,
}

export const reducer = reducerFromActionHandlers(initialState, [
  {
    actionType: JobsiteActions.loadJobsite.COMPLETE,
    handler: (
      state,
      action: ReturnType<(typeof JobsiteActions.loadJobsite)['createComplete']>,
    ) => ({
      ...state,
      currentJobsite: action.payload?.jobsite,
    }),
  },
  {
    actionType: JobsiteActions.updateJobsite.COMPLETE,
    handler: (
      state,
      action: ReturnType<
        (typeof JobsiteActions.updateJobsite)['createComplete']
      >,
    ) => {
      if (state.currentJobsite?.id !== action.payload?.jobsite?.id) {
        throw Error('trying to update another jobsite than the current one')
      }
      return {
        ...state,
        currentJobsite: action.payload?.jobsite,
      }
    },
  },
  {
    actionType: JobsiteActions.setJobsiteStatus.COMPLETE,
    handler: (
      state,
      action: ReturnType<
        (typeof JobsiteActions.setJobsiteStatus)['createComplete']
      >,
    ) => {
      if (state.currentJobsite?.id !== action.payload?.jobsiteId) {
        throw Error(
          'trying to update status of another jobsite than the current one',
        )
      }
      return {
        ...state,
        currentJobsite: {
          ...state.currentJobsite,
          status: action.payload?.status,
        },
      }
    },
  },
  {
    actionType: JobsiteProgressActions.updateProgressCategory.COMPLETE,
    handler: (
      state,
      action: ReturnType<
        (typeof JobsiteProgressActions.updateProgressCategory)['createComplete']
      >,
    ) => {
      const techniqueName = action.payload.techniqueName
      if (state.currentJobsite?.id !== action.payload?.jobsiteId) {
        throw Error(
          'trying to update status of another jobsite than the current one',
        )
      }

      const jobsite = cloneDeep(state.currentJobsite)
      jobsite.techniques.find(t => t.name === techniqueName).progressCategory =
        action.payload.progressCategory

      return {
        ...state,
        currentJobsite: jobsite,
      }
    },
  },
  {
    actionType: JobsiteActions.setLastCheckValues.CREATE,
    handler: (
      state,
      action: ReturnType<(typeof JobsiteActions.setLastCheckValues)['create']>,
    ) => ({
      ...state,
      lastCheckAvailabilityValues: action.payload,
    }),
  },
  {
    actionType: JobsiteActions.setCheckResponse.CREATE,
    handler: (
      state,
      action: ReturnType<(typeof JobsiteActions.setCheckResponse)['create']>,
    ) => ({
      ...state,
      checkAvailability: action.payload,
    }),
  },
])

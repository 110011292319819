import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { TechniqueDto } from '../../shared/remote-services/dtos/technique.dto'

@Injectable()
export class TechniqueService {
  constructor(private http: HttpClient) {}

  public getAll(): Observable<TechniqueDto[]> {
    return this.http.get<TechniqueDto[]>(`jobsite/techniques`)
  }
}

import { Injectable } from '@angular/core'
import { Actions, createEffect } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { onStart } from 'app/shared/utils/redux/operators'
import { map, switchMap, withLatestFrom } from 'rxjs/operators'
import { getJobsiteId } from '../router/router.selectors'
import { State } from '../state'
import { ConcreteActions } from './concrete.actions'
import { ConcreteService } from '../../../shared/remote-services/concrete.service'

@Injectable()
export class ConcreteEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private service: ConcreteService,
  ) {}

  loadConcrete$ = createEffect(() =>
    this.actions$.pipe(
      onStart(ConcreteActions.concreteTypeDefActions),
      withLatestFrom(this.store.pipe(select(getJobsiteId))),
      switchMap(([_, jobsiteId]) =>
        this.service.getAllTypeDefByJobsite(jobsiteId),
      ),
      map(res => ConcreteActions.concreteTypeDefActions.createAddItems(res)),
    ),
  )

  loadConcreteDeliveries$ = createEffect(() =>
    this.actions$.pipe(
      onStart(ConcreteActions.concreteDeliveryActions),
      withLatestFrom(this.store.pipe(select(getJobsiteId))),
      switchMap(([_, jobsiteId]) =>
        this.service.getConcreteDeliveriesByJobsite(jobsiteId),
      ),
      map(res => ConcreteActions.concreteDeliveryActions.createAddItems(res)),
    ),
  )
}

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { Observable } from 'rxjs'
import {
  DtoPlatformDisruption,
  PlatformDisruptionMessage,
} from '../../core/models/PlatformDisruption.model'
import { Store } from '@ngrx/store'
import { State } from '../../core/store'
import { platformDisruptionsSelector } from '../../core/store/platform-disruptions/platform-disruptions.selectors'
import { PlatformDisruptionsActions } from '../../core/store/platform-disruptions/platform-disruptions.actions'

@Component({
  selector: 'manage-disruptions-dialog-container',
  template: `
    <manage-disruptions-dialog
      [disruptions]="disruptions$ | async"
      (closeDialog)="closeDialog()"
      (closeDisruption)="closeDisruption($event)"
      (createDisruption)="createDisruption($event)"
    ></manage-disruptions-dialog>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageDisruptionsDialogContainerComponent implements OnInit {
  disruptions$: Observable<DtoPlatformDisruption[]> = this.store.select(
    platformDisruptionsSelector.getValue,
  )

  constructor(
    private store: Store<State>,
    private dialogRef: MatDialogRef<ManageDisruptionsDialogContainerComponent>,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(
      PlatformDisruptionsActions.loadAllDisruptions.createStart(),
    )
  }

  closeDialog(): void {
    this.dialogRef.close()
  }

  closeDisruption(id: string): void {
    this.store.dispatch(
      PlatformDisruptionsActions.closeDisruption.createStart(id),
    )
  }

  createDisruption(dto: PlatformDisruptionMessage): void {
    this.store.dispatch(
      PlatformDisruptionsActions.createNewDisruption.createStart(dto),
    )
  }
}

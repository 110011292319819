import { createSelector } from '@ngrx/store'
import { getJobsiteContractNumber } from 'app/core/store/jobsite'
import {
  getColumnId,
  getJobsiteId,
  getTechnique,
} from 'app/core/store/router/router.selectors'
import { HFType } from 'app/shared/constants/hf-type.enum'
import { notEmpty } from 'app/shared/utils/notEmpty'
import { EntitiesSelectorFactory } from 'app/shared/utils/redux/entities/entities.selectors'
import { LoadableSelectorFactory } from 'app/shared/utils/redux/loadable/loadable.selectors'
import { State } from '../../../core/store'
import { ListSelectorFactory } from '../../../shared/utils/redux/list/list.selectors'
import { CastaurPileDto } from '../models/castaur.dto'
import { ColumnsSelectionModel } from '../models/columns-selection.model'
import { EventModel } from '../models/event.model'
import { HFActionTypeValues, ToolsActionType } from '../models/action-type.enum'
import { getState, JobsiteSummaryState, Mode } from './state'
import { TechniqueNames } from '../../../shared/remote-services/dtos/technique.dto'
import { ColumnDto } from '../../../shared/remote-services/dtos/column.dto'
import { ZoneColorDto } from '../models/zone-color.dto'
import { Process } from '../../../shared/constants/process.model'
import { appTabs } from '../models/jobsite-tabs.model'
import { pileTaggedAsDoneSelector } from '../pile-tagged-as-done/store/pile-tagged-as-done.selectors'
import { DtoPileTaggedAsDone } from '../pile-tagged-as-done/models/pile-tagged-as-done.model'
import { getEnableDataScienceDwall } from '../../settings/store/feature-toggle/feature-toggle.selectors'

export const getMode = createSelector(
  getState,
  (state: JobsiteSummaryState) => state.mode,
)

export const getSelectedTab = createSelector(
  getState,
  (state: JobsiteSummaryState) => state.selectedTab,
)

export const getIsTabLoading = createSelector(
  getState,
  (state: JobsiteSummaryState) => state.tabIsLoading,
)

export const getHighlightColumnId = createSelector(
  getState,
  (state: JobsiteSummaryState) => state.highlightColumnId,
)

export const getVisibleTabs = createSelector(
  getMode,
  getTechnique,
  getEnableDataScienceDwall,
  (mode: Mode, techniqueName: TechniqueNames, dataScienceDwallEnabled) =>
    appTabs
      .filter(
        tab =>
          tab.mode === mode &&
          tab.techniques.find(t => t.name === techniqueName) != null,
      )
      .filter(tab =>
        tab.path === 'data-science-dwall' ? dataScienceDwallEnabled : true,
      )
      .map(tab => ({
        path: tab.path,
        position: tab.techniques.find(t => t.name === techniqueName).position,
      }))
      .sort((a, b) => a.position - b.position)
      .map(val => val.path),
)

export const getColumnLoaded = createSelector(
  getState,
  (state: JobsiteSummaryState) => state.columns.loaded,
)

export const getTypeTreeLoaded = createSelector(
  getState,
  (state: JobsiteSummaryState) => state.typeTree.loaded,
)

export const getTypeTreeList = createSelector(
  getState,
  (state: JobsiteSummaryState) => state.typeTree.value,
)

export const getTypeTreeOpened = createSelector(
  getState,
  (state: JobsiteSummaryState) => state.typeTreeOpened,
)

export const getColumns = new EntitiesSelectorFactory(
  getState,
  (state: JobsiteSummaryState) => state.columns,
)

export const doneColumnIdsSelectors = new ListSelectorFactory(
  getState,
  (state: JobsiteSummaryState) => state.doneColumnIds,
)

export const doneAndTaggedAsDoneColumnIdsSelectors = createSelector(
  doneColumnIdsSelectors.getAll,
  pileTaggedAsDoneSelector.getAll,
  (doneIds: string[], taggedAsDone: DtoPileTaggedAsDone[]) => [
    ...doneIds,
    ...taggedAsDone.map(t => t.dataId),
  ],
)

export const getAllDoneColumns = createSelector(
  getColumns.getAll,
  doneColumnIdsSelectors.getAll,
  (columns: ColumnDto[], doneColumnIds: string[]) =>
    columns.filter(c => doneColumnIds.includes(c.key.id)),
)

export const getAllNotDiscardedColumns = createSelector(
  getColumns.getAll,
  columns => columns.filter(c => !c.values?.discarded),
)

export const getNotDiscardedColumnIds = createSelector(
  getAllNotDiscardedColumns,
  columns => columns.map(c => c.key.id),
)

export const getAllNotDiscardedNotDoneColumns = createSelector(
  getAllNotDiscardedColumns,
  doneColumnIdsSelectors.getAll,
  (columns, doneIds) => columns.filter(c => !doneIds.includes(c.key.id)),
)

export const columnProdSelectors = new LoadableSelectorFactory(
  getState,
  state => state.columnsProd,
)

export const cageProdRecordSelectors = new EntitiesSelectorFactory(
  getState,
  state => state.cageProdRecordsByColumn,
)

export const concreteProdRecordSelectors = new EntitiesSelectorFactory(
  getState,
  state => state.concreteProdRecordsByColumn,
)

export const getProdEventSummaries = createSelector(
  getState,
  (state: JobsiteSummaryState) => state.prodEventSummaries,
)

export const getNullCoordColumnIds = createSelector(
  getState,
  state => state.nullCoordinateColumnIds.value,
)

export const getProcessesUsedInJobsite = createSelector<
  State,
  [ColumnDto[]],
  Process[]
>(getColumns.getAll, columns => [
  ...new Set(columns.map(col => col.key?.process).filter(notEmpty)),
])

const eventSelectors = new EntitiesSelectorFactory(
  getState,
  state => state.events,
)

export const getEvents = createSelector<State, [EventModel[]], EventModel[]>(
  eventSelectors.getAll,
  events =>
    events.sort((a, b) => {
      if (a.Date < b.Date) {
        return 1
      }
      if (a.Date > b.Date) {
        return -1
      }
      return 0
    }),
)

export const getParameter = createSelector(
  getState,
  (state: JobsiteSummaryState) => state.parameter,
)

export const getPopoverContentArray = createSelector(
  getState,
  state => state.mapPopoverContent,
)

export const getPopoverPosition = createSelector(
  getState,
  state => state.popoverPosition,
)

export const nameFiltersSelectors = new ListSelectorFactory(
  getState,
  (state: JobsiteSummaryState) => state.nameFilters,
)

export const metricsFiltersSelectors = new ListSelectorFactory(
  getState,
  (state: JobsiteSummaryState) => state.metricFilters,
)

export const getDateRangeFilter = createSelector(
  getState,
  (state: JobsiteSummaryState) => state.dateRangeFilter,
)

export const getInteractionMode = createSelector(
  getState,
  state => state.interactionMode,
)

export const selectedColumnIdsSelectors = new ListSelectorFactory(
  getState,
  state => state.selectedColumnIds,
)

export const getSelectedColumns = createSelector(
  selectedColumnIdsSelectors.getAll,
  getColumns.getEntities,
  (selectedColumnIds, columns) => selectedColumnIds.map(id => columns[id]),
)

export const columnsSelectionsSelectors = new EntitiesSelectorFactory(
  getState,
  state => state.columnsSelections,
)

export const selectedColumnsTargetCriteria = createSelector(
  getColumns.getEntities,
  selectedColumnIdsSelectors.getAll,
  (entities: any, ids: string[]) => {
    return ids.map(columnId => {
      const columnElement = entities[columnId]
      if (columnElement) {
        return columnElement.targetCriteria
      } else {
        return null
      }
    })
  },
)

export const getExportMode = createSelector(getState, state => state.exportMode)

export const getColumnColorMode = createSelector(
  getState,
  state => state.columnColorMode,
)

export const getZonesColorSelectors = new EntitiesSelectorFactory(
  getState,
  state => state.zonesColor,
)

export const getPanels = createSelector(getColumns.getAll, columns =>
  columns.filter(c => c.key.type === HFType.PANEL),
)

export const getZones = createSelector<
  State,
  [ColumnDto[], string[], ZoneColorDto[]],
  ColumnsSelectionModel[]
>(
  getColumns.getAll,
  getNullCoordColumnIds,
  getZonesColorSelectors.getAll,
  (columns, nullCoordIds, zonesColor) => {
    const zones = Object.values(
      columns
        .filter(column => !!column.values.zone)
        .reduce((acc, column) => {
          const zone = column.values.zone
          if (!acc[zone]) {
            acc[zone] = { id: zone, name: zone, dataPreparationIds: [] }
          }
          acc[zone].dataPreparationIds.push(column.key.id)
          const currentZoneColor = zonesColor.filter(
            zoneColor => zoneColor.zone === zone,
          )
          acc[zone].color =
            currentZoneColor && currentZoneColor.length > 0
              ? currentZoneColor[0].color
              : 'white'
          return { ...acc }
        }, {} as Record<string, ColumnsSelectionModel>),
    ).sort((a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0))
    if (nullCoordIds.length > 0) {
      const currentZoneColor = zonesColor.filter(
        zoneColor => zoneColor.zone === 'No-coordinates',
      )
      const nullCoordsZone = {
        id: 'No-coordinates',
        name: 'No-coordinates',
        dataPreparationIds: nullCoordIds,
        color:
          currentZoneColor && currentZoneColor.length > 0
            ? currentZoneColor[0].color
            : 'white',
      }
      zones.unshift(nullCoordsZone)
    }
    return zones
  },
)

export type MapColorMode = 'WARNING' | 'PARAMETER' | 'BY_DAY' | 'DEFAULT'
export const getMapColorMode = createSelector(
  getState,
  (state: JobsiteSummaryState): MapColorMode => {
    if (
      state.selectedTab === 'data-table' &&
      state.asBuiltWarningsColorModeEnabled
    ) {
      return 'WARNING'
    }
    if (
      state.selectedTab === 'visualization' &&
      state.columnColorMode === 'PARAMETER'
    ) {
      return 'PARAMETER'
    }
    if (state.colorizeByDayEnabled) {
      return 'BY_DAY'
    }
    return 'DEFAULT'
  },
)

export const notDiscardedDoneColumnIdsSelectors = createSelector(
  doneColumnIdsSelectors.getAll,
  getNotDiscardedColumnIds,
  (doneIds, notDiscardedIds) =>
    doneIds.filter(id => notDiscardedIds.includes(id)),
)

export const hasColumnDone = createSelector(
  doneColumnIdsSelectors.getAll,
  columnsDone => columnsDone && columnsDone.length,
)
export const isDone = createSelector(
  doneColumnIdsSelectors.getAll,
  getColumnId,
  (columnDoneIds, columnId) =>
    columnDoneIds ? columnDoneIds.includes(columnId) : false,
)

export const columnIdsToFitSelectors = new ListSelectorFactory(
  getState,
  (state: JobsiteSummaryState) => state.columnIdsToFit,
)

export const getCastaurInfos = createSelector(
  getJobsiteId,
  getJobsiteContractNumber,
  getColumns.getEntities,
  columnProdSelectors.getValue,
  (jobsiteId, jobsiteName, columnState, columnsProd) => {
    const castaurPiles: CastaurPileDto[] = Object.entries(
      columnState ?? [],
    ).map(([id, col]) => {
      return {
        id,
        name: col?.key.name,
        process: col?.key.process,
        diameter: columnsProd?.[id]?.diameter,
        localX: col?.values.localX,
        localY: col?.values.localY,
        dmxAxe: col?.values.axeDistance,
        dmxAngle: col?.values.axeAngle,
      }
    })
    return {
      url: location.origin,
      jobsiteId,
      jobsiteName,
      piles: castaurPiles,
    }
  },
)

export const getHfEditEnabled = createSelector(
  getState,
  getTechnique,
  (state: JobsiteSummaryState, technique: TechniqueNames) =>
    technique === 'HF' &&
    (
      [
        HFActionTypeValues.ADD,
        HFActionTypeValues.DELETE,
        HFActionTypeValues.EDIT,
        HFActionTypeValues.TRANSLATE,
        HFActionTypeValues.RELOCATE_TRANSLATE,
      ] as ToolsActionType[]
    ).includes(state.selectedTool),
)

export const getSelectedTool = createSelector(
  getState,
  (state: JobsiteSummaryState) => state.selectedTool,
)

export const getSelectedOrderPhasingSubTool = createSelector(
  getState,
  (state: JobsiteSummaryState) => state.orderPhasingSubToolSelected,
)

// method heavely inspired by https://github.com/ayamflow/polygon-centroid/blob/master/index.js
export function computePolygonCentroid(
  points: Array<[number, number]>,
): [number, number] {
  const l = points.length

  return points.reduce<[number, number]>(
    (center, p, i) => {
      center[0] += p[0]
      center[1] += p[1]

      if (i === l - 1) {
        center[0] /= l
        center[1] /= l
      }

      return center
    },
    [0, 0],
  )
}

export const getColorizeByDayEnabled = createSelector(
  getState,
  (state: JobsiteSummaryState) => state.colorizeByDayEnabled,
)

const rigEnabledPathSelectors = new EntitiesSelectorFactory(
  getState,
  (state: JobsiteSummaryState) => state.rigEnabledPaths,
)

export const getEnabledRigPathMap = createSelector(
  rigEnabledPathSelectors.getEntities,
  entities =>
    Object.fromEntries(
      Object.entries(entities).map(([k, v]) => [k, v.value] as const),
    ),
)

export const getToolNbSelectedFeatures = createSelector(
  getState,
  state => state.toolNbSelectedFeatures,
)

export const getMissingSelectedSideCount = createSelector(
  getColumns.getAll,
  columns =>
    columns
      .filter(c => c?.key?.type !== HFType.PANEL)
      .filter(c => !(c?.values?.lineString && c?.values?.lineRatio)).length,
)

export const getMapVisibleColumns = createSelector(
  getColumns.getAll,
  getTechnique,
  (columns: ColumnDto[], technique: TechniqueNames) =>
    columns.filter(column =>
      technique === 'PILES'
        ? column.values?.localX != null && column.values?.localY != null
        : column.values?.polygon != null,
    ),
)

export const getWarnMessageKey = createSelector(
  getState,
  state => state.warnMessageKey,
)

export const getDisplayWarnMessage = createSelector(
  getState,
  state => state.displayWarnMessage,
)

export const getMapFocusCenterValue = createSelector(
  getState,
  state => state.mapFocusCenterValue,
)

import { ListActionFactory } from '../../../../shared/utils/redux/list/list.actions'
import { DtoEnergyRecord } from '../models/EnergyRecord.type'
import { SyncActionFactory } from '../../../../shared/utils/redux/sync.actions'

export class JobsiteEnergyActions {
  public static loadEnergyRecords = new ListActionFactory<
    DtoEnergyRecord,
    void
  >('[JOBSITE ENERGY] LOAD ENERGY_RECORDS')

  public static setSelectedEnergyRecordId = new SyncActionFactory<number>(
    '[JOBSITE ENERGY] SET SELECTED RECORD ID',
  )
}

import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ProviderDto } from './dtos/provider.dto'
import { Observable } from 'rxjs'

@Injectable()
export class ProviderService {
  constructor(private http: HttpClient) {}

  private baseUrl = 'rest/provider'

  public getAll(): Observable<ProviderDto[]> {
    return this.http.get<ProviderDto[]>(`${this.baseUrl}/all`)
  }

  public getAllConcreteProviders(): Observable<ProviderDto[]> {
    return this.http.get<ProviderDto[]>(`${this.baseUrl}/all-concrete`)
  }

  public getAllProviders(): Observable<ProviderDto[]> {
    return this.http.get<ProviderDto[]>(`${this.baseUrl}/all`)
  }
}

export class WarningData {
  translateKey: string
  value: number | null

  constructor(translateKey: string, value: number | null = null) {
    this.translateKey = translateKey
    this.value = value
  }
}

export class ColumnChecksModel {
  id: string
  warningData: WarningData[]

  constructor(id: string) {
    this.id = id
    this.warningData = []
  }
}

import { Injectable } from '@angular/core'
import { AbstractLoadableGuard } from '../../shared/utils/abstract-loadable.guard'
import { State as CoreState } from '../store'
import { Store } from '@ngrx/store'
import { weeklyReportConfigSelector } from '../../jobsites-management/jobsites-list/store/jobsites-list.selectors'
import { JobsitesListActions } from '../../jobsites-management/jobsites-list/store/jobsites-list.actions'

@Injectable()
export class WeeklyReportConfigGuard extends AbstractLoadableGuard<CoreState> {
  constructor(store: Store<CoreState>) {
    super(
      store,
      weeklyReportConfigSelector.getLoaded,
      JobsitesListActions.loadWeeklyReportConfig,
    )
  }
}

import * as Guards from './progress.model.type.guard'
import { Moment } from 'moment'
import { ProgressCategory } from './progress-category.enum'
import { ProgressType } from './progress-type.enum'

export class GenericProgressDto<T extends string | Moment> {
  id?: string
  type: ProgressType
  date: T
  count: number
  category: ProgressCategory
  unit: string | undefined
}

export class ProgressDto extends GenericProgressDto<Moment> {
  constructor(
    type: ProgressType,
    category: ProgressCategory,
    unit: string,
    count: number = undefined,
    date: Moment = undefined,
  ) {
    super()
    this.type = type
    this.category = category
    this.unit = this.category === ProgressCategory.COUNT ? undefined : unit
    this.count = count ? count : 0
    this.date = date
  }
}

/** @see {isProgressionBatchImportDto} ts-auto-guard:type-guard */
export interface ProgressionBatchImportDto {
  progress: ProgressDto[]
  type: ProgressType
  category: ProgressCategory
}

/** @see {isProgressUpdatePayload} ts-auto-guard:type-guard */
export interface ProgressUpdatePayload {
  old: ProgressDto
  new: ProgressDto
}

export { Guards }

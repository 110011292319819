import { ChangeDetectionStrategy, Component } from '@angular/core'
import { ServerInfosService } from '../../remote-services/server-infos.service'
import { forkJoin, Observable } from 'rxjs'
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { ServerInfosDto } from '../../remote-services/dtos/serverInfos.dto'

@Component({
  selector: 'server-info-dialog-container',
  template: `
    <server-info-dialog
      [serverInfos]="serverInfos$ | async"
      (closeEvent)="onClose()"
    ></server-info-dialog>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServerInfoDialogContainerComponent {
  serverInfos$: Observable<ServerInfosDto[]> = forkJoin([
    this.serverInfosService.getServerEquipmentInfo(),
    this.serverInfosService.getServerExportInfo(),
    this.serverInfosService.getServerGraphInfo(),
    this.serverInfosService.getServerJobsiteInfo(),
    this.serverInfosService.getServerPreparationInfo(),
    this.serverInfosService.getServerRestPilesInfo(),
    this.serverInfosService.getServerRestHFInfo(),
    this.serverInfosService.getServerSoillibInfo(),
    this.serverInfosService.getServerUserInfo(),
  ])
  constructor(
    private serverInfosService: ServerInfosService,
    private dialogRef: MatDialogRef<ServerInfoDialogContainerComponent>,
  ) {}

  onClose(): void {
    this.dialogRef.close()
  }
}

import { AsyncActionFactory } from 'app/shared/utils/redux/async.actions'
import { SyncActionFactory } from 'app/shared/utils/redux/sync.actions'
import { VectorRotationToolState } from './vector-rotation-tool.reducer'

export class VectorRotationToolActions {
  public static toggleVectorRotationTool = new SyncActionFactory<void>(
    '[VECTOR_ROTATION_TOOL] TOGGLE VECTOR ROTATION TOOL',
  )
  public static rotateVector = new AsyncActionFactory<
    {
      colId: string
    },
    Partial<VectorRotationToolState>
  >('[VECTOR_ROTATION_TOOL] ROTATE VECTOR')
}

import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { MachineDto } from './dtos/machine.dto'
import { TechniqueNames } from './dtos/technique.dto'

@Injectable()
export class MachineService {
  constructor(private http: HttpClient) {}

  public getMachinesByTechnique(
    techniqueName: TechniqueNames,
  ): Observable<MachineDto[]> {
    return this.http.get<MachineDto[]>(
      `equipment/resources/machines/${techniqueName}`,
    )
  }

  public getMachineIdsByJobsiteIds(jobsiteIds: string[]): Observable<string[]> {
    if (!jobsiteIds || jobsiteIds.length === 0) {
      return of(null)
    }
    return this.http.post<string[]>('equipment/resources/machines', jobsiteIds)
  }
}

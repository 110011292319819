import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { TechniqueNames } from './dtos/technique.dto'
import { WeeklyReportConfig } from '../../jobsites-management/jobsites-list/models/weekly-report-config.model'
import { WeeklyReportConfigDialogOutput } from '../../jobsites-management/jobsites-list/components/weekly-report/weekly-report-config-dialog.components'

@Injectable()
export class WeeklyReportConfigService {
  constructor(private http: HttpClient) {}

  private baseUrl = 'rest/'

  public get(
    technique: TechniqueNames,
    userId: string,
  ): Observable<WeeklyReportConfig> {
    return this.http
      .get<WeeklyReportConfig>(
        `${this.baseUrl}${technique}/weekly-report-config/user-id/${userId}`,
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status !== 404) {
            console.error(err)
          }
          return of({
            weeklyReportLang: 'en',
            weeklyReportUnitSystem: 'METRIC',
            weeklyReportHourOfDay: 18,
            weeklyReportDayOfWeek: 'FRIDAY',
            weeklyReportEmail: '',
            jobsiteIds: [],
          } as WeeklyReportConfig)
        }),
      )
  }

  public save(
    technique: TechniqueNames,
    config: WeeklyReportConfigDialogOutput,
  ): Observable<void> {
    return this.http.post<void>(
      `${this.baseUrl}${technique}/weekly-report-config`,
      config,
    )
  }
}

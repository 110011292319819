import { MomentInput } from 'moment'

export class Group {
  expanded = true
  totalCounts = 0
  date: MomentInput

  constructor(group: Group = null, isExpanded: boolean = null) {
    if (group !== null && isExpanded !== null) {
      this.totalCounts = group.totalCounts
      this.date = group.date
      this.expanded = isExpanded
    }
  }
}

import { TechniqueNames } from 'app/shared/remote-services/dtos/technique.dto'
import { AsyncActionFactory } from 'app/shared/utils/redux/async.actions'
import { JobsiteDto } from '../../../shared/remote-services/dtos/jobsite.dto'
import { SyncActionFactory } from '../../../shared/utils/redux/sync.actions'
import {
  CheckAvailabilityInput,
  CheckAvailabilityResponse,
} from '../../../jobsites-management/jobsite-creation/models/checkAvailability.model'
import { State } from './jobsite.reducer'
import { UUID } from '../../../shared/models/utils.type'

export class JobsiteActions {
  public static loadJobsite = new AsyncActionFactory<
    UUID,
    {
      techniqueId: string
      jobsite: JobsiteDto
    }
  >('[JOBSITE] LOAD_JOBSITE')

  public static updateJobsite = new AsyncActionFactory<
    JobsiteDto,
    {
      techniqueId: string
      jobsite: JobsiteDto
    }
  >('[JOBSITE] UPDATE JOBSITE')

  public static setJobsiteStatus = new AsyncActionFactory<
    string,
    {
      status: JobsiteDto['status']
      technique: TechniqueNames
      jobsiteId: string
    }
  >('[JOBSITE] SET JOBSITE STATUS')

  public static checkAvailability = new AsyncActionFactory<
    CheckAvailabilityInput,
    CheckAvailabilityResponse
  >('[JOBSITE] CHECK NAME & CONTRACT NUMBER AVAILABILITY')

  public static setLastCheckValues = new SyncActionFactory<
    State['lastCheckAvailabilityValues']
  >('[JOBSITE] SET LAST CHECK VALUES')

  public static setCheckResponse = new SyncActionFactory<
    State['checkAvailability']
  >('[JOBSITE] SET CHECK RESPONSE')

  public static initCheckValues = new SyncActionFactory<void>(
    '[JOBSITE] INIT CHECK VALUES',
  )

  public static resetJobsite = new SyncActionFactory<boolean>(
    '[JOBSITE] RESET JOBSITE',
  )

  public static removeDuplicatedPileNames = new SyncActionFactory<void>(
    '[JOBSITE] REMOVE DUPLICATED PILE NAMES',
  )
}

/*
 * Generated type guards for "technique.dto.ts".
 * WARNING: Do not manually change this file.
 */
import {
  TechniqueNames,
  TechniqueDto,
  FavoriteTechniqueDto,
} from './technique.dto'
import { ProgressCategory } from '../../../jobsites-management/jobsite-summary/models/progress/progress-category.enum'
import { isJobsiteStatusDto } from './jobsiteStatus.dto.type.guard'
import { isJobsiteDto } from './jobsite.dto.type.guard'

export function isTechniqueName(obj: unknown): obj is TechniqueNames {
  const typedObj = obj as TechniqueNames
  return typedObj === 'HF' || typedObj === 'PILES'
}

export function isTechniqueDto(obj: unknown): obj is TechniqueDto {
  const typedObj = obj as TechniqueDto
  return (
    ((typedObj !== null && typeof typedObj === 'object') ||
      typeof typedObj === 'function') &&
    typeof typedObj['id'] === 'string' &&
    (isTechniqueName(typedObj['name']) as boolean) &&
    (typeof typedObj['description'] === 'undefined' ||
      typeof typedObj['description'] === 'string') &&
    (typeof typedObj['status'] === 'undefined' ||
      typedObj['status'] === 'NEW' ||
      typedObj['status'] === 'DOING' ||
      typedObj['status'] === 'DONE') &&
    (typeof typedObj['progressCategory'] === 'undefined' ||
      typedObj['progressCategory'] === ProgressCategory.COUNT ||
      typedObj['progressCategory'] === ProgressCategory.LENGTH ||
      typedObj['progressCategory'] === ProgressCategory.AREA_SIMPLE) &&
    typeof typedObj['userFavorite'] === 'boolean' &&
    Array.isArray(typedObj['statuses']) &&
    typedObj['statuses'].every((e: any) => isJobsiteStatusDto(e) as boolean)
  )
}

export function isFavoriteTechniqueDto(
  obj: unknown,
): obj is FavoriteTechniqueDto {
  const typedObj = obj as FavoriteTechniqueDto
  return (
    ((typedObj !== null && typeof typedObj === 'object') ||
      typeof typedObj === 'function') &&
    (isJobsiteDto(typedObj['jobsite']) as boolean) &&
    typeof typedObj['isFavorite'] === 'boolean'
  )
}

import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { YieldPerDepth } from '../../jobsites-management/jobsite-summary/jobsite-soillib-data/models/jobsite-soillib-survey.model'

@Injectable()
export class YieldPerDepthService {
  constructor(private http: HttpClient) {}

  public getYieldPerDepthByColumnId(
    technique: string,
    columnId: string,
  ): Observable<YieldPerDepth[]> {
    return this.http.get<YieldPerDepth[]>(
      `rest/${technique}/yield-per-depth/${columnId}`,
    )
  }
}

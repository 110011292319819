import { Actions, createEffect } from '@ngrx/effects'
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { State } from '../state'
import { AsyncEffects } from '../../../shared/utils/redux/async.effects'
import { PlatformDisruptionsActions } from './platform-disruptions.actions'
import { PlatformDisruptionsService } from '../../../shared/remote-services/platform-disruptions.service'
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators'
import { onStart } from '../../../shared/utils/redux/operators'
import { of } from 'rxjs'

@Injectable()
export class PlatformDisruptionsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private effectFactory: AsyncEffects,
    private platformDisruptionsService: PlatformDisruptionsService,
  ) {}

  loadPlatformDisruptionMessage$ = createEffect(() =>
    this.effectFactory.asyncEffect(
      PlatformDisruptionsActions.loadPlatformDisruptionMessage,
      () => this.platformDisruptionsService.getLastActivePlatformDisruption(),
    ),
  )

  loadAllPlatformDisruptions$ = createEffect(() =>
    this.effectFactory.asyncEffect(
      PlatformDisruptionsActions.loadAllDisruptions,
      () => this.platformDisruptionsService.getAllPlatformDisruptions(),
    ),
  )

  createNewDisruption$ = createEffect(() =>
    this.actions$.pipe(
      onStart(PlatformDisruptionsActions.createNewDisruption),
      map(action => action.payload),
      switchMap(dto =>
        this.platformDisruptionsService.createPlatformDisruption(dto).pipe(
          mergeMap(_ => [
            PlatformDisruptionsActions.createNewDisruption.createComplete(),
            PlatformDisruptionsActions.loadPlatformDisruptionMessage.createStart(),
            PlatformDisruptionsActions.loadAllDisruptions.createStart(),
          ]),
          catchError((_: Error) =>
            of(PlatformDisruptionsActions.createNewDisruption.createFailed()),
          ),
        ),
      ),
    ),
  )

  closeDisruption$ = createEffect(() =>
    this.actions$.pipe(
      onStart(PlatformDisruptionsActions.closeDisruption),
      map(action => action.payload),
      switchMap(id =>
        this.platformDisruptionsService.closePlatformDisruption(id).pipe(
          mergeMap(_ => [
            PlatformDisruptionsActions.closeDisruption.createComplete(),
            PlatformDisruptionsActions.loadPlatformDisruptionMessage.createStart(),
            PlatformDisruptionsActions.loadAllDisruptions.createStart(),
          ]),
          catchError((_: Error) =>
            of(PlatformDisruptionsActions.closeDisruption.createFailed()),
          ),
        ),
      ),
    ),
  )
}

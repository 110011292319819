import { Injectable } from '@angular/core'
import { Actions, createEffect } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { onStart } from 'app/shared/utils/redux/operators'
import { map, switchMap } from 'rxjs/operators'
import { State } from '../state'
import { ProviderActions } from './provider.actions'
import { ProviderService } from '../../../shared/remote-services/provider.service'

@Injectable()
export class ProviderEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private service: ProviderService,
  ) {}

  loadProviders = createEffect(() =>
    this.actions$.pipe(
      onStart(ProviderActions.providerActions),
      switchMap(() => this.service.getAllProviders()),
      map(res => {
        return ProviderActions.providerActions.createAddItems(res)
      }),
    ),
  )
}

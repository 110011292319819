import { createSelector } from '@ngrx/store'
import { getFieldIdByName } from 'app/core/store/fields/fields.selectors'
import { getJobsiteId } from 'app/core/store/router/router.selectors'
import { getState, JobsiteSummaryState } from '../state'

export const getStats3dState = createSelector(
  getState,
  (state: JobsiteSummaryState) => state.columnsStats3d,
)

export const getParametersLoaded = createSelector(
  getStats3dState,
  state => state.loadedMetrics,
)

export const getLoadedDeviationOperators = (name: 'p_dx' | 'p_dy') =>
  createSelector(
    getParametersLoaded,
    getFieldIdByName(name),
    getJobsiteId,
    (loadedMetrics, fieldDxId, jobsiteId) => {
      if (loadedMetrics && loadedMetrics[jobsiteId] && fieldDxId) {
        return loadedMetrics[jobsiteId][fieldDxId]
      }
      return null
    },
  )

import { HFType } from 'app/shared/constants/hf-type.enum'
import { Process } from 'app/shared/constants/process.model'
import { SyncActionFactory } from 'app/shared/utils/redux/sync.actions'
import { ListActionFactory } from '../../../../shared/utils/redux/list/list.actions'
import { TreeFlatNode } from '../../models/columns-type-tree.model'
import {
  BackgroundType,
  MapSettingsModel,
  SceneType,
  TaggedAsDoneFilter,
} from '../../models/map-settings.model'
import { ThreeDSceneSettingsModel } from '../../models/three-d-scene-settings.model'

export class MapSettingsActions {
  public static updateMapSettings = new SyncActionFactory<
    {
      id: string
    } & Partial<MapSettingsModel>
  >('[JOBSITE MAP] UPDATE MAP SETTINGS')

  public static updateRadius = new SyncActionFactory<number>(
    '[JOBSITE MAP] UPDATE RADIUS',
  )

  public static updatePosition = new SyncActionFactory<{
    zoom: number
    center: [number, number]
    rotation: number
  }>('[JOBSITE MAP] UPDATE POSITION')

  public static updateTypeTreeVisibility = new ListActionFactory<TreeFlatNode>(
    '[JOBSITE MAP] UPDATE VISIBILITY OF TYPE TREE',
  )

  public static updateVisibleProcessEntries = new SyncActionFactory<
    [process: Process, visible: boolean]
  >('[JOBSITE MAP] UPDATE VISIBLE PROCESS ENTRIES')

  public static updateTaggedAsDoneFilter =
    new SyncActionFactory<TaggedAsDoneFilter>(
      '[JOBSITE MAP] UPDATE TAGGED AS DONE FILTER',
    )

  public static updateSceneType = new SyncActionFactory<SceneType>(
    '[JOBSITE MAP] UPDATE SCENE TYPE',
  )

  public static updateBackgroundType = new SyncActionFactory<BackgroundType>(
    '[JOBSITE MAP] UPDATE BACKGROUND TYPE',
  )

  public static update3DSettings =
    new SyncActionFactory<ThreeDSceneSettingsModel>(
      '[JOBSITE MAP] UPDATE 3D SETTINGS',
    )

  public static updateIdsInMap = new SyncActionFactory<
    MapSettingsModel['pilesExtentProfile']
  >('[JOBSITE MAP] UPDATE COLUMN IDS INSIDE MAP')

  public static updateActiveLayer = new SyncActionFactory<HFType[]>(
    '[JOBSITE MAP] UPDATE ACTIVE LAYER',
  )

  public static resetActiveLayer = new SyncActionFactory<void>(
    '[JOBSITE MAP] RESET ACTIVE LAYER',
  )
}

import * as Guards from './user.model.type.guard'
import { Permissions } from './permissions.model'
import { TechniqueNames } from '../../shared/remote-services/dtos/technique.dto'

/** @see {isUserModel} ts-auto-guard:type-guard */
export interface UserModel {
  id: string
  name: string
  country: string
  permissions: Permissions
  companyName: string
  roCompanyNames: string[]
  env: string
  applications: ApplicationModel[]
}

/** @see {isApplicationModel} ts-auto-guard:type-guard */
export class ApplicationModel {
  name: string
  dependsOnTechnique: boolean
  url: string
  techniques?: TechniqueNames[] = []
  externalUrl?: boolean
}

export { Guards }

import { SyncActionFactory } from 'app/shared/utils/redux/sync.actions'
import { ListActionFactory } from '../../../../shared/utils/redux/list/list.actions'
import { DtoPileTaggedAsDone } from '../models/pile-tagged-as-done.model'
import { AsyncActionFactory } from '../../../../shared/utils/redux/async.actions'

export class PileTaggedAsDoneActions {
  public static loadPilesTaggedAsDone = new ListActionFactory<
    DtoPileTaggedAsDone,
    void
  >('[PILE TAGGED AS DONE] LOAD PILES TAGGED AS DONE')

  public static clickTagAsDone = new SyncActionFactory<string>(
    '[PILE TAGGED AS DONE] CLICK TAG AS DONE',
  )

  public static openAddModal = new SyncActionFactory<string>(
    '[PILE TAGGED AS DONE] OPEN ADD DIALOG',
  )

  public static saveTaggedAsDone = new AsyncActionFactory<DtoPileTaggedAsDone>(
    '[PILE TAGGED AS DONE] SAVE PILE TAGGED AS DONE',
  )

  public static deleteTaggedAsDone =
    new AsyncActionFactory<DtoPileTaggedAsDone>(
      '[PILE TAGGED AS DONE] DELETE PILE TAGGED AS DONE',
    )
}

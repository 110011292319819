import { reducerFromActionHandlers } from '../utils.reducers'
import { LoadableMapActionsFactory } from './loadable-map.actions'
import { LoadableMapState } from './loadable-map.state'

export function createLoadableMapReducer<Item>(
  actionFactory: LoadableMapActionsFactory<Item>,
) {
  return reducerFromActionHandlers<
    LoadableMapState<Item>,
    LoadableMapActionsFactory<Item>
  >({}, [
    {
      actionType: actionFactory.RESET,
      handler: () => ({}),
    },
    {
      actionType: actionFactory.START,
      handler: (
        state,
        action: ReturnType<(typeof actionFactory)['createStart']>,
      ) => {
        const stateCopy = { ...state }
        action.payload.forEach(
          key =>
            (stateCopy[key] = {
              value: undefined,
              loading: true,
              loaded: false,
            }),
        )
        return { ...stateCopy }
      },
    },

    {
      actionType: actionFactory.SET_ITEM,
      handler: (
        state,
        action: ReturnType<(typeof actionFactory)['setItem']>,
      ) => {
        const stateCopy = { ...state }
        stateCopy[action.payload.key] = {
          value: action.payload.value,
          loading: false,
          loaded: true,
        }
        return { ...stateCopy }
      },
    },
  ])
}

export class ServerInfosDto {
  build: BuildInfosDto
  env: EnvInfosDto
  git?: GitInfosDto
}

export class BuildInfosDto {
  name: string | null
  version: string | null
  group?: string | null
  artifact?: string | null
  time?: Date | null

  constructor(name: string | null, version: string | null = null) {
    this.name = name
    this.version = version
  }
}

export class GitInfosDto {
  branch: string | null
  commit: string | null
  commit_time: Date | null

  constructor(
    branch: string | null,
    commit: string | null,
    commitTime: Date | null,
  ) {
    this.branch = branch
    this.commit = commit
    this.commit_time = commitTime
  }
}

export class EnvInfosDto {
  profiles: string[]

  constructor(profiles: string[] = []) {
    this.profiles = profiles
  }
}

import { createSelector } from '@ngrx/store'
import { EntitiesSelectorFactory } from 'app/shared/utils/redux/entities/entities.selectors'
import { State } from '../../../core/store/state'
import { getState, UploaderFilesState } from './state'

export const filesSelectors = new EntitiesSelectorFactory(
  getState,
  (state: UploaderFilesState) => state.files,
)

export const getFiles = createSelector(filesSelectors.getAll, files =>
  files
    ? files.sort((a, b) => {
        if (!a.loaded || !b.loaded) {
          if (a.loaded !== b.loaded) {
            return a.loaded ? 1 : -1
          } else if (a.progress !== b.progress) {
            return a.progress > b.progress ? -1 : 1
          }
        }
        return 0
      })
    : [],
)

export const isExpanded = createSelector<State, [UploaderFilesState], boolean>(
  getState,
  (state: UploaderFilesState) => state.isExpanded,
)

export const isVisible = createSelector<State, [UploaderFilesState], boolean>(
  getState,
  (state: UploaderFilesState) => state.isVisible,
)

export const getNbWaitingFiles = createSelector(filesSelectors.getAll, files =>
  files ? files.filter(file => !file.loaded && !file.inError).length : 0,
)

export const isUploading = createSelector(filesSelectors.getAll, files =>
  files ? files.some(file => !file.loaded && !file.inError) : false,
)

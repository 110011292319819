import { ListSelectorFactory } from '../../../../shared/utils/redux/list/list.selectors'
import { getState, SoillibDataState } from './state'
import { LoadableSelectorFactory } from '../../../../shared/utils/redux/loadable/loadable.selectors'
import { createSelector } from '@ngrx/store'
import { getNotEnoughCoordinates } from '../../store/columns-stats/columns-stats.selectors'
import { getJobsiteEmptyLocation } from '../../../../core/store/jobsite'
import { getSelectedTab } from '../../store/jobsite-summary.selectors'
import { getTechnique } from '../../../../core/store/router/router.selectors'
import { TechniqueNames } from '../../../../shared/remote-services/dtos/technique.dto'
import { Jobsite, SurveyData } from '@sde-ild/ssd-soillib-lib'
import { JobsiteTab } from '../../models/jobsite-tabs.model'
import { SoillibSurveyWithData } from '../models/jobsite-soillib-survey.model'

export const getSoillibJobsites = new ListSelectorFactory(
  getState,
  (state: SoillibDataState) => state.jobsites,
)

export const getSoilStats = new LoadableSelectorFactory(
  getState,
  (state: SoillibDataState) => state.soilStats,
)

export const getSoillibJobsite = new LoadableSelectorFactory(
  getState,
  (state: SoillibDataState) => state.jobsite,
)

export const getSuggestedSoillibJobsite = (jobsiteName: string) =>
  createSelector(getSoillibJobsites.getAll, (jobsites: Jobsite[]) => {
    return jobsiteName != null && jobsiteName != ''
      ? jobsites.filter(jobsite =>
          jobsite.nickname?.toLowerCase().includes(jobsiteName.toLowerCase()),
        )
      : []
  })

export const getSoillibJobsiteSurveys = new ListSelectorFactory(
  getState,
  (state: SoillibDataState) => state.jobsiteSoilSurveys,
)

export const getSurveysData = new LoadableSelectorFactory(
  getState,
  (state: SoillibDataState) => state.surveysData,
)

export const getSurveysWithData = createSelector(
  getSoillibJobsiteSurveys.getAll,
  getSurveysData.getValue,
  (surveys, surveysData: Map<string, SurveyData[]>) => {
    return surveys
      .map(survey => {
        const data = surveysData?.[survey.id]
        const result: SoillibSurveyWithData = {
          ...survey,
          data,
        }
        return result
      })
      .filter(survey => survey.data != null)
  },
)

export const getDisplaySoillibSurveyOnMap = createSelector(
  getNotEnoughCoordinates,
  getJobsiteEmptyLocation,
  getSelectedTab,
  getTechnique,
  (
    notEnoughCoordinates: boolean,
    emptyLocation: boolean,
    selectedTab: JobsiteTab,
    currentTechnique: TechniqueNames,
  ) =>
    selectedTab === 'soillib-data' &&
    !emptyLocation &&
    (currentTechnique !== 'HF' ? !notEnoughCoordinates : true),
)

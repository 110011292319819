import { Component, Input } from '@angular/core'
import { JobsiteStatusDto } from '../remote-services/dtos/jobsiteStatus.dto'
import moment from 'moment'

@Component({
  selector: 'jobsite-status-history',
  template: `
    <table mat-table [dataSource]="statuses" class="flex-fill">
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'JOBSITE_STATUS_HISTORY.TABLE.DATE' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ formatDate(element.date) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'JOBSITE_STATUS_HISTORY.TABLE.STATUS' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.status }}</td>
      </ng-container>

      <ng-container matColumnDef="note">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'JOBSITE_STATUS_HISTORY.TABLE.NOTE' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.note }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  `,
  styles: [],
})
export class JobsiteStatusHistoryComponent {
  @Input() statuses: JobsiteStatusDto[]

  displayedColumns: string[] = ['date', 'status', 'note']

  formatDate(date: string): string {
    return moment(date).format('DD-MM-YYYY HH:mm')
  }
}

import { Injectable } from '@angular/core'
import { AbstractLoadableGuard } from '../../shared/utils/abstract-loadable.guard'
import { State } from '../../jobsites-management/jobsite-summary/jobsite-soillib-data/store/state'
import { Store } from '@ngrx/store'
import { JobsiteSoillibDataActions } from '../../jobsites-management/jobsite-summary/jobsite-soillib-data/store/jobsite-soillib-data.actions'
import { getSoillibJobsites } from '../../jobsites-management/jobsite-summary/jobsite-soillib-data/store/jobsite-soillib-data.selector'

@Injectable()
export class SoillibJobsitesGuard extends AbstractLoadableGuard<State> {
  constructor(store: Store<State>) {
    super(
      store,
      getSoillibJobsites.getLoaded,
      JobsiteSoillibDataActions.loadSoillibJobsites,
    )
  }
}

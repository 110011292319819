import {
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { finalize } from 'rxjs/operators'
import { State } from '../store'
import { LoaderActions } from '../store/loader/loader.actions'

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private store: Store<State>) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<
    | HttpSentEvent
    | HttpHeaderResponse
    | HttpProgressEvent
    | HttpResponse<unknown>
    | HttpUserEvent<unknown>
  > {
    this.store.dispatch(LoaderActions.start.create())
    return next
      .handle(req)
      .pipe(finalize(() => this.store.dispatch(LoaderActions.stop.create())))
  }
}

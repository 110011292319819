import { Injectable } from '@angular/core'
import { MatomoTracker } from '@ngx-matomo/tracker'
import { MatomoCustomDimensions } from '../constants/matomo/matomo-custom-dimensions.enum'
import {
  TrackAction,
  TrackActionBuilder,
  TrackingData,
} from '../constants/matomo/tracking.model'
import { isMatomoCustomDimensions } from '../constants/matomo/matomo-custom-dimensions.enum.type.guard'

@Injectable({
  providedIn: 'root',
})
export class MatomoAnalyticsService {
  constructor(private matomoTracker: MatomoTracker) {}

  /*
  Track visited components, providing information on the page consulted by the user.
  this function will be called automatically when changing route
   */
  public trackVisit(
    pageTitle: string,
    { businessUnit, jobsiteId, techniqueName, userId }: TrackingData,
  ): void {
    this.matomoTracker.setUserId(userId)
    this.resetAllCustomDimensions()
    this.matomoTracker.setCustomDimension(
      MatomoCustomDimensions.VISIT_USER_ID,
      userId,
    )
    this.matomoTracker.setCustomDimension(
      MatomoCustomDimensions.VISIT_BUSINESS_UNIT,
      businessUnit,
    )
    this.matomoTracker.setCustomDimension(
      MatomoCustomDimensions.ACTION_USER_ID,
      userId,
    )
    this.matomoTracker.setCustomDimension(
      MatomoCustomDimensions.ACTION_BUSINESS_UNIT,
      businessUnit,
    )
    if (techniqueName) {
      this.matomoTracker.setCustomDimension(
        MatomoCustomDimensions.ACTION_TECHNIQUE_NAME,
        techniqueName,
      )
    }
    if (jobsiteId) {
      this.matomoTracker.setCustomDimension(
        MatomoCustomDimensions.ACTION_JOBSITE_ID,
        jobsiteId,
      )
    }
    this.matomoTracker.setDocumentTitle(pageTitle)
  }

  /*
    Tracks specific user interactions, such as button clicks, form submissions, downloading a file or open link
    The data argument is optional and contains the personalized dimensions to send according to our needs during trackAction
    withTrackingData(trackingData: TrackingData):  Here, we send the TrackingData object containing (userId, businessUnit, techniqueName, jobsiteId).
    addCustomDimension: Contains additional custom dimensions to be sent with actions as required.(for example : data)

     Example usage :
                trackAction(
                  TrackAction.getBuilder()
                    .withEventAction(eventAction)
                    .withEventLabel(eventLabel)
                    .withEventCategory(eventCategory)
                    .withTrackingData(trackingData)
                    .addCustomDimension(
                      MatomoCustomDimensions.ACTION_DATA,
                      value,
                    ),
                )
     */
  public trackAction(action: TrackActionBuilder): void {
    const { eventAction, eventCategory, eventLabel, userId, customDimensions } =
      TrackAction.build(action)
    this.matomoTracker.setUserId(userId)
    this.resetAllCustomDimensions()
    Object.entries(customDimensions).forEach(([key, value]) => {
      const dimensionKey = parseInt(key)
      if (isMatomoCustomDimensions(dimensionKey)) {
        this.matomoTracker.setCustomDimension(dimensionKey, value)
      }
    })
    this.matomoTracker.trackEvent(eventCategory, eventAction, eventLabel)
  }

  /*
    To solve the problem where custom dimensions retain their old values when not present when moving to a new route,
    you need to reset or delete these custom dimensions before updating them with the new values.
   */
  private resetAllCustomDimensions(): void {
    const allDimensions = Object.values(MatomoCustomDimensions)
    allDimensions.forEach((dimension: number) => {
      this.matomoTracker.deleteCustomDimension(dimension)
    })
  }
}

import { InjectionToken } from '@angular/core'
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store'
import * as core from '../../../core/store'
import { createEntitiesReducer } from '../../../shared/utils/redux/entities/entities.reducer'
import { EntitiesState } from '../../../shared/utils/redux/entities/entities.state'
import { createValueReducer } from '../../../shared/utils/redux/utils.reducers'
import { UploadFileModel } from '../models/upload-file.model'
import { FilesUploadActions } from './files-upload.actions'

export interface State extends core.State {
  uploaderFiles: UploaderFilesState
}

export interface UploaderFilesState {
  files: EntitiesState<UploadFileModel>
  isExpanded: boolean
  isVisible: boolean
}

const reducers: ActionReducerMap<UploaderFilesState> = {
  files: createEntitiesReducer(FilesUploadActions.files),
  isExpanded: createValueReducer(false, FilesUploadActions.setIsExpanded),
  isVisible: createValueReducer(false, FilesUploadActions.setVisible),
}

export const getState =
  createFeatureSelector<UploaderFilesState>('uploaderFiles')

export const reducerInjecteur = new InjectionToken(
  'Uploader Files Registered Reducers',
)

function getReducers(): ActionReducerMap<UploaderFilesState> {
  return reducers
}

export const reducerProvider = [
  { provide: reducerInjecteur, useFactory: getReducers },
]

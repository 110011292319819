import { TypedAction } from 'app/shared/utils/redux/loadable/loadable.actions'
import { MapVisibilityEnum } from '../../../../shared/constants/map-visibility.enum'
import { MapVisibilityActions } from './map-visibility.actions'

export const initialState = MapVisibilityEnum.DEFAULT

export function reducer(
  state = initialState,
  action: TypedAction<MapVisibilityEnum>,
): MapVisibilityEnum {
  switch (action.type) {
    case MapVisibilityActions.expandMap.CREATE: {
      if (state === MapVisibilityEnum.EXPANDED) {
        return state
      }
      return state === MapVisibilityEnum.DEFAULT
        ? MapVisibilityEnum.EXPANDED
        : MapVisibilityEnum.DEFAULT
    }
    case MapVisibilityActions.collapseMap.CREATE: {
      if (state === MapVisibilityEnum.COLLAPSED) {
        return state
      }
      return state === MapVisibilityEnum.DEFAULT
        ? MapVisibilityEnum.COLLAPSED
        : MapVisibilityEnum.DEFAULT
    }
    case MapVisibilityActions.setMapVisibility.CREATE: {
      return action.payload
    }
  }
  return state
}

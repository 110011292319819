import { Injectable } from '@angular/core'
import { TechniqueNames } from '../remote-services/dtos/technique.dto'
import { DateFormatDelimiter } from '../components/planned-progress-edit-csv-config.dialog'
import { UserPreferencesDto } from '../remote-services/dtos/userPreferences.dto'
import { DATE_FORMATS } from '../models/date-time-format.model'

@Injectable()
export class CsvDateFormatLocalStorageService {
  getLsKey(jobsiteId: string, techniqueName: TechniqueNames) {
    return `${techniqueName}-${jobsiteId}-csv-import-date-format`
  }

  public saveToLocalStorage(
    dateFormat: DateFormatDelimiter,
    jobsiteId: string,
    technique: TechniqueNames,
  ) {
    localStorage.setItem(
      this.getLsKey(jobsiteId, technique),
      JSON.stringify(dateFormat),
    )
  }

  loadFromLocalStorageOrDefault(
    userDateFormat: UserPreferencesDto['dateFormat'],
    jobsiteId: string,
    technique: TechniqueNames,
  ): DateFormatDelimiter {
    const storedDateFormat = localStorage.getItem(
      this.getLsKey(jobsiteId, technique),
    )

    let dateFormat = JSON.parse(storedDateFormat) as DateFormatDelimiter

    if (dateFormat) {
      if (dateFormat.delimiter?.length !== 1) {
        dateFormat.delimiter = '/'
      }
      if (!DATE_FORMATS.includes(dateFormat.format)) {
        dateFormat.format = userDateFormat === 'DD-MM-YYYY' ? 'FR' : 'EN'
      }
    } else {
      dateFormat = {
        delimiter: '/',
        format: userDateFormat === 'DD-MM-YYYY' ? 'FR' : 'EN',
      }
    }
    return dateFormat
  }
}

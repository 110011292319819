import { Action } from '@ngrx/store'
import { TypedAction } from './loadable/loadable.actions'

export class AsyncActionFactory<StartPayload, CompletePayload = StartPayload> {
  get START(): string {
    return this.name + '.START'
  }

  get COMPLETE(): string {
    return this.name + '.COMPLETE'
  }

  get FAILED(): string {
    return this.name + '.FAILED'
  }

  public createStart(payload: StartPayload): TypedAction<StartPayload> {
    return { type: this.START, payload }
  }

  public createComplete(
    payload: CompletePayload,
  ): TypedAction<CompletePayload> {
    return { type: this.COMPLETE, payload }
  }

  public createFailed(): Action {
    return { type: this.FAILED }
  }

  constructor(protected name: string) {}
}

import { Pipe, PipeTransform } from '@angular/core'
import { LocalizedDateService } from '../services/localized-date.service'
import { MomentInput } from 'moment'

@Pipe({
  name: 'localizedValue',
  pure: false,
})
export class LocalizedValuePipe implements PipeTransform {
  constructor(private service: LocalizedDateService) {}

  transform(value: MomentInput, pattern?: string): string {
    return this.service.getFormattedValue(value, pattern)
  }
}

@Pipe({
  name: 'localizedDate',
  pure: false,
})
export class LocalizedDatePipe implements PipeTransform {
  constructor(private service: LocalizedDateService) {}

  transform(value: MomentInput): string {
    return this.service.getFormattedDate(value)
  }
}

@Pipe({
  name: 'localizedTime',
  pure: false,
})
export class LocalizedTimePipe implements PipeTransform {
  constructor(private service: LocalizedDateService) {}

  transform(value: MomentInput): string {
    return this.service.getFormattedTime(value)
  }
}

@Pipe({
  name: 'localizedDateTime',
  pure: false,
})
export class LocalizedDateTimePipe implements PipeTransform {
  constructor(private service: LocalizedDateService) {}

  transform(value: MomentInput): string {
    return this.service.getFormattedDateTime(value)
  }
}

import { ActionReducer } from '@ngrx/store'
import { AsyncActionFactory } from './async.actions'
import { TypedAction } from './loadable/loadable.actions'
import { SyncActionFactory } from './sync.actions'

interface ActionHandler<State, Payload> {
  actionType: string
  handler: (state: State, action: TypedAction<Payload>) => State
}

interface SyncActionHandler<State, A extends SyncActionFactory<P>, P> {
  action: A
  handleCreate: (state: State, action: TypedAction<P>) => State
}

interface AsyncActionHandler<
  State,
  A extends AsyncActionFactory<SP, CP>,
  SP,
  CP,
> {
  action: A
  handleStart: (state: State, action: TypedAction<SP>) => State
  handleComplete: (state: State, action: TypedAction<CP>) => State
  handleFailed: (state: State) => State
}

interface LoadableActionHandler<
  State,
  A extends AsyncActionFactory<SP, CP>,
  SP,
  CP,
> extends AsyncActionHandler<State, A, SP, CP> {
  handleReset: (state: State) => State
}

export function reducerFromActionHandlers<S, P>(
  initialState: S,
  actionHandlers: ActionHandler<S, any>[],
): ActionReducer<S, TypedAction<P>> {
  const actionHandlerFunctionByType = actionHandlers.reduce<
    Record<string, ActionHandler<S, any>['handler']>
  >((acc, actionHandler) => {
    acc[actionHandler.actionType] = actionHandler.handler
    return acc
  }, {})
  return function handleAction(
    state: S = initialState,
    action: TypedAction<P>,
  ) {
    if (typeof actionHandlerFunctionByType[action.type] === 'function') {
      return actionHandlerFunctionByType[action.type](state, action)
    } else {
      return state
    }
  }
}

export function createValueReducer<Item>(
  initialState: Item,
  actionFactory: SyncActionFactory<Item>,
) {
  return reducerFromActionHandlers<Item, Item>(initialState, [
    {
      actionType: actionFactory.CREATE,
      handler: (state, action: ReturnType<(typeof actionFactory)['create']>) =>
        action.payload,
    },
    { actionType: actionFactory.RESET_VALUE, handler: () => initialState },
  ])
}

export function createDefaultValueReducer<Item>(
  actionFactory: SyncActionFactory<Item>,
) {
  return createValueReducer(undefined, actionFactory)
}

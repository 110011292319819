import { EntitiesActionFactory } from '../../../shared/utils/redux/entities/entities.actions'
import {
  ConcreteDelivery,
  ConcreteTypeDefinition,
} from '../../../shared/remote-services/dtos/concrete.dto'

export class ConcreteActions {
  public static concreteTypeDefActions = new EntitiesActionFactory<
    ConcreteTypeDefinition,
    void
  >('[CORE] CONCRETE_TYPE_DEF_ACTIONS')

  public static concreteDeliveryActions = new EntitiesActionFactory<
    ConcreteDelivery,
    void
  >('[CORE] CONCRETE_DELIVERY_ACTIONS')
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core'
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms'
import { TypedChanges } from '../../../../../shared/utils/TypedChange'
import { ZlHelperComponentOptions } from '../../../../../shared/models/zl-helper.model'
import {
  JobsiteChecksSettingsControls,
  JobsiteChecksSettingsDto,
  JobsiteChecksWithValueControls,
} from '../../../../../shared/remote-services/dtos/jobsite-checks.model'

@Component({
  selector: 'warning-checks-settings',
  templateUrl: './warning-checks-settings.component.html',
  styles: [
    `
      mat-slide-toggle {
        margin-left: 10px;
      }
      zl-helper {
        margin-right: 10px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WarningChecksSettingsComponent implements OnInit, OnChanges {
  @Input() data: JobsiteChecksSettingsDto

  @Output() saveEvent = new EventEmitter<JobsiteChecksSettingsDto>()
  @Output() closeEvent = new EventEmitter<void>()
  @Output() resetEvent = new EventEmitter<JobsiteChecksSettingsDto>()

  public form: FormGroup<JobsiteChecksSettingsControls>
  public options: ZlHelperComponentOptions = {
    greyBackground: true,
    iconSize: 'MEDIUM',
    marginBottom: null,
    marginLeft: '10px',
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      overBreakCheck: this.getCheckFormGroup(
        this.data.overBreakCheck.checkValue,
        this.data.overBreakCheck.checkEnabled,
        0,
        100,
      ),
      depthComparisonCheck: this.getCheckFormGroup(
        this.data.depthComparisonCheck.checkValue,
        this.data.depthComparisonCheck.checkEnabled,
        0,
        100,
      ),
      depthCheck: this.getCheckFormGroup(
        this.data.depthCheck.checkValue,
        this.data.depthCheck.checkEnabled,
      ),
      diameterCheckEnable: new FormControl(
        this.data.diameterCheck.checkEnabled,
        [Validators.required],
      ),
    })
  }

  ngOnChanges(
    changes: TypedChanges<{
      data: JobsiteChecksSettingsDto
    }>,
  ): void {
    if (changes && changes.data) {
      this.form
        ?.get('overBreakCheck')
        .get('checkValue')
        .setValue(this.data.overBreakCheck.checkValue)
      this.form
        ?.get('overBreakCheck')
        .get('checkEnabled')
        .setValue(this.data.overBreakCheck.checkEnabled)
      this.form
        ?.get('depthComparisonCheck')
        .get('checkValue')
        .setValue(this.data.depthComparisonCheck.checkValue)
      this.form
        ?.get('depthComparisonCheck')
        .get('checkEnabled')
        .setValue(this.data.depthComparisonCheck.checkEnabled)
      this.form
        ?.get('depthCheck')
        .get('checkValue')
        .setValue(this.data.depthCheck.checkValue)
      this.form
        ?.get('depthCheck')
        .get('checkEnabled')
        .setValue(this.data.depthCheck.checkEnabled)
      this.form
        ?.get('diameterCheckEnable')
        .setValue(this.data.diameterCheck.checkEnabled)
    }
  }

  onSave(): void {
    const rawValue = this.form.getRawValue()
    this.saveEvent.emit({
      id: this.data.id,
      depthCheck: rawValue.depthCheck,
      depthComparisonCheck: rawValue.depthComparisonCheck,
      overBreakCheck: rawValue.overBreakCheck,
      diameterCheck: {
        checkEnabled: rawValue.diameterCheckEnable,
      },
    })
  }

  private getCheckFormGroup(
    defaultValue: number,
    defaultEnable: boolean,
    valueMin?: number,
    valueMax?: number,
  ): FormGroup<JobsiteChecksWithValueControls> {
    const valueValidators: ValidatorFn[] = [Validators.required]
    if (valueMin != null) {
      valueValidators.push(Validators.min(valueMin))
    }
    if (valueMax != null) {
      valueValidators.push(Validators.max(valueMax))
    }
    return new FormGroup({
      checkValue: new FormControl(defaultValue, valueValidators),
      checkEnabled: new FormControl(defaultEnable, [Validators.required]),
    })
  }
}

<form class="flex-layout-column flex-gap-30px" [formGroup]="form">
  <h1 class="flex-1">
    {{ 'AS_BUILT_CHECKS.TITLE' | translate }}
  </h1>

  <div class="flex-1 flex-layout-column">
    <warning-checks-form-row
      [inputLabel]="'AS_BUILT_CHECKS.OVERBREAK_CHECK_THRESHOLD' | translate"
      [helperText]="
        'AS_BUILT_CHECKS.OVERBREAK_CHECK_HELPER'
          | translate
            : { value: form.controls.overBreakCheck.value.checkValue ?? 'N/A' }
      "
      [parentFormGroup]="form?.controls?.overBreakCheck"
      [minValue]="0"
      [maxValue]="100"
    ></warning-checks-form-row>

    <warning-checks-form-row
      [inputLabel]="
        'AS_BUILT_CHECKS.DEPTH_COMPARISON_CHECK_THRESHOLD' | translate
      "
      [helperText]="
        'AS_BUILT_CHECKS.DEPTH_COMPARISON_CHECK_HELPER'
          | translate
            : {
                value:
                  form.controls.depthComparisonCheck.value.checkValue ?? 'N/A'
              }
      "
      [parentFormGroup]="form?.controls?.depthComparisonCheck"
      [minValue]="0"
      [maxValue]="100"
    ></warning-checks-form-row>

    <warning-checks-form-row
      [inputLabel]="'AS_BUILT_CHECKS.DEPTH_CHECK_THRESHOLD' | translate"
      [helperText]="
        'AS_BUILT_CHECKS.DEPTH_CHECK_HELPER'
          | translate
            : { value: form.controls.depthCheck.value.checkValue ?? 'N/A' }
      "
      [parentFormGroup]="form?.controls?.depthCheck"
    ></warning-checks-form-row>

    <mat-slide-toggle
      class="flex-1 flex-align-end--center"
      formControlName="diameterCheckEnable"
      color="primary"
      labelPosition="before"
    >
      <div class="flex flex-wrap">
        <span>
          {{ 'AS_BUILT_CHECKS.DIAMETER_CHECK' | translate }}
        </span>

        <zl-helper
          matSuffix
          [popoverText]="'AS_BUILT_CHECKS.DIAMETER_CHECK_HELPER' | translate"
          [options]="options"
        ></zl-helper>
      </div>
    </mat-slide-toggle>
  </div>

  <div class="flex-1 flex flex-align-center--center flex-gap-10px">
    <button
      mat-raised-button
      color="accent"
      type="button"
      (click)="closeEvent.emit()"
    >
      {{ 'GENERAL.CANCEL' | translate }}
    </button>
    <button
      *ngIf="data.id != null"
      mat-raised-button
      color="warn"
      type="submit"
      (click)="resetEvent.emit(data)"
    >
      {{ 'GENERAL.RESET' | translate }}
    </button>
    <button
      mat-raised-button
      color="primary"
      type="submit"
      (click)="onSave()"
      [ngClass]="{ disabled: !this.form.valid }"
      [disabled]="!this.form.valid"
    >
      {{ 'GENERAL.SAVE' | translate }}
    </button>
  </div>
</form>

import * as Guards from './progress-type.enum.type.guard'

/** @see {isProgressType} ts-auto-guard:type-guard */
export enum ProgressType {
  CURRENT = 'CURRENT',
  PLANNED = 'PLANNED',
  PLANNED_CUSTOMER = 'PLANNED_CUSTOMER',
}

export { Guards }

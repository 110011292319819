import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { UUID } from '../../shared/models/utils.type'
import {
  CompoundParamDto,
  CompoundParamDtoEdit,
} from '../models/compoundParameter.model'

@Injectable()
export class CompoundParamsService {
  constructor(private http: HttpClient) {}

  private baseUrl = 'rest/compoundparams'

  public getAllCompoundParams(
    techniqueName: string,
  ): Observable<CompoundParamDto[]> {
    return this.http.get<CompoundParamDto[]>(
      `${this.baseUrl}/all/${techniqueName}`,
    )
  }

  public getCompoundParamById(id: UUID): Observable<CompoundParamDto> {
    return this.http.get<CompoundParamDto>(`${this.baseUrl}/${id}`)
  }

  public createCompoundParam(
    dto: Omit<CompoundParamDtoEdit, 'id' | 'userId'>,
  ): Observable<Omit<CompoundParamDtoEdit, 'userId'>> {
    return this.http.post<string>(`${this.baseUrl}/add`, dto).pipe(
      map(id => ({
        ...dto,
        id,
      })),
    )
  }

  public updateCompoundParam(dto: CompoundParamDtoEdit): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/${dto.id}`, dto)
  }

  public deleteCompoundParm(dto: CompoundParamDtoEdit): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${dto.id}`)
  }
}

import { JobsiteService } from './jobsite.service'
import { MachineService } from './machine.service'
import { FieldService } from './field.service'
import { UserPreferencesService } from './user-preferences.service'
import { UserService } from './user.service'
import { CageService } from './cage.service'
import { ConcreteService } from './concrete.service'
import { ProcessesService } from './processes.service'
import { ProviderService } from './provider.service'
import { YieldPerDepthService } from './yieldPerDepth.service'
import { JobsiteChecksService } from './jobsite-checks.service'
import { ServerInfosService } from './server-infos.service'
import { PlatformDisruptionsService } from './platform-disruptions.service'
import { WeeklyReportConfigService } from './weekly-report-config.service'
import { JobsiteStatsService } from './jobsiteStats.service'

export const remoteServices = [
  CageService,
  ConcreteService,
  FieldService,
  JobsiteService,
  JobsiteChecksService,
  MachineService,
  ProcessesService,
  ProviderService,
  ServerInfosService,
  UserService,
  UserPreferencesService,
  YieldPerDepthService,
  PlatformDisruptionsService,
  WeeklyReportConfigService,
  JobsiteStatsService,
]

import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { TechniqueNames } from './dtos/technique.dto'
import { JobsiteStatsDto } from './dtos/jobsiteStats.dto'

@Injectable()
export class JobsiteStatsService {
  constructor(private http: HttpClient) {}

  private baseUrl = 'rest'

  public get(
    techniqueName: TechniqueNames,
    jobsiteId: string,
  ): Observable<JobsiteStatsDto> {
    return this.http.get<JobsiteStatsDto>(
      `${this.baseUrl}/${techniqueName}/jobsites-stats/${jobsiteId}`,
    )
  }
}

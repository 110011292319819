import { Coordinate } from 'ol/coordinate'
import { toLonLat } from 'ol/proj'
import { getDistance } from 'ol/sphere'

export function parseExtentString(extent: string): Coordinate[] {
  const coordString = extent.match(/\(\(([^)]+)\)\)/)?.[1]
  return coordString
    ?.split(',')
    .map(a => a.trim().split(' '))
    .map(a => [Number(a[0]), Number(a[1])])
    .map(coord => {
      return coord
    })
}

export function isParallelogram(extent: Coordinate[] | string): boolean {
  const coords = typeof extent === 'string' ? parseExtentString(extent) : extent
  return (
    coords?.length === 5 &&
    coords[0][0] === coords[coords.length - 1][0] &&
    coords[0][1] === coords[coords.length - 1][1]
  )
}

export function getParallelogramSize(extent: string): [number, number] | null {
  if (extent != null) {
    const coords = parseExtentString(extent)
    if (isParallelogram(coords)) {
      return [
        Number(
          getDistance(toLonLat(coords[0]), toLonLat(coords[1]))?.toFixed(2),
        ),
        Number(
          getDistance(toLonLat(coords[1]), toLonLat(coords[2]))?.toFixed(2),
        ),
      ]
    }
  }
  return null
}

export function toExtentString(coords: Coordinate[]): string {
  return (
    'POLYGON((' +
    coords
      .map(a => `${a[0]} ${a[1]}`)
      .reduce((acc, curr) => `${acc}, ${curr}`) +
    '))'
  )
}

export function lineToExtentString(coords: Coordinate[]): string {
  return (
    'LINESTRING(' +
    coords
      .map(a => `${a[0]} ${a[1]}`)
      .reduce((acc, curr) => `${acc}, ${curr}`) +
    ')'
  )
}

export function isValidPolygon(polygon?: string): boolean {
  if (!polygon) {
    return false
  } else {
    const coords = parseExtentString(polygon)
    return /^POLYGON\s*\(\(([^)]+)\)\)$/g.test(polygon) && isValidCoords(coords)
  }
}

function isValidCoords(coords: Coordinate[]): boolean {
  if (coords instanceof Array && coords.length > 0 && coords[0].length === 2) {
    for (const coord of coords) {
      if (isNaN(coord[0]) || isNaN(coord[1])) {
        return false
      }
    }
    return true
  } else {
    return false
  }
}

export function getCoordsFromString(pointString: string): Coordinate | null {
  const reX = /x\s*=\s*(-?\d+.\d+)\s*/i
  const reY = /y\s*=\s*(-?\d+.\d+)\s*/i
  const x = Number(pointString.match(reX)?.[1])
  const y = Number(pointString.match(reY)?.[1])
  if (isNaN(x) || isNaN(y)) {
    return null
  } else {
    return [x, y]
  }
}

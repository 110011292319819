import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'
import { ProgressType } from '../../../../jobsites-management/jobsite-summary/models/progress/progress-type.enum'
import {
  ProgressDto,
  ProgressionBatchImportDto,
  ProgressUpdatePayload,
} from '../../../../jobsites-management/jobsite-summary/models/progress/progress.model'
import { UserPreferencesDto } from '../../../remote-services/dtos/userPreferences.dto'
import { ProgressCategory } from '../../../../jobsites-management/jobsite-summary/models/progress/progress-category.enum'
import { TechniqueNames } from '../../../remote-services/dtos/technique.dto'
import { TranslateService } from '@ngx-translate/core'
import { CsvSeparator } from '../../../../jobsites-management/settings/models/csv-separator.enum'
import { DEFAULT_CSV_PREFERENCES } from '../../../../core/models/companyCsvPreferencesInput.dto'

@Component({
  selector: 'app-jobsite-chart-edit-planned',
  templateUrl: './jobsite-chart-edit-planned-dialog.component.html',
  styleUrls: ['./jobsite-chart-edit-planned-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobsiteChartEditPlannedDialogComponent {
  constructor(private translateService: TranslateService) {}

  @Input() plannedProgress: ProgressDto[]
  @Input() userPreferences: UserPreferencesDto
  @Input() progressCategory: ProgressCategory
  @Input() progressType: ProgressType[]
  @Input() depthUnits: string
  @Input() technique: TechniqueNames
  @Input() jobsiteId: string
  @Input() csvSeparator: CsvSeparator = DEFAULT_CSV_PREFERENCES.csvSeparator
  @Input() categories: ProgressCategory[]

  @Output() closeEvent = new EventEmitter()
  @Output() updateProgressCategoryEvent = new EventEmitter<ProgressCategory>()
  @Output() plannedProgressBatch = new EventEmitter<ProgressionBatchImportDto>()
  @Output() addPlannedProgress = new EventEmitter<ProgressDto>()
  @Output() editPlannedProgress = new EventEmitter<ProgressUpdatePayload>()
  @Output() deletePlannedProgress = new EventEmitter<ProgressDto>()

  batchPlannedProgress(
    progresses: ProgressDto[],
    type: ProgressType,
    category: ProgressCategory,
  ): void {
    this.plannedProgressBatch.emit({
      progress: progresses,
      type,
      category,
    })
  }

  getCategoryHeaderTranslation(category: ProgressCategory) {
    switch (category) {
      case ProgressCategory.AREA_SIMPLE:
        return this.translateService.instant(
          'JOBSITE_MANAGEMENT.FOLLOW_UP.CHOICE_' + category,
          {
            unit: this.userPreferences.unitSystem === 'imperial' ? 'in²' : 'm²',
          },
        )
      case ProgressCategory.LENGTH:
        return this.translateService.instant(
          `JOBSITE_MANAGEMENT.FOLLOW_UP.CHOICE_${category}_${this.userPreferences.unitSystem.toUpperCase()}`,
        )
      default:
        return this.translateService.instant(
          'JOBSITE_MANAGEMENT.FOLLOW_UP.CHOICE_' + category,
        )
    }
  }
}

import { produce } from 'immer'
import { reducerFromActionHandlers } from 'app/shared/utils/redux/utils.reducers'
import * as coloringByDay from './coloringbyday/coloringbyday.reducer'
import { ColoringByDayMapping } from './coloringbyday/coloringbyday.reducer'
import { GeneralSettingsActions } from './general-settings.actions'

export interface State {
  warningRowColoration: boolean
  coloringByDay: ColoringByDayMapping
}

export const initialState: State = {
  warningRowColoration: true,
  coloringByDay: coloringByDay.initialState,
}

export const reducers = reducerFromActionHandlers(
  initialState,
  [
    {
      actionType: GeneralSettingsActions.setWarningRowColoration.CREATE,
      handler: (state, action) => {
        return produce(state, draft => {
          draft.warningRowColoration = action.payload
        })
      },
    },
  ].concat(coloringByDay.actionHandlers),
)

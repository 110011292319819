import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { FieldDto } from './dtos/field.dto'
import { TechniqueNames } from './dtos/technique.dto'

@Injectable()
export class FieldService {
  private baseUrl = 'charts/charts'

  private static returnParsedLabel(
    techniqueName: TechniqueNames,
    fieldName?: string,
  ): string {
    if (typeof fieldName === 'string') {
      if (techniqueName) {
        const fieldCode = fieldName.replace(techniqueName.toUpperCase(), '')
        return `PROJECT_MANAGEMENT.CHARTS.PROPERTIES.${fieldCode}`
      }
    }
    return fieldName
  }

  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
  ) {}

  getFields(techniqueName: TechniqueNames): Observable<FieldDto[]> {
    return this.http
      .get<FieldDto[]>(`${this.baseUrl}/${techniqueName}/fields`)
      .pipe(
        map(fields =>
          fields.map(field => ({
            ...field,
            displayName: this.translateService.instant(
              FieldService.returnParsedLabel(techniqueName, field?.name),
            ) as string,
          })),
        ),
      )
  }
}

import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { AbstractLoadableGuard } from '../../shared/utils/abstract-loadable.guard'
import { State } from '../store'
import { platformDisruptionMessageSelector } from '../store/platform-disruptions/platform-disruptions.selectors'
import { PlatformDisruptionsActions } from '../store/platform-disruptions/platform-disruptions.actions'

@Injectable()
export class PlatformDisruptionsGuard extends AbstractLoadableGuard<State> {
  constructor(store: Store<State>) {
    super(
      store,
      platformDisruptionMessageSelector.getLoaded,
      PlatformDisruptionsActions.loadPlatformDisruptionMessage,
    )
  }
}

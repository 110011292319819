import { createSelector } from '@ngrx/store'
import { getState, JobsiteSummaryState } from '../state'
import {
  columnProdSelectors,
  getAllDoneColumns,
  getColumns,
} from '../jobsite-summary.selectors'
import { ColumnDto } from '../../../../shared/remote-services/dtos/column.dto'

export const getDataScienceChartsUrls = createSelector(
  getState,
  (state: JobsiteSummaryState) => state.dataScienceChartUrls,
)

export const getDataScienceDateRange = createSelector(
  getState,
  (state: JobsiteSummaryState) => state.dataScienceDateRange,
)

export const getDataScienceFilterValues = createSelector(
  getState,
  (state: JobsiteSummaryState) => state.dataScienceFilterValues.value,
)

export const getDataScienceFilterValuesLoaded = createSelector(
  getState,
  state => state.dataScienceFilterValues.loaded,
)

export const getDataScienceFilter = createSelector(
  getState,
  state => state.dataScienceFilter,
)

export const getDataScienceFilteredIds = createSelector(
  columnProdSelectors.getValue,
  getColumns.getAll,
  getDataScienceDateRange,
  (prod, columns, range) =>
    columns
      .map(c => ({
        column: c,
        prod: prod[c.key.id] ?? null,
      }))
      .filter(({ column, prod }) => {
        return (
          ((range.startDate != null || range.endDate != null) &&
            (prod == null || prod?.endDate == null)) ||
          (range.startDate != null && range.startDate.isAfter(prod?.endDate)) ||
          (range.endDate != null && range.endDate.isBefore(prod?.endDate))
        )
      })
      .map(({ column }) => {
        return column.key.id
      }),
)

export const getDataScienceAvailablePiles = createSelector(
  getDataScienceFilteredIds,
  getAllDoneColumns,
  (filteredIds: string[], doneColumns: ColumnDto[]) =>
    doneColumns.filter(c => !filteredIds.includes(c.key.id)),
)

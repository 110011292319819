import uniqWith from 'lodash.uniqwith'
import mergeWith from 'lodash.mergewith'

export function parseObj(str: string): Record<string, unknown> | false {
  try {
    const rslt = JSON.parse(str)
    if (typeof rslt === 'object' && !Array.isArray(rslt)) {
      return rslt
    }
    return false
  } catch (error) {
    return false
  }
}

function isOldEntitiesState(
  obj: any,
): obj is { entities: Record<string, any>; ids: any[] } {
  return (
    obj?.entities &&
    typeof obj.entities === 'object' &&
    !Array.isArray(obj.entities) &&
    obj?.ids &&
    Array.isArray(obj.ids)
  )
}

function isOldListState(obj: any): obj is { list: any[] } {
  return obj?.list && typeof obj.list === 'object' && Array.isArray(obj.list)
}

function cutomMergeFn(a: any, b: any) {
  if (Array.isArray(a) && Array.isArray(b) && typeof a?.[0]?.id === 'string') {
    return uniqWith([...a, ...b], (c, d) => c?.id != null && c?.id === d?.id)
  } else if (
    Array.isArray(a) &&
    Array.isArray(b) &&
    (typeof a?.[0] !== 'object' || typeof a?.[0] !== 'undefined')
  ) {
    return [...new Set([...a, ...b])]
  }
  return undefined
}

export function convertToNewState(
  item: Record<string, unknown>,
): false | string {
  if (isOldListState(item)) {
    const { list, ...rest } = item
    return JSON.stringify(
      mergeWith(
        {
          value: [...list],
        },
        { ...rest },
        cutomMergeFn,
      ),
    )
  } else if (isOldEntitiesState(item)) {
    const { entities, ids, ...rest } = item
    return JSON.stringify(
      mergeWith(
        {
          value: { ...entities },
        },
        { ...rest },
        cutomMergeFn,
      ),
    )
  }
  return false
}

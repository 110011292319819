import { migrations } from './migrationChangeLog'

export function migrateLocalStorage(): void {
  const localStore = window.localStorage
  const currentLocalStoreVersion = localStore.getItem('localStoreVersion')
    ? parseInt(localStore.getItem('localStoreVersion'))
    : 0
  migrations
    .filter(migration => migration.localStoreVersion > currentLocalStoreVersion)
    .forEach(migration => {
      migration.migrationFn(localStore)
      localStore.setItem('localStoreVersion', `${migration.localStoreVersion}`)
      console.log(
        `local storage successfully migrated to ${migration.localStoreVersion}`,
      )
    })
}

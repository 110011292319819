import { Location } from '@angular/common'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { tap } from 'rxjs/operators'
import { State } from '../state'
import * as RouterActions from './router.actions'

@Injectable()
export class RouterEffects {
  constructor(
    private store: Store<State>,
    private actions$: Actions,
    private router: Router,
    private location: Location,
  ) {}

  navigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouterActions.GO),
        tap((action: RouterActions.Go) => {
          if (action.externalUrl) {
            // same domain but different app
            window.location.href = window.location.origin + action.payload
          } else {
            this.router.navigateByUrl(action.payload)
          }
        }),
      ),
    { dispatch: false },
  )

  navigateBack$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouterActions.BACK),
        tap(() => this.location.back()),
      ),
    { dispatch: false },
  )

  navigateForward$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouterActions.FORWARD),
        tap(() => this.location.forward()),
      ),
    { dispatch: false },
  )
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { Subscription } from 'rxjs'

@Component({
  selector: 'shared-csv-import-settings',
  templateUrl: 'shared-csv-import-settings.component.html',
  styleUrls: ['./shared-csv-import-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedCsvImportSettingsComponent implements OnInit, OnDestroy {
  @Output() toggleImportSettingPopupEvent = new EventEmitter<boolean>()
  @Output() updateCSVSeparatorEvent = new EventEmitter<string>()
  @Input() initialSeparator: string

  separatorFormControl: FormControl<string>

  separatorSubscription: Subscription

  ngOnInit(): void {
    this.separatorFormControl = new FormControl('', {
      validators: Validators.required,
      updateOn: 'blur',
    })
    this.separatorFormControl.setValue(this.initialSeparator)
    this.separatorSubscription =
      this.separatorFormControl.valueChanges.subscribe(value => {
        if (value) {
          this.updateCSVSeparatorEvent.emit(value)
        }
      })
  }

  applySettings(): void {
    this.toggleImportSettingPopupEvent.emit(false)
  }

  ngOnDestroy(): void {
    if (this.separatorSubscription) {
      this.separatorSubscription.unsubscribe()
    }
  }
}

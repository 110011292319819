/*
 * Generated type guards for "userPreferences.dto.ts".
 * WARNING: Do not manually change this file.
 */
import {
  UserLanguage,
  UnitSystem,
  DateTimeFormatsDto,
  HomePagePreferenceDto,
  UserPreferencesDto,
} from './userPreferences.dto'

export function isUserLanguage(obj: unknown): obj is UserLanguage {
  const typedObj = obj as UserLanguage
  return typedObj === 'en' || typedObj === 'fr' || typedObj === 'es'
}

export function isUnitSystem(obj: unknown): obj is UnitSystem {
  const typedObj = obj as UnitSystem
  return typedObj === 'imperial' || typedObj === 'metric'
}

export function isDateTimeFormatsDto(obj: unknown): obj is DateTimeFormatsDto {
  const typedObj = obj as DateTimeFormatsDto
  return (
    ((typedObj !== null && typeof typedObj === 'object') ||
      typeof typedObj === 'function') &&
    typeof typedObj['dateFormat'] === 'string' &&
    typeof typedObj['timeFormat'] === 'string' &&
    typeof typedObj['dateTimeFormat'] === 'string' &&
    (typeof typedObj['highChartsDateFormat'] === 'undefined' ||
      typeof typedObj['highChartsDateFormat'] === 'string')
  )
}

export function isHomePagePreferenceDto(
  obj: unknown,
): obj is HomePagePreferenceDto {
  const typedObj = obj as HomePagePreferenceDto
  return (
    ((typedObj !== null && typeof typedObj === 'object') ||
      typeof typedObj === 'function') &&
    (typeof typedObj['id'] === 'undefined' ||
      typeof typedObj['id'] === 'string') &&
    (typedObj['editingSection'] === 'follow-up' ||
      typedObj['editingSection'] === 'productivity' ||
      typedObj['editingSection'] === 'manual-records' ||
      typedObj['editingSection'] === 'visualization' ||
      typedObj['editingSection'] === 'data-table' ||
      typedObj['editingSection'] === 'daily-diary' ||
      typedObj['editingSection'] === 'kpi' ||
      typedObj['editingSection'] === 'information' ||
      typedObj['editingSection'] === 'equipments' ||
      typedObj['editingSection'] === 'instructions' ||
      typedObj['editingSection'] === 'cage' ||
      typedObj['editingSection'] === 'concrete' ||
      typedObj['editingSection'] === 'soillib-data' ||
      typedObj['editingSection'] === 'hf-energy' ||
      typedObj['editingSection'] === 'data-science' ||
      typedObj['editingSection'] === 'data-science-dwall') &&
    (typedObj['followingSection'] === 'follow-up' ||
      typedObj['followingSection'] === 'productivity' ||
      typedObj['followingSection'] === 'manual-records' ||
      typedObj['followingSection'] === 'visualization' ||
      typedObj['followingSection'] === 'data-table' ||
      typedObj['followingSection'] === 'daily-diary' ||
      typedObj['followingSection'] === 'kpi' ||
      typedObj['followingSection'] === 'information' ||
      typedObj['followingSection'] === 'equipments' ||
      typedObj['followingSection'] === 'instructions' ||
      typedObj['followingSection'] === 'cage' ||
      typedObj['followingSection'] === 'concrete' ||
      typedObj['followingSection'] === 'soillib-data' ||
      typedObj['followingSection'] === 'hf-energy' ||
      typedObj['followingSection'] === 'data-science' ||
      typedObj['followingSection'] === 'data-science-dwall') &&
    (typedObj['defaultSection'] === 'EDITING' ||
      typedObj['defaultSection'] === 'FOLLOWING')
  )
}

export function isUserPreferencesDto(obj: unknown): obj is UserPreferencesDto {
  const typedObj = obj as UserPreferencesDto
  return (
    ((typedObj !== null && typeof typedObj === 'object') ||
      typeof typedObj === 'function') &&
    (isUserLanguage(typedObj['language']) as boolean) &&
    (isUnitSystem(typedObj['unitSystem']) as boolean) &&
    typeof typedObj['dateFormat'] === 'string' &&
    (isDateTimeFormatsDto(typedObj['zlDateTimeFormats']) as boolean) &&
    typeof typedObj['zlRigPathEnabled'] === 'boolean' &&
    ((typedObj['homePage'] !== null &&
      typeof typedObj['homePage'] === 'object') ||
      typeof typedObj['homePage'] === 'function') &&
    Object.entries<any>(typedObj['homePage']).every(
      ([key, value]) =>
        (isHomePagePreferenceDto(value) as boolean) && typeof key === 'string',
    ) &&
    typeof typedObj['zlAsBuiltDescendingDate'] === 'boolean' &&
    typeof typedObj['tutoIsRead'] === 'number'
  )
}

import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { debounceTime, filter, map } from 'rxjs/operators'
import { migrateLocalStorage } from '../localStorage/migrateLocalStorage'
import { GoogleAnalyticsService } from './core/services/google-analytics.service'
import { State } from './core/store'
import { getUserCompanyName } from './core/store/companies/companies.selectors'
import {
  getJobsite,
  getJobsiteCompany,
  getJobsiteConfigurable,
  getJobsiteContractNumber,
  getJobsiteName,
} from './core/store/jobsite'
import { isLoading } from './core/store/loader/loader.selectors'
import {
  getBackUrl,
  getRouterUrl,
  getTechnique,
} from './core/store/router/router.selectors'
import {
  getPermissions,
  getUserId,
  getUserName,
  UserActions,
  userPreferencesSelector,
} from './core/store/user'
import { UserPreferencesDto } from './shared/remote-services/dtos/userPreferences.dto'
import { notEmpty } from './shared/utils/notEmpty'
import { FilesUploadActions } from './widgets/uploader-files/store/files-upload.actions'
import { isUploading } from './widgets/uploader-files/store/files-upload.selectors'
import { RouterActions } from './core/store/router/router.actions'
import { JobsiteSummaryActions } from './jobsites-management/jobsite-summary/store/jobsite-summary.actions'
import { platformDisruptionMessageSelector } from './core/store/platform-disruptions/platform-disruptions.selectors'
import { HighchartsService } from './shared/service/highcharts.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  isLoading$ = this.store.pipe(select(isLoading), debounceTime(10))
  jobsiteContractNumber$ = this.store.pipe(
    select(getJobsiteContractNumber),
    map((cn: string | undefined) => cn ?? ''),
  )
  userPreferences$ = this.store.pipe(select(userPreferencesSelector.getValue))
  backUrl$ = this.store.pipe(select(getBackUrl), debounceTime(0)) // debounceTime needed to avoid NG0100 error
  userName$ = this.store.pipe(select(getUserName))
  userId$ = this.store.pipe(select(getUserId))
  userPermission$ = this.store.select(getPermissions)
  company$ = this.store.pipe(select(getUserCompanyName))
  isUploading$ = this.store.pipe(select(isUploading))
  currentUrl$ = this.store.pipe(select(getRouterUrl))
  jobsiteConfigurable$ = this.store.pipe(select(getJobsiteConfigurable))
  jobsiteCompany$ = this.store.pipe(select(getJobsiteCompany), filter(notEmpty))
  jobsiteName$ = this.store.pipe(
    select(getJobsiteName),
    map((name: string | undefined) => `${name}` ?? ''),
  )
  currentJobsite$ = this.store.select(getJobsite)
  technique$ = this.store.select(getTechnique)

  platformDisruptionMessage$ = this.store.select(
    platformDisruptionMessageSelector.getValue,
  )

  constructor(
    private store: Store<State>,
    private googleAnalyticsService: GoogleAnalyticsService,
    private highchartsService: HighchartsService,
  ) {}

  ngOnInit(): void {
    migrateLocalStorage()
    this.googleAnalyticsService.init()
    this.highchartsService.setupDefaultChartOptions()
  }

  setTranslationLang(userPre: UserPreferencesDto): void {
    this.store.dispatch(UserActions.updateLanguage.createStart(userPre))
  }

  setTutoIsRead(userPre: UserPreferencesDto): void {
    this.store.dispatch(UserActions.updateTutoIsRead.createStart(userPre))
  }

  setUnitSystem(userPre: UserPreferencesDto): void {
    this.store.dispatch(UserActions.updateUnitSystem.create(userPre))
  }

  unloadPageEvent(): void {
    this.store.dispatch(FilesUploadActions.setVisible.create(true))
    this.store.dispatch(FilesUploadActions.setIsExpanded.create(true))
  }

  onGoToSettingsEvent(backUrl: string | null): void {
    this.store.dispatch(JobsiteSummaryActions.leaveSummary.create())
    if (backUrl) {
      this.store.dispatch(RouterActions.setBackUrl.create(backUrl))
    }
  }

  onGoToAsBuiltTemplateEvent(backUrl: string | null): void {
    if (backUrl) {
      this.store.dispatch(RouterActions.setBackUrl.create(backUrl))
    }
  }
}

import { createSelector } from '@ngrx/store'
import { getState as getCoreState, State } from '../state'

export const getFeatureToggles = createSelector(
  getCoreState,
  (state: State) => state.featureToggles,
)

export const getEnableActivityData = createSelector(
  getFeatureToggles,
  state =>
    state.enableActivityData?.userValue ?? state.enableActivityData?.envValue,
)

export const getEnableHomePagePreferences = createSelector(
  getFeatureToggles,
  state =>
    state.enableHomePagePreferences?.userValue ??
    state.enableHomePagePreferences?.envValue,
)

export const getEnableSortAsBuiltPreference = createSelector(
  getFeatureToggles,
  state =>
    state.enableSortAsBuiltPreference?.userValue ??
    state.enableSortAsBuiltPreference?.envValue,
)

export const getEnableCsvPreference = createSelector(
  getFeatureToggles,
  state =>
    state.enableCsvPreference?.userValue ?? state.enableCsvPreference?.envValue,
)

export const getEnableCesiumMapForVisualization = createSelector(
  getFeatureToggles,
  state =>
    state.enableCesiumMapForVisualization?.userValue ??
    state.enableCesiumMapForVisualization?.envValue,
)

export const getEnableDataScienceDwall = createSelector(
  getFeatureToggles,
  state =>
    state.enableDataScienceDwall?.userValue ??
    state.enableDataScienceDwall?.envValue,
)

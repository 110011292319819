<div class="flex-fill flex-layout-column" style="overflow-y: hidden">
  <app-header
    class="flex-none"
    (changeUnitSystemEvent)="setUnitSystem($event)"
    (changeLanguageEvent)="setTranslationLang($event)"
    (changeTutoIsReadEvent)="setTutoIsRead($event)"
    (goToSettingsEvent)="onGoToSettingsEvent($event)"
    (goToAsBuiltTemplateEvent)="onGoToAsBuiltTemplateEvent($event)"
    (unloadPageEvent)="unloadPageEvent()"
    [loading]="isLoading$ | async"
    [isUploading]="isUploading$ | async"
    [selectedJobsiteContractNumber]="jobsiteContractNumber$ | async"
    [userPreferences]="userPreferences$ | async"
    [backUrl]="backUrl$ | async"
    [username]="userName$ | async"
    [userId]="userId$ | async"
    [company]="company$ | async"
    [currentUrl]="currentUrl$ | async"
    [jobsiteConfigurable]="jobsiteConfigurable$ | async"
    [jobsiteCompany]="jobsiteCompany$ | async"
    [jobsiteName]="jobsiteName$ | async"
    [message]="platformDisruptionMessage$ | async"
  >
  </app-header>

  <div class="flex-grow" style="overflow-y: auto">
    <router-outlet></router-outlet>
  </div>

  <app-footer
    [userPermission]="userPermission$ | async"
    [userPreferences]="userPreferences$ | async"
    [currentJobsite]="currentJobsite$ | async"
    [technique]="technique$ | async"
  ></app-footer>
</div>
<div class="widget">
  <uploader-files-container></uploader-files-container>
</div>

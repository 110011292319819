import {
  HttpErrorResponse,
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, of, throwError } from 'rxjs'
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators'
import { KeycloakService } from './keycloak.service'

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private keycloakService: KeycloakService) {}

  private isRefreshingToken = false
  private tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(
    null,
  )

  private addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    if (!req.url.startsWith('https://maps.googleapis.com/')) {
      return req.clone({ setHeaders: { Authorization: 'Bearer ' + token } })
    }
    return req
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true

      this.tokenSubject.next(null)

      return this.keycloakService.updateToken().pipe(
        switchMap((newToken: string) => {
          if (newToken) {
            this.tokenSubject.next(newToken)
            return next.handle(this.addToken(req, newToken))
          } else {
            this.keycloakService.logout()
            return throwError('')
          }
        }),
        catchError((error: Error) => {
          return throwError(error)
        }),
        finalize(() => {
          this.isRefreshingToken = false
        }),
      )
    } else {
      return this.tokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(this.addToken(req, token))
        }),
      )
    }
  }

  private handle400Error(error) {
    if (
      error &&
      error.status === 400 &&
      error.error &&
      error.error.error === 'invalid_grant'
    ) {
      this.keycloakService.logout()
    }
    return throwError(error)
  }

  private handleHttpClientEmptyResponse(error) {
    const res = new HttpResponse({
      body: null,
      headers: error.headers,
      status: error.status,
      statusText: error.statusText,
      url: error.url,
    })
    return of(res)
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<
    | HttpSentEvent
    | HttpHeaderResponse
    | HttpProgressEvent
    | HttpResponse<any>
    | HttpUserEvent<any>
  > {
    return next
      .handle(this.addToken(req, this.keycloakService.getToken()))
      .pipe(
        catchError((error: Error) => {
          // if (error instanceof HttpErrorResponse) {
          switch ((error as HttpErrorResponse).status) {
            case 400:
              return this.handle400Error(error)
            case 401:
              return this.handle401Error(req, next)
            case 200:
              return this.handleHttpClientEmptyResponse(error)
          }
          // } else {
          return throwError(error)
          // }
        }),
      )
  }
}

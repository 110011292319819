import { ChangeDetectionStrategy, Component, Inject } from '@angular/core'
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog'
import { JobsiteChecksSettingsDto } from '../../../../shared/remote-services/dtos/jobsite-checks.model'
import { WarningChecksSettingsDialogResult } from '../../models/warning-checks-settings.model'

@Component({
  selector: 'warning-checks-settings-dialog',
  template: `
    <warning-checks-settings
      [data]="data"
      (saveEvent)="closeDialog($event)"
      (closeEvent)="closeDialog(null)"
      (resetEvent)="closeDialog($event, true)"
    ></warning-checks-settings>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WarningChecksSettingsDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<
      JobsiteChecksSettingsDto,
      WarningChecksSettingsDialogResult
    >,
    @Inject(MAT_DIALOG_DATA)
    public data: Readonly<JobsiteChecksSettingsDto>,
  ) {}

  closeDialog(
    result: JobsiteChecksSettingsDto | null,
    isReset: boolean = false,
  ): void {
    this.dialogRef.close({
      isReset,
      result: result,
    })
  }
}
